import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

enum Language {
  ARABIC = 'Arabic',
  CHINESE = 'Mandarin',
  FRENCH = 'French',
  GERMAN = 'German',
  JAPANESE = 'Japanese',
  LATIN = 'Latin',
  SPANISH = 'Spanish',
  OTHER = 'Other',
}

const useAnotherLanguage = (withOther?: boolean) => {
  const { t } = useTranslation();

  const anotherLanguages = useMemo(
    () =>
      Object.keys(Language).map((key) => ({
        label: t(`Language.${key}`),
        value: Language[key as keyof typeof Language],
      })),
    [t],
  );

  return anotherLanguages;
};

export default useAnotherLanguage;
