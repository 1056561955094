import React from 'react';
import classNames from 'classnames';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import Card from 'components/Card';
import { useTotalCardContent } from '../../hooks/useTotalCardContent';
import { useTranslation } from 'react-i18next';
import { School } from 'models/School';

import './TotalPricePreview.styles.scss';

type TotalPricePreviewProps = {
  className?: string;
  school: School;
};

const TotalPricePreview: React.FC<TotalPricePreviewProps> = (props) => {
  const { className, school } = props;

  const { t } = useTranslation();

  const classes = classNames('total-price-preview', className);

  const totalCardContent = useTotalCardContent(school);

  return (
    <Card
      title={t('Pricing.Total.title')}
      subtitle={totalCardContent.subtitle}
      className={classes}
    >
      {totalCardContent.content}
      <p className="card__hint">
        <InformationIcon />
        {t('Pricing.Total.description')}
      </p>
    </Card>
  );
};

export default TotalPricePreview;
