import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';

export default function () {
  const [currentSchooldIndex, setCurrentSchooldIndex] = useState(0);

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { schools } = schoolGroup;

  const { t } = useTranslation();

  const length = schools?.length;

  const previousStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === 0 ? length - 1 : prev - 1));
  }, [length]);

  const nextStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === length - 1 ? 0 : prev + 1));
  }, [length]);

  const list = useMemo(() => {
    if (!schools?.length) return [];

    const { city, name, postcode, country, website } =
      schools[currentSchooldIndex];

    return [
      {
        label: t('schoolName'),
        value: name,
      },
      {
        label: t('schoolWebsite'),
        value: website,
      },
      {
        label: t('cityAndCountry'),
        value: `${city} - ${country}`,
      },
      {
        label: t('postcode'),
        value: postcode,
      },
    ];
  }, [currentSchooldIndex, schools, t]);

  return { list, currentSchooldIndex, schools, nextStep, previousStep };
}
