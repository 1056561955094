import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EducationLevel,
  VacancyType,
  NonAcademicEducationLevel,
} from 'searchality-data';

const useEducationLevelOptions = (isAcademic?: boolean) => {
  const { t } = useTranslation();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const isAcademicVacancy = type === VacancyType.ACADEMIC || isAcademic;

  const educationLevelOptions = useMemo(
    () =>
      Object.entries(
        isAcademicVacancy ? EducationLevel : NonAcademicEducationLevel,
      ).map(([key, value]) => ({
        label: t(`educationLevelOptions.${key}`),
        value: value,
      })),
    [isAcademicVacancy, t],
  );

  return educationLevelOptions;
};

export default useEducationLevelOptions;
