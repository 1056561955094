import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import OrderSchoolItem from '../OrderSchoolItem';
import { SchoolGroupStructureType } from 'searchality-data';
import ValueContext from 'providers/ValueContext/Value.context';
import { numberPriceToCurrencyString } from 'utils';
import DateService from 'services/Date.service';
import confirm from 'modules/confirm';
import OrderSchoolGroupItem from '../OrderSchoolGroupItem';
import { calculatePricePerYear } from '../../utils';
import PageLoader from 'components/PageLoader';
import CheckoutContext from 'providers/Checkout/Checkout.context';

import './OrderSummary.styles.scss';

type OrderSummaryProps = {
  className?: string;
};

const OrderSummary: React.FC<OrderSummaryProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);
  const { schools: userSchools, structureType } = schoolGroup || {};

  const reduxSchools = useAppSelector(
    ({ addNewSchool }) => addNewSchool.schools,
  );

  const { manageId, selectedIds } = useContext(ValueContext);
  const { stripePreviewObject, previewLoading, isSubscriptionCheckout } =
    useContext(CheckoutContext);

  const classes = classNames('order-summary', className);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const defaultStringForPricing = isStandalone
    ? 'The total of you annual subscription will be <bold>{{price}}</bold>/year after completing the setup. Once you confirm this subscription the next payment will be scheduled for <bold>{{date}}</bold>'
    : 'The total of your annual subscription will be <bold>{{price}}</bold>/year including your previous plan and new school(s) added. Once you confirm this subscription the next payment will be scheduled for <0>{{date}}</0>';

  const schools = useMemo(
    () => (isSubscriptionCheckout ? userSchools : reduxSchools),
    [isSubscriptionCheckout, reduxSchools, userSchools],
  );

  const onChange = useCallback(
    async (_id: string) => {
      if (
        schools?.length <= 2 &&
        selectedIds?.includes(_id) &&
        isSubscriptionCheckout
      ) {
        await confirm({
          title: t('OrderSummary.includedInSubPlanTitle'),
          content: <p>{t('OrderSummary.includedInSubPlanDescription')}</p>,
          onSubmit: () => {
            manageId(_id);
          },
        });
      } else {
        manageId(_id);
      }
    },
    [isSubscriptionCheckout, manageId, schools?.length, selectedIds, t],
  );

  const schoolsForSchoolItems = useMemo(() => {
    const sliceCondition = isSubscriptionCheckout ? 2 : undefined;

    return schools?.slice(sliceCondition);
  }, [isSubscriptionCheckout, schools]);

  const schoolNamesIncluded = useMemo(() => {
    return (
      isSubscriptionCheckout &&
      schools
        ?.slice(0, 2)
        .map((school) => school.name)
        .join(', ')
    );
  }, [isSubscriptionCheckout, schools]);

  return (
    <div className={classes}>
      {previewLoading ? (
        <PageLoader />
      ) : (
        <>
          <p className="order-summary__title">{t('OrderSummary.title')}</p>
          {stripePreviewObject?.discount && (
            <p className="order-summary__coupon">
              {stripePreviewObject?.discount?.coupon?.metadata?.description}
            </p>
          )}
          <div className="order-summary__cart">
            {isSubscriptionCheckout && (
              <OrderSchoolGroupItem schoolNamesIncluded={schoolNamesIncluded} />
            )}
            {structureType !== SchoolGroupStructureType.STANDALONE &&
              schoolsForSchoolItems?.map((school, index) => (
                <OrderSchoolItem
                  key={school?.name}
                  {...school}
                  onChange={onChange}
                  includedInGroupPrice={
                    isSubscriptionCheckout
                      ? false
                      : userSchools?.length + index < 2
                  }
                />
              ))}
          </div>
          <p className="order-summary__subtotal">
            {t('subtotal')}
            <span>
              {numberPriceToCurrencyString(stripePreviewObject?.subtotal)}
            </span>
          </p>
          <p className="order-summary__subtotal">
            {t('vat', {
              procent: (
                (stripePreviewObject?.tax / stripePreviewObject?.amount_due) *
                100
              ).toFixed(1),
            })}
            <span>{numberPriceToCurrencyString(stripePreviewObject?.tax)}</span>
          </p>
          <p className="order-summary__total-price">
            {t('OrderSummary.totalPriceSum')}
            <span>
              {numberPriceToCurrencyString(stripePreviewObject?.amount_due)}
            </span>
          </p>
          {isSubscriptionCheckout && (
            <p className="order-summary__total-price-description">
              <Trans
                i18nKey={`OrderSummary.totalPrice${
                  isStandalone ? 'Standalone' : ''
                }`}
                values={{
                  price: `${numberPriceToCurrencyString(
                    calculatePricePerYear(stripePreviewObject),
                  )}`,
                  date: `${DateService.getUSADateFormatLong(
                    DateService.addYearToCurrentDate(),
                  )}`,
                }}
                components={{ bold: <strong style={{ fontWeight: 700 }} /> }}
                defaults={defaultStringForPricing}
              />
            </p>
          )}
          {!isStandalone && isSubscriptionCheckout && (
            <p className="order-summary__total-price-description">
              {t('OrderSummary.legend')}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default OrderSummary;
