import { useTranslation } from 'react-i18next';
import useHiringPreferencesList from './useHiringPreferencesList';
import useHostCountryRequiremets from './useHostCountryRequiremets';
import useContractInformationList from './useContractInformationList';
import useBenefitsList from './useBenefitsList';

export default () => {
  const { t } = useTranslation();

  const hiringPreferencesList = useHiringPreferencesList();
  const hostCountryRequirementsList = useHostCountryRequiremets();
  const contractInformationList = useContractInformationList();
  const benefitsList = useBenefitsList();

  return [
    {
      list: hiringPreferencesList,
      to: '/my-account/hiring-preferences-settings/school-profile/hiring-preferences',
      title: t('SchoolProfileBuilder.hiringPreferencesTitle'),
    },
    {
      list: hostCountryRequirementsList,
      to: '/my-account/hiring-preferences-settings/school-profile/host-country-requirements',
      title: t('SchoolProfileBuilder.hostCountryRequirementsTitle'),
    },
    {
      list: contractInformationList,
      to: '/my-account/hiring-preferences-settings/school-profile/contract-information',
      title: t('SchoolProfileBuilder.contractInformationTitle'),
    },
    {
      list: benefitsList,
      to: '/my-account/hiring-preferences-settings/school-profile/benefits',
      title: t('SchoolProfileBuilder.benefitsTitle'),
    },
  ];
};
