import { ComplexRoute } from 'router/components/Routes/Routes';
import SpbGetStarted from './pages/SpbGetStarted';
import SpbHiringPreferences from './pages/SpbHiringPreferences';
import SpbHostCountryRequirements from './pages/SpbHostCountryRequirements';
import SpbContractInformation from './pages/SpbContractInformation';
import SpbBenefits from './pages/SpbBenefits';

export default [
  {
    element: SpbHiringPreferences,
    path: 'hiring-preferences',
  },
  {
    element: SpbHostCountryRequirements,
    path: 'host-country-requirements',
  },
  {
    element: SpbContractInformation,
    path: 'contract-information',
  },
  {
    element: SpbBenefits,
    path: 'benefits',
  },
  {
    index: true,
    element: SpbGetStarted,
  },
] as Array<ComplexRoute>;
