import { Dispatch, SetStateAction, createContext } from 'react';
import { PaymentMethod, PreviewSubscriptionPrice } from 'types';

export type CheckoutContextProps = {
  subscribe: (skipPayment: boolean) => void;
  purchaseOrder: string;
  setPurchaseOrder: Dispatch<SetStateAction<string>>;
  checked: string;
  setChecked: React.Dispatch<React.SetStateAction<string>>;
  checkedItem: PaymentMethod;
  isLoading: boolean;
  stripePreviewObject: PreviewSubscriptionPrice;
  previewLoading: boolean;
  isSubscriptionCheckout: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export default createContext<Partial<CheckoutContextProps>>({});
