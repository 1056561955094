import { getFileFromURL } from 'components/ImageCropper/utils';
import {
  MAX_ACADEMIC_SALARY,
  MAX_NON_ACADEMIC_SALARY,
} from 'constants/general';
import { HiringPreferences } from 'models/School';
import { User } from 'models/User';
import {
  CurriculumExp,
  CurriculumExpForm,
  Vacancy,
  VacancyFormValues,
} from 'models/Vacancy';
import {
  ApplicantStage,
  Language,
  NonAcademicVacancyCategory,
  SchoolGroupStructureType,
  SubjectGradeLevel,
  VacancyType,
} from 'searchality-data';
import { countryCurrencyObject } from '../pages/VacancyWizard/constants';

/**
 * Covert Curriculum experience from form values to vacancy object
 * @param curriculumExperience d
 * @param curriculumExperienceOther
 * @returns
 */
const mapCurriculumExperienceIntoVacancy = (curricula: CurriculumExpForm[]) => {
  return curricula.map(
    ({ curriculumExperience, yearsOfCurriculumExperienceRequired }) => ({
      curriculumExperience,
      yearsOfCurriculumExperienceRequired: parseInt(
        yearsOfCurriculumExperienceRequired,
      ),
    }),
  );
};

/**
 * Map vacancy curriculumExperience value into form
 * @param curriculumExperience
 * @returns
 */
const mapCurriculumExperienceIntoForm = (curricula: CurriculumExp[]) => {
  return curricula?.map(
    ({ yearsOfCurriculumExperienceRequired, curriculumExperience }) => ({
      curriculumExperience,
      yearsOfCurriculumExperienceRequired:
        yearsOfCurriculumExperienceRequired?.toString(),
    }),
  );
};

const teacherLanguageParseValue = (
  gradeLevel: string,
  teacherLanguage: string,
) => {
  if (
    gradeLevel === SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE ||
    gradeLevel === SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.FOREIGN_LANGUAGE
  ) {
    if (
      Object.keys(Language).some(
        (key) => Language[key as keyof typeof Language] === teacherLanguage,
      )
    ) {
      return teacherLanguage;
    }
    return 'Other';
  }
  return undefined;
};

/**
 *
 */
const convertFormIntoVacancy = (
  formValues: VacancyFormValues,
  type: VacancyType,
  standaloneSchoolId?: string,
  user?: User,
) => {
  const { disableAdd, ...rest } = formValues || {};
  const {
    isMultipleSchools,
    schoolId,
    schoolIds,
    immediately,
    teacherLanguage,
    teacherLanguageOther,
    curricula,
    yearsOfExperienceRequired,
    numberOfReferences,
    salaryInfo,
    applicantStageOther,
    studentContactDays,
    studentNonContactDays,
    schools,
    category,
    categoryOther,
    positionDescription,
    subjectGradeLevel,
    boardingRoleDescription,
    boardingPositionBenefits,
    minimumAge,
    maximumAge,
    adminContractedWorkingDays,
    adminEndDate,
    adminStartDate,
    teacherContractedWorkingDays,
    teacherEndDate,
    teacherStartDate,
    ...restOfValues
  } = (rest as VacancyFormValues) || {};

  const vacancy: Partial<Vacancy> = {
    ...restOfValues,
    subjectGradeLevel,
  };

  vacancy.type = type;

  if (user?.schoolGroup?.residentialType) {
    vacancy.residentialType = user?.schoolGroup?.residentialType;
  }

  //SCHOOL-INFORMATION
  if (user?.schoolGroup.structureType === SchoolGroupStructureType.STANDALONE) {
    vacancy.schools = user?.schools;
  } else {
    vacancy.schools = user?.schools?.filter((el) => {
      if (isMultipleSchools) {
        return schoolIds?.some((id) => id === el._id);
      }
      return schoolId === el._id;
    });
  }
  if (standaloneSchoolId) {
    vacancy.schoolIds = [standaloneSchoolId];
  } else {
    vacancy.schoolIds = isMultipleSchools ? schoolIds : [schoolId];
  }

  //POSITION_INFORMATION
  if (immediately) {
    vacancy.startDate = null;
  }
  if (category) {
    if (category !== NonAcademicVacancyCategory.OTHER) {
      vacancy.category = category;
    } else {
      vacancy.category = categoryOther;
    }
  }
  if (
    subjectGradeLevel ===
      SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.PARAPROFESSIONAL_ELEMENTARY.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.PARAPROFESSIONAL_MIDDLE_SCHOOL.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.FOREIGN_LANGUAGE
  ) {
    if (teacherLanguage === 'Other') {
      vacancy.teacherLanguage = teacherLanguageOther;
    } else {
      vacancy.teacherLanguage = teacherLanguage;
    }
  }
  if (positionDescription?.length) {
    vacancy.positionDescriptionUrl = URL.createObjectURL(
      positionDescription[0],
    );
  }

  //POSITION-REQUIREMENTS

  if (
    boardingPositionBenefits &&
    JSON.stringify(boardingPositionBenefits) !== '{}'
  ) {
    vacancy.boardingPositionBenefits = {
      ...(boardingPositionBenefits.salary && {
        salary: parseInt(boardingPositionBenefits.salary),
      }),
      additionalBenefits: boardingPositionBenefits.additionalBenefits,
    };
  }
  if (boardingRoleDescription?.length) {
    vacancy.boardingPositionRequirementsUrl = URL.createObjectURL(
      boardingRoleDescription[0],
    );
  }

  if (yearsOfExperienceRequired) {
    vacancy.yearsOfExperienceRequired = parseInt(yearsOfExperienceRequired);
  }

  if (
    curricula?.length &&
    (rest as VacancyFormValues)?.isSpecificCurriculumExperienceRequired
  ) {
    vacancy.curricula = mapCurriculumExperienceIntoVacancy(curricula);
  }

  if (numberOfReferences) {
    vacancy.numberOfReferences = parseInt(numberOfReferences);
  }

  //HOST-COUNTRY-REQUIREMENTS
  if (minimumAge) {
    vacancy.minimumAge = parseInt(minimumAge);
  }
  if (maximumAge) {
    vacancy.maximumAge = parseInt(maximumAge);
  }

  //CONTRACT-INFORMATION

  if (type === VacancyType.NON_ACADEMIC) {
    vacancy.adminContractedWorkingDays = adminContractedWorkingDays;
    vacancy.adminEndDate = adminEndDate;
    vacancy.adminStartDate = adminStartDate;
  } else {
    vacancy.teacherContractedWorkingDays = teacherContractedWorkingDays;
    vacancy.teacherEndDate = teacherEndDate;
    vacancy.teacherStartDate = teacherStartDate;
  }

  //BENEFITS
  if (studentContactDays) {
    vacancy.studentContactDays = parseInt(studentContactDays);
  }
  if (studentNonContactDays) {
    vacancy.studentNonContactDays = parseInt(studentNonContactDays);
  }

  //SALARY-INFO
  if (salaryInfo?.from !== undefined && salaryInfo?.to !== undefined) {
    vacancy.salaryInfo = {
      ...salaryInfo,
      from: parseInt(salaryInfo.from),
      to: parseInt(salaryInfo.to),
      applicantStage:
        salaryInfo.applicantStage === 'Other'
          ? applicantStageOther
          : salaryInfo.applicantStage,
    };
  }

  return vacancy;
};

const isCategoryInEnum = (category: string) => {
  return Object.values(NonAcademicVacancyCategory).some(
    (value) => value === category,
  );
};
const convertVacancyBenefits = (vacancy: Partial<Vacancy>) => {
  const {
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isTravelAllowanceOffered,
    travelAllowanceOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isHousingOffered,
    housingOffered,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isLeaveAllowanceOffered,
    leaveAllowanceOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    studentContactDays,
    studentNonContactDays,
  } = vacancy;

  return {
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isTravelAllowanceOffered,
    travelAllowanceOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isHousingOffered,
    housingOffered,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isLeaveAllowanceOffered,
    leaveAllowanceOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    studentContactDays: studentContactDays?.toString(),
    studentNonContactDays: studentNonContactDays?.toString(),
  };
};

const convertVacancyHCR = (vacancy: Partial<Vacancy>) => {
  const {
    educationalLevelRequired,
    healthRequirements,
    minimumYearsOfExperienceRequired,
    preferredPassports,
    maximumAge,
    minimumAge,
  } = vacancy;

  return {
    educationalLevelRequired,
    healthRequirements,
    minimumYearsOfExperienceRequired,
    preferredPassports,
    maximumAge: maximumAge?.toString(),
    minimumAge: minimumAge?.toString(),
  };
};

const convertVacancyPositionInformation = async (vacancy: Partial<Vacancy>) => {
  const {
    positionTitle,
    positionType,
    positionDivision,
    subjectGradeLevel,
    teacherLanguage,
    roleDescription,
    positionDescriptionUrl,
    startDate,
    isFullTimeEmployment,
    amountOfTimeRequired,
    isPermanentContract,
    lengthOfContract,
    category,
  } = vacancy;

  const positionInformationFormFields = {
    subjectGradeLevel,
    positionTitle,
    positionType,
    positionDivision,
    startDate: startDate ?? undefined,
    immediately: startDate === null ? true : false,
    isFullTimeEmployment,
    amountOfTimeRequired,
    isPermanentContract,
    lengthOfContract,
    roleDescription,
    teacherLanguage: teacherLanguageParseValue(
      subjectGradeLevel,
      teacherLanguage,
    ),
    teacherLanguageOther:
      teacherLanguageParseValue(subjectGradeLevel, teacherLanguage) === 'Other'
        ? teacherLanguage
        : undefined,
    category: isCategoryInEnum(category)
      ? (category as NonAcademicVacancyCategory)
      : NonAcademicVacancyCategory.OTHER,

    categoryOther: isCategoryInEnum(category) ? undefined : category,
    positionDescription: positionDescriptionUrl
      ? [
          await getFileFromURL(
            positionDescriptionUrl,
            'Position Description',
            'application/pdf',
          ),
        ]
      : [],
  };

  return positionInformationFormFields;
};

const convertVacancyPositionRequirements = async (
  vacancy: Partial<Vacancy>,
) => {
  const {
    educationLevel,
    certificates,
    isCoverLetterRequired,
    isResumeRequired,
    isEducationPhilosophyRequired,
    curricula,
    isSecondLanguageRequired,
    requiredLanguages,
    isRightToWorkRequired,
    boardingPositionRequirementStatus,
    boardingPositionRequirements,
    numberOfReferences,
    yearsOfExperienceRequired,
    isSpecificCurriculumExperienceRequired,
    boardingPositionBenefits,
    boardingPositionRequirementsUrl,
  } = vacancy;

  return {
    boardingPositionRequirementStatus,
    boardingPositionBenefits: {
      salary: boardingPositionBenefits?.salary.toString(),
      additionalBenefits: boardingPositionBenefits?.additionalBenefits,
    },
    isEducationPhilosophyRequired,
    educationLevel,
    isCoverLetterRequired,
    isResumeRequired,
    numberOfReferences: numberOfReferences?.toString(),
    yearsOfExperienceRequired: yearsOfExperienceRequired?.toString(),
    isSpecificCurriculumExperienceRequired,
    ...(curricula?.length && {
      curricula: mapCurriculumExperienceIntoForm(curricula),
    }),
    isSecondLanguageRequired,
    requiredLanguages,
    isRightToWorkRequired,
    certificates,
    ...(boardingPositionRequirements && {
      boardingPositionRequirements: {
        roleDescription: boardingPositionRequirements.roleDescription,
      },
    }),
    boardingRoleDescription: boardingPositionRequirementsUrl
      ? [
          await getFileFromURL(
            boardingPositionRequirementsUrl,
            'Boarding position Description',
            'application/pdf',
          ),
        ]
      : [],
  };
};

const convertVacancyContractInformation = (vacancy: Partial<Vacancy>) => {
  const {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
  } = vacancy;

  return {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
  };
};

async function convertVacancyIntoForm(
  vacancy: Partial<Vacancy>,
  includeStatus = true,
) {
  const {
    _id,
    schoolIds,
    schools,
    status,
    type,
    salaryInfo,
    matchingInformation,
  } = vacancy;

  const isMultipleSchools = schoolIds?.length > 0;

  const salaryInfoTo =
    type === VacancyType.ACADEMIC
      ? MAX_ACADEMIC_SALARY
      : MAX_NON_ACADEMIC_SALARY;

  const formValues: VacancyFormValues = {
    ...convertVacancyBenefits(vacancy),
    ...convertVacancyHCR(vacancy),
    ...(await convertVacancyPositionInformation(vacancy)),
    ...(await convertVacancyPositionRequirements(vacancy)),
    ...convertVacancyContractInformation(vacancy),
    _id,
    schools,
    isMultipleSchools,
    matchingInformation: {
      isMatchingActive: matchingInformation?.isMatchingActive,
    },
    salaryInfo: {
      ...salaryInfo,
      from: salaryInfo?.from?.toString() ?? '0',
      to: salaryInfo?.to?.toString() ?? salaryInfoTo.toString(),
      ...(salaryInfo?.applicantStage && {
        applicantStage: Object.keys(ApplicantStage).some(
          (el) =>
            ApplicantStage[el as keyof typeof ApplicantStage] ===
            salaryInfo?.applicantStage,
        )
          ? salaryInfo?.applicantStage
          : 'Other',
      }),
    },
    applicantStageOther: Object.keys(ApplicantStage).some(
      (el) =>
        ApplicantStage[el as keyof typeof ApplicantStage] ===
        salaryInfo?.applicantStage,
    )
      ? undefined
      : salaryInfo?.applicantStage,
  };

  if (isMultipleSchools) {
    formValues.schoolIds = schoolIds;
  } else {
    formValues.schoolId = schoolIds[0];
  }

  if (includeStatus) {
    formValues.status = status;
  }

  return formValues;
}

const convertHPIntoForm = (
  hiringPreferences: HiringPreferences,
  country: string,
  type: VacancyType,
) => {
  const {
    minimumAge,
    maximumAge,
    studentContactDays,
    studentNonContactDays,
    ...rest
  } = hiringPreferences;

  const salaryInfoTo =
    type === VacancyType.ACADEMIC
      ? MAX_ACADEMIC_SALARY
      : MAX_NON_ACADEMIC_SALARY;

  return {
    ...rest,
    studentContactDays: studentContactDays?.toString(),
    studentNonContactDays: studentNonContactDays?.toString(),
    minimumAge: minimumAge?.toString(),
    maximumAge: maximumAge?.toString(),
    ...(countryCurrencyObject[
      country as keyof typeof countryCurrencyObject
    ] && {
      salaryInfo: {
        currency:
          countryCurrencyObject[country as keyof typeof countryCurrencyObject],
        from: '0',
        to: salaryInfoTo.toString(),
      },
    }),
  };
};

export default {
  convertFormIntoVacancy,
  convertVacancyIntoForm,
  convertHPIntoForm,
};
