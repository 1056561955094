import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return [
    {
      name: 'isAcceptingSingleCandidates',
      label: t('SchoolProfileBuilder.HiringPreferences.label1'),
    },
    {
      name: 'isAcceptingSingleWithNonTeachingPartner',
      label: t('SchoolProfileBuilder.HiringPreferences.label2'),
    },
    {
      name: 'isAcceptingSingleWithTeachingPartner',
      label: t('SchoolProfileBuilder.HiringPreferences.label3'),
    },
    {
      name: 'isAcceptingMarriedWithNonTeachingPartner',
      label: t('SchoolProfileBuilder.HiringPreferences.label4'),
    },
    {
      name: 'isAcceptingMarriedWithTeachingPartner',
      label: t('SchoolProfileBuilder.HiringPreferences.label5'),
    },
    {
      name: 'isAcceptingWithChildren',
      label: t('SchoolProfileBuilder.HiringPreferences.label6'),
    },
  ];
};
