import React from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import CheckoutPaymentSubscribtionWrapper from '../../components/CheckoutPaymentSubscribtionWrapper';
import DiscountCodeWrapper from '../../components/DiscountCodeWrapper';

import './Checkout.styles.scss';

type CheckoutProps = {
  className?: string;
};

const Checkout: React.FC<CheckoutProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('checkout', className);

  const {
    schoolGroup: { schools },
  } = useAppSelector(authSelectors.selectUser);

  return (
    <div className={classes}>
      <GoBackButton to="../" />
      <h1>{t('CheckoutPage.title')}</h1>
      <p>{t('CheckoutPage.description')}</p>
      <ValueContextProvider
        isMultiple
        initialIds={schools?.map(({ _id }) => _id)}
      >
        <DiscountCodeWrapper>
          <CheckoutPaymentSubscribtionWrapper />
        </DiscountCodeWrapper>
      </ValueContextProvider>
    </div>
  );
};

export default Checkout;
