import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button, Input } from 'ncoded-component-library';
import DiscountCode from '../DiscountCode';
import { useTranslation } from 'react-i18next';
import PaymentMethods from '../PaymentMethods';
import OrderSummary from '../OrderSummary';
import Anim from 'components/animations';
import useAppSelector from 'hooks/useAppSelector';
import { Elements } from '@stripe/react-stripe-js';
import StripeSubscribe from '../StripeSubscribe';
import CheckoutContext from 'providers/Checkout/Checkout.context';
import { loadStripe } from '@stripe/stripe-js';
import env from 'env';

import './CheckoutPayment.styles.scss';

type CheckoutPaymentProps = {
  className?: string;
};

const CheckoutPayment: React.FC<CheckoutPaymentProps> = (props) => {
  const { className } = props;

  const {
    checkedItem,
    checked,
    setChecked,
    setPurchaseOrder,
    purchaseOrder,
    subscribe,
    isLoading,
    isSubscriptionCheckout,
  } = useContext(CheckoutContext);

  const clientSecret = useAppSelector(
    ({ payment }) => payment?.clientSecret ?? '',
  );

  const stripePromise = loadStripe(env.STRIPE_KEY);

  const { t } = useTranslation();

  const classes = classNames('checkout-payment', className);

  return (
    <div className={classes}>
      <div className="checkout-payment__left">
        <Anim.Collapse active={checkedItem?.type === 'card'}>
          <div className="checkout-payment__left__billing">
            <p className="heading">{t('billingAddress')}</p>
            {checkedItem?.type === 'card' && (
              <p className="desc">{`${checkedItem.billingAddress?.line1}, ${checkedItem.billingAddress?.city}\n${checkedItem.billingAddress?.postal_code}, ${checkedItem.billingAddress?.country}`}</p>
            )}
          </div>
        </Anim.Collapse>
        <PaymentMethods checked={checked} onClick={setChecked} />
        <DiscountCode />
        <Input
          innerLabel={t('purchaseOrderOptinal')}
          value={purchaseOrder}
          onChange={(ev) => setPurchaseOrder(ev.target.value)}
        />
        <p className="descriptive">{t('purchaseOrderNumberDescription')}</p>
        <div>
          <Button
            disabled={!checked || isLoading}
            onClick={() => subscribe(false)}
          >
            {t('confirmPayment')}
          </Button>
          {!isSubscriptionCheckout && (
            <Button
              disabled={isLoading}
              variant="outline"
              onClick={() => subscribe(true)}
              style={{ marginLeft: 16 }}
            >
              {t('skipPayment')}
            </Button>
          )}
        </div>
      </div>
      <div className="checkout-payment__right">
        <OrderSummary />
      </div>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <StripeSubscribe selectedPaymentMethodId={checked} />
        </Elements>
      )}
    </div>
  );
};

export default CheckoutPayment;
