import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolResidentialType from '../../pages/SchoolResidentialType';

export default [
  // {
  //   path: 'residential-type/:residentialType',
  //   element: PublicSchoolSubtypeForm,
  // },
  {
    path: 'residential-type',
    element: SchoolResidentialType,
  },
  {
    index: true,
    element: <Navigate to="residential-type" replace />,
  },
] as Array<ComplexRoute>;
