import auth from './auth';
import user from './user';
import schools from './schools';
import vacancies from './vacancies';
import places from './places';
import applications from './applications';
import candidates from './candidates';
import teachersAuth from './teachersAuth';

export default {
  auth,
  user,
  schools,
  vacancies,
  places,
  applications,
  candidates,
  teachersAuth,
};
