import { useLazyPreviewSubscriptionPriceQuery } from 'api/payment.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import ValueContext from 'providers/ValueContext/Value.context';
import { useCallback, useContext, useEffect } from 'react';
import authSelectors from 'store/selectors/auth.selectors';
import { updateCouponValue } from 'store/slices/payment.slice';
import { popServerError } from 'store/slices/popNotifications.slice';

const usePreviewSubscriptionPrice = () => {
  const [previewSubscriptionPrice, { data, isLoading }] =
    useLazyPreviewSubscriptionPriceQuery();

  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(({ payment }) => payment.coupon);

  const { selectedIds } = useContext(ValueContext);
  const {
    schoolGroup: { schools },
  } = useAppSelector(authSelectors.selectUser);

  const handlePreviewSubscriptionPrice = useCallback(async () => {
    const schoolsForPricingPreview = schools?.map(({ _id }) => ({
      id: _id,
      optOutIntegration: !selectedIds?.includes(_id),
    }));
    previewSubscriptionPrice(
      { schools: schoolsForPricingPreview, promoCode },
      true,
    )
      .unwrap()
      .then((res) => {
        if (res?.isPromoCodeValid === false) {
          dispatch(updateCouponValue(undefined));
        }
      })
      .catch((e) => {
        dispatch(popServerError(e));
        dispatch(updateCouponValue(undefined));
      });
  }, [dispatch, previewSubscriptionPrice, promoCode, schools, selectedIds]);

  useEffect(() => {
    if (schools) {
      handlePreviewSubscriptionPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePreviewSubscriptionPrice]);

  return { data, isLoading };
};

export default usePreviewSubscriptionPrice;
