import { ComplexRoute } from 'router/components/Routes/Routes';
import vacancyRoutes from './subrouters/Vacancies/Vacancy.routes';
import candidatesRoutes from './subrouters/Candidates/Candidates.routes';
import MyAccountRoutes from './subrouters/MyAccount/MyAccount.routes';
import { createSchoolRoutes } from './subrouters/CreateSchool';
import TeacherProfileBuilderRoutes from './subrouters/TeacherProfileBuilder/TeacherProfileBuilder.routes';
import { applicationsRoutes } from './subrouters/Applications';
import TeacherAccountRoutes from './subrouters/TeacherAccount/TeacherAccount.routes';
import { Role } from 'searchality-data';
import MembersRoutes from './subrouters/Members/Members.routes';
// import AssociationAccountRoutes from './subrouters/AssociationAccount/AssociationAccount.routes';
import React, { Suspense } from 'react';
import PageLoader from 'components/PageLoader';

const CandidatesRouter = React.lazy(() => import('./subrouters/Candidates'));
const VacanciesRouter = React.lazy(() => import('./subrouters/Vacancies'));
const TeacherAccount = React.lazy(() => import('./subrouters/TeacherAccount'));
const MyAccount = React.lazy(() => import('./subrouters/MyAccount'));
const CreateSchool = React.lazy(() => import('./subrouters/CreateSchool'));
const TeacherProfile = React.lazy(() => import('./subrouters/TeacherProfile'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));

const EmailConfirmation = React.lazy(
  () => import('router/subrouters/Dashboard/pages/EmailConfirmation'),
);

// const AssociationMembers = React.lazy(
//   () => import('./pages/AssociationMembers'),
// );
// const AssociationSchools = React.lazy(
//   () => import('./pages/AssociationSchools'),
// );
// const AssociationAccount = React.lazy(
//   () => import('./subrouters/AssociationAccount/AssociationAccount.router'),
// );
const MembersRouter = React.lazy(
  () => import('./subrouters/Members/Members.router'),
);
const ApplicationsRouter = React.lazy(
  () => import('./subrouters/Applications'),
);
const TeacherProfileBuilderRouter = React.lazy(
  () =>
    import('./subrouters/TeacherProfileBuilder/TeacherProfileBuilder.router'),
);

export default [
  {
    path: 'vacancies',
    element: (
      <Suspense fallback={<PageLoader />}>
        <VacanciesRouter />
      </Suspense>
    ),
    routes: vacancyRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.HiringManager,
      Role.AccountManager,
      Role.Reviewer,
    ],
  },
  {
    path: 'candidates',
    element: (
      <Suspense fallback={<PageLoader />}>
        <CandidatesRouter />
      </Suspense>
    ),
    routes: candidatesRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.HiringManager,
      Role.AccountManager,
      Role.Reviewer,
    ],
  },
  {
    path: 'members',
    element: (
      <Suspense fallback={<PageLoader />}>
        <MembersRouter />
      </Suspense>
    ),
    routes: MembersRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.HiringManager,
      Role.AccountManager,
    ],
  },

  {
    path: 'profile',
    authorizedRoles: [Role.Teacher],
    element: (
      <Suspense fallback={<PageLoader />}>
        <TeacherProfile />
      </Suspense>
    ),
  },
  {
    path: 'applications',
    element: (
      <Suspense fallback={<PageLoader />}>
        <ApplicationsRouter />
      </Suspense>
    ),
    routes: applicationsRoutes,
    authorizedRoles: [Role.Teacher],
  },
  {
    path: 'profile/edit',
    element: (
      <Suspense fallback={<PageLoader />}>
        <TeacherProfileBuilderRouter />
      </Suspense>
    ),
    routes: TeacherProfileBuilderRoutes,
    authorizedRoles: [Role.Teacher],
  },
  {
    path: 'teacher-account',
    element: (
      <Suspense fallback={<PageLoader />}>
        <TeacherAccount />
      </Suspense>
    ),
    routes: TeacherAccountRoutes,
    authorizedRoles: [Role.Teacher],
  },
  {
    path: 'my-account',
    element: (
      <Suspense fallback={<PageLoader />}>
        <MyAccount />
      </Suspense>
    ),
    routes: MyAccountRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.HiringManager,
      Role.AccountManager,
      Role.Reviewer,
    ],
  },
  {
    path: 'create-school',
    element: (
      <Suspense fallback={<PageLoader />}>
        <CreateSchool />
      </Suspense>
    ),
    routes: createSchoolRoutes,
    authorizedRoles: [Role.AccountOwner],
  },
  // {
  //   path: 'schools',
  //   element: (
  //     <Suspense fallback={<PageLoader />}>
  //       <AssociationSchools />
  //     </Suspense>
  //   ),
  //   authorizedRoles: [Role.AssociationOwner, Role.AssociationMember],
  // },
  // {
  //   path: 'association-members',
  //   element: (
  //     <Suspense fallback={<PageLoader />}>
  //       <AssociationMembers />
  //     </Suspense>
  //   ),
  //   authorizedRoles: [Role.AssociationOwner, Role.AssociationMember],
  // },
  // {
  //   path: 'association-account',

  //   element: (
  //     <Suspense fallback={<PageLoader />}>
  //       <AssociationAccount />
  //     </Suspense>
  //   ),
  //   routes: AssociationAccountRoutes,
  //   authorizedRoles: [Role.AssociationOwner, Role.AssociationMember],
  // },
  {
    path: 'contact-us',
    element: (
      <Suspense fallback={<PageLoader />}>
        <ContactUs />
      </Suspense>
    ),
  },
  {
    path: 'confirm-email',
    element: (
      <Suspense fallback={<PageLoader />}>
        <EmailConfirmation />
      </Suspense>
    ),
    validate: ({ auth: { user } }) => {
      if (user?.emailConfirmed) {
        return '/';
      }
    },
  },
  {
    index: true,
    validate: ({ auth: { user } }) => {
      if (!user?.emailConfirmed) {
        return 'confirm-email';
      }
      if (user?.role === Role.AccountOwner && !user?.schoolGroupId) {
        return 'create-school';
      }
      // if (
      //   [Role.AssociationOwner, Role.AssociationMember].includes(user?.role)
      // ) {
      //   return 'schools';
      // }
      return user?.role === Role.Teacher ? 'profile' : 'vacancies';
    },
  },
] as Array<ComplexRoute>;
