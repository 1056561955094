import React from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { useForm, useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import TPBBottomActions from '../../components/TPBBottomActions';
import TargetLocationsField from '../../components/TargetLocationsField';

import './TargetLocations.styles.scss';
import './TargetLocations.styles.responsive.scss';

type TargetLocationsProps = {
  className?: string;
};

const TargetLocations: React.FC<TargetLocationsProps> = (props) => {
  const { className } = props;

  const classes = classNames('target-locations', className);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = Object.keys(dirtyFields).some((el) =>
    el.includes('targetLocations'),
  );

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      navigate('/profile');
    }
  };

  const onSkip = () => {
    navigate('/profile');
  };

  return (
    <div className={classes}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <TPBPageHeading
          text={t('teacherProfileBuilder.targetLocations.title')}
          description={t('teacherProfileBuilder.targetLocations.description')}
        />
        <TargetLocationsField />
        <TPBBottomActions
          canSkip
          onSkip={onSkip}
          onSubmit={onSubmit}
          submitBtnDisabled={submitting}
        />
      </TPBPageContent>
    </div>
  );
};

export default TargetLocations;
