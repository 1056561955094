import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import AcceptCandidateModal from '../AcceptCandidateModal';
import RejectCondidateModal from '../RejectCondidateModal';
import { ApplicationSchoolStatus } from 'searchality-data';
import {
  useArchiveCandidateMutation,
  useGetCandidateQuery,
  useMarkAsFavoriteMutation,
} from 'api/canidates.api';
import { ReactComponent as StarIcon } from 'icons/Star.icon.svg';
import { ReactComponent as CloseIcon } from 'icons/RejectCandidate.icon.svg';
import { ReactComponent as MemberIcon } from 'icons/MemberNew.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import confirm from 'modules/confirm';
import useMatchMedia from 'hooks/useMatchMedia';

import './ProfileActions.styles.scss';

type ProfileActionsProps = {
  className?: string;
  statusInSchool: ApplicationSchoolStatus;
  candidateId: string;
  isFavourite: boolean;
  onlyIcons?: boolean;
  isArchived?: boolean;
  withTitle?: boolean;
};

const ProfileActions: React.FC<ProfileActionsProps> = (props) => {
  const {
    className,
    statusInSchool,
    candidateId,
    isFavourite = false,
    onlyIcons = false,
    isArchived = false,
    withTitle = false,
  } = props;

  const { t } = useTranslation();

  const { data: candidate } = useGetCandidateQuery(candidateId);

  const { vacancy, schools, firstName } = candidate || {};
  const { positionTitle } = vacancy || {};

  const isPhablet = useMatchMedia('isPhablet');

  const acceptModal = useRef(null);
  const rejectModal = useRef(null);

  const classes = classNames(
    'seachality-profile-actions',
    { 'seachality-profile-actions--only-icons': onlyIcons },
    className,
  );

  const getSchoolsName = () => schools?.map(({ name }) => name).join('/');

  const [markAsFavorite, { isLoading }] = useMarkAsFavoriteMutation();
  const [archivedCandidate, { isLoading: isArchivedLoading }] =
    useArchiveCandidateMutation();

  const handleSaveAsFavourite = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      markAsFavorite({
        candidateId,
        isFavourite: !isFavourite,
      });
    },
    [markAsFavorite, candidateId, isFavourite],
  );

  const handleUnarchived = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      await confirm({
        title: t('UnarchiveCandidateModal.title'),
        content: <p>{t('UnarchiveCandidateModal.description')}</p>,
        onSubmit: () => {
          archivedCandidate({
            candidateId,
            isArchived: !isArchived,
          });
        },
      });
    },
    [archivedCandidate, candidateId, isArchived, t],
  );

  return (
    <div className={classes}>
      {withTitle && <p className="title">{t('actions')}</p>}
      <div className="seachality-profile-actions__buttons">
        {isArchived && (
          <Button
            disabled={isArchivedLoading}
            onClick={handleUnarchived}
            variant="outline"
            className="delete-candidate"
          >
            <DeleteIcon />
          </Button>
        )}
        <Button
          disabled={isLoading}
          onClick={handleSaveAsFavourite}
          variant="outline"
        >
          <StarIcon className={classNames({ transparent: !isFavourite })} />
        </Button>
        {(statusInSchool === ApplicationSchoolStatus.NEW ||
          statusInSchool === ApplicationSchoolStatus.IN_PROCESS) && (
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              rejectModal.current.open();
            }}
          >
            {onlyIcons || isPhablet ? <CloseIcon /> : t('rejectCandidate')}
          </Button>
        )}
        {(statusInSchool === ApplicationSchoolStatus.NEW ||
          statusInSchool === ApplicationSchoolStatus.REJECTED) && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              acceptModal.current.open();
            }}
          >
            {onlyIcons || isPhablet ? (
              <MemberIcon />
            ) : (
              t('proceedWithCandidate')
            )}
          </Button>
        )}
        <AcceptCandidateModal
          ref={acceptModal}
          candidateId={candidateId}
          firstName={firstName}
          positionTitle={positionTitle}
          schoolName={getSchoolsName()}
        />
        <RejectCondidateModal
          ref={rejectModal}
          candidateId={candidateId}
          firstName={firstName}
          positionTitle={positionTitle}
          schoolName={getSchoolsName()}
        />
      </div>
    </div>
  );
};

export default ProfileActions;
