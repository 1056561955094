import React, { useCallback } from 'react';
import FormFieldLabel from 'components/FormFieldLabel';
import SelectField from 'components/SelectField';
import { Field, FormSpy, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import InputField from 'components/InputField';
import { required, youtubeLink } from 'validations';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';
import { useGenderOptions } from 'hooks/selectOptions/useGenderOptions';
import TPBTopActions from '../../components/TPBTopActions';
import TPBBottomActions from '../../components/TPBBottomActions';
import api from 'api';
import NotToAnswerCheckbox from 'components/NotToAnswerCheckboxField';
import DatepickerField from 'components/DatepickerField';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import useAppSelector from 'hooks/useAppSelector';
import bus from 'modules/bus';
import { UserGender } from 'searchality-data';
import ToggleField from 'components/ToggleField';
import useMaritalStatusOptions from 'hooks/selectOptions/useMaritalStatusOptions';
import CheckboxField from 'components/CheckboxField';

import './PersonalInformation.styles.scss';
import './PersonalInformation.styles.responsive.scss';

const PersonalInformationPage: React.FC = () => {
  const { t } = useTranslation();

  const genderOptions = useGenderOptions();
  const maritalStatusOptions = useMaritalStatusOptions();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const handleDeletePhoto = useCallback(async () => {
    try {
      await api.user.deleteUserPhoto();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { change, submit } = useForm();
  const {
    dirtyFields,
    submitting,
    valid,
    values: {
      gender: selectedGender,
      isRelocatingWithPartner,
      isWithChildren,
      isRelocatingWithPartnerAnswerRejected,
      isWithChildrenAnswerRejected,
    },
  } = useFormState();

  const isSubmitable = [
    'gender',
    'genderDescription',
    'birthDate',
    'birthDateAnswerRejected',
    'website',
    'videoUrl',
    'meritalStatus',
    'isRelocatingWithPartnerAnswerRejected',
    'isWithChildrenAnswerRejected',
    'isWithChildren',
    'isRelocatingWithPartner',
    'numberOfChildren',
    'isPartnerLookingForTeachingJob',
  ].some((fieldName) => dirtyFields[fieldName]);

  const isImageSubmittable = dirtyFields['imageFile'];

  const onSubmit = async () => {
    if (
      isSubmitable ||
      isImageSubmittable ||
      !finishedRoutes.includes(currentRoute) ||
      !valid
    ) {
      await submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Contact Information');
    }
  };

  const baseClass = 'tpb-personal-information';

  const partnerValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.isRelocatingWithPartnerAnswerRejected) {
        if (typeof value === 'undefined') {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  const childrenValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.isWithChildrenAnswerRejected) {
        if (typeof value === 'undefined') {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  return (
    <div className={baseClass}>
      <TPBTopActions />
      <TPBPageContent>
        <TPBPageHeading
          text={t('teacherProfileNavItems.personal-information')}
          description={t(
            'teacherProfileBuilder.personalInformation.description',
          )}
        />
        <FormFieldLabel
          text={t('teacherProfileBuilder.personalInformation.genderIdenity')}
        />

        <Field
          component={SelectField}
          name="gender"
          options={genderOptions}
          placeholder={t(
            'teacherProfileBuilder.personalInformation.genderIdenity',
          )}
          validate={required()}
        />
        {selectedGender === UserGender.PREFER_TO_SELF_DESCRIBE && (
          <>
            <FormFieldLabel
              text={t(
                'teacherProfileBuilder.personalInformation.genderNoAnswer',
              )}
            />
            <Field
              component={InputField}
              name="genderDescription"
              text={t(
                'teacherProfileBuilder.personalInformation.genderIdenity',
              )}
            />
          </>
        )}
        <FormFieldLabel text={t('dateOfBirth')} isOptional />
        <Field
          component={NotToAnswerCheckbox}
          name="birthDateAnswerRejected"
          isStyledDefault
          type="checkbox"
          label={t('teacherProfileBuilder.notToAnswer')}
        >
          <Field
            component={DatepickerField}
            name="birthDate"
            placeholder="Date of birth"
            onlyPast
          />
        </Field>

        <FormFieldLabel
          text={t('teacherProfileBuilder.personalInformation.website')}
          isOptional
        />

        <Field
          name="website"
          component={InputField}
          placeholder={t(
            'teacherProfileBuilder.personalInformation.websitePlaceholder',
          )}
        />

        <FormFieldLabel
          text={t('photo')}
          isOptional
          optionalText={t('optionalPhoto')}
        />

        <Field
          name="imageFile"
          render={(props) => {
            const {
              input: { value, onChange },
            } = props;

            return (
              <DropzoneProfilePhoto
                disabled={props.disabled}
                value={value?.length ? value[0] : null}
                setFileCallback={(file: File) => {
                  onChange(file ? [file] : []);
                  if (!file) {
                    handleDeletePhoto();
                  }
                }}
              />
            );
          }}
        />
        <FormFieldLabel
          text={t(
            'teacherProfileBuilder.personalInformation.introductionVideo',
          )}
          isOptional
          hint={
            <>
              <p>{t('videoTippy.title')}</p>
              <ol>
                <li>{t('videoTippy.li1')}</li>
                <li>{t('videoTippy.li2')}</li>
                <li>{t('videoTippy.li3')}</li>
                <li>{t('videoTippy.li4')}</li>
                <li>{t('videoTippy.li5')}</li>
              </ol>
            </>
          }
        />

        <Field
          name="videoUrl"
          component={InputField}
          label={t('url')}
          validate={youtubeLink()}
        />
        <FormFieldLabel
          text={t('teacherProfileBuilder.personalInformation.maritalStatus')}
        />
        <Field
          name="maritalStatus"
          component={SelectField}
          options={maritalStatusOptions}
          label={t('maritalStatus')}
          validate={required()}
        />
        <FormFieldLabel
          text={t('teacherProfileBuilder.personalInformation.relocate')}
        />
        <Field
          key={'partner' + isRelocatingWithPartnerAnswerRejected?.toString()}
          name={'isRelocatingWithPartner'}
          component={ToggleField}
          validate={partnerValidation}
          yesLabel={t('withAPartner')}
          noLabel={t('alone')}
          reverse
        />
        <Field
          name="isRelocatingWithPartnerAnswerRejected"
          component={CheckboxField}
          type="checkbox"
          isStyledDefault
          label={t('MaritalStatus.PREFER_NOT_TO_ANSWER')}
        />
        {isRelocatingWithPartner && (
          <Field
            name="isPartnerLookingForTeachingJob"
            component={ToggleField}
            label={t('teacherProfileBuilder.personalInformation.partner')}
            validate={required()}
          />
        )}
        <FormFieldLabel
          text={t('teacherProfileBuilder.personalInformation.children')}
        />
        <Field
          key={'children' + isWithChildrenAnswerRejected?.toString()}
          name={'isWithChildren'}
          component={ToggleField}
          validate={childrenValidation}
        />
        <Field
          name="isWithChildrenAnswerRejected"
          component={CheckboxField}
          type="checkbox"
          isStyledDefault
          label={t('MaritalStatus.PREFER_NOT_TO_ANSWER')}
        />
        {isWithChildren && (
          <Field
            name="numberOfChildren"
            component={InputField}
            label={t('teacherProfileBuilder.personalInformation.howMany')}
            onlyNumbers
            validate={required()}
          />
        )}
        <TPBBottomActions submitBtnDisabled={submitting} onSubmit={onSubmit} />
        <FormSpy
          onChange={({ values }) => {
            const {
              genderAnswerRejected,
              birthDateAnswerRejected,
              isRelocatingWithPartnerAnswerRejected,
              isRelocatingWithPartner,
              isPartnerLookingForTeachingJob,
              isWithChildrenAnswerRejected,
              isWithChildren,
              numberOfChildren,
            } = values;
            if (genderAnswerRejected) {
              change('gender', undefined);
            }
            if (birthDateAnswerRejected) {
              change('birthDate', undefined);
            }
            if (isRelocatingWithPartnerAnswerRejected) {
              change('isPartnerLookingForTeachingJob', undefined);
              change('isRelocatingWithPartner', undefined);
            }
            if (isWithChildrenAnswerRejected) {
              change('numberOfChildren', undefined);
              change('isWithChildren', undefined);
            }
            if (!isWithChildren && !!numberOfChildren) {
              change('numberOfChildren', undefined);
            }
            if (!isRelocatingWithPartner && isPartnerLookingForTeachingJob) {
              change('isPartnerLookingForTeachingJob', undefined);
            }
          }}
        />
      </TPBPageContent>
    </div>
  );
};

export default PersonalInformationPage;
