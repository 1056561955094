import React from 'react';
import classNames from 'classnames';
import { Select as LibSelect } from 'ncoded-component-library';
import Anim from 'components/animations';
import type { FormControl } from 'components/Input/Input.component';
import { ReactComponent as ToggleIcon } from 'icons/Toggle.icon.svg';
import { SelectProps } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './Select.styles.scss';

type SelectPropsTes = FormControl & {
  required?: boolean;
} & SelectProps<any>;

const Select: React.FC<SelectPropsTes> = (props) => {
  const {
    className,
    wrapperClassName,
    label,
    error,
    hint,
    required,
    options,
    ...rest
  } = props;

  const classes = classNames(
    's-select',
    { 's-select--error': !!error },
    className,
  );

  return (
    <div className={classNames('s-select-wrapper', wrapperClassName)}>
      <LibSelect
        hasError={!!error}
        className={classNames(classes, { 's-select--asterisk': required })}
        innerLabel={label}
        icon={<ToggleIcon />}
        options={options}
        {...rest}
      />
      <Anim.Collapse active={!!error}>
        <p className="s-select__error">{error}</p>
      </Anim.Collapse>
      {hint && <p className="s-select__hint">{hint}</p>}
    </div>
  );
};

export default Select;
