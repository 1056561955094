import { ComplexRoute } from 'router/components/Routes/Routes';
import Checkout from './pages/Checkout';
import CheckoutNewSchool from './pages/CheckoutNewSchool';
import ManageSubscription from './pages/ManageSubscription';
import { SchoolGroupStructureType } from 'searchality-data';
import EditUpcomingInvoiceDetails from './pages/EditUpcomingInvoiceDetails';

export default [
  {
    path: 'checkout',
    element: Checkout,
    validate: ({ auth: { user } }) =>
      user?.schoolGroup?.subscriptionId ||
      user?.schoolGroup?.schools?.length === 0
        ? '/my-account'
        : '',
  },
  {
    path: 'checkout-new-schools',
    element: CheckoutNewSchool,
    validate: ({ auth: { user }, addNewSchool: { schools } }) =>
      schools?.length === 0 ||
      user?.schoolGroup?.structureType ===
        SchoolGroupStructureType.STANDALONE ||
      !user?.schoolGroup?.subscriptionId
        ? '/my-account/subscription'
        : '',
  },
  {
    path: 'edit',
    element: EditUpcomingInvoiceDetails,
    validate: ({ auth: { user } }) =>
      !user?.schoolGroup?.subscriptionId ? '/my-account' : '',
  },
  {
    index: true,
    element: ManageSubscription,
  },
] as Array<ComplexRoute>;
