import { School } from 'models/School';
import { useMemo } from 'react';
import {
  STANDALONE_PRICE,
  SchoolGroupStructureType,
  WEBSITE_INTEGRATION_PRICE,
} from 'searchality-data';

export const useSchoolPrices = (
  structureType: SchoolGroupStructureType,
  schools: School[],
) => {
  const schoolPrice = useMemo(() => {
    return STANDALONE_PRICE;
  }, []);

  return {
    schoolPrice,
    totalPrice: schoolPrice + WEBSITE_INTEGRATION_PRICE,
    websiteAdditionalPrice: 0,
  };
};
