import React from 'react';
import { SubscriptionLinkProps } from '../components/SubscriptionLink/SubscriptionLink.component';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { ReactComponent as MembersIcon } from 'icons/Members.icon.svg';
import { ReactComponent as LanguageIcon } from 'icons/Language.icon.svg';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import {
  SchoolGroupStructureType,
  GROUP_ADDITIONAL_SCHOOL_PRICE,
  WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
} from 'searchality-data';

const useSubscriptionLinksList = () => {
  const { t } = useTranslation();
  const {
    schoolGroup: { structureType, schoolCount, schools },
  } = useAppSelector(authSelectors.selectUser);

  return [
    ...(structureType === SchoolGroupStructureType.GROUP
      ? [
          {
            priceText: t('UpgradeSubscription.addSchoolPrice', {
              time: t('year'),
              schoolPrice: numberPriceToCurrencyString(
                GROUP_ADDITIONAL_SCHOOL_PRICE,
              ),
              integrationPrice: numberPriceToCurrencyString(
                WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
              ),
            }),
            description: t('UpgradeSubscription.addSchoolDescription'),
            to: '/my-account/school-settings/add-new-school',
            iconLeft: <EducationIcon />,
            disabled: schoolCount === schools?.length,
            ...(schoolCount === schools?.length && {
              errorMessage: t('UpgradeSubscription.addSchoolError'),
            }),
          },
          {
            description: t('UpgradeSubscription.removeSchoolDescription'),
            priceText: t('UpgradeSubscription.removeSchoolDescriptionSub'),
            to: '/my-account/school-settings',
            iconLeft: <DeleteIcon />,
            disabled: schools?.length < 1,
            ...(schools?.length < 1 && {
              errorMessage: t('UpgradeSubscription.removeSchoolError'),
            }),
          },
        ]
      : []),
    {
      // priceText: t('UpgradeSubscription.price', {
      //   time: t('month'),
      //   price: numberPriceToCurrencyString(50),
      // }),
      priceText: t('comingSoon'),
      description: t('UpgradeSubscription.matchingDescription'),
      to: '/',
      iconLeft: <MembersIcon />,
      disabled: true,
    },
    {
      // priceText: t('UpgradeSubscription.price', {
      //   time: t('month'),
      //   price: numberPriceToCurrencyString(50),
      // }),
      priceText: t('comingSoon'),
      description: t('UpgradeSubscription.marketingDescription'),
      to: '/',
      iconLeft: <LanguageIcon />,
      disabled: true,
    },
  ] as SubscriptionLinkProps[];
};

export default useSubscriptionLinksList;
