import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { useSchoolPrices } from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Pricing/hooks/useSchoolPrices';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { numberPriceToCurrencyString } from 'utils';

const useYourSubscriptionList = () => {
  const { t } = useTranslation();

  const {
    schoolIds,
    schools,
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const { schoolPrice } = useSchoolPrices(structureType, schools);

  return [
    {
      label: t('YourSubscription.totalCost'),
      value: t('YourSubscription.price', {
        price: numberPriceToCurrencyString(schoolPrice),
      }),
    },
    ...(structureType === SchoolGroupStructureType.GROUP
      ? [
          {
            label: t('YourSubscription.numberOfSchoolsLabel'),
            value: t('YourSubscription.numberOfSchools', {
              count: schoolIds?.length,
            }),
          },
        ]
      : []),
  ];
};

export default useYourSubscriptionList;
