import React, { useCallback } from 'react';
import MainLayout from 'components/MainLayout';
import AuthSection from 'router/subrouters/Auth/components/AuthSection';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { composeValidators, emailValidation, required } from 'validations';
import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import classNames from 'classnames';

import './ForgotPassword.styles.scss';
import SelectSearchalityVersion from 'components/SelectSearchalityVersion';
import env from 'env';

type ForgotPasswordProps = {
  className?: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const { isTeacher } = state || {};

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await api.auth.forgotPassword(email);
        dispatch(popSuccess(t('forgotPasswordPage.success')));
        navigate(`../signin`);
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, t],
  );

  const classes = classNames(
    'auth',
    { teachers: isTeacher },
    'forgot-password',
  );

  return (
    <MainLayout className={classes} isTeacher={isTeacher}>
      <div>
        <SelectSearchalityVersion
          description={t('selectVersionDefault')}
          link={`${env.SEARCHALITY_US_VERSION}/auth/forgot-password`}
        />
        <AuthSection className="auth-form">
          <h1>{t('forgotPassword')}</h1>
          <p>{t('weHaveAllBeenThere')}</p>
          <div className="forgot-password__dont-have-account">
            <p className="descriptive">{t('dontHaveAccount')}</p>
            <Link to={`../school-signup`}>{t('signUp')}</Link>
          </div>
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, invalid, submitting }) => (
              <form onSubmit={handleSubmit} className="auth-section__form">
                <Field
                  name="email"
                  component={InputField}
                  label={t('email')}
                  required
                  validate={composeValidators(required(), emailValidation())}
                />
                <Button
                  type="submit"
                  disabled={invalid || submitting}
                  className="forgot-password__submit"
                >
                  {t('send')}
                </Button>
              </form>
            )}
          />
        </AuthSection>
      </div>
    </MainLayout>
  );
};

export default ForgotPassword;
