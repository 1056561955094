import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import YesNoExplainField from 'components/YesNoExplainField';
import {
  BoardingPositionRequirementStatus,
  VacancyStatus,
  VacancyType,
} from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import AcademicContractDaysFields from './components/AcademicContractDaysFields';
import { Field, useFormState } from 'react-final-form';
import FormFieldLabel from 'components/FormFieldLabel';
import TextAreaField from 'components/TextAreaField';

import './BenefitsFormFields.styles.scss';

const BenefitsFormFields: React.FC = () => {
  const { t } = useTranslation();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const baseClass = 'teacher-offers-fields';

  const {
    values: { status, boardingPositionRequirementStatus },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const isAcademic = useMemo(() => type === VacancyType.ACADEMIC, [type]);

  return (
    <div className={baseClass}>
      <YesNoExplainField
        name="isTuitionForDependentsOffered"
        inputName="dependentsDevelopmentOffered"
        label={t('SchoolProfileBuilder.Benefits.tuition')}
        inputLabel={t('vacancyWizard.benefits.specifyTuition')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isTravelAllowanceOffered"
        inputName="travelAllowanceOffered"
        label={t('SchoolProfileBuilder.Benefits.travel')}
        inputLabel={t('SchoolProfileBuilder.Benefits.travelInput')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isFinancialSupportForRelocationOffered"
        inputName="financialSupportForRelocationOffered"
        label={t('vacancyWizard.benefits.tuition')}
        inputLabel={t('vacancyWizard.benefits.specifyTuition')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isHousingOffered"
        inputName="housingOffered"
        label={t('SchoolProfileBuilder.Benefits.housing')}
        inputLabel={t('SchoolProfileBuilder.Benefits.housingInput')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isPrivateHealthInsuranceOffered"
        inputName="privateHealthInsuranceOffered"
        label={t('SchoolProfileBuilder.Benefits.insurance')}
        inputLabel={t('vacancyWizard.benefits.privateHealthInsuranceOffered')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isPensionContributionOffered"
        inputName="pensionContributionOffered"
        label={t('SchoolProfileBuilder.Benefits.pension')}
        inputLabel={t('vacancyWizard.benefits.pensionContributionOffered')}
        required
        disabled={isOngoingVacancyEdit}
      />
      {isAcademic && <AcademicContractDaysFields baseClass={baseClass} />}
      <YesNoExplainField
        name="isLeaveAllowanceOffered"
        inputName="leaveAllowanceOffered"
        label={t('SchoolProfileBuilder.Benefits.leave')}
        inputLabel={t('SchoolProfileBuilder.Benefits.leaveInput')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isProfessionalDevelopmentOffered"
        inputName="professionalDevelopmentOffered"
        label={t('SchoolProfileBuilder.Benefits.development')}
        inputLabel={t('vacancyWizard.benefits.specifyDevelopment')}
        required
        disabled={isOngoingVacancyEdit}
      />
      <YesNoExplainField
        name="isAdditionalBenefitOffered"
        inputName="additionalBenefitOffered"
        label={t('vacancyWizard.benefits.benefits')}
        inputLabel={t('vacancyWizard.benefits.specifyBenefitsDescriptionLabel')}
        required
        disabled={isOngoingVacancyEdit}
      />
      {boardingPositionRequirementStatus &&
        boardingPositionRequirementStatus !==
          BoardingPositionRequirementStatus.NOT_REQUIRED && (
          <div className={`${baseClass}__boarding`}>
            <FormFieldLabel
              text={t('vacancyWizard.step3.additionalBenefits')}
            />
            <Field
              name="boardingPositionBenefits.additionalBenefits"
              component={TextAreaField}
              placeholder={t('vacancyWizard.step3.additionalBenefitsLabel')}
              required
              disabled={isOngoingVacancyEdit}
            />
          </div>
        )}
    </div>
  );
};

export default BenefitsFormFields;
