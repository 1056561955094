import React, { useCallback, useContext, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import {
  SchoolCurriculumType,
  SchoolResidentialType as SearchalityResidentialType,
} from 'searchality-data';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { useNavigate } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import utils from 'utils';
import GoBackButton from 'components/GoBackButton';
import { CURRICULUM_TYPE_ROUTE } from '../../constants';
import { CREATE_SCHOOL_WIZARD } from 'enums';

const SchoolResidentialType: React.FC = () => {
  const { t } = useTranslation();

  const { submit, reset, getState } = useForm();

  const {
    values: { curriculumType, residentialType },
  } = useFormState();

  const { resetCompletedSteps, completeStep } = useContext(
    CreateSchoolWizardContext,
  );

  const navigate = useNavigate();

  const options = useMemo(
    () =>
      Object.entries(SearchalityResidentialType).map(([key, value]) => ({
        label: <RadioButtonLabel label={t(`ResidentialType.${key}`)} />,
        value,
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ curriculumType, residentialType });
    resetCompletedSteps([CREATE_SCHOOL_WIZARD.SCHOOL_TYPE]);
  }, [reset, resetCompletedSteps, residentialType, curriculumType]);

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.residentialType) resetForm();
        completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);
        navigate(
          `/create-school/${
            CURRICULUM_TYPE_ROUTE[curriculumType as SchoolCurriculumType]
          }/residential-type/${utils.mapResidentialTypeToRoute(
            residentialType,
          )}/school-information`,
        );
      }}
    >
      <GoBackButton to={`/create-school/school-type`} />
      <h1>{t('ResidentialType.title')}</h1>
      <p className="description">{t('ResidentialType.description')}</p>
      <Field
        name="residentialType"
        component={RadioGroupField}
        options={options}
        validate={required(t('mustSelectOne'))}
        wrapperClassName="school-type__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default SchoolResidentialType;
