import React from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import { useGetMatchingOpportunityQuery } from 'api/matching.api';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import OpportunityFeedback from '../../components/OpportunityFeedback';
import { Button } from 'ncoded-component-library';
import OpportunityMetadata from '../../components/OpportunityMetadata';
import ApplicationPreviewAboutPosition from '../../components/ApplicationPreviewAboutPosition';
import ApplicationPreviewOffers from '../../components/ApplicationPreviewOffers';
import ApplicationPreviewBoarding from '../../components/ApplicationPreviewBoarding';

import './Opportunity.styles.scss';
import './Opportunity.styles.responsive.scss';

type OpportunityProps = {
  className?: string;
};

const Opportunity: React.FC<OpportunityProps> = (props) => {
  const { className } = props;

  const classes = classNames('opportunity', className);

  const { t } = useTranslation();
  const { opportunityId } = useParams();
  const navigate = useNavigate();

  const { data: opportunity, isLoading } =
    useGetMatchingOpportunityQuery(opportunityId);

  const { vacancy } = opportunity || {};

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <GoBackButton to="../" />
      {opportunity && (
        <>
          <OpportunityMetadata />
          <Button onClick={() => navigate(`/job-application/${vacancy?._id}`)}>
            {t('applyNow')}
          </Button>
          {!opportunity?.isTeacherInterested && <OpportunityFeedback />}
          <ApplicationPreviewAboutPosition vacancy={opportunity?.vacancy} />
          <ApplicationPreviewOffers vacancy={opportunity?.vacancy} />
          <ApplicationPreviewBoarding vacancy={vacancy} />
        </>
      )}
    </div>
  );
};

export default Opportunity;
