import React, { useCallback, useEffect, useState } from 'react';
import {
  useGetVacancyQuery,
  usePublishVacancyMutation,
  useUpdateOngoingVacancyMutation,
  useUpdateVacancyMutation,
} from 'api/vacancies.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  changeIsFormFilled,
  changeVacancyType,
  resetVacancyStep,
  resetVacancyType,
} from 'store/slices/createVacancyWizard.slice';
import { useTranslation } from 'react-i18next';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import useStandaloneSchoolId from '../../../../pages/VacancyWizard/hooks/useStandaloneSchoolId';
import { VacancyStatus } from 'searchality-data';
import VacancyWizardService from '../../../../services/VacancyWizard.service';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import PageLoader from 'components/PageLoader';
import authSelectors from 'store/selectors/auth.selectors';
import useUploadVacancyFiles from '../../../../hooks/useUploadVacancyFiles';
import VacancyBuilderFormWrapper from '../../../../components/VacancyBuilderFormWrapper';
import vacancyConstants from 'constants/vacancy';

const EditVacancy: React.FC = () => {
  const vacancyBuilderValues = useAppSelector((state) => state.vacancyBuilder);
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const dispatch = useAppDispatch();
  const { vacancyId } = useParams();
  const { data: vacancy, isLoading } = useGetVacancyQuery(vacancyId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [initialValues, setInitialValues] = useState<VacancyFormValues>(
    {} as VacancyFormValues,
  );
  const [isLoadingInitialValues, setIsLoadingInitialValues] = useState(false);

  const { schoolGroup, marketingInformation } = useAppSelector(
    authSelectors.selectUser,
  );

  const standaloneSchoolId = useStandaloneSchoolId();

  const [updateVacancy, { isLoading: isUVLoading }] =
    useUpdateVacancyMutation();
  const [publishVacancy, { isLoading: isPLoading }] =
    usePublishVacancyMutation();
  const [updateOngoingVacancy, { isLoading: isOVLoading }] =
    useUpdateOngoingVacancyMutation();
  const { uploadVacancyFiles, isLoading: isFileUploadLoading } =
    useUploadVacancyFiles();

  const handleOngoingSubmit = useCallback(
    async (values: VacancyFormValues) => {
      const {
        positionDescription,
        boardingRoleDescription,
        roleDescription,
        boardingPositionRequirements,
        positionTitle,
      } = values;
      const { _id } = vacancy;

      updateOngoingVacancy({
        _id,
        roleDescription,
        boardingPositionRequirements,
        positionTitle,
      })
        .unwrap()
        .then(
          async ({
            _id,
            positionDescriptionUrl,
            boardingPositionRequirementsUrl,
          }) => {
            await uploadVacancyFiles(
              positionDescription,
              boardingRoleDescription,
              _id,
              positionDescriptionUrl,
              boardingPositionRequirementsUrl,
            );
            dispatch(popSuccess(t('successPublishedVacancy')));
            dispatch(vacancyBuilderActions.clearValues());
            navigate('/vacancies/active');
          },
        )
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, navigate, t, updateOngoingVacancy, uploadVacancyFiles, vacancy],
  );

  const handleDraftSubmit = useCallback(
    async (
      values: VacancyFormValues,
      submitObject: Omit<Vacancy, 'status'>,
    ) => {
      const { positionDescription, boardingRoleDescription } = values;

      if (!pathname.includes('preview')) {
        navigate(
          `/vacancies/${vacancyId}/edit/${vacancyConstants.vacancyTypeToRouteMapping[type]}/preview`,
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(changeIsFormFilled(true));
        return;
      }
      updateVacancy(submitObject)
        .unwrap()
        .then(async () => {
          await publishVacancy(values._id)
            .unwrap()
            .then(
              async ({
                _id,
                positionDescriptionUrl,
                boardingPositionRequirementsUrl,
              }) => {
                await uploadVacancyFiles(
                  positionDescription,
                  boardingRoleDescription,
                  _id,
                  positionDescriptionUrl,
                  boardingPositionRequirementsUrl,
                );
                dispatch(popSuccess(t('successPublishedVacancy')));
                dispatch(vacancyBuilderActions.clearValues());
                navigate('/vacancies/active');
              },
            )
            .catch((e) => dispatch(popServerError(e)));
        })
        .catch((e) => dispatch(popServerError(e)));

      navigate('../preview');
    },
    [
      dispatch,
      navigate,
      pathname,
      publishVacancy,
      t,
      type,
      updateVacancy,
      uploadVacancyFiles,
      vacancyId,
    ],
  );

  const handlePublishSubmit = useCallback(
    async (
      values: VacancyFormValues,
      submitObject: Omit<Vacancy, 'status'>,
    ) => {
      const { positionDescription, boardingRoleDescription } = values;

      await updateVacancy({ ...submitObject, _id: vacancyId })
        .unwrap()
        .then(
          async ({
            boardingPositionRequirementsUrl,
            positionDescriptionUrl,
            _id,
          }) => {
            await uploadVacancyFiles(
              positionDescription,
              boardingRoleDescription,
              _id,
              positionDescriptionUrl,
              boardingPositionRequirementsUrl,
            );
            dispatch(popSuccess(t('successPublishedVacancy')));
            dispatch(vacancyBuilderActions.clearValues());
            navigate('/vacancies/active');
          },
        )
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, navigate, t, updateVacancy, uploadVacancyFiles, vacancyId],
  );

  const onEditVacancy = useCallback(
    async (values: VacancyFormValues) => {
      const {
        status,
        schools,
        positionDescriptionUrl,
        boardingPositionRequirementsUrl,
        ...submitObject
      } = VacancyWizardService.convertFormIntoVacancy(
        values,
        type,
        standaloneSchoolId,
      ) as Vacancy;

      try {
        if (vacancy?.status === VacancyStatus.ONGOING) {
          await handleOngoingSubmit(values);
        } else if (vacancy?.status === VacancyStatus.DRAFT) {
          await handleDraftSubmit(values, submitObject);
        } else {
          handlePublishSubmit(values, submitObject);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      handleDraftSubmit,
      handleOngoingSubmit,
      handlePublishSubmit,
      standaloneSchoolId,
      type,
      vacancy?.status,
    ],
  );

  const convertVacancyAsync = useCallback(async () => {
    setIsLoadingInitialValues(true);
    if (vacancy) {
      const formValues: VacancyFormValues =
        await VacancyWizardService.convertVacancyIntoForm(vacancy);

      if (vacancy.status === VacancyStatus.DRAFT) {
        setInitialValues({ ...formValues, ...vacancyBuilderValues });
      } else {
        setInitialValues(formValues);
      }
    }
    setIsLoadingInitialValues(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    marketingInformation?.isMatchingAdDisabled,
    schoolGroup?.schools,
    vacancy,
    vacancyBuilderValues,
  ]);

  useEffect(() => {
    convertVacancyAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vacancy && !type) dispatch(changeVacancyType(vacancy?.type));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    return () => {
      dispatch(resetVacancyType());
      dispatch(changeIsFormFilled(false));
      dispatch(resetVacancyStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isLoadingInitialValues) {
    return <PageLoader />;
  }

  return (
    <VacancyBuilderFormWrapper
      initialValues={initialValues}
      onSubmit={onEditVacancy}
    >
      {(isUVLoading || isPLoading || isOVLoading || isFileUploadLoading) && (
        <div className="searchality-overlay">
          <PageLoader />
        </div>
      )}
      <Outlet />
    </VacancyBuilderFormWrapper>
  );
};

export default EditVacancy;
