import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import RadioGroupField from 'components/RadioGroupField';
import DatepickerField from 'components/DatepickerField';
import InputField from 'components/InputField';
import { InternationalRightToWorkType } from 'searchality-data';
import useWorkRightOptions from '../PositionRequirements/hooks/useWorkRightOptions/useWorkRightOptions';
import MultipleSelectField from 'components/MultipleSelectField';
import useCountryOptions from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/hooks/useCountryOptions';

import './JahcrHaveRightToWork.styles.scss';
import './JahcrHaveRightToWork.styles.responsive.scss';

type JahcrHaveRightToWorkProps = {
  className?: string;
};

const JahcrHaveRightToWork: React.FC<JahcrHaveRightToWorkProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const {
    values: { hostCountryRequirements },
  } = useFormState();

  const { change } = useForm();

  const workRightOptions = useWorkRightOptions();

  const { rightToWork, passports, citizenships } =
    hostCountryRequirements || {};

  const countryOptions = useCountryOptions();

  const options = useMemo(() => {
    if (citizenships?.length) {
      return citizenships.map((value: string) => ({
        label: value,
        value,
      }));
    }

    return countryOptions;
  }, [citizenships, countryOptions]);

  const baseClass = 'jahcr-have-right-to-work';
  const classes = classNames(baseClass, className);

  useEffect(() => {
    change(
      'hostCountryRequirements.passports',
      passports?.filter((el: any) => citizenships?.includes(el)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citizenships]);

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>
        {t('JobApplication.HCR.specifyWorkPermit')}
      </p>
      <p className={`${baseClass}__description`}>
        {t('JobApplication.HCR.specifyWorkPermitDesc')}
      </p>
      <Field
        name="hostCountryRequirements.rightToWork"
        component={RadioGroupField}
        options={workRightOptions}
        validate={required()}
        direction="row"
        className={`${baseClass}__radio-group`}
      />
      {rightToWork === InternationalRightToWorkType.PASSPORT_CITIZENSHIP && (
        <div className={`${baseClass}__passports`}>
          <p className={`${baseClass}__description`}>
            {t('JobApplication.HCR.citizenships')}
          </p>
          <Field
            name="hostCountryRequirements.passports"
            key={citizenships?.toString()}
            component={MultipleSelectField}
            options={options}
            validate={required()}
            searchable
            disabled={passports?.length > 1}
            label={t('workRequiredTypeOptions.PASSPORT_CITIZENSHIP')}
            required
          />
        </div>
      )}
      {rightToWork === InternationalRightToWorkType.WORK_PERMIT && (
        <div className={`${baseClass}__passports`}>
          <p className={`${baseClass}__description`}>
            {t('JobApplication.HCR.specifyVisa')}
          </p>
          <Field
            name="hostCountryRequirements.visaType"
            component={InputField}
            validate={required()}
            label={t('jobApplicationPositionRequirements.visaTypeLabel')}
            className={`${baseClass}__select`}
            required
          />
          <Field
            name="hostCountryRequirements.dateUntilVisaIsValid"
            component={DatepickerField}
            validate={required()}
            placeholder={t('jobApplicationPositionRequirements.visaDateLabel')}
            onlyFuture
          />
        </div>
      )}
    </div>
  );
};

export default JahcrHaveRightToWork;
