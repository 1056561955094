import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import useApplicantStageOptions from '../../hooks/useApplicantStageOptions';
import { Field, useForm, useFormState } from 'react-final-form';
import {
  MAX_ACADEMIC_SALARY,
  MAX_NON_ACADEMIC_SALARY,
} from 'constants/general';
import useAppSelector from 'hooks/useAppSelector';
import {
  BoardingPositionRequirementStatus,
  VacancyStatus,
  VacancyType,
} from 'searchality-data';
import { useTranslation } from 'react-i18next';
import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import { composeValidators, required, requiredRange } from 'validations';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import { currencyOptions } from '../../constants';

import './SalaryFormFields.styles.scss';

type SalaryFormFieldsProps = {
  className?: string;
};

const SalaryFormFields: React.FC<SalaryFormFieldsProps> = (props) => {
  const { className } = props;

  const baseClass = 'salary-form-fields';
  const classes = classNames(baseClass, className);

  const applicantStageOptions = useApplicantStageOptions();

  const {
    values: { salaryInfo, status, boardingPositionRequirementStatus },
    initialValues,
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );
  const { change } = useForm();
  const { t } = useTranslation();

  const isAcademic = useMemo(() => type === VacancyType.ACADEMIC, [type]);

  const MAX_SALARY = useMemo(
    () => (isAcademic ? MAX_ACADEMIC_SALARY : MAX_NON_ACADEMIC_SALARY),
    [isAcademic],
  );

  const showBoardingSalary = useMemo(() => {
    if (!boardingPositionRequirementStatus) return false;

    return (
      boardingPositionRequirementStatus !==
      BoardingPositionRequirementStatus.NOT_REQUIRED
    );
  }, [boardingPositionRequirementStatus]);

  const validateTo = (value: any, allValues: any) => {
    if (typeof value === 'undefined' || value === '') {
      return t('requiredField');
    }

    if (parseInt(value) > MAX_SALARY || parseInt(value) < 0) {
      return t('requiredRangeMessage', { min: 0, max: MAX_SALARY });
    }

    if (
      allValues?.salaryInfo?.from &&
      parseInt(value) < parseInt(allValues?.salaryInfo?.from)
    ) {
      return t('minNumberValidation', { min: allValues?.salaryInfo?.from });
    }
  };

  useEffect(() => {
    if (!salaryInfo) {
      change('salaryInfo.to', MAX_SALARY.toString());
      change('salaryInfo.from', '0');
    }
  }, [MAX_SALARY, change, initialValues, salaryInfo]);

  return (
    <div className={classes}>
      <div className={`${baseClass}__currency`}>
        <FormFieldLabel text={t('vacancyWizard.Salary.currency')} />
        <Field
          component={SelectField}
          name="salaryInfo.currency"
          options={currencyOptions}
          placeholder={t('vacancyWizard.Salary.curr')}
          validate={required()}
          searchable
          disabled={isOngoingVacancyEdit}
        />
      </div>
      <div className={`${baseClass}__salary`}>
        <FormFieldLabel text={t('salaryRange')} />
        <div className={`${baseClass}__salary__inputs`}>
          <Field
            name="salaryInfo.from"
            component={InputField}
            label={t('from')}
            validate={composeValidators(
              required(),
              requiredRange(MAX_SALARY, 0),
            )}
            disabled={isOngoingVacancyEdit}
            onlyNumbers
          />
          <Field
            name="salaryInfo.to"
            component={InputField}
            label={t('to')}
            validate={validateTo}
            disabled={isOngoingVacancyEdit}
            onlyNumbers
          />
        </div>
        {showBoardingSalary && (
          <div className={`${baseClass}__salary__boarding`}>
            <FormFieldLabel
              text={t('vacancyWizard.step3.additionalSalary')}
              hint={t('vacancyWizard.step3.additionalSalaryHint')}
            />
            <p className="boarding-description">
              {t('vacancyWizard.step3.additionalSalaryDescription')}
            </p>
            <Field
              name="boardingPositionBenefits.salary"
              component={InputField}
              label={t('vacancyWizard.step3.additionalSalaryHintLabel')}
              validate={required()}
              onlyNumbers
              required
              disabled={isOngoingVacancyEdit}
            />
          </div>
        )}
        <Field
          name="salaryInfo.applicantStage"
          component={SelectField}
          label={t('selectApplicantStage')}
          options={applicantStageOptions}
          validate={required()}
          required
          disabled={isOngoingVacancyEdit}
        />
        {salaryInfo?.applicantStage === 'Other' && (
          <Field
            name="applicantStageOther"
            component={InputField}
            label={t('pleaseSpecify')}
            validate={required()}
            required
            disabled={isOngoingVacancyEdit}
          />
        )}
      </div>
      <Field
        component={ToggleField}
        name="salaryInfo.isGrossSalary"
        reverse
        yesLabel="before"
        noLabel="after"
        label={t('vacancyWizard.Salary.grossLabel')}
        validate={required()}
        disabled={isOngoingVacancyEdit}
      />
      <Field
        component={ToggleField}
        name="salaryInfo.isSchoolPayingIncomeTax"
        reverse
        yesLabel="vacancyWizard.Salary.yes"
        noLabel="vacancyWizard.Salary.no"
        label={t('vacancyWizard.Salary.behalfLabel')}
        className={'salary-form-fields__school-pays'}
        validate={required()}
        disabled={isOngoingVacancyEdit}
      />
      <Field
        component={ToggleField}
        name="salaryInfo.isThereFinancialBonusAvailable"
        reverse
        label={t('vacancyWizard.Salary.bonus')}
        validate={required()}
        disabled={isOngoingVacancyEdit}
      />
      <Field
        component={ToggleField}
        name="salaryInfo.isThereBonusForAdditionalResponsibilities"
        reverse
        label={t('vacancyWizard.Salary.other')}
        validate={required()}
        disabled={isOngoingVacancyEdit}
      />
    </div>
  );
};

export default SalaryFormFields;
