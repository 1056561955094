import React, { useCallback, useEffect, useMemo } from 'react';
import JobPreviewComponent from 'components/JobPreviewComponent';
import { useTranslation } from 'react-i18next';
import { Role, VacancyStatus } from 'searchality-data';
import { useNavigate } from 'react-router-dom';
import VacancyPreviewCard from '../../../../components/VacancyPreviewCard';
import useAppSelector from 'hooks/useAppSelector';
import VacancyWizardService from '../../../../services/VacancyWizard.service';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import useStandaloneSchoolId from '../../../../pages/VacancyWizard/hooks/useStandaloneSchoolId';
import authSelectors from 'store/selectors/auth.selectors';
import vacancyConstants from 'constants/vacancy';
import { useForm } from 'react-final-form';

const VacancyBeforePublishPreview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { submit, getState } = useForm();
  const standaloneSchoolId = useStandaloneSchoolId();
  const { type, isFormFilled } = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard,
  );

  const user = useAppSelector(authSelectors.selectUser);

  const vacancy = useMemo(() => {
    return VacancyWizardService.convertFormIntoVacancy(
      getState().values as VacancyFormValues,
      type,
      standaloneSchoolId,
      user,
    ) as Vacancy;
  }, [getState, standaloneSchoolId, type, user]);

  const onEdit = useCallback(async () => {
    const { status, _id, type } = vacancy;
    navigate(
      status === VacancyStatus.DRAFT
        ? `/vacancies/${_id}/edit/${vacancyConstants.vacancyTypeToRouteMapping[type]}`
        : `/vacancies/create-vacancy/${vacancyConstants.vacancyTypeToRouteMapping[type]}`,
    );
  }, [navigate, vacancy]);

  useEffect(() => {
    if (!isFormFilled || getState().invalid) navigate('..');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormFilled]);

  return (
    <>
      <VacancyPreviewCard
        title={t('VacancyPreviewCard.beforePublishTitle')}
        subtitle={t('VacancyPreviewCard.beforePublishSubtitle')}
        onPublish={submit}
        onEdit={onEdit}
      />
      <JobPreviewComponent
        vacancy={vacancy}
        noActions
        noPreviousButton={user.role === Role.Teacher}
        previousPage={() => navigate('..')}
        previousPageButtonText={t('goBack')}
      />
    </>
  );
};

export default VacancyBeforePublishPreview;
