import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionLink from '../components/SubscriptionLink';
import { ReactComponent as RejectIcon } from 'icons/RejectCandidate.icon.svg';
import { ReactComponent as AcceptIcon } from 'icons/Vacancy.icon.svg';
import DateService from 'services/Date.service';
import confirm from 'modules/confirm';
import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import { useNavigate } from 'react-router-dom';

const useSubscriptionAction = (
  subscriptionId: string,
  isSubscriptionCancelledAtPeriodEnd: boolean,
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const user = useAppSelector(authSelectors.selectUser);

  const { schoolGroup, schools } = user;

  const { isTaxLocationValid } = schoolGroup || {};

  const unsubscribeRef = useRef<ModalRef>(null);

  const { paymentInfo } = useContext(PaymentMethodsContext);

  const navigate = useNavigate();

  const handleRenew = useCallback(async () => {
    if (
      !paymentInfo?.paymentMethods?.length ||
      !paymentInfo?.paymentMethods?.find(({ isDefault }) => isDefault)
    ) {
      await confirm({
        title: t('RenewSubscriptionEditFirstConfirmationModal.title'),
        content: (
          <p>{t('RenewSubscriptionEditFirstConfirmationModal.description')}</p>
        ),
        confirmBtnText: t('confirm'),
        onSubmit: () => {
          navigate('edit');
        },
      });
    } else {
      await confirm({
        title: t('RenewSubscriptionConfirmationModal.title'),
        confirmBtnText: t('confirm'),
        onSubmit: async () => {
          try {
            await api.schools.renewSubscription();
            dispatch(
              updateUser({
                ...user,
                schoolGroup: {
                  ...user.schoolGroup,
                  isSubscriptionCancelledAtPeriodEnd: false,
                },
              }),
            );
            dispatch(popSuccess(t('successSubscribe')));
          } catch (error) {
            dispatch(popServerError(error));
          }
        },
      });
    }
  }, [dispatch, navigate, paymentInfo?.paymentMethods, t, user]);

  if (subscriptionId && isSubscriptionCancelledAtPeriodEnd) {
    return {
      title: t('SubscriptionActions.renewSubscription'),
      action: (
        <SubscriptionLink
          className="subscription-link--checkout"
          description={t('SubscriptionActions.renewSubscriptionSubText')}
          priceText={t('SubscriptionActions.until', {
            date: DateService.getUSADateFormatLong(
              user?.schoolGroup?.subscriptionEndDate,
            ),
          })}
          iconLeft={<AcceptIcon />}
          onClick={handleRenew}
        />
      ),
    };
  }

  if (subscriptionId && !isSubscriptionCancelledAtPeriodEnd) {
    return {
      unsubscribeRef,
      title: t('SubscriptionActions.cancelSubscription'),
      action: (
        <SubscriptionLink
          className="subscription-link--cancel"
          priceText={t('SubscriptionActions.until', {
            date: DateService.getUSADateFormatLong(
              user?.schoolGroup?.subscriptionEndDate,
            ),
          })}
          description={t('SubscriptionActions.cancelSubscriptionDescription')}
          iconLeft={<RejectIcon />}
          onClick={() => unsubscribeRef?.current?.open()}
        />
      ),
    };
  }

  if (!isTaxLocationValid) {
    return {
      title: t('SubscriptionActions.paySubscription'),
      action: (
        <SubscriptionLink
          className="subscription-link--cancel"
          priceText={t('SubscriptionActions.invalid')}
          description={t('SubscriptionActions.invalidTitle')}
          iconLeft={<RejectIcon />}
          onClick={() =>
            navigate(`/my-account/school-settings/${schools?.[0]?._id}/edit`)
          }
        />
      ),
    };
  }

  return {
    title: t('SubscriptionActions.paySubscription'),
    action: (
      <SubscriptionLink
        className="subscription-link--checkout"
        priceText={t('SubscriptionActions.paySubscriptionSubText')}
        description={t('SubscriptionActions.goToCheckout')}
        iconLeft={<AcceptIcon />}
        to={'/my-account/manage-subscription/checkout'}
        disabled={!user?.schoolGroup?.schools?.length}
        {...(!user?.schoolGroup?.schools?.length && {
          errorMessage: t('SubscriptionActions.zeroSchoolError'),
        })}
      />
    ),
  };
};

export default useSubscriptionAction;
