import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { ContractDurationType } from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/types';
import Select from 'components/Select';
import { ContractDurationUnit } from 'searchality-data';

import './ContractDurationField.styles.scss';
import './ContractDurationField.styles.responsive.scss';
import { useTranslation } from 'react-i18next';

type ContractDurationFieldProps = FieldRenderProps<
  ContractDurationType,
  HTMLElement
> & {
  className?: string;
  required?: boolean;
};

const ContractDurationField: React.FC<ContractDurationFieldProps> = (props) => {
  const {
    className,
    input: { onBlur, onChange, value: fieldValue, name, ...restInput },
    meta: { error, touched },
    required,
    ...rest
  } = props;

  const { t } = useTranslation();

  const amountOfOptions = useMemo(() => {
    const array: { label: string; value: number }[] = [];

    for (let index = 1; index <= 12; index++) {
      array.push({ label: index.toString(), value: index });
    }

    return array;
  }, []);

  const unitOptions = useMemo(
    () =>
      Object.values(ContractDurationUnit).map((value) => ({
        label: value,
        value,
      })),
    [],
  );

  const classes = classNames('contract-duration-field');

  return (
    <div className={classes}>
      <div className="contract-duration-field__fields">
        <Select
          {...rest}
          {...restInput}
          options={amountOfOptions}
          value={fieldValue?.value}
          label={t('numberOf')}
          multiple={false}
          required={required}
          onChange={({ value }) => {
            if (fieldValue?.unit) {
              return onChange({ value, unit: fieldValue.unit });
            }

            onChange({ value });
          }}
        />
        <Select
          {...rest}
          {...restInput}
          options={unitOptions}
          value={fieldValue?.unit}
          label={t('monthsYears')}
          onClose={onBlur}
          multiple={false}
          required={required}
          onChange={({ value }) => {
            if (fieldValue?.value) {
              return onChange({ value: fieldValue.value, unit: value });
            }

            onChange({ unit: value });
          }}
        />
      </div>
      {!!error && touched && (
        <p className="contract-duration-field__error">{error}</p>
      )}
    </div>
  );
};

export default ContractDurationField;
