import React from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { useParams } from 'react-router-dom';
import { Field, useFormState } from 'react-final-form';
import ToggleField from 'components/ToggleField';
import JahcrHaveRightToWork from '../JahcrHaveRightToWork';
import JahcrNoRightToWork from '../JahcrNoRightToWork';
import MultipleSelectField from 'components/MultipleSelectField';
import useCountryOptions from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/hooks/useCountryOptions';

import './JobApplicationHcr.styles.scss';
import './JobApplicationHcr.styles.responsive.scss';
import { required } from 'validations';

type JobApplicationHcrProps = {
  className?: string;
};

const JobApplicationHcr: React.FC<JobApplicationHcrProps> = (props) => {
  const { className } = props;

  const { jobId } = useParams();
  const { data: job } = useGetOpenJobQuery(jobId);

  const { values } = useFormState();
  const countryOptions = useCountryOptions();

  const { schools } = job || {};
  const country = schools?.[0]?.country;

  const { hostCountryRequirements } = values;
  const { hasRightToWork } = hostCountryRequirements || {};

  const { t } = useTranslation();

  const baseClass = 'job-application-hcr';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__passports`}>
        <p className={`${baseClass}__passports__title`}>
          {t('JobApplication.PI.citizenship')}
        </p>
        <p className={`${baseClass}__passports__description`}>
          {t('JobApplication.PI.citizenshipDesc')}
        </p>
        <Field
          name="hostCountryRequirements.citizenships"
          component={MultipleSelectField}
          options={countryOptions}
          validate={required()}
          searchable
          required
          label={t('selectCountries')}
        />
      </div>
      <div className="job-application-hcr__work-permit">
        <p className="job-application-hcr__work-permit__title">
          <Trans
            i18nKey="JobApplication.HCR.title"
            values={{ country }}
            components={{
              it: <em style={{ textDecoration: 'underline' }} />,
            }}
            defaults={
              'Do you have right to work in the <it>"{{country}}"<it></it>?'
            }
          />
        </p>
        <p className="job-application-hcr__work-permit__description">
          {t('JobApplication.HCR.description', { country })}
        </p>
        <Field
          name="hostCountryRequirements.hasRightToWork"
          component={ToggleField}
          noLabel={t('JobApplication.HCR.no')}
          yesLabel={t('JobApplication.HCR.yes', { country })}
        />
      </div>
      {hasRightToWork !== undefined &&
        (hasRightToWork ? <JahcrHaveRightToWork /> : <JahcrNoRightToWork />)}
    </div>
  );
};

export default JobApplicationHcr;
