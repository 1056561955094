import React from 'react';
import classNames from 'classnames';
import './ProfilePersonalInformation.styles.scss';
import { ProfileCardType } from 'models/ProfileCard.type';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';

type ProfilePersonalInformationProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  | 'maritalStatus'
  | 'isRelocatingWithPartner'
  | 'isWithChildren'
  | 'numberOfChildren'
  | 'birthDate'
  | 'isRelocatingWithPartnerAnswerRejected'
  | 'isWithChildrenAnswerRejected'
  | 'isRelocationNotRequired'
>;

const ProfilePersonalInformation: React.FC<ProfilePersonalInformationProps> = (
  props,
) => {
  const {
    className,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
    isRelocationNotRequired,
  } = props;

  const { t } = useTranslation();

  const partnerValue =
    isRelocatingWithPartner === undefined ||
    isRelocatingWithPartnerAnswerRejected
      ? t('noData')
      : isRelocatingWithPartner
      ? t('partneredWithATeacher')
      : t('noPartneredWithATeacher');

  const childrenValue =
    isWithChildren === undefined || isWithChildrenAnswerRejected
      ? t('noData')
      : isWithChildren
      ? t('childrenYes', { count: numberOfChildren })
      : t('no');

  const classes = classNames(
    'profile-personal-information',
    'profile-section',
    className,
  );

  return (
    <div className={classes}>
      {birthDate && (
        <div className="profile-personal-information__section">
          <p className="profile-personal-information__section__title">
            {t('dateOfBirth')}
          </p>
          <p className="profile-personal-information__section__value">
            {DateService.getUSADateFormatLong(birthDate)}
          </p>
        </div>
      )}
      {isRelocationNotRequired !== undefined && (
        <div className="profile-personal-information__section">
          <p className="profile-personal-information__section__title">
            {t('relocation')}
          </p>
          <p className="profile-personal-information__section__value">
            {t(isRelocationNotRequired ? 'notRequired' : 'required')}
          </p>
        </div>
      )}
      <div className="profile-personal-information__section">
        <p className="profile-personal-information__section__title">
          {t('maritalStatus')}
        </p>
        <p className="profile-personal-information__section__value">
          {maritalStatus ?? t('noData')}
        </p>
      </div>
      <div className="profile-personal-information__section">
        <p className="profile-personal-information__section__title">
          {t('partnerStatus')}
        </p>
        <p className="profile-personal-information__section__value">
          {partnerValue}
        </p>
      </div>
      <div className="profile-personal-information__section">
        <p className="profile-personal-information__section__title">
          {t('accompanyingChildren')}
        </p>
        <p className="profile-personal-information__section__value">
          {childrenValue}
        </p>
      </div>
    </div>
  );
};

export default ProfilePersonalInformation;
