import React from 'react';
import classNames from 'classnames';
import { RequestedDocument as RequestedDocs } from 'models/Candidate';
import RequestedDocument from '../RequestedDocument';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { InternationalRequestedDocumentTitle } from 'searchality-data';

import './DocumentsRequestedSection.styles.scss';

type DocumentsRequestedSectionProps = {
  className?: string;
  documents: RequestedDocs[];
};

const DocumentsRequestedSection: React.FC<DocumentsRequestedSectionProps> = (
  props,
) => {
  const { className, documents } = props;

  const { t } = useTranslation();

  const classes = classNames('documents-requested-section', className);

  return (
    <div className={classes}>
      <p>{t('documentsRequested')}</p>
      {documents?.map(({ _id, title, description, status, files }) => (
        <RequestedDocument
          key={_id}
          title={t(
            `RequestDocumentOptions.Title.${utils.getKeyByValue(
              InternationalRequestedDocumentTitle,
              title,
            )}`,
          )}
          status={status}
          description={description}
          documentId={_id}
          disableCancel={!!files?.length}
        />
      ))}
    </div>
  );
};

export default DocumentsRequestedSection;
