import GoBackButton from 'components/GoBackButton';
import InputField from 'components/InputField';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import { required } from 'validations';
import { ReactComponent as IconClose } from 'icons/Close.icon.svg';
import confirm from 'modules/confirm';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import api from 'api';
import { updateUser } from 'store/slices/auth.slice';
import { School } from 'models/School';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import useGetPlaceDetails from 'hooks/useGetPlaceDetails';

import './SchoolInformationEdit.styles.scss';
import './SchoolInformationEdit.styles.responsive.scss';

const SchoolInformationEdit: React.FC = () => {
  const { schoolId } = useParams();
  const user = useAppSelector(authSelectors.selectUser);

  const {
    schoolGroup: { schools, isTaxLocationValid },
  } = user;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAddressObject } = useGetPlaceDetails();

  const initialValues = useMemo(() => {
    const selectedSchool = schools.find(({ _id }) => {
      return _id === schoolId;
    });

    if (!selectedSchool) {
      return {};
    }
    const { name, website, city, country, postcode, address } = selectedSchool;
    return {
      name,
      website,
      city,
      country,
      postcode,
      address,
    };
  }, [schoolId, schools]);

  const handleClose = useCallback(async () => {
    await confirm({
      title: t('myAccount.SchoolGroupInfo.cancel'),
      onSubmit: () => {
        navigate('/my-account/school-settings');
      },
    });
  }, [navigate, t]);

  const handleSubmit = useCallback(
    async (
      values: Pick<
        School,
        'address' | 'name' | 'website' | 'postcode' | 'country' | 'city'
      >,
    ) => {
      try {
        const { data } = await api.schools.updateSchoolInformation(
          values,
          schoolId,
        );
        const userCopy = structuredClone(user);

        const newSchools = userCopy.schoolGroup.schools.map((school) => {
          if (schoolId === school._id) {
            return data;
          }
          return school;
        });

        userCopy.schoolGroup.schools = newSchools as any;

        dispatch(updateUser(userCopy));
        dispatch(popSuccess(t('successfullyEditSchoolInformation')));
        navigate('../');
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, schoolId, t, user],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        dirty,
        invalid,
        submitting,
        values: { address, city, country },
        form: { change, batch },
      }) => (
        <form onSubmit={handleSubmit} className="school-information-edit-form">
          <GoBackButton className="school-information-edit-form__go-back" />
          <Button
            variant="link"
            iconPosition="left"
            icon={<IconClose />}
            className="school-information-edit-form__close"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <header>
            <h1>{t('myAccount.SchoolGroupInfo.title')}</h1>
            <p>{t('myAccount.SchoolGroupInfo.description')}</p>
          </header>
          <Field
            name="name"
            component={InputField}
            label={t('schoolName')}
            validate={required()}
            required
          />
          <Field
            name="website"
            component={InputField}
            label={t('schoolWebsite')}
            validate={required()}
            required
          />
          <Field
            name="address"
            key={address}
            component={MapboxAutocompleteField}
            callback={async (place_id: string, sessiontoken: string) => {
              const address = await getAddressObject(place_id, sessiontoken);

              batch(() => {
                if (address?.city) {
                  change('city', address.city);
                }
                if (address?.country) {
                  change('country', address.country);
                }
                if (address?.postcode) {
                  change('postcode', address.postcode);
                }
              });
            }}
            resultType={['address']}
            label={t('address')}
            required
            validate={required()}
          />
          <Field
            name="city"
            key={city}
            component={MapboxAutocompleteField}
            label={t('city')}
            validate={required()}
            required
            searchable={false}
          />
          <div className="school-information-edit-form__row-container">
            <Field
              name="country"
              key={country}
              component={MapboxAutocompleteField}
              searchable={false}
              label={t('country')}
              validate={required()}
              required
            />
            <Field
              name="postcode"
              component={InputField}
              label={t('zipCode')}
              validate={required()}
              maxLength={10}
              required
            />
          </div>
          {!isTaxLocationValid && (
            <p className="not-valid">{t('locationNotValid')}</p>
          )}
          <Button type="submit" disabled={invalid || submitting || !dirty}>
            {t('save')}
          </Button>
        </form>
      )}
    />
  );
};

export default SchoolInformationEdit;
