import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import React, { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  PositionDivision,
  PositionType,
  SubjectGradeLevel,
} from 'searchality-data';
import { requiredSubjectGrade, required } from 'validations';
import useGradeOptions from '../../../../hooks/useGradeOptions';
import usePositionDivisionOptions from '../../../../hooks/usePositionDivisionOptions';
import usePositionTypeOptions from '../../../../hooks/usePositionTypeOptions';
import useTeacherLanguageOptions from '../../../../hooks/useTeacherLanguageOptions';

type AcademicJobPositionsProps = {
  className: string;
  isDisabled?: boolean;
};

const AcademicJobPositions: React.FC<AcademicJobPositionsProps> = (props) => {
  const { className, isDisabled } = props;

  const { t } = useTranslation();

  const positionTypeOptions = usePositionTypeOptions();

  const positionDivisionOptions = usePositionDivisionOptions();

  const teacherLanguageOptions = useTeacherLanguageOptions();

  const { change } = useForm();

  const {
    values: {
      positionType,
      positionDivision,
      subjectGradeLevel,
      teacherLanguage,
    },
  } = useFormState();

  const gradeSubjectLevelOptions = useGradeOptions(
    positionType as PositionType,
    positionDivision as PositionDivision,
  );

  const clearSubjectGradeLevel = useCallback(() => {
    change('subjectGradeLevel', undefined);
  }, [change]);

  return (
    <div className={className}>
      <FormFieldLabel
        text={t('details')}
        hint={t('vacancyWizard.step2.tippyContent')}
      />
      <Field
        name="positionType"
        options={positionTypeOptions}
        label={t('positionType')}
        component={SelectField}
        validate={required()}
        onChangeCallback={clearSubjectGradeLevel}
        required
        disabled={isDisabled}
      />
      <Field
        name="positionDivision"
        options={positionDivisionOptions}
        onChangeCallback={clearSubjectGradeLevel}
        label={t('schoolLevel')}
        component={SelectField}
        validate={required()}
        required
        disabled={isDisabled}
      />
      <Field
        name="subjectGradeLevel"
        options={gradeSubjectLevelOptions}
        disabled={isDisabled}
        label={
          positionType === PositionType.LEADERSHIP_ADMINISTRATOR
            ? t('leadershipRole')
            : t('RoleSubject')
        }
        component={SelectField}
        validate={(value, allValues) => requiredSubjectGrade(value, allValues)}
      />
      {(subjectGradeLevel ===
        SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE ||
        subjectGradeLevel ===
          SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.FOREIGN_LANGUAGE) && (
        <>
          <Field
            name="teacherLanguage"
            component={SelectField}
            options={teacherLanguageOptions}
            label={t('selectLanguage')}
            validate={required()}
            required
            disabled={isDisabled}
          />
          {teacherLanguage === 'Other' && (
            <Field
              name="teacherLanguageOther"
              component={InputField}
              label={t('pleaseEnterTeacherLanguage')}
              validate={required()}
              required
              disabled={isDisabled}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AcademicJobPositions;
