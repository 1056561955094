import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RequestedDocument } from 'models/Candidate';

import './CandidateReqDocuments.styles.scss';
import utils from 'utils';
import { InternationalRequestedDocumentTitle } from 'searchality-data';

type CandidateReqDocumentsProps = {
  className?: string;
  pendingDocumentRequests?: RequestedDocument[];
};

const CandidateReqDocuments: React.FC<CandidateReqDocumentsProps> = (props) => {
  const { className, pendingDocumentRequests } = props;

  const baseClass = 'candidate-req-documents';
  const classes = classNames(baseClass, className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>{t('requestedDocuments')}</p>
      <div className={`${baseClass}__pending-requests`}>
        {pendingDocumentRequests.map(({ title, description, _id }) => (
          <div key={_id} className={`${baseClass}__pending-requests__request`}>
            <div className={`${baseClass}__info`}>
              <p className={`${baseClass}__info__title`}>
                {t(
                  `RequestDocumentOptions.Title.${utils.getKeyByValue(
                    InternationalRequestedDocumentTitle,
                    title,
                  )}`,
                )}
              </p>
              <p className={`${baseClass}__info__description`}>{description}</p>
              <p className={`${baseClass}__info__not-uploaded`}>
                {t('notUploadedYet')}
              </p>
            </div>
            <div className="searchality-pending" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CandidateReqDocuments;
