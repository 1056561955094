import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolProfile from './pages/SchoolProfile';
import SchoolSettings from './pages/SchoolSettings';
import SchoolInformationEdit from './pages/SchoolInformationEdit';
import { Role } from 'searchality-data';
import Payment from './pages/Payment';
import ManageSubscription from './subrouters/ManageSubscription/ManageSubscription.router';
import ManageSubscriptionRoutes from './subrouters/ManageSubscription/ManageSubscription.routes';
import PaymentMethodsProvider from 'providers/PaymentMethods/PaymentMethods.provider';
import Integration from '../../pages/Integration';
import EditAssociationList from './subrouters/EditAssociationList/EditAssociationList.router';
import EditAssociationListRoutes from './subrouters/EditAssociationList/EditAssociationList.routes';
import PersonalInformation, {
  PersonalInformationRoutes,
} from './subrouters/PersonalInformation';
import HiringPreferencesRouter from './subrouters/HiringPreferences/HiringPreferences.router';
import HiringPreferencesRoutes from './subrouters/HiringPreferences/HiringPreferences.routes';

export default [
  // {
  //   path: 'group-settings',
  //   element: GroupSettings,
  //   validate: ({ auth: { user } }) => {
  //     if (
  //       user.schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE
  //     ) {
  //       return '/my-account';
  //     }
  //     return '';
  //   },
  // },
  // {
  //   path: 'group-settings/school-group',
  //   element: SchoolGroupInfo,
  //   validate: ({ auth: { user } }) => {
  //     if (
  //       user.schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE
  //     ) {
  //       return '/my-account';
  //     }
  //     return '';
  //   },
  // },
  // {
  //   path: 'group-settings/school-information-edit/:schoolId',
  //   element: SchoolInformationEdit,
  //   validate: ({ auth: { user } }) => {
  //     if (
  //       user.schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE
  //     ) {
  //       return '/my-account';
  //     }
  //     return '';
  //   },
  // },
  {
    path: 'school-settings',
    element: SchoolSettings,
  },
  {
    path: 'school-settings/:schoolId',
    element: SchoolProfile,
    authorizedRoles: [Role.AccountOwner, Role.AccountManager],
  },
  {
    path: 'school-settings/:schoolId/edit',
    element: SchoolInformationEdit,
    authorizedRoles: [Role.AccountOwner, Role.AccountManager],
  },
  {
    path: 'hiring-preferences-settings',
    element: HiringPreferencesRouter,
    routes: HiringPreferencesRoutes,
    authorizedRoles: [
      Role.AccountOwner,
      Role.AccountManager,
      Role.HiringManager,
    ],
  },
  {
    path: 'edit-association-information/:schoolId',
    element: EditAssociationList,
    routes: EditAssociationListRoutes,
    authorizedRoles: [Role.AccountOwner, Role.AccountManager],
  },
  {
    path: 'personal-information',
    routes: PersonalInformationRoutes,
    element: PersonalInformation,
  },
  {
    path: 'subscription',
    element: (
      <PaymentMethodsProvider>
        <Payment />
      </PaymentMethodsProvider>
    ),
    authorizedRoles: [Role.AccountOwner],
    validate: ({ auth: { user } }) =>
      !user?.schoolGroup?.subscriptionId ? '/my-account' : '',
  },
  {
    path: 'manage-subscription',
    element: ManageSubscription,
    routes: ManageSubscriptionRoutes,
    authorizedRoles: [Role.AccountOwner],
  },
  {
    path: 'integration',
    element: Integration,
    authorizedRoles: [Role.AccountOwner, Role.AccountManager],
    validate: ({ auth: { user } }) => {
      if (!user?.schoolGroup?.isWidgetEnabled) {
        return '/';
      }
    },
  },
  {
    index: true,
    element: <Navigate to="personal-information" replace />,
  },
] as Array<ComplexRoute>;
