import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import ResetPassword from 'router/pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Signup from './pages/Signup';
import TeacherSignup from './pages/TeacherSignup';

export default [
  {
    path: 'signin',
    element: Login,
  },
  {
    path: 'teacher-signup',
    element: TeacherSignup,
  },
  {
    path: 'school-signup',
    element: Signup,
  },
  {
    path: 'forgot-password',
    element: ForgotPassword,
  },
  {
    path: 'reset-password',
    element: ResetPassword,
  },
  // {
  //   path: 'confirm-association-account',
  //   element: AssociationSetupPassword,
  // },
  // {
  //   path: 'association-signup',
  //   element: AssociationSignup,
  // },
  {
    index: true,
    element: <Navigate to="signin" replace />,
  },
] as Array<ComplexRoute>;
