import api from 'api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { popError } from 'store/slices/popNotifications.slice';
import { AddressObject } from 'types';
import useAppDispatch from './useAppDispatch';

export default () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getAddressObject = useCallback(
    async (place_id: string, sessiontoken: string): Promise<AddressObject> => {
      try {
        if (!place_id) return;

        const detailsParams = {
          place_id,
          sessiontoken,
          fields: ['address_components', 'postal_code', 'formatted_address'],
        };

        const { data } = await api.places.getPlaceDetails(detailsParams);

        const { result } = data || {};

        const { address_components, formatted_address } = result || {};

        const object = address_components.reduce((acc: any, curr: any) => {
          const partialAddress: Record<string, string> = {};
          const { types, long_name, short_name } = curr;

          if (types?.includes('country')) {
            partialAddress.country = long_name;
            partialAddress.countryShortCode = short_name;
          }

          if (types?.includes('locality')) {
            partialAddress.city = long_name;
          } else if (
            types?.includes('administrative_area_level_1') &&
            !acc.city
          ) {
            partialAddress.city = long_name;
          } else if (
            types?.includes('administrative_area_level_2') &&
            !acc.city
          ) {
            partialAddress.city = long_name;
          }

          if (types?.includes('postal_code')) {
            partialAddress.postcode = long_name;
          }

          return {
            ...acc,
            ...partialAddress,
          };
        }, {});

        return { ...object, address: formatted_address };
      } catch (err) {
        console.error(err);

        dispatch(popError(t('reverseGeocodingError')));
      }
    },
    [dispatch, t],
  );
  return { getAddressObject };
};
