import React, { useCallback } from 'react';
import classNames from 'classnames';
import { User } from 'models/User';
import MemberNameTableData from '../../MemberInfo';
import { Role, SchoolGroupStructureType, UserStatus } from 'searchality-data';
import { getNameInitials } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RolesSelect from '../../RolesSelect';
import DateService from 'services/Date.service';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { School } from 'models/School';
import PendingInvite from '../../PendingInvite';

import './MemberCard.styles.scss';

type MemberCardProps = {
  className?: string;
  member: User;
  isReadonly?: boolean;
  schoolName?: string;
};

const MemberCard: React.FC<MemberCardProps> = (props) => {
  const { className, member, isReadonly = false, schoolName } = props;
  const user = useAppSelector(authSelectors.selectUser);

  const {
    _id: currentId,
    schoolGroup: { structureType },
  } = user;

  const {
    email,
    firstName,
    lastName,
    status,
    imageFile,
    _id,
    systemColor,
    invitedAt,
    role,
    schools,
  } = member;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isPending = status === UserStatus.PENDING;

  const schoolTableValue = useCallback(
    (status: UserStatus, role: Role, schools?: School[]) => {
      if (schoolName) return schoolName;

      if (status === UserStatus.PENDING || role === Role.Reviewer) {
        return '';
      }

      if (role === Role.AccountOwner || role === Role.AccountManager) {
        return t('all');
      }

      return schools?.[0]?.name;
    },
    [schoolName, t],
  );

  const classes = classNames('member-card', className);

  return (
    <li
      className={classNames(classes, {
        'member-card--clickable': !(isReadonly || isPending),
      })}
      onClick={() =>
        isReadonly || isPending ? undefined : navigate(`/members/${_id}`)
      }
    >
      <MemberNameTableData
        placeholderText={
          !isPending ? getNameInitials(firstName, lastName) : 'NN'
        }
        email={
          member.title ??
          t('inviteDaysRemaining', {
            days: DateService.substractDatesForInviteExpire(invitedAt),
          })
        }
        src={imageFile?.url}
        backgroundColor={systemColor}
        name={
          !isPending
            ? `${firstName} ${lastName} ${
                currentId === _id ? t('Members.you') : ''
              }`
            : email
        }
      />
      <span>
        {structureType === SchoolGroupStructureType.GROUP && (
          <p>{schoolTableValue(status, role, schools)}</p>
        )}
        {status === UserStatus.PENDING ? (
          <PendingInvite id={_id} />
        ) : (
          <RolesSelect currentRole={role} isReadonly userId={_id} />
        )}
      </span>
    </li>
  );
};

export default MemberCard;
