import useAppSelector from 'hooks/useAppSelector';
import { useCallback, useMemo } from 'react';
import authSelectors from 'store/selectors/auth.selectors';
import { getHiringPreferencesFromSG } from '../subrouters/SchoolProfileBuilder/utils';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { hiringPreferences } = schoolGroup || {};

  const mapBooleanToTranslation = useCallback(
    (value: boolean) => {
      return value ? t('yes') : t('no');
    },
    [t],
  );

  const list = useMemo(() => {
    const hiringPreferencesFields =
      getHiringPreferencesFromSG(hiringPreferences);

    return Object.values(hiringPreferencesFields).map((value, index) => ({
      label: t(`SchoolProfileBuilder.HiringPreferences.label${index + 1}`),
      value: mapBooleanToTranslation(value),
    }));
  }, [hiringPreferences, mapBooleanToTranslation, t]);

  return list;
};
