import React, { forwardRef, useCallback } from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import classNames from 'classnames';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useStandaloneSchoolId from '../../hooks/useStandaloneSchoolId';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import { resetVacancyStep } from 'store/slices/createVacancyWizard.slice';
import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  useGetVacancyQuery,
  useUpdateOngoingVacancyMutation,
  useUpdateVacancyMutation,
} from 'api/vacancies.api';
import VacancyWizardService from '../../../../services/VacancyWizard.service';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import useAppSelector from 'hooks/useAppSelector';
import { VacancyStatus } from 'searchality-data';
import useUploadVacancyFiles from '../../../../hooks/useUploadVacancyFiles';

import './CloseEditModal.styles.scss';

type CloseEditModalProps = {
  className?: string;
  onDiscard: () => void;
} & OverlayProps;

const CloseEditModal: React.ForwardRefRenderFunction<
  ModalRef,
  CloseEditModalProps
> = (props, ref) => {
  const { className, onDiscard, ...rest } = props;

  const { t } = useTranslation();

  const { vacancyId } = useParams();
  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  const standaloneSchoolId = useStandaloneSchoolId();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { values } = useFormState();

  const baseClass = 'close-modal';

  const classes = classNames(baseClass, className);
  const [updateOngoingVacancy, { isLoading: isOVLoading }] =
    useUpdateOngoingVacancyMutation();
  const { uploadVacancyFiles, isLoading: isUploadFunctionExe } =
    useUploadVacancyFiles();

  const [updateVacancy, { isLoading: isDraftReqLoading }] =
    useUpdateVacancyMutation();
  const [
    uploadPositionDescription,
    { isLoading: isUploadPositionDescLoading },
  ] = useAttachPositionDescriptionMutation();
  const [
    deletePositionDescription,
    { isLoading: isDeletePositionDescLoading },
  ] = useDeletePositionDescriptionMutation();
  const [
    uploadBoardingPositionDescription,
    { isLoading: isUploadBoardingDescLoading },
  ] = useAttachBoardingPositionDescriptionMutation();
  const [
    deleteBoardingPositionDescription,
    { isLoading: isDeleteBoardingDescLoading },
  ] = useDeleteBoardingPositionDescriptionMutation();

  const isSubmitting =
    isDraftReqLoading ||
    isUploadPositionDescLoading ||
    isDeletePositionDescLoading ||
    isUploadBoardingDescLoading ||
    isDeleteBoardingDescLoading ||
    isUploadFunctionExe ||
    isOVLoading;

  const handleOngoingSubmit = useCallback(
    async (values: VacancyFormValues) => {
      const {
        positionDescription,
        boardingRoleDescription,
        roleDescription,
        boardingPositionRequirements,
        positionTitle,
      } = values;
      const { _id } = vacancy;

      await updateOngoingVacancy({
        _id,
        roleDescription,
        boardingPositionRequirements,
        positionTitle,
      })
        .unwrap()
        .then(
          async ({
            _id,
            positionDescriptionUrl,
            boardingPositionRequirementsUrl,
          }) => {
            await uploadVacancyFiles(
              positionDescription,
              boardingRoleDescription,
              _id,
              positionDescriptionUrl,
              boardingPositionRequirementsUrl,
            );
            dispatch(popSuccess(t('successPublishedVacancy')));
            dispatch(vacancyBuilderActions.clearValues());
            navigate('/vacancies/active');
          },
        )
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, navigate, t, updateOngoingVacancy, uploadVacancyFiles, vacancy],
  );

  const onCloseConfirm = async () => {
    const { status, ...vacancyObject } =
      VacancyWizardService.convertFormIntoVacancy(
        values as VacancyFormValues,
        type,
        standaloneSchoolId,
      );

    const { schools, ...rest } = vacancyObject;

    if (JSON.stringify(vacancyObject) === '{}') {
      dispatch(popError(t('cantSaveAsDraft')));

      return;
    }

    if (status === VacancyStatus.ONGOING) {
      await handleOngoingSubmit(values as VacancyFormValues);
      return;
    }

    await updateVacancy(
      status === VacancyStatus.DRAFT
        ? (vacancyObject as Vacancy)
        : (rest as Vacancy),
    )
      .unwrap()
      .then(
        async ({
          _id,
          positionDescriptionUrl,
          boardingPositionRequirementsUrl,
          status,
        }) => {
          if (values?.positionDescription?.length) {
            await uploadPositionDescription({
              vacancyId: _id,
              positionDescriptionFile: values.positionDescription[0],
            });
          } else if (
            !values?.positionDescription?.length &&
            positionDescriptionUrl
          ) {
            await deletePositionDescription({
              vacancyId: _id,
            });
          }
          if (values?.boardingRoleDescription?.length) {
            await uploadBoardingPositionDescription({
              vacancyId: _id,
              boardingPositionDescriptionFile:
                values.boardingRoleDescription[0],
            });
          } else if (
            !values?.boardingRoleDescription?.length &&
            boardingPositionRequirementsUrl
          ) {
            await deleteBoardingPositionDescription({
              vacancyId: _id,
            });
          }
          dispatch(
            popSuccess(
              t(
                `${
                  status === VacancyStatus.DRAFT
                    ? 'successSaveAsDraftVacancy'
                    : 'successSaveAsPublishedVacancy'
                }`,
              ),
            ),
          );
          navigate('/vacancies');
          dispatch(vacancyBuilderActions.clearValues());
        },
      )
      .catch((e) => {
        dispatch(popServerError(e));
        dispatch(resetVacancyStep());
      });
  };

  return (
    <Modal
      ref={ref}
      title={t('areYouSureToExitVacancyUpdate')}
      className={classes}
      {...rest}
      footer={
        <>
          <Button
            onClick={() => {
              onDiscard();
            }}
            variant="outline"
            className={`${baseClass}__exit-process`}
          >
            {t('discardChanges')}
          </Button>
          <Button
            onClick={onCloseConfirm}
            className={`${baseClass}__save-draft`}
            disabled={isSubmitting}
          >
            {t('saveChanges')}
          </Button>
        </>
      }
    />
  );
};

export default forwardRef(CloseEditModal);
