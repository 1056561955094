import React from 'react';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import HiringPreferencesBanner from 'components/HiringPreferencesBanner';
import HPSettingsComponent from '../../components/HiringPreferencesSettings';
import { Role } from 'searchality-data';

const HiringPreferencesSettings: React.FC = () => {
  const {
    schoolGroup: { hiringPreferences },
    role,
  } = useAppSelector(authSelectors.selectUser);

  const { isFilled } = hiringPreferences || {};

  const notAccountOwner = role !== Role.AccountOwner;

  if (!isFilled) {
    if (notAccountOwner) {
      return null;
    }

    return <HiringPreferencesBanner />;
  }

  return <HPSettingsComponent notAccountOwner={notAccountOwner} />;
};

export default HiringPreferencesSettings;
