import React from 'react';
import classNames from 'classnames';
import SpbTopActions from '../../components/SpbTopActions';
import TPBPageContent from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageContent';
import TPBPageHeading from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import SpbBottomActions from '../../components/SpbBottomActions';
import YesNoExplainField from 'components/YesNoExplainField';
import { Field } from 'react-final-form';
import InputField from 'components/InputField';
import { maxNumberValidation } from 'validations';
import FormFieldLabel from 'components/FormFieldLabel';

import './SpbBenefits.styles.scss';
import './SpbBenefits.styles.responsive.scss';

type SpbBenefitsProps = {
  className?: string;
};

const SpbBenefits: React.FC<SpbBenefitsProps> = (props) => {
  const { className } = props;

  const classes = classNames('spb-benefits', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <SpbTopActions to="../contract-information" />
      <TPBPageContent>
        <TPBPageHeading
          text={t('SchoolProfileBuilder.benefitsTitle')}
          description={t('SchoolProfileBuilder.benefitsDescription')}
        />
        <YesNoExplainField
          name="isTuitionForDependentsOffered"
          inputName="dependentsDevelopmentOffered"
          label={t('SchoolProfileBuilder.Benefits.tuition')}
          inputLabel={t('vacancyWizard.benefits.specifyTuition')}
          required
        />
        <YesNoExplainField
          name="isTravelAllowanceOffered"
          inputName="travelAllowanceOffered"
          label={t('SchoolProfileBuilder.Benefits.travel')}
          inputLabel={t('SchoolProfileBuilder.Benefits.travelInput')}
          required
        />
        <YesNoExplainField
          name="isFinancialSupportForRelocationOffered"
          inputName="financialSupportForRelocationOffered"
          label={t('vacancyWizard.benefits.tuition')}
          inputLabel={t('vacancyWizard.benefits.specifyTuition')}
          required
        />
        <YesNoExplainField
          name="isHousingOffered"
          inputName="housingOffered"
          label={t('SchoolProfileBuilder.Benefits.housing')}
          inputLabel={t('SchoolProfileBuilder.Benefits.housingInput')}
          required
        />
        <YesNoExplainField
          name="isPrivateHealthInsuranceOffered"
          inputName="privateHealthInsuranceOffered"
          label={t('SchoolProfileBuilder.Benefits.insurance')}
          inputLabel={t('vacancyWizard.benefits.privateHealthInsuranceOffered')}
          required
        />
        <YesNoExplainField
          name="isPensionContributionOffered"
          inputName="pensionContributionOffered"
          label={t('SchoolProfileBuilder.Benefits.pension')}
          inputLabel={t('vacancyWizard.benefits.pensionContributionOffered')}
          required
        />
        <div className="spb-benefits__container">
          <FormFieldLabel text={t('SchoolProfileBuilder.Benefits.student')} />
          <Field
            name="studentContactDays"
            component={InputField}
            label={t('studentContactDays')}
            validate={maxNumberValidation(365)}
            onlyNumbers
            maxLength={3}
          />
        </div>
        <div className="spb-benefits__container">
          <FormFieldLabel text={t('SchoolProfileBuilder.Benefits.non')} />
          <Field
            name="studentNonContactDays"
            component={InputField}
            label={t('nonContactDays')}
            validate={maxNumberValidation(365)}
            onlyNumbers
            maxLength={3}
          />
        </div>
        <YesNoExplainField
          name="isLeaveAllowanceOffered"
          inputName="leaveAllowanceOffered"
          label={t('SchoolProfileBuilder.Benefits.leave')}
          inputLabel={t('SchoolProfileBuilder.Benefits.leaveInput')}
          required
        />
        <YesNoExplainField
          name="isProfessionalDevelopmentOffered"
          inputName="professionalDevelopmentOffered"
          label={t('SchoolProfileBuilder.Benefits.development')}
          inputLabel={t('vacancyWizard.benefits.specifyDevelopment')}
          required
        />
        <YesNoExplainField
          name="isAdditionalBenefitOffered"
          inputName="additionalBenefitOffered"
          label={t('vacancyWizard.benefits.benefits')}
          inputLabel={t(
            'vacancyWizard.benefits.specifyBenefitsDescriptionLabel',
          )}
          required
        />
        <SpbBottomActions />
      </TPBPageContent>
    </div>
  );
};

export default SpbBenefits;
