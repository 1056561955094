import React, { useCallback, useContext, useMemo } from 'react';
import RadioButtonLabel from 'components/RadioButtonLabel';
import RadioGroupField from 'components/RadioGroupField';
import { schoolTypeOptions } from 'constants/schoolTypeOptions';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SchoolCurriculumType } from 'searchality-data';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import { CURRICULUM_TYPE_ROUTE } from '../../constants';
import Tippy from '@tippyjs/react';

import './SchoolType.styles.scss';

const SchoolType: React.FC = () => {
  const { t } = useTranslation();

  const { submit, reset, getState } = useForm();

  const {
    values: { curriculumType },
  } = useFormState();

  const { resetCompletedSteps } = useContext(CreateSchoolWizardContext);

  const navigate = useNavigate();

  const options = useMemo(
    () =>
      Object.values(SchoolCurriculumType).map((value) => ({
        label: <RadioButtonLabel label={t(schoolTypeOptions[value].label)} />,
        value,
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ curriculumType });
    resetCompletedSteps([]);
  }, [reset, resetCompletedSteps, curriculumType]);

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.curriculumType) resetForm();
        navigate(
          `${
            CURRICULUM_TYPE_ROUTE[curriculumType as SchoolCurriculumType]
          }/residential-type`,
        );
      }}
      className="school-type"
    >
      <h1>{t('schoolType.title')}</h1>
      <p className="description">
        {t('schoolType.description')}{' '}
        <Tippy
          className="tooltip-information"
          content={t('schoolType.hint')}
          placement="bottom"
        >
          <InformationIcon />
        </Tippy>
      </p>
      <Field
        name="curriculumType"
        component={RadioGroupField}
        options={options}
        validate={required(t('mustSelectOne'))}
        wrapperClassName="school-type__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default SchoolType;
