import React from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import utils from 'utils';
import { EducationLevel } from 'searchality-data';

import './JahcrNoRightToWork.styles.scss';
import './JahcrNoRightToWork.styles.responsive.scss';

type JahcrNoRightToWorkProps = {
  className?: string;
};

const JahcrNoRightToWork: React.FC<JahcrNoRightToWorkProps> = (props) => {
  const { className } = props;

  const classes = classNames('jahcr-no-right-to-work', className);

  const { t } = useTranslation();

  const { jobId } = useParams();
  const { data: job } = useGetOpenJobQuery(jobId);
  const {
    isRightToWorkRequired,
    schools,
    minimumAge,
    maximumAge,
    minimumYearsOfExperienceRequired,
    healthRequirements,
    educationalLevelRequired,
  } = job || {};

  const country = schools?.[0]?.country;

  return (
    <div className="jahcr-no-right-to-work--wrapper">
      <p>{t('JobApplication.HCR.countryReq', { country })}</p>
      <div className={classes}>
        {isRightToWorkRequired ? (
          <div className="jahcr-no-right-to-work__not-eligible">
            <p className="jahcr-no-right-to-work__not-eligible__title">
              {t('JobApplication.HCR.notEligibleT')}
            </p>
            <p className="jahcr-no-right-to-work__not-eligible__description">
              {t('JobApplication.HCR.notEligibleD', { country })}
            </p>
          </div>
        ) : (
          <div className="jahcr-no-right-to-work__eligible">
            <div className="jahcr-no-right-to-work__eligible__requirements">
              {educationalLevelRequired && (
                <div className="jahcr-no-right-to-work__eligible__requirements__req">
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__label">
                    {t('JobApplication.HCR.minimumEducation')}
                  </p>
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__value">
                    {t(
                      `educationLevelOptions.${utils.getKeyByValue(
                        EducationLevel,
                        educationalLevelRequired,
                      )}`,
                    )}
                  </p>
                </div>
              )}
              {minimumYearsOfExperienceRequired && (
                <div className="jahcr-no-right-to-work__eligible__requirements__req">
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__label">
                    {t('JobApplication.HCR.minimumExp')}
                  </p>
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__value">
                    {t('JobApplication.HCR.minimumExpV', {
                      exp: minimumYearsOfExperienceRequired,
                    })}
                  </p>
                </div>
              )}
              {healthRequirements && (
                <div className="jahcr-no-right-to-work__eligible__requirements__req">
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__label">
                    {t('JobApplication.HCR.healthReq')}
                  </p>
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__value">
                    {t(`HealthRequirementsOptions.${healthRequirements}`)}
                  </p>
                </div>
              )}
              {minimumAge && maximumAge && (
                <div className="jahcr-no-right-to-work__eligible__requirements__req">
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__label">
                    {t('JobApplication.HCR.yearsSpan')}
                  </p>
                  <p className="jahcr-no-right-to-work__eligible__requirements__req__value">
                    {t('JobApplication.HCR.yearsSpanV', {
                      min: minimumAge,
                      max: maximumAge,
                    })}
                  </p>
                </div>
              )}
            </div>
            <Field
              name="hostCountryRequirements.doesCandidateMeetAllRequirementsForVisa"
              component={CheckboxField}
              isStyledDefault
              label={t('JobApplication.HCR.tick', { country })}
              type="checkbox"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default JahcrNoRightToWork;
