import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ApplicationSchoolStatus } from 'searchality-data';
import DateService from 'services/Date.service';

import './ProfileMetadata.styles.scss';

type ProfileMetadataProps = {
  className?: string;
  appliedAt: string | Date;
  statusInSchool: ApplicationSchoolStatus;
  positionTitle: string;
  schoolGroupInfo: string;
};

const ProfileMetadata: React.FC<ProfileMetadataProps> = (props) => {
  const {
    className,
    statusInSchool,
    appliedAt,
    positionTitle,
    schoolGroupInfo,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('searchality-profile-metadata', className);

  return (
    <div className={classes}>
      <div className="status">{statusInSchool}</div>
      <div>
        <p className="title">{schoolGroupInfo}</p>
        <p className="description">{positionTitle}</p>
      </div>
      <div>
        <p className="title">{t('Profile.Metadata.applicationDate')}</p>
        <p className="description">{DateService.getUSADateFormat(appliedAt)}</p>
      </div>
    </div>
  );
};

export default ProfileMetadata;
