import { useLazyPreviewNewSchoolsPriceQuery } from 'api/payment.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import ValueContext from 'providers/ValueContext/Value.context';
import { useCallback, useContext, useEffect } from 'react';
import authSelectors from 'store/selectors/auth.selectors';
import { updateCouponValue } from 'store/slices/payment.slice';
import { popServerError } from 'store/slices/popNotifications.slice';

const usePreviewNewSchoolsPrice = () => {
  const [previewNewSchoolsPrice, { data, isLoading }] =
    useLazyPreviewNewSchoolsPriceQuery();

  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(({ payment }) => payment.coupon);
  const { schoolGroupId } = useAppSelector(authSelectors.selectUser);

  const { selectedIds } = useContext(ValueContext);
  const schools = useAppSelector(({ addNewSchool }) => addNewSchool?.schools);

  const handlePreviewNewSchoolsPrice = useCallback(async () => {
    const schoolsForPricingPreview = schools?.map((school) => ({
      ...school,
      optOutIntegration: !selectedIds?.includes(school?.name),
    }));
    previewNewSchoolsPrice(
      { schools: schoolsForPricingPreview, promoCode, schoolGroupId },
      true,
    )
      .unwrap()
      .then((res) => {
        if (res?.isPromoCodeValid === false) {
          dispatch(updateCouponValue(undefined));
        }
      })
      .catch((e) => {
        dispatch(popServerError(e));
        dispatch(updateCouponValue(undefined));
      });
  }, [
    dispatch,
    previewNewSchoolsPrice,
    promoCode,
    schoolGroupId,
    schools,
    selectedIds,
  ]);

  useEffect(() => {
    if (schools) {
      handlePreviewNewSchoolsPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePreviewNewSchoolsPrice]);

  return { data, isLoading };
};

export default usePreviewNewSchoolsPrice;
