import React from 'react';
import { useTranslation } from 'react-i18next';
import usePricingInformation from '../../../hooks/usePricingInformation';
import ReviewCardWrapper from '../../ReviewCardWrapper';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import classNames from 'classnames';

import './PricingPreview.styles.scss';

const PicingPreview: React.FC<{ payLater?: boolean }> = ({
  payLater = true,
}) => {
  const list = usePricingInformation();

  const { t } = useTranslation();

  const classes = classNames('pricing-preview', {
    'pricing-preview--pay-later': payLater,
  });

  return (
    <div className={classes}>
      <ReviewCardWrapper title={t('pricingPlan')} list={list} />
      {payLater && (
        <div className="pricing-preview__pay-later">
          <InformationIcon />
          <p>{t('pricingPlanReviewPayLater')}</p>
        </div>
      )}
    </div>
  );
};

export default PicingPreview;
