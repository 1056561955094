import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Role, UserStatus } from 'searchality-data';
import MemberNameTableData from '../MemberInfo';
import { getNameInitials } from 'utils';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import { User } from 'models/User';
import { useNavigate } from 'react-router-dom';
import RolesSelect from '../RolesSelect';
import useMembersTableHeader from 'router/subrouters/Dashboard/hooks/useMembersTableHeader';
import useMatchMedia from 'hooks/useMatchMedia';
import MemberCards from '../MemberCards';
import AssociationRolesSelect from '../AssociationRolesSelect';
import DateService from 'services/Date.service';
import PendingInvite from '../PendingInvite';

import './MembersTable.styles.scss';

type MembersTableProps = {
  className?: string;
  members: User[];
  isReadonly?: boolean;
  schoolName?: string;
  isAssociation?: boolean;
};

const MembersTable: React.FC<MembersTableProps> = (props) => {
  const {
    className,
    members,
    schoolName,
    isReadonly = false,
    isAssociation = false,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPablet = useMatchMedia('isPhablet');

  const tableHeader = useMembersTableHeader(isAssociation);

  const user = useAppSelector(authSelectors.selectUser);

  const { role: currentUserRole } = user;

  const classes = classNames('members-table', className);

  const headerMapped = useMemo(
    () =>
      tableHeader?.map(({ id, label, key }) => (
        <th key={id} className={`'members-table__column__${key}`}>
          {label}
        </th>
      )),
    [tableHeader],
  );

  if (isPablet) {
    return (
      <MemberCards
        members={members}
        isReadonly={isReadonly}
        schoolName={schoolName}
      />
    );
  }

  return (
    <>
      <table className={classes}>
        <thead>
          <tr>{headerMapped}</tr>
        </thead>
        <tbody>
          {members?.map(
            ({
              _id,
              firstName,
              lastName,
              imageFile,
              email,
              title,
              systemColor,
              status,
              role,
              invitedAt,
            }) => (
              <tr key={_id}>
                <td className={`members-table__column__name`}>
                  <MemberNameTableData
                    placeholderText={
                      status !== UserStatus.PENDING
                        ? getNameInitials(firstName, lastName)
                        : 'NN'
                    }
                    email={
                      title ??
                      t('inviteDaysRemaining', {
                        days: DateService.substractDatesForInviteExpire(
                          invitedAt,
                        ),
                      })
                    }
                    src={imageFile?.url}
                    backgroundColor={systemColor}
                    name={
                      status !== UserStatus.PENDING
                        ? `${firstName} ${lastName} ${
                            user?._id === _id ? t('Members.you') : ''
                          }`
                        : email
                    }
                    className={classNames({
                      'members-table__column__name--clickable': !(
                        isReadonly || isAssociation
                      ),
                    })}
                    onClick={() =>
                      isReadonly ||
                      isAssociation ||
                      status === UserStatus.PENDING
                        ? undefined
                        : navigate(`/members/${_id}`)
                    }
                  />
                </td>
                {!isAssociation && (
                  <td className={'members-table__column__school'}>
                    {status !== UserStatus.PENDING && <p>{email}</p>}
                  </td>
                )}
                <td className={'members-table__column__role'}>
                  {status === UserStatus.PENDING ? (
                    <PendingInvite id={_id} />
                  ) : isAssociation ? (
                    <AssociationRolesSelect
                      currentRole={role}
                      isReadonly={
                        isReadonly ||
                        currentUserRole !== Role.AssociationOwner ||
                        role === Role.AssociationOwner
                      }
                      userId={_id}
                    />
                  ) : (
                    <RolesSelect
                      currentRole={role}
                      isReadonly={
                        isReadonly ||
                        currentUserRole !== Role.AccountOwner ||
                        role === Role.AccountOwner
                      }
                      userId={_id}
                    />
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </>
  );
};

export default MembersTable;
