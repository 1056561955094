import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Checkbox,
  Collapsible,
  Dropdown,
  Input,
} from 'ncoded-component-library';
import { Country, InternationalTargetLocations } from 'searchality-data';
import { Field, useForm, useFormState } from 'react-final-form';
import CheckboxGroupField from 'components/CheckboxGroupField';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'icons/CloseDialog.icon.svg';
import Tag from 'components/Tag';

import './TargetLocationsField.styles.scss';

type TargetLocationsFieldProps = {
  className?: string;
};

const TargetLocationsField: React.FC<TargetLocationsFieldProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { change, getState } = useForm();
  const { values } = useFormState();

  const { targetLocations } = values || {};

  const targetLocationOptions = useMemo(() => {
    const optionsObject: Partial<
      Record<
        keyof typeof InternationalTargetLocations,
        { label: string; value: string }[]
      >
    > = {};
    Object.entries(InternationalTargetLocations).forEach(([key, value]) => {
      optionsObject[key as keyof typeof InternationalTargetLocations] = value
        .filter((country) =>
          country.toLowerCase().includes(searchString.toLowerCase()),
        )
        .map((country) => ({
          label: country,
          value: country,
        }));
    });

    return optionsObject;
  }, [searchString]);

  const classes = classNames('target-locations-field', className);

  const tags = useMemo(
    () =>
      !targetLocations
        ? []
        : Object.entries(targetLocations as Record<string, Country[]>).flatMap(
            ([key, value]) => {
              if (
                (value as any)?.length ===
                InternationalTargetLocations[
                  key as keyof typeof InternationalTargetLocations
                ].length
              ) {
                return {
                  text: t('Continent.selected', {
                    count: (value as any)?.length,
                    continent: t(`Continent.${key}`),
                  }),
                  handleRemove: () => change(`targetLocations.${key}`, []),
                };
              }
              return value.map((country) => ({
                text: country,
                handleRemove: () =>
                  change(
                    `targetLocations.${key}`,
                    targetLocations?.[key].filter(
                      (el: Country) => el !== country,
                    ),
                  ),
              }));
            },
          ),
    [change, t, targetLocations],
  );

  return (
    <div className={classes}>
      <Dropdown
        trigger={
          <Input
            ref={(r) => (inputRef.current = r)}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={t('teacherProfileBuilder.targetLocations.selectLabel')}
            suffixNode={<ArrowIcon />}
          />
        }
      >
        <div className="target-locations-field__dropdown-wrapper">
          {Object.keys(InternationalTargetLocations).map((continent) => (
            <Collapsible
              key={continent}
              trigger={
                <div
                  key={`${targetLocationOptions?.[
                    continent as keyof typeof InternationalTargetLocations
                  ].length?.toString()}`}
                  className="target-locations-field__trigger"
                  onClick={(e) => {
                    e.stopPropagation();
                    inputRef.current.focus();
                  }}
                >
                  <ArrowIcon stroke="black" strokeWidth={'0.1px'} />
                  {t(`Continent.${continent}`)}
                  <Checkbox
                    onChange={(value) => {
                      change(
                        `targetLocations.${continent}`,
                        value
                          ? InternationalTargetLocations[
                              continent as keyof typeof InternationalTargetLocations
                            ]
                          : [],
                      );
                    }}
                    checked={
                      InternationalTargetLocations[
                        continent as keyof typeof InternationalTargetLocations
                      ].length ===
                      getState().values?.targetLocations?.[continent]?.length
                    }
                  />
                </div>
              }
            >
              {targetLocationOptions?.[
                continent as keyof typeof InternationalTargetLocations
              ].length ? (
                <div onClick={() => inputRef.current.focus()}>
                  <Field
                    name={`targetLocations.${continent}`}
                    component={CheckboxGroupField}
                    options={
                      targetLocationOptions?.[
                        continent as keyof typeof InternationalTargetLocations
                      ] ?? []
                    }
                  />
                </div>
              ) : (
                <p>{t('noCountryForS', { search: searchString })}</p>
              )}
            </Collapsible>
          ))}
        </div>
      </Dropdown>
      {!!tags?.length && (
        <div className="target-locations-field__tags">
          {tags?.map(({ text, handleRemove }) => (
            <Tag key={text} text={text}>
              <button
                onClick={() => handleRemove()}
                className="svg-button-wrapper"
                type="button"
              >
                <DeleteIcon />
              </button>
            </Tag>
          ))}
        </div>
      )}
    </div>
  );
};

export default TargetLocationsField;
