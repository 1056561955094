import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from 'models/User';
import { Vacancy } from 'models/Vacancy';
import { InviteMemberValues } from 'router/subrouters/Dashboard/components/InviteMember/InviteMember.component';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

export const membersApi = createApi({
  reducerPath: 'membersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Members', 'MembersVacancies'],
  endpoints: (builder) => ({
    getMembers: builder.query<PaginatedResponse<User>, unknown>({
      query: (params?: Params) => ({
        url: 'school-users',
        method: 'get',
        params,
      }),
      keepUnusedDataFor: 30,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ _id }) => ({ type: 'Members', id: _id } as const),
              ),
              { type: 'Members', id: 'LIST' },
            ]
          : [{ type: 'Members', id: 'LIST' }],
    }),
    updateMemberPermission: builder.mutation<
      User,
      InviteMemberValues & { userId: string }
    >({
      query: (requestInfo) => {
        const { userId, role, schoolId, vacancyIds } = requestInfo;
        return {
          url: `school-users/${userId}/role`,
          method: 'post',
          data: {
            role,
            schoolId,
            vacancyIds,
          },
          params: { $populate: ['schools', 'vacancies'] },
          invalidatesTags: ['Members'],
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { userId: memberId } = requestInfo;
          dispatch(
            membersApi.util.updateQueryData('getMember', memberId, () => {
              return data;
            }),
          );
        });
      },

      invalidatesTags: (result, error, { userId }) => [
        { type: 'Members', id: userId },
        'MembersVacancies',
      ],
    }),

    addMember: builder.mutation<User, InviteMemberValues & { userId?: string }>(
      {
        query: (requestInfo) => {
          return {
            url: `school-users`,
            method: 'post',
            data: requestInfo,
            params: { $populate: ['schools'] },
          };
        },
        invalidatesTags: ['Members'],
      },
    ),
    addCandidateMember: builder.mutation<User, InviteMemberValues>({
      query: (requestInfo) => {
        return {
          url: `school-users/candidate`,
          method: 'post',
          data: requestInfo,
          params: { $populate: ['schools'] },
        };
      },
      invalidatesTags: ['MembersVacancies'],
    }),
    deleteMember: builder.mutation<unknown, string>({
      query: (memberId) => {
        return {
          url: `school-users/${memberId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Members'],
    }),
    getMember: builder.query<User, unknown>({
      query: (memberId) => {
        return {
          url: `school-users/${memberId}`,
          method: 'get',
          params: {
            $populate: ['schoolGroup', 'schoolGroup.schools', 'vacancies'],
          },
        };
      },
    }),
    getMemberVacancies: builder.query<
      PaginatedResponse<Vacancy>,
      { memberId: string; params: Params }
    >({
      providesTags: ['MembersVacancies'],
      query: ({ memberId, params }) => {
        return {
          url: `school-users/${memberId}/vacancies`,
          method: 'get',
          params: {
            $populate: ['schoolGroup', 'schoolGroup.schools', 'schools'],
            ...params,
          },
        };
      },
    }),
    getVacancyMembers: builder.query<
      PaginatedResponse<User>,
      { vacancyId: string; params?: Params }
    >({
      providesTags: ['MembersVacancies'],
      query: ({ vacancyId, params }) => {
        return {
          url: `school-users/vacancies/${vacancyId}`,
          method: 'get',
          params,
        };
      },
    }),
    resendInvite: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `school-users/${id}/resend-invitation-email`,
          method: 'post',
        };
      },
    }),
    cancelInvite: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `school-users/${id}/cancel-invitation-email`,
          method: 'post',
        };
      },
      invalidatesTags: ['Members'],
    }),
  }),
});

export const {
  useGetMembersQuery,
  useUpdateMemberPermissionMutation,
  useAddMemberMutation,
  useGetMemberQuery,
  useGetMemberVacanciesQuery,
  useDeleteMemberMutation,
  useGetVacancyMembersQuery,
  useAddCandidateMemberMutation,
  useCancelInviteMutation,
  useResendInviteMutation,
} = membersApi;
