import { School } from 'models/School';

export const getVacancyType = (pathname: string) => {
  if (pathname.includes('drafted')) return 'drafted';
  if (pathname.includes('archived')) return 'archived';
  return 'active';
};

export const checkIfSomeSchoolPayedMatching = (
  isMultipleSchools: boolean,
  schoolIds: string[],
  schoolId: string,
  schools: School[],
) =>
  isMultipleSchools
    ? schools?.some(
        (el) =>
          schoolIds?.includes(el._id) &&
          el?.matchingInformation?.isMatchingPaid,
      )
    : !!schools?.find((el) => el?._id === schoolId)?.matchingInformation
        ?.isMatchingPaid;

export const checkIfSomeVacancySchoolHasPaidMatching = (schools: School[]) =>
  schools?.some((el) => el.matchingInformation?.isMatchingPaid);
