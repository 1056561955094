import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DoneIcon } from 'assets/images/Done.icon.svg';
import { numberPriceToCurrencyString } from 'utils';
import { STANDALONE_PRICE } from 'searchality-data';

const STANDALONE_ITEMS = ['Pricing.Standalone.li1', 'Pricing.Standalone.li2'];

export const useLeftCardContent = () => {
  const { t } = useTranslation();

  const standaloneItems = useMemo(
    () => (
      <ul>
        {STANDALONE_ITEMS.map((el, index) => (
          <li key={index}>
            <p className="card__description">
              <DoneIcon />
              {t(el)}
            </p>
          </li>
        ))}
      </ul>
    ),
    [t],
  );

  return {
    title: t('Pricing.Standalone.title'),
    subtitle: t('Pricing.Standalone.price', {
      price: numberPriceToCurrencyString(STANDALONE_PRICE),
    }),
    description: t('Pricing.Standalone.billed'),
    content: standaloneItems,
  };
};
