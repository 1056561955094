import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { School } from 'models/School';
import { Checkbox } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import {
  GROUP_ADDITIONAL_SCHOOL_PRICE,
  InternationalStripeProductType,
  WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
} from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';
import ValueContext from 'providers/ValueContext/Value.context';
import OrderItem from '../OrderItem';
import CheckoutContext from 'providers/Checkout/Checkout.context';

import './OrderSchoolItem.styles.scss';

type OrderSchoolItemProps = {
  className?: string;
  withoutCheckbox?: boolean;
  includedInGroupPrice?: boolean;
  onChange: (_id: string) => void;
} & School;

const OrderSchoolItem: React.FC<OrderSchoolItemProps> = (props) => {
  const {
    className,
    _id,
    name,
    onChange,
    withoutCheckbox = false,
    includedInGroupPrice = false,
  } = props;

  const { t } = useTranslation();

  const { selectedIds } = useContext(ValueContext);
  const { stripePreviewObject, isSubscriptionCheckout } =
    useContext(CheckoutContext);

  const classes = classNames('order-school-item', className);

  const schoolLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find((element) =>
        isSubscriptionCheckout
          ? element?.price?.product?.name ===
            InternationalStripeProductType.ANNUAL_ADDITIONAL_SCHOOL_SUBSCRIPTION
          : element?.price?.product?.name ===
            InternationalStripeProductType.MONTHLY_SCHOOL_SUBSCRIPTION,
      ),
    [isSubscriptionCheckout, stripePreviewObject?.lines?.data],
  );

  const schoolPrice = useMemo(() => {
    const amount = schoolLineObject?.discount_amounts?.[0]?.amount;
    if (amount > 0) {
      return isSubscriptionCheckout
        ? GROUP_ADDITIONAL_SCHOOL_PRICE - amount / schoolLineObject?.quantity
        : GROUP_ADDITIONAL_SCHOOL_PRICE - amount;
    }
    return GROUP_ADDITIONAL_SCHOOL_PRICE;
  }, [
    isSubscriptionCheckout,
    schoolLineObject?.discount_amounts,
    schoolLineObject?.quantity,
  ]);

  const schoolPriceComponent = useMemo(
    () =>
      !includedInGroupPrice ? (
        <>
          {schoolLineObject?.discount_amounts?.[0]?.amount > 0 && (
            <span>
              {numberPriceToCurrencyString(GROUP_ADDITIONAL_SCHOOL_PRICE)}
            </span>
          )}{' '}
          {numberPriceToCurrencyString(schoolPrice)}
        </>
      ) : (
        t('OrderSummary.includedInGroupPrice')
      ),
    [includedInGroupPrice, schoolLineObject?.discount_amounts, schoolPrice, t],
  );

  const widgetLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
          InternationalStripeProductType.ADDITIONAL_SCHOOL_WIDGET_INTEGRATION,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const widgetIntegrationPrice = useMemo(() => {
    const amount = widgetLineObject?.discount_amounts?.[0]?.amount;
    if (amount > 0) {
      return (
        WEBSITE_ADDITIONAL_INTEGRATION_PRICE -
        amount / widgetLineObject?.quantity
      );
    }
    return WEBSITE_ADDITIONAL_INTEGRATION_PRICE;
  }, [widgetLineObject?.discount_amounts, widgetLineObject?.quantity]);

  const widgetIntegrationLabel = useMemo(
    () =>
      includedInGroupPrice ? (
        t('OrderSummary.includedInGroupPrice')
      ) : (
        <>
          {widgetLineObject?.discount_amounts?.[0]?.amount > 0 && (
            <span>
              {numberPriceToCurrencyString(
                WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
              )}
            </span>
          )}{' '}
          {numberPriceToCurrencyString(widgetIntegrationPrice)}
        </>
      ),
    [
      includedInGroupPrice,
      t,
      widgetIntegrationPrice,
      widgetLineObject?.discount_amounts,
    ],
  );

  return (
    <>
      <OrderItem
        className={classes}
        name={t('OrderSummary.OrderItem.SchoolItem.schoolFeeName', { name })}
        price={schoolPriceComponent}
      />
      <OrderItem
        className={classes}
        name={t('OrderSummary.OrderItem.SchoolItem.widgetIntegrationName', {
          name,
        })}
        price={widgetIntegrationLabel}
      >
        {!withoutCheckbox && (
          <Checkbox
            className="order-school-item__widget-price"
            onChange={() =>
              !includedInGroupPrice &&
              onChange(isSubscriptionCheckout ? _id : name)
            }
            checked={
              includedInGroupPrice ||
              selectedIds?.includes(isSubscriptionCheckout ? _id : name)
            }
            disabled={includedInGroupPrice}
          />
        )}
      </OrderItem>
    </>
  );
};

export default OrderSchoolItem;
