import { useFormState } from 'react-final-form';
import { SchoolCurriculumType } from 'searchality-data';
import utils from 'utils';
import { CURRICULUM_TYPE_ROUTE } from '../subrouters/SchoolTypeRouter/constants';

export default function useSchoolInformationRoute(withPreview = true) {
  const {
    values: { curriculumType, residentialType },
  } = useFormState();

  const route = `${
    CURRICULUM_TYPE_ROUTE[curriculumType as SchoolCurriculumType]
  }/residential-type/${utils.mapResidentialTypeToRoute(
    residentialType,
  )}/school-information`;

  return route;
}
