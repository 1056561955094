import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'icons/Calendar.icon.svg';
import { Country } from 'searchality-data';
import { formatDate } from 'utils';

import './ProfileWorkAvailabilitySection.styles.scss';

type ProfileWorkAvailabilitySectionProps = {
  className?: string;
  startDate: Date | string;
  passports: Country[];
  country: string;
  hasRightToWork: boolean;
};

const ProfileWorkAvailabilitySection: React.FC<
  ProfileWorkAvailabilitySectionProps
> = (props) => {
  const { className, startDate, passports, hasRightToWork, country } = props;

  const { t } = useTranslation();

  const baseClass = 'profile-work-availability-section';
  const classes = classNames(baseClass, 'profile-section', className);

  return (
    <div className={classes}>
      <div className="profile-section__title">
        <CalendarIcon />
        <p>{t('Profile.WorkProfile.workAvailability')}</p>
      </div>
      <div className={`${baseClass}__section`}>
        <p className="title">{t('Profile.WorkProfile.startingDate')}</p>
        <p className="value">
          {startDate
            ? formatDate(startDate)
            : startDate === null
            ? t('immediately')
            : t('noData')}
        </p>
      </div>
      <div className={`${baseClass}__section`}>
        <p className="title">{t('Profile.WorkProfile.citizenship')}</p>
        {passports?.length ? (
          <p className="value max-width">{passports?.join(', ')}</p>
        ) : (
          <p className="value">{t('noData')}</p>
        )}
      </div>
      {country && hasRightToWork !== undefined && (
        <div className={`${baseClass}__section`}>
          <p className="title">{t('rightToWorkIn', { country })}</p>
          {hasRightToWork ? (
            <p className="value">{t('Profile.WorkProfile.have')}</p>
          ) : (
            <p className="value">{t('Profile.WorkProfile.dontHave')}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileWorkAvailabilitySection;
