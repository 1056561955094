import { useTranslation } from 'react-i18next';
import { HealthRequirements } from 'searchality-data';

export default () => {
  const { t } = useTranslation();
  return Object.values(HealthRequirements).map((value) => ({
    label: t(`HealthRequirementsOptions.${value}`),
    value,
  }));
};
