import React from 'react';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { useTranslation } from 'react-i18next';
import { InternationalRightToWorkType } from 'searchality-data';

const useWorkRightOptions = () => {
  const { t } = useTranslation();

  return Object.keys(InternationalRightToWorkType).map((key) => ({
    label: <RadioButtonLabel label={t(`workRequiredTypeOptions.${key}`)} />,
    value:
      InternationalRightToWorkType[
        key as keyof typeof InternationalRightToWorkType
      ],
  }));
};

export default useWorkRightOptions;
