import React, { useContext } from 'react';
import BannersContext from 'providers/Banners/Banners.context';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import ViewGuard from 'router/components/ViewGuard';
import { Role } from 'searchality-data';
import Banner from 'components/Banner';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';

type HiringPreferencesBannerProps = {
  className?: string;
  cameThroughVacancy?: boolean;
};

const HiringPreferencesBanner: React.FC<HiringPreferencesBannerProps> = (
  props,
) => {
  const { className, cameThroughVacancy } = props;

  const navigate = useNavigate();

  const { showHiringPreferences: showBanner, updateHiringPreferencesBanner } =
    useContext(BannersContext);

  const { t } = useTranslation();

  const {
    schoolGroup: { hiringPreferences },
  } = useAppSelector(authSelectors.selectUser);

  const { isFilled } = hiringPreferences || {};

  if (isFilled) {
    return null;
  }

  return (
    <ViewGuard roles={[Role.AccountOwner]} permission="allow">
      <div className={className}>
        <Banner
          showBanner={showBanner}
          onClose={() => updateHiringPreferencesBanner(false)}
          icon={<EducationIcon />}
          title={t('HiringPreferencesBanner.title')}
          subtitle={t('HiringPreferencesBanner.subtitle')}
          actions={
            <Button
              onClick={() =>
                navigate(
                  '/my-account/hiring-preferences-settings/school-profile',
                  { state: { cameThroughVacancy } },
                )
              }
            >
              {t('HiringPreferencesBanner.buttonText')}
            </Button>
          }
        />
      </div>
    </ViewGuard>
  );
};

export default HiringPreferencesBanner;
