import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ToggleField from 'components/ToggleField';
import SelectField from 'components/SelectField';
import useMaritalStatusOptions from 'hooks/selectOptions/useMaritalStatusOptions';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';

import './JobApplicationRelocation.styles.scss';
import './JobApplicationRelocation.styles.responsive.scss';

type JobApplicationRelocationProps = {
  className?: string;
};

const JobApplicationRelocation: React.FC<JobApplicationRelocationProps> = (
  props,
) => {
  const { className } = props;

  const maritalStatusOptions = useMaritalStatusOptions(true);

  const {
    values: { relocation },
  } = useFormState();

  const { isRelocationNotRequired, isWithChildren } = relocation || {};

  const { t } = useTranslation();

  const baseClass = 'job-application-relocation';
  const classes = classNames(baseClass, className);

  const relocationRequired = useCallback(
    (value: any, allValues: any) => {
      if (
        !allValues?.relocation?.isRelocationNotRequired &&
        value === undefined
      ) {
        return t('requiredField');
      }
    },
    [t],
  );

  return (
    <div className={classes}>
      <Field
        name="relocation.isRelocationNotRequired"
        component={CheckboxField}
        label={t('JobApplication.Relocation.liveLocally')}
        isStyledDefault
        type="checkbox"
      />
      <div className={`${baseClass}__field`}>
        <p>{t('teacherProfileBuilder.personalInformation.maritalStatus')}</p>
        <Field
          name="relocation.maritalStatus"
          component={SelectField}
          options={maritalStatusOptions}
          label={t('maritalStatus')}
          validate={relocationRequired}
          required={!isRelocationNotRequired}
        />
      </div>
      <div className={`${baseClass}__field`}>
        <p>{t('teacherProfileBuilder.personalInformation.relocate')}</p>
        <Field
          key={'partner' + isRelocationNotRequired?.toString()}
          name="relocation.isRelocatingWithPartner"
          component={ToggleField}
          yesLabel={t('withAPartner')}
          noLabel={t('alone')}
          reverse
          validate={relocationRequired}
        />
      </div>
      <div className={`${baseClass}__field`}>
        <p>{t('teacherProfileBuilder.personalInformation.children')}</p>
        <Field
          key={'children' + isRelocationNotRequired?.toString()}
          component={ToggleField}
          reverse
          name="relocation.isWithChildren"
          validate={relocationRequired}
        />
        {isWithChildren && (
          <Field
            key={'number' + isRelocationNotRequired?.toString()}
            name="relocation.numberOfChildren"
            component={InputField}
            label={t('teacherProfileBuilder.personalInformation.howMany')}
            validate={relocationRequired}
            onlyNumbers
          />
        )}
      </div>
    </div>
  );
};

export default JobApplicationRelocation;
