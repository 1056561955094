import api from 'api';
import { useState, useEffect } from 'react';
import { ApplicationSchoolStatusWithFavourites } from 'types';

function useCandidatesFiltersInfo(
  vacancyId: string,
  currentStatus: ApplicationSchoolStatusWithFavourites,
) {
  const [filterInfo, setFilterInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCandidatesFilterInfo = async () => {
      try {
        const { data } = await api.candidates.getCandidatesFiltersInfo(
          vacancyId,
        );
        setFilterInfo(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    if (vacancyId) fetchCandidatesFilterInfo();
  }, [vacancyId, currentStatus]);

  return { filterInfo, isLoading };
}

export default useCandidatesFiltersInfo;
