import httpClient from 'api/httpClient';
import { Candidate } from 'models/Candidate';
import { VacancyCandidatesFilterInfo } from 'models/Vacancy';
import {
  GetCommentsType,
  GlobalFilters,
  PaginatedResponse,
  Params,
} from 'types';

function getCandidates(params?: Params) {
  return httpClient.get<PaginatedResponse<Candidate>>('/candidates', {
    params,
  });
}

function getCandidatesFilters(params?: Partial<Params>) {
  return httpClient.get<GlobalFilters>('candidates/filters', { params });
}

function getCandidatesFiltersInfo(vacancyId: string) {
  return httpClient.get<VacancyCandidatesFilterInfo[]>(
    `vacancies/${vacancyId}/candidates/info`,
  );
}

function getComments(id: string, params: GetCommentsType) {
  return httpClient.get(`candidates/${id}/comments`, { params });
}

function addComment(id: string, content: string) {
  return httpClient.post(`candidates/${id}/comments`, { content });
}

export default {
  getCandidates,
  getCandidatesFilters,
  getCandidatesFiltersInfo,
  getComments,
  addComment,
};
