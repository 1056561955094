import React, { useMemo } from 'react';
import classNames from 'classnames';
import ApplicationInformationCard from '../ApplicationInformationCard';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { EducationLevel } from 'searchality-data';
import DateService from 'services/Date.service';
import { DateTime } from 'luxon';
import pdfImage from 'assets/images/pdf-image.webp';
import { Vacancy } from 'models/Vacancy';

import './ApplicationPreviewAboutPosition.styles.scss';

type ApplicationPreviewAboutPositionProps = {
  className?: string;
  vacancy: Vacancy;
};

const ApplicationPreviewAboutPosition: React.FC<
  ApplicationPreviewAboutPositionProps
> = (props) => {
  const { className, vacancy } = props;

  const classes = classNames('application-preview-about-position', className);

  const { t } = useTranslation();

  const startDateJobApplication = useMemo(() => {
    if (!vacancy) return;

    if (
      DateService.toDateString(new Date(vacancy?.startDate)) ===
      DateService.toDateString(new Date(0))
    ) {
      return t('applicationPreview.startDateFullTime', {
        startDate: t('immediately'),
      });
    }

    const { monthLong, day, year } = DateTime.fromJSDate(
      new Date(vacancy?.startDate),
    );

    const startDate = `${monthLong} ${day}, ${year}`;

    return t('applicationPreview.startDateFullTime', { startDate });
  }, [t, vacancy]);

  const curriculumExperience = useMemo(() => {
    if (!vacancy?.isSpecificCurriculumExperienceRequired) {
      return (
        <li>
          <b>{t('jobPreview.teachingSystem')}</b>
          {t('noExperienceNeeded')}
        </li>
      );
    }

    if (vacancy?.curricula?.length < 2) {
      return vacancy?.curricula?.map(
        ({ curriculumExperience, yearsOfCurriculumExperienceRequired }) => (
          <>
            <li>
              <b>{t('jobPreview.teachingSystem')}</b>
              {curriculumExperience}
            </li>
            <li>
              <b>
                {t('jobPreview.minimumCurriculumExperience', {
                  curriculumExperience,
                })}
              </b>
              {t('jobPreview.minimumExperienceRequired', {
                count: yearsOfCurriculumExperienceRequired,
              })}
            </li>
          </>
        ),
      );
    }

    return (
      <li>
        <b>{t('jobPreview.teachingSystem')}</b>
        <ul className="curriculum-list">
          {vacancy?.curricula?.map(
            ({ curriculumExperience, yearsOfCurriculumExperienceRequired }) => (
              <li key={curriculumExperience}>
                {curriculumExperience}, {t('minimum')}{' '}
                {t('jobPreview.minimumExperienceRequired', {
                  count: yearsOfCurriculumExperienceRequired,
                })}
              </li>
            ),
          )}
        </ul>
      </li>
    );
  }, [t, vacancy?.curricula, vacancy?.isSpecificCurriculumExperienceRequired]);

  const languages = useMemo(() => {
    if (vacancy?.requiredLanguages?.length < 2) {
      return vacancy?.requiredLanguages?.map(({ language, level }) => (
        <li key={language}>
          <b>{t('jobPreview.language')}</b>
          {`${language} / ${level}`}
        </li>
      ));
    }

    return (
      <li>
        <b>{t('jobPreview.languages')}</b>
        <ul>
          {vacancy?.requiredLanguages?.map(({ language, level }) => (
            <li key={language}>{`${language} / ${level}`}</li>
          ))}
        </ul>
      </li>
    );
  }, [t, vacancy?.requiredLanguages]);

  return (
    <ApplicationInformationCard className={classes}>
      <h4>{t('aboutOurPosition')}</h4>
      <div
        dangerouslySetInnerHTML={utils.createMarkup(vacancy?.roleDescription)}
      />
      <h4 className="title-margin-top">{t('vacancyInformation')}</h4>
      <ul>
        <li>
          {t('jobPreview.locationSubtitle') +
            vacancy?.schools?.[0]?.name +
            ', ' +
            vacancy?.schools?.[0]?.city +
            ', ' +
            vacancy?.schools?.[0]?.country}
        </li>
        <li>{startDateJobApplication}</li>
        <li>
          {t('jobPreview.employmentType')}
          {vacancy?.isFullTimeEmployment
            ? t('fullTime')
            : t('jobPreview.partTime', {
                details: `, ${vacancy?.amountOfTimeRequired}`,
              })}
        </li>
        <li>
          {t('applicationPreview.contractType', {
            type: vacancy?.isPermanentContract
              ? t('permanent')
              : t('jobPreview.temporary', {
                  details: `, ${vacancy?.lengthOfContract}`,
                }),
          })}
        </li>
        <li>
          <b>{t('jobPreview.contractTerms')}</b>
          <ul>
            <li>
              {t('jobPreview.cre', {
                date: `${utils.convertToOrdinal(
                  vacancy?.contractRenewalDate?.day,
                )} ${vacancy?.contractRenewalDate?.month}`,
              })}
            </li>
            <li>
              {t('jobPreview.sd', {
                date: `${utils.convertToOrdinal(
                  vacancy?.teacherStartDate?.day ??
                    vacancy?.adminStartDate?.day,
                )} ${
                  vacancy?.teacherStartDate?.month ??
                  vacancy?.adminStartDate?.month
                }`,
              })}
            </li>
            <li>
              {t('jobPreview.ld', {
                date: `${utils.convertToOrdinal(
                  vacancy?.teacherEndDate?.day ?? vacancy?.adminEndDate?.day,
                )} ${
                  vacancy?.teacherEndDate?.month ?? vacancy?.adminEndDate?.month
                }`,
              })}
            </li>
            <li>
              {t('jobPreview.cd', {
                number:
                  vacancy?.teacherContractedWorkingDays ??
                  vacancy?.adminContractedWorkingDays,
              })}
            </li>
          </ul>
        </li>
      </ul>
      {vacancy?.positionDescriptionUrl && (
        <div className="application-preview-about-position__detailed-container">
          <p>{t('jobPreview.viewFullDescription')}</p>
          <a
            href={vacancy.positionDescriptionUrl}
            target="_blank"
            rel="noreferrer"
          >
            <img src={pdfImage} alt="pdf" />
            {t('detailedPositionDescription')}
          </a>
        </div>
      )}
      <h4 className="title-margin-top">{t('requirements')}</h4>
      <ul>
        <li>
          {t('applicationPreview.minimumDiplomaRequired', {
            diploma: vacancy?.educationLevel,
          })}
        </li>
        <li>
          {vacancy?.yearsOfExperienceRequired > 0
            ? t('applicationPreview.minimumExperienceRequired', {
                count: vacancy?.yearsOfExperienceRequired,
              })
            : t('noExperienceRequired')}
        </li>
        {curriculumExperience}
        {languages}
        {!!vacancy?.certificates?.length &&
          vacancy?.educationLevel === EducationLevel.TEACHING_DIPLOMA && (
            <li>
              {t('educationCertificates')}
              <ul>
                {vacancy.certificates?.map((certificate, index) => (
                  <li key={index}>{certificate}</li>
                ))}
              </ul>
            </li>
          )}
      </ul>
      <h4 className="title-margin-top">
        {vacancy?.isRightToWorkRequired
          ? t('rightToWorkIn', {
              country: vacancy?.schools?.[0]?.country,
            })
          : t('relocation')}
      </h4>
      <ul>
        <li>
          {vacancy?.isRightToWorkRequired
            ? t('rightToWorkDescription', {
                country: vacancy?.schools?.[0]?.country,
              })
            : t('relocationDescription', {
                country: vacancy?.schools?.[0]?.country,
              })}
        </li>
      </ul>
    </ApplicationInformationCard>
  );
};

export default ApplicationPreviewAboutPosition;
