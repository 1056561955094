import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import FormFieldLabel from 'components/FormFieldLabel';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import { required } from 'validations';
import {
  BoardingPositionRequirementStatus,
  VacancyStatus,
} from 'searchality-data';
import RadioButtonLabel from 'components/RadioButtonLabel';
import TextAreaField from 'components/TextAreaField';
import DropzoneField from 'components/DropzoneField';

import './BoardingSchoolPositionRequirements.styles.scss';

type BoardingSchoolPositionRequirementsProps = {
  className?: string;
};

const BoardingSchoolPositionRequirements: React.FC<
  BoardingSchoolPositionRequirementsProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'boarding-school-position-requirements';
  const classes = classNames(baseClass, className);

  const {
    values: { boardingPositionRequirementStatus, status },
  } = useFormState();

  const { change, batch } = useForm();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const options = useMemo(
    () =>
      Object.entries(BoardingPositionRequirementStatus).map(([key, value]) => ({
        label: (
          <RadioButtonLabel
            label={t(`BoardingPositionRequirementStatus.${key}`)}
          />
        ),
        value,
      })),
    [t],
  );

  useEffect(() => {
    if (
      boardingPositionRequirementStatus &&
      boardingPositionRequirementStatus ===
        BoardingPositionRequirementStatus.NOT_REQUIRED
    ) {
      batch(() => {
        change('BoardingPositionRequirements', undefined);
        change('BoardingPositionBenefits', undefined);
        change('boardingRoleDescription', []);
      });
    }
  }, [batch, boardingPositionRequirementStatus, change]);

  return (
    <div className={classes}>
      <h3>{t('JobApplication.boardingSectionTitle')}</h3>
      <div className={`${baseClass}__radio-group--wrapper`}>
        <FormFieldLabel text={t('vacancyWizard.step3.boardingSchoolParent')} />
        <p className="boarding-description">
          {t('vacancyWizard.step3.boardingSchoolParentDescription')}
        </p>
        <Field
          name="boardingPositionRequirementStatus"
          component={RadioGroupField}
          validate={required()}
          options={options}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      {boardingPositionRequirementStatus &&
        boardingPositionRequirementStatus !==
          BoardingPositionRequirementStatus.NOT_REQUIRED && (
          <div className={`${baseClass}__additional-fields`}>
            <FormFieldLabel
              text={t('vacancyWizard.step3.boardingRequirements')}
            />
            <div className={`${baseClass}__additional-fields__role-desc`}>
              <FormFieldLabel text={t('vacancyWizard.step3.roleDescription')} />
              <Field
                name="boardingPositionRequirements.roleDescription"
                component={TextAreaField}
                placeholder={t('vacancyWizard.step3.roleDescriptionLabel')}
                required
                validate={required()}
              />
              <p className="boarding-description">
                {t('vacancyWizard.step3.roleDescriptionFile')}
              </p>
              <Field name="boardingRoleDescription" component={DropzoneField} />
            </div>
          </div>
        )}
    </div>
  );
};

export default BoardingSchoolPositionRequirements;
