import { useTranslation } from 'react-i18next';
import { MaritalStatus } from 'searchality-data';

export default (isWithoutPreferNotToAnwer?: boolean) => {
  const { t } = useTranslation();

  let options = Object.entries(MaritalStatus);

  if (isWithoutPreferNotToAnwer) {
    options = options.filter(
      ([_, value]) => value !== MaritalStatus.PREFER_NOT_TO_ANSWER,
    );
  }

  return options.map(([key, value]) => ({
    label: t(`MaritalStatus.${key}`),
    value,
  }));
};
