import { getFileFromURL } from 'components/ImageCropper/utils';
import { DEFAULT_COUNTRY_CODE } from 'constants/general';
import { t } from 'i18next';
import { Application, ExperienceType, Reference } from 'models/Candidate';
import { User } from 'models/User';
import TeacherProfileDataService from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/services/TeacherProfileData.service';
import {
  ApplicationType,
  InternationalRightToWorkType,
} from 'searchality-data';
import ModelsMappingService from 'services/ModelsMapping.service';
import {
  JobApplicationCurricula,
  JobApplicationFormHCR,
  JobApplicationFormPersonalInformation,
  JobApplicationFormPositionRequirements,
  JobApplicationFormRelocation,
  JobApplicationFormValues,
  JobApplicationFormYourProfile,
} from '../types/JobApplicationFormValues';
import { CurriculumExp } from 'models/Vacancy';
import { TeacherCurriculla } from 'models/Teacher';

const convertPersonalInformationIntoApplication = (
  personalInformation: JobApplicationFormPersonalInformation,
) => {
  const { firstName, lastName, address, email, phoneNumber, videoUrl } =
    personalInformation;

  return {
    firstName,
    lastName,
    address,
    email,
    ...(phoneNumber?.phoneNumber && { phoneNumber }),
    videoUrl,
  };
};

const convertYourProfileIntoApplication = (
  yourProfile: JobApplicationFormYourProfile,
) => {
  const { experience, education } = yourProfile;

  const parsedExperiences: ExperienceType[] = experience?.map((exp) =>
    ModelsMappingService.parseExperience(exp),
  );
  return {
    experience: parsedExperiences?.length > 0 ? parsedExperiences : undefined,
    education: education
      ? education?.map(({ endDate, ...rest }) => ({
          ...rest,
          endDate: rest.isCurrentlyStudying ? undefined : endDate,
        }))
      : undefined,
  };
};

const convertReferencesIntoApplicaiton = (references: Reference[]) => {
  return references?.length > 0
    ? references?.map((reference) => ({
        ...reference,
        contactPhone: reference.contactPhone?.phoneNumber
          ? reference.contactPhone
          : undefined,
      }))
    : undefined;
};

const transformToApplicationCurricula = (
  curricula: Record<string, JobApplicationCurricula>,
) => {
  const entries = Object.entries(curricula ?? {});

  if (!entries?.length) {
    return undefined;
  }

  return entries.map(([key, value]) => ({
    curriculumExperience: key,
    ...value,
  }));
};

const convertPositionRequirementIntoApplication = (
  values: JobApplicationFormValues,
) => {
  const {
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    curricula,
    earliestAvailableStartingDate,
    isAbleToStartOnRequiredDate,
    languageRequirement,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
  } = values.positionRequirements;

  const mappedLanguageRequirement =
    ModelsMappingService.formatLanguageReuirementType(languageRequirement);

  const applicationCurricula = transformToApplicationCurricula(curricula);

  return {
    yearsOfExperienceWithFaithBasedSchools,
    doesMeetMinimumEducationLevel,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
    curricula: applicationCurricula,
    earliestAvailableStartingDate: isAbleToStartOnRequiredDate
      ? undefined
      : earliestAvailableStartingDate,

    isAbleToStartOnRequiredDate,
    doesHaveYearsOfExperience,
    languageRequirement:
      mappedLanguageRequirement?.length === 0
        ? undefined
        : mappedLanguageRequirement,
  };
};

const convertHCRIntoApplication = (hcr: JobApplicationFormHCR) => {
  const {
    hasRightToWork,
    dateUntilVisaIsValid,
    doesCandidateMeetAllRequirementsForVisa,
    rightToWork,
    passports,
    visaType,
    citizenships,
  } = hcr;

  if (hasRightToWork) {
    if (rightToWork === InternationalRightToWorkType.WORK_PERMIT) {
      return {
        citizenships,
        dateUntilVisaIsValid,
        visaType,
        hasRightToWork,
        rightToWork,
      };
    } else {
      return {
        citizenships,
        passports,
        hasRightToWork,
        rightToWork,
      };
    }
  }

  return {
    hasRightToWork,
    rightToWork,
    doesCandidateMeetAllRequirementsForVisa,
  };
};

const convertRelocationIntoApplication = (
  relocation: JobApplicationFormRelocation,
) => {
  const {
    isRelocatingWithPartner,
    isRelocationNotRequired,
    isWithChildren,
    numberOfChildren,
    maritalStatus,
  } = relocation;

  return {
    isRelocatingWithPartner,
    isRelocationNotRequired,
    isWithChildren,
    numberOfChildren: parseInt(numberOfChildren),
    maritalStatus,
  };
};

/**
 * When user submits/save-as-draft application
 * @param values
 * @returns
 */
const convertFormIntoApplication = (
  values: JobApplicationFormValues,
  type: ApplicationType,
) => {
  const submitObject: Partial<Application> = {
    ...convertHCRIntoApplication(values.hostCountryRequirements),
    ...convertPersonalInformationIntoApplication(values.personalInformation),
    ...convertRelocationIntoApplication(values.relocation),
    ...convertYourProfileIntoApplication(values.yourProfile),
    ...convertPositionRequirementIntoApplication(values),
    references: convertReferencesIntoApplicaiton(values.references),
    ...(values?.isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent:
        values?.isAbleToWorkAsBoardingParent === 'true' ? true : false,
    }),
    type,
  };

  return submitObject;
};
/**
 * It's used in case when you want to finish your application (finish the draft)
 * Application -> ApplicationProfile ...
 * @param application
 * @returns
 */

const convertApplicationToJobApplicationForm = async (
  application: Application,
) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    videoUrl,
    education,
    references,
    experience,
    educationPhilosophyUrl,
    languageRequirement,
    rightToWork,
    doesHaveYearsOfExperience,
    visaType,
    dateUntilVisaIsValid,
    imageFile,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    isAbleToStartOnRequiredDate,
    doesMeetMinimumEducationLevel,
    curricula,
    earliestAvailableStartingDate,
    hasRightToWork,
    coverLetterUrl,
    resumeUrl,
    publications,
    englishLevel,
    isAbleToWorkAsBoardingParent,
    citizenships,
    maritalStatus,
    isRelocatingWithPartner,
    isRelocationNotRequired,
    isWithChildren,
    numberOfChildren,
    passports,
    doesCandidateMeetAllRequirementsForVisa,
  } = application;

  const formCurricula = curricula?.reduce(
    (prev, { curriculumExperience, ...rest }) => ({
      ...prev,
      [curriculumExperience]: rest,
    }),
    {},
  );

  const positionRequirements: JobApplicationFormPositionRequirements = {
    languageRequirement:
      ModelsMappingService.parseApplicationLangugageToForm(languageRequirement),
    isAbleToStartOnRequiredDate,
    curricula: formCurricula,
    doesMeetMinimumEducationLevel,
    doesHaveYearsOfExperience,
    earliestAvailableStartingDate,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
  };

  const formValues: JobApplicationFormValues = {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      videoUrl,
      applicantPhoto: undefined,
    },
    yourProfile: {
      education,
      experience: experience?.map((exp) =>
        ModelsMappingService.formatExperience(exp),
      ),
      educationPhilosophy: undefined,
      coverLetter: null,
      resume: undefined,
    },
    positionRequirements,
    references,
    publications: await TeacherProfileDataService.formatPublications(
      publications,
    ),
    ...(isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent: isAbleToWorkAsBoardingParent
        ? 'true'
        : 'false',
    }),
    relocation: {
      maritalStatus,
      isRelocationNotRequired,
      isRelocatingWithPartner,
      isWithChildren,
      numberOfChildren: numberOfChildren?.toString(),
    },
    hostCountryRequirements: {
      citizenships,
      hasRightToWork,
      rightToWork,
      passports,
      visaType,
      dateUntilVisaIsValid,
      doesCandidateMeetAllRequirementsForVisa,
    },
  };

  if (imageFile) {
    const file = await getFileFromURL(imageFile.url, 'photo', 'image/jpeg');
    formValues.personalInformation = {
      ...formValues.personalInformation,
      applicantPhoto: [file],
    };
  }

  if (educationPhilosophyUrl) {
    const file = await getFileFromURL(
      educationPhilosophyUrl,
      t('educationPhilosophy'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      educationPhilosophy: [file],
    };
  }
  if (coverLetterUrl) {
    const file = await getFileFromURL(
      coverLetterUrl,
      t('coverLetter'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      coverLetter: [file],
    };
  }

  if (resumeUrl) {
    const file = await getFileFromURL(
      resumeUrl,
      t('resume'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      resume: [file],
    };
  }

  return formValues;
};

const populateTeacherCurricula = (
  vacancyCurrciula: CurriculumExp[],
  teacherCurricula: TeacherCurriculla[],
) => {
  if (!vacancyCurrciula?.length) return {};

  return vacancyCurrciula?.reduce((prev, curr) => {
    const curricula = teacherCurricula?.find(
      (el) => el?.curriculumExperience === curr?.curriculumExperience,
    );

    return ((prev as any)[curr.curriculumExperience] = {
      doesHaveExperienceInTheField: !!curricula,
      yearsOfExperienceInTheField: curricula?.yearsOfExperience,
    });
  }, {});
};

const populateFormWithTeacher = async (
  user: User,
  isEducationPhilosophyRequired: boolean,
  requiredLanguages?: {
    language: string;
    level: string;
  }[],
  curricula?: CurriculumExp[],
) => {
  const {
    firstName,
    lastName,
    email,
    mobilePhone,
    streetAddress,
    videoUrl,
    education,
    references,
    curricula: teacherCurricula,
    experience,
    educationPhilosophyUrl,
    languages,
    imageFile,
    publications,
    englishLevel,
    isAbleToWorkAsBoardingParent,
    passports,
    maritalStatus,
    isWithChildren,
    isWithChildrenAnswerRejected,
    isRelocatingWithPartnerAnswerRejected,
    isRelocatingWithPartner,
    numberOfChildren,
  } = user;

  const applicationCurricula = populateTeacherCurricula(
    curricula,
    teacherCurricula,
  );

  const formValues: JobApplicationFormValues = {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber: mobilePhone,
      address: streetAddress,
      videoUrl,
      applicantPhoto: undefined,
    },
    yourProfile: {
      education,
      experience: experience.map((exp) =>
        ModelsMappingService.formatExperience(exp),
      ),
      educationPhilosophy: undefined,
      coverLetter: undefined,
      resume: undefined,
    },
    positionRequirements: {
      languageRequirement: ModelsMappingService.parseLanguageReuirementType(
        languages,
        requiredLanguages,
      ),
      curricula: applicationCurricula,
      englishLevel,
    },
    references,
    publications: await TeacherProfileDataService.formatPublications(
      publications,
    ),
    ...(isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent: isAbleToWorkAsBoardingParent
        ? 'true'
        : 'false',
    }),
    relocation: {
      maritalStatus,
      isRelocationNotRequired: undefined,
      isRelocatingWithPartner: isRelocatingWithPartnerAnswerRejected
        ? undefined
        : isRelocatingWithPartner,
      isWithChildren: isWithChildrenAnswerRejected ? undefined : isWithChildren,
      numberOfChildren: numberOfChildren?.toString(),
    },
    hostCountryRequirements: {
      citizenships: passports,
    },
  };

  if (!mobilePhone) {
    formValues.personalInformation.phoneNumber = {
      countryCode: DEFAULT_COUNTRY_CODE,
    };
  }

  if (imageFile) {
    const file = await getFileFromURL(imageFile.url, 'photo', 'image/jpeg');
    formValues.personalInformation = {
      ...formValues.personalInformation,
      applicantPhoto: [file],
    };
  }

  if (educationPhilosophyUrl && isEducationPhilosophyRequired) {
    const file = await getFileFromURL(
      educationPhilosophyUrl,
      t('educationPhilosophy'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      educationPhilosophy: [file],
    };
  }

  return formValues;
};

export default {
  convertFormIntoApplication,
  populateFormWithTeacher,
  convertApplicationToJobApplicationForm,
};
