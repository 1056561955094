import React from 'react';
import classNames from 'classnames';
import SpbTopActions from '../../components/SpbTopActions';
import SpbBottomActions from '../../components/SpbBottomActions';
import TPBPageContent from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageContent';
import TPBPageHeading from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import FormFieldLabel from 'components/FormFieldLabel';
import ContractDurationField from 'components/ContractDurationField';

import './SpbContractInformation.styles.scss';
import './SpbContractInformation.styles.responsive.scss';
import MonthDayField from 'components/MonthDayField';
import InputField from 'components/InputField';
import {
  required,
  requiredContractDuration,
  requiredDayMonth,
} from 'validations';

type SpbContractInformationProps = {
  className?: string;
};

const SpbContractInformation: React.FC<SpbContractInformationProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'spb-contract-information';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <SpbTopActions to="../host-country-requirements" />
      <TPBPageContent>
        <TPBPageHeading
          text={t('SchoolProfileBuilder.contractInformationTitle')}
          description={t('SchoolProfileBuilder.description')}
        />
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.startingContractDuration')}
          />
          <Field
            name="startingContractDuration"
            component={ContractDurationField}
            validate={requiredContractDuration()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.subsequentContractDuration')}
          />
          <Field
            name="subsequentContractDuration"
            component={ContractDurationField}
            validate={requiredContractDuration()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.contractRenewalDate')}
          />
          <Field
            name="contractRenewalDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.teacherContractedWorkingDays')}
          />
          <Field
            name="teacherContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '120' })}
            validate={required()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.adminContractedWorkingDays')}
          />
          <Field
            name="adminContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '50' })}
            validate={required()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.teacherStartDate')}
          />
          <Field
            name="teacherStartDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.teacherEndDate')} />
          <Field
            name="teacherEndDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.adminStartDate')} />
          <Field
            name="adminStartDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.adminEndDate')} />
          <Field
            name="adminEndDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <SpbBottomActions />
      </TPBPageContent>
    </div>
  );
};

export default SpbContractInformation;
