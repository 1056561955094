import React, { forwardRef, useRef } from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import { ShareVacancyModalRef } from '../../../../components/ShareVacancyModal/ShareVacancyModal.component';
import ShareVacancyModal from '../../../../components/ShareVacancyModal';
import useSaveAsDraftVacancy from '../../../../hooks/useSaveAsDraftVacancy';

import './CloseModal.styles.scss';

type CloseModalProps = {
  className?: string;
} & OverlayProps;

const CloseModal: React.ForwardRefRenderFunction<ModalRef, CloseModalProps> = (
  props,
  ref,
) => {
  const { className, ...rest } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const baseClass = 'close-modal';

  const classes = classNames(baseClass, className);

  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();

  const { saveAsDraft, isSubmitting } =
    useSaveAsDraftVacancy(shareVacancyModalRef);

  return (
    <>
      <Modal
        ref={ref}
        title={t('vacancyBuilderCreateModal.title')}
        className={classes}
        {...rest}
        footer={
          <>
            <Button
              onClick={() => {
                navigate('../../../');
                dispatch(vacancyBuilderActions.clearValues());
              }}
              variant="outline"
              className={`${baseClass}__exit-process`}
            >
              {t('cancelDelete')}
            </Button>
            <Button
              onClick={saveAsDraft}
              className={`${baseClass}__save-draft`}
              disabled={isSubmitting}
            >
              {t('saveDraft')}
            </Button>
          </>
        }
      />
      <ShareVacancyModal
        ref={shareVacancyModalRef}
        title={t('ShareVacancyModal.draftTitle')}
        description={t('ShareVacancyModal.draftDescription')}
        onClose={() => {
          dispatch(vacancyBuilderActions.clearValues());
          navigate('../../../');
        }}
      />
    </>
  );
};

export default forwardRef(CloseModal);
