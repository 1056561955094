import { createApi } from '@reduxjs/toolkit/query/react';
import { Candidate } from 'models/Candidate';
import { ApplicationSchoolStatus } from 'searchality-data';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

export const candidatesApi = createApi({
  reducerPath: 'candidatesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'AllCandidates',
    'QualifiedCandidates',
    'FavoritedCandidates',
    'VacancyCandidates',
    'Candidate',
  ],
  endpoints: (builder) => ({
    getCandidates: builder.query<PaginatedResponse<Candidate>, unknown>({
      query: (params: Params) => ({ url: 'candidates', method: 'get', params }),
      keepUnusedDataFor: 10,
      providesTags: (_, __, params: Params) => {
        if (params?.$where?.isFavourite) return ['FavoritedCandidates'];
        return ['AllCandidates'];
      },
    }),
    getCandidate: builder.query<Candidate, unknown>({
      query: (candidateId) => ({
        url: `candidates/${candidateId}`,
        method: 'get',
        params: {
          $populate: [
            'schools',
            'vacancy',
            'schoolGroup',
            'teacher',
            'requestedDocuments.additionalDocuments.owner',
          ],
        },
      }),
      providesTags: ['Candidate'],
    }),

    updateCandidate: builder.mutation<
      Candidate,
      {
        candidateId: string;
        message: string;
        candidateStatus: ApplicationSchoolStatus;
        skipNotification?: boolean;
        saveCandidate?: boolean;
        ccEmails?: string[];
      }
    >({
      query: (requestInfo) => {
        const {
          candidateId,
          candidateStatus: status,
          message,
          skipNotification,
          saveCandidate,
          ccEmails,
        } = requestInfo;
        return {
          url: `candidates/${candidateId}`,
          method: 'patch',
          data: {
            status,
            message,
            skipNotification,
            saveCandidate,
            ccEmails,
          },
          params: {
            $populate: ['schools', 'vacancy', 'schoolGroup', 'teacher'],
          },
        };
      },
      invalidatesTags: [
        'FavoritedCandidates',
        'AllCandidates',
        'QualifiedCandidates',
        'VacancyCandidates',
      ],
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { candidateId } = requestInfo;

          dispatch(
            candidatesApi.util.updateQueryData(
              'getCandidate',
              candidateId,
              (draft) => {
                return data;
              },
            ),
          );
        });
      },
    }),
    markAsFavorite: builder.mutation<
      Candidate,
      {
        candidateId: string;
        isFavourite: boolean;
      }
    >({
      query: (requestInfo) => {
        const { candidateId, isFavourite } = requestInfo;
        return {
          url: `candidates/${candidateId}/favourite`,
          method: 'post',
          data: {
            isFavourite,
          },
          params: {
            $populate: ['schools', 'vacancy', 'schoolGroup', 'teacher'],
          },
        };
      },
      invalidatesTags: [
        'FavoritedCandidates',
        'AllCandidates',
        'QualifiedCandidates',
        'VacancyCandidates',
      ],
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { candidateId } = requestInfo;

          dispatch(
            candidatesApi.util.updateQueryData(
              'getCandidate',
              candidateId,
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    archiveCandidate: builder.mutation<
      Candidate,
      {
        candidateId: string;
        isArchived: boolean;
      }
    >({
      query: (requestInfo) => {
        const { candidateId, isArchived } = requestInfo;
        return {
          url: `candidates/${candidateId}/archived`,
          method: 'post',
          data: {
            isArchived,
          },
          params: {
            $populate: ['schools', 'vacancy', 'schoolGroup', 'teacher'],
          },
        };
      },
      invalidatesTags: [
        'FavoritedCandidates',
        'AllCandidates',
        'QualifiedCandidates',
        'VacancyCandidates',
      ],
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { candidateId } = requestInfo;

          dispatch(
            candidatesApi.util.updateQueryData(
              'getCandidate',
              candidateId,
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    getVacancyCandidates: builder.query<
      PaginatedResponse<Candidate>,
      { id: string; params?: Params }
    >({
      query: ({ id, params }) => {
        return {
          url: `vacancies/${id}/candidates`,
          method: 'get',
          params,
        };
      },
      providesTags: ['VacancyCandidates'],
    }),
    requestDocuments: builder.mutation<
      Candidate,
      {
        candidateId: string;
        submitByDate: string | Date;
        documents: {
          title: string;
          description: string;
        }[];
        includeVideoDescription: boolean;
        includeFollowUpQuestions: boolean;
      }
    >({
      query: ({
        candidateId,
        documents,
        submitByDate,
        includeFollowUpQuestions,
        includeVideoDescription,
      }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents`,
          method: 'PATCH',
          data: {
            documents,
            submitByDate,
            includeFollowUpQuestions,
            includeVideoDescription,
          },
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
    cancelRequestedDocument: builder.mutation<
      Candidate,
      {
        candidateId: string;
        documentId: string;
      }
    >({
      query: ({ candidateId, documentId }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents/${documentId}/cancel-requested-document`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
    deleteSubmittedDocument: builder.mutation<
      Candidate,
      {
        candidateId: string;
        documentId: string;
        key: string;
      }
    >({
      query: ({ candidateId, documentId, key }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents/${documentId}/delete-file`,
          method: 'PATCH',
          data: {
            key,
          },
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
    addAdditionalDocument: builder.mutation<
      Candidate,
      {
        candidateId: string;
        data: FormData;
      }
    >({
      query: ({ candidateId, data }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents/upload-additional-documents`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
    deleteAdditionalDocument: builder.mutation<
      Candidate,
      {
        candidateId: string;
        key: string;
      }
    >({
      query: ({ candidateId, key }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents/delete-additional-document`,
          method: 'PATCH',
          data: {
            key,
          },
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
    requestAgainDocument: builder.mutation<
      Candidate,
      {
        candidateId: string;
        documentId: string;
        additionalDocumentsRequestReason: string;
      }
    >({
      query: ({
        candidateId,
        documentId,
        additionalDocumentsRequestReason,
      }) => {
        return {
          url: `school-users/applications/${candidateId}/request-documents/${documentId}/request-additional-documents`,
          method: 'PATCH',
          data: {
            additionalDocumentsRequestReason,
          },
        };
      },
      invalidatesTags: ['AllCandidates', 'Candidate'],
    }),
  }),
});

export const {
  useGetCandidatesQuery,
  useGetCandidateQuery,
  useLazyGetCandidateQuery,
  useUpdateCandidateMutation,
  useMarkAsFavoriteMutation,
  useArchiveCandidateMutation,
  useGetVacancyCandidatesQuery,
  useRequestDocumentsMutation,
  useCancelRequestedDocumentMutation,
  useAddAdditionalDocumentMutation,
  useRequestAgainDocumentMutation,
  useDeleteAdditionalDocumentMutation,
  useDeleteSubmittedDocumentMutation,
} = candidatesApi;
