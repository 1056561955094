import React, { useState } from 'react';
import BannersContext from './Banners.context';

type BannersProps = {
  children: JSX.Element;
};

const Banners: React.FC<BannersProps> = (props) => {
  const { children } = props;

  const [showRenew, setShowRenew] = useState(true);
  const [showLink, setShowLink] = useState(true);
  const [showSettlePayment, setShowSettlePayment] = useState(true);
  const [showHiringPreferences, setShowHiringPreferences] = useState(true);

  const updateRenewBanner = (show: boolean) => setShowRenew(show);
  const updateLinkBanner = (show: boolean) => setShowLink(show);
  const updateSettlePaymentBanner = (show: boolean) =>
    setShowSettlePayment(show);
  const updateHiringPreferencesBanner = (show: boolean) =>
    setShowHiringPreferences(show);

  return (
    <BannersContext.Provider
      value={{
        showRenew,
        showLink,
        showSettlePayment,
        showHiringPreferences,
        updateRenewBanner,
        updateLinkBanner,
        updateSettlePaymentBanner,
        updateHiringPreferencesBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};

export default Banners;
