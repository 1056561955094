import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { CURRICULUM_TYPE_ROUTE } from 'router/subrouters/Dashboard/subrouters/CreateSchool/subrouters/SchoolTypeRouter/constants';
import { SchoolCurriculumType } from 'searchality-data';
import utils from 'utils';

export default function () {
  const {
    values: { curriculumType, residentialType },
  } = useFormState();

  return useMemo(
    () =>
      [
        {
          to: 'school-type',
          label: 'School type',
        },
        {
          to: `${
            CURRICULUM_TYPE_ROUTE[curriculumType as SchoolCurriculumType]
          }/residential-type/${utils.mapResidentialTypeToRoute(
            residentialType,
          )}/school-information`,
          label: 'School information',
        },
        {
          to: 'pricing-information',
          label: 'Pricing information',
        },
      ] as const,
    [curriculumType, residentialType],
  );
}
