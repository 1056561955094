import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import { WEBSITE_INTEGRATION_PRICE, STANDALONE_PRICE } from 'searchality-data';
import { School } from 'models/School';

export const useTotalCardContent = (school: School) => {
  const { t } = useTranslation();

  const initialPrice = useMemo(() => STANDALONE_PRICE, []);

  const schoolIncludedInInitialPrice = useMemo(
    () => school?.name,

    [school],
  );

  const totalPrice = useMemo(
    () => initialPrice + WEBSITE_INTEGRATION_PRICE,
    [initialPrice],
  );

  return {
    subtitle: numberPriceToCurrencyString(totalPrice),
    content: (
      <ul>
        <li className="card__li-price">
          {t('Pricing.Total.initialPrice', {
            schoolNames: schoolIncludedInInitialPrice,
            price: numberPriceToCurrencyString(initialPrice),
          })}
        </li>
        <li className="card__li-price">
          {t('Pricing.Total.oneTimeSetupFee', {
            price: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
          })}
        </li>
      </ul>
    ),
  };
};
