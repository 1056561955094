import { ComplexRoute } from 'router/components/Routes/Routes';
import HiringPreferencesSettings from './pages/HiringPreferencesSettings';
import SchoolProfileBuilderRouter from './subrouters/SchoolProfileBuilder/SchoolProfileBuilder.router';
import SchoolProfileBuilderRoutes from './subrouters/SchoolProfileBuilder/SchoolProfileBuilder.routes';
import { Role } from 'searchality-data';

export default [
  {
    path: 'school-profile',
    element: SchoolProfileBuilderRouter,
    routes: SchoolProfileBuilderRoutes,
    authorizedRoles: [Role.AccountOwner],
  },
  {
    index: true,
    element: HiringPreferencesSettings,
  },
] as Array<ComplexRoute>;
