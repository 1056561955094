import httpClient from 'api/httpClient';
import { School, SchoolGroup } from 'models/School';
import {
  AddNewSchoolValues,
  PaymentInfo,
  PaymentMethodType,
  SchoolExist,
  SchoolGroupExist,
  UnsubscribeValues,
} from 'types';

function checkIfSchoolExists(params: SchoolExist) {
  return httpClient.get<{ exists: boolean }>('schools/exists', { params });
}

function checkIfSchoolGroupExists(params: SchoolGroupExist) {
  return httpClient.get<{ exists: boolean }>('school-groups/exists', {
    params,
  });
}

function createSchool(params: any) {
  return httpClient.post('school-groups', params);
}

function getMySchools() {
  return httpClient.get<School[]>('schools');
}

function updateSchoolGroup(data: {
  state: string;
  website: string;
  schoolCount?: number;
}) {
  return httpClient.patch('school-groups', data);
}

function deleteSchool(schoolGroupId: string, schoolId: string) {
  return httpClient.delete(
    `school-groups/${schoolGroupId}/schools/${schoolId}`,
  );
}

function addSchools(schoolGroupId: string, data: AddNewSchoolValues) {
  return httpClient.post<{ schoolGroup: SchoolGroup; clientSecret?: string }>(
    `school-groups/${schoolGroupId}/schools`,
    data,
    {
      params: {
        $populate: ['schools', 'schools.associations'],
      },
    },
  );
}

function updateSchoolInformation(
  values: {
    schoolName?: string;
    website?: string;
  },
  id: string,
) {
  return httpClient.patch<SchoolGroup>(`schools/${id}`, values);
}

function updateWidgetUrl(
  values: {
    widgetUrl: string;
  },
  schoolId: string,
) {
  return httpClient.patch<SchoolGroup>(`schools/${schoolId}`, values, {
    params: {
      $populate: ['associations'],
    },
  });
}

function createSetupIntent(paymentMethodTypes?: PaymentMethodType[]) {
  return httpClient.post('school-groups/setup-intent', {
    paymentMethodTypes,
  });
}

function addPaymentInfo(data: { purchaseOrder?: string }) {
  return httpClient.post('school-groups/payment-info', data);
}

function getPaymentInfo() {
  return httpClient.get<PaymentInfo>('school-groups/payment-info');
}

function subscribe() {
  return httpClient.post('school-groups/subscriptions');
}

function unsubscribe(values: UnsubscribeValues) {
  return httpClient.post('school-groups/subscriptions/cancel', values);
}

function renewSubscription() {
  return httpClient.post('school-groups/subscriptions/renew');
}

function getInvoices() {
  return httpClient.get('school-groups/invoices');
}

function setDefaultPaymentMethod(paymentMethodId: string) {
  return httpClient.post('school-groups/payment-methods/default', {
    paymentMethodId,
  });
}

function deletePaymentMethod(paymentMethodId: string) {
  return httpClient.post('school-groups/payment-methods/delete', {
    paymentMethodId,
  });
}

function applyCoupon(promoCode: string) {
  return httpClient.post('school-groups/subscriptions/apply-coupon', {
    promoCode,
  });
}

export default {
  checkIfSchoolExists,
  checkIfSchoolGroupExists,
  createSchool,
  getMySchools,
  updateSchoolGroup,
  deleteSchool,
  addSchools,
  updateSchoolInformation,
  createSetupIntent,
  addPaymentInfo,
  getPaymentInfo,
  subscribe,
  unsubscribe,
  getInvoices,
  renewSubscription,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  applyCoupon,
  updateWidgetUrl,
};
