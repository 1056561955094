import React, { useCallback, useEffect } from 'react';
import Pagination from 'components/Pagination';
import VacancyCard from 'components/VacancyCard';
import { useLocation, useNavigate } from 'react-router-dom';
import VacanciesFilters from '../../components/VacanciesFilters';
import PageLoader from 'components/PageLoader';
import PaginationInfo from 'components/PaginationInfo';
import VacanciesEmptyState from '../../components/VacanciesEmptyState';
import { useTranslation } from 'react-i18next';
import { Role, VacancyStatus } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useGetVacanciesQuery } from 'api/vacancies.api';
import { getVacancyType } from '../../utils';
import vacancyConstants from 'constants/vacancy';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import { PaginationProps } from 'types';
import SortSelect from 'components/SortSelect';
import { useDefaultSortByOptions } from 'hooks/selectOptions/useDefaultSortByOptions';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import MatchingVacanciesAddBanner from 'components/MatchingVacanciesAddBanner';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';
import storageService from 'services/storageService';
import MatchingInformationBetaBanner from 'components/MatchingInformationBetaBanner';

import './VacancyWrapper.styles.scss';

const initialParams = {
  $limit: 6,
};

const typeBaseParams: any = {
  active: {
    $where: {
      status: {
        $in: [
          VacancyStatus.PUBLISHED,
          VacancyStatus.ONGOING,
          VacancyStatus.CLOSED,
        ],
      },
    },
  },
  archived: {
    $where: {
      status: {
        $in: [
          VacancyStatus.ARCHIVED,
          VacancyStatus.FILLED,
          VacancyStatus.EXPIRED,
        ],
      },
    },
  },
  drafted: {
    $where: {
      status: {
        $in: [VacancyStatus.DRAFT],
      },
      subjectGradeLevel: undefined,
      positionDivision: undefined,
    },
  },
};

const VacancyWrapper: React.FC = () => {
  const { pathname } = useLocation();

  const type = getVacancyType(pathname);

  const params = useAppSelector(({ vacanciesParams }) => vacanciesParams);

  const { $sort } = params || {};

  const { data, isLoading, isFetching } = useGetVacanciesQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const {
    role,
    _id: userId,
    marketingInformation,
    schools,
  } = useAppSelector(authSelectors.selectUser);

  const dispatch = useAppDispatch();

  const sortByOptions = useDefaultSortByOptions();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onSort = useCallback(
    (value: string) => {
      dispatch(paramsActions.sort('vacanciesParams', value));
    },
    [dispatch],
  );

  const noActions = useCallback(
    (creatorId: string) => role === Role.HiringManager && userId !== creatorId,
    [role, userId],
  );

  const noArchive = useCallback(
    (status: VacancyStatus) => {
      return (
        [
          VacancyStatus.ONGOING,
          VacancyStatus.ARCHIVED,
          VacancyStatus.FILLED,
          VacancyStatus.CLOSED,
        ].includes(status) || role === Role.Reviewer
      );
    },
    [role],
  );

  const noEdit = useCallback(
    (status: VacancyStatus, creatorId: string) => {
      const isHiringManagerAndNotCreator =
        role === Role.HiringManager && userId !== creatorId;

      return (
        [
          VacancyStatus.ARCHIVED,
          VacancyStatus.FILLED,
          VacancyStatus.CLOSED,
        ].includes(status) || isHiringManagerAndNotCreator
      );
    },
    [role, userId],
  );

  useEffect(() => {
    if (type) {
      dispatch(
        paramsActions.initializeParams('vacanciesParams', {
          ...initialParams,
          ...typeBaseParams[type],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const { items: vacancies, ...pagination } = data || {};

  const showBanner =
    !schools?.some((el) => el?.matchingInformation?.isMatchingPaid) &&
    !marketingInformation?.isMatchingAdDisabled &&
    storageService.getItem(STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin) !==
      'true';

  const showMatchingBetaInformation =
    !marketingInformation?.isMatchingAdDisabled &&
    storageService.getItem(STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin) !==
      'true';

  return (
    <div>
      {showBanner && (
        <div className="vacancy-wrapper__banner-wrapper">
          <MatchingVacanciesAddBanner />
        </div>
      )}
      {showMatchingBetaInformation && (
        <div className="vacancy-wrapper__banner-wrapper">
          <MatchingInformationBetaBanner iconColor="teal" />
        </div>
      )}
      <VacanciesFilters
        initialParams={{ ...initialParams, ...typeBaseParams[type] }}
        reducerPath="vacanciesParams"
        isDraftVacancies={pathname.includes('draft')}
      />
      <div className="vacancy-wrapper__pagination-wrapper">
        <PaginationInfo
          name={t('vacanciesSmall')}
          {...(pagination as PaginationProps)}
          limit={params?.$limit}
        />
        <SortSelect
          options={sortByOptions}
          value={$sort}
          placeholder={t('sortBy')}
          multiple={false}
          icon={<ArrowIcon />}
          onChange={({ value }) => {
            onSort(value);
          }}
        />
      </div>
      {isLoading || isFetching ? (
        <PageLoader />
      ) : (pagination as PaginationProps)?.totalItems > 0 ? (
        <>
          <ul>
            {vacancies.map((vacancy) => (
              <VacancyCard
                key={vacancy._id}
                {...vacancy}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`../${vacancy._id}`);
                }}
                noEdit={noEdit(vacancy.status, vacancy.creatorId)}
                noArchive={noArchive(vacancy.status)}
                onEdit={() =>
                  navigate(
                    `../${vacancy._id}/edit/${
                      vacancyConstants.vacancyTypeToRouteMapping[vacancy?.type]
                    }`,
                  )
                }
                initialParams={{ ...initialParams, ...typeBaseParams[type] }}
                noDelete={role === Role.Reviewer}
                noActions={noActions(vacancy.creatorId)}
              />
            ))}
          </ul>
          {(pagination as PaginationProps)?.totalPages > 1 && (
            <Pagination
              {...(pagination as PaginationProps)}
              onChange={(pageNumber) =>
                dispatch(
                  paramsActions.changeCurrentPage(
                    'vacanciesParams',
                    pageNumber,
                  ),
                )
              }
            />
          )}
        </>
      ) : (
        <VacanciesEmptyState />
      )}
    </div>
  );
};

export default VacancyWrapper;
