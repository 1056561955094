import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSchoolInformation from '../../hooks/useSchoolInformation';
import ReviewCardWrapper from '../ReviewCardWrapper';
import useSchoolInformationRoute from '../../../../hooks/useSchoolInformationRoute';

import './SchoolInformationPreview.styles.scss';

const SchoolInformationPreview: React.FC = () => {
  const { list } = useSchoolInformation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigateRoute = useSchoolInformationRoute();

  return (
    <div className="school-information-preview">
      <ReviewCardWrapper
        title={t('schoolInformation')}
        onEdit={() => navigate(`../${navigateRoute}`)}
        list={list}
      />
    </div>
  );
};

export default SchoolInformationPreview;
