import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './ContractInformationFormFields.styles.scss';
import FormFieldLabel from 'components/FormFieldLabel';
import { Field, useFormState } from 'react-final-form';
import ContractDurationField from 'components/ContractDurationField';
import {
  required,
  requiredContractDuration,
  requiredDayMonth,
} from 'validations';
import MonthDayField from 'components/MonthDayField';
import InputField from 'components/InputField';
import { useLocation } from 'react-router-dom';
import { VacancyStatus } from 'searchality-data';

type ContractInformationFormFieldsProps = {
  className?: string;
};

const ContractInformationFormFields: React.FC<
  ContractInformationFormFieldsProps
> = (props) => {
  const { className } = props;

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const {
    values: { status },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const isAcademic = !pathname.includes('non-academic');

  const baseClass = 'contract-information-form-fields';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__field-container`}>
        <FormFieldLabel
          text={t('SchoolProfileBuilder.CI.startingContractDuration')}
        />
        <Field
          name="startingContractDuration"
          component={ContractDurationField}
          validate={requiredContractDuration()}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      <div className={`${baseClass}__field-container`}>
        <FormFieldLabel
          text={t('SchoolProfileBuilder.CI.subsequentContractDuration')}
        />
        <Field
          name="subsequentContractDuration"
          component={ContractDurationField}
          validate={requiredContractDuration()}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      <div className={`${baseClass}__field-container`}>
        <FormFieldLabel
          text={t('SchoolProfileBuilder.CI.contractRenewalDate')}
        />
        <Field
          name="contractRenewalDate"
          component={MonthDayField}
          validate={requiredDayMonth()}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      {isAcademic ? (
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.teacherContractedWorkingDays')}
          />
          <Field
            name="teacherContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '120' })}
            validate={required()}
            disabled={isOngoingVacancyEdit}
          />
        </div>
      ) : (
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.adminContractedWorkingDays')}
          />
          <Field
            name="adminContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '50' })}
            validate={required()}
            disabled={isOngoingVacancyEdit}
          />
        </div>
      )}
      {isAcademic ? (
        <>
          <div className={`${baseClass}__field-container`}>
            <FormFieldLabel
              text={t('SchoolProfileBuilder.CI.teacherStartDate')}
            />
            <Field
              name="teacherStartDate"
              component={MonthDayField}
              validate={requiredDayMonth()}
              disabled={isOngoingVacancyEdit}
            />
          </div>
          <div className={`${baseClass}__field-container`}>
            <FormFieldLabel
              text={t('SchoolProfileBuilder.CI.teacherEndDate')}
            />
            <Field
              name="teacherEndDate"
              component={MonthDayField}
              validate={requiredDayMonth()}
              disabled={isOngoingVacancyEdit}
            />
          </div>
        </>
      ) : (
        <>
          <div className={`${baseClass}__field-container`}>
            <FormFieldLabel
              text={t('SchoolProfileBuilder.CI.adminStartDate')}
            />
            <Field
              name="adminStartDate"
              component={MonthDayField}
              validate={requiredDayMonth()}
              disabled={isOngoingVacancyEdit}
            />
          </div>
          <div className={`${baseClass}__field-container`}>
            <FormFieldLabel text={t('SchoolProfileBuilder.CI.adminEndDate')} />
            <Field
              name="adminEndDate"
              component={MonthDayField}
              validate={requiredDayMonth()}
              disabled={isOngoingVacancyEdit}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContractInformationFormFields;
