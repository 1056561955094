import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export default function () {
  const { t } = useTranslation();

  const {
    values: { school },
  } = useFormState();

  const list = useMemo(() => {
    if (!school) return [];
    const { city, name, country, address, website } = school;

    return [
      {
        label: t('schoolName'),
        value: name,
      },
      {
        label: t('schoolWebsite'),
        value: website,
      },
      {
        label: t('address'),
        value: address,
      },
      {
        label: t('city'),
        value: city,
      },
      {
        label: t('country'),
        value: country,
      },
    ];
  }, [school, t]);

  return { list };
}
