import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SPBRoute, SchoolProfileBuilderFormValues } from './types';
import TPBPageWrapper from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageWrapper';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import { schoolProfileBuilderActions } from 'store/slices/schoolProfileBuilder.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  finishedStepsDataMapping,
  iconsMapping,
  pathMapping,
  routesMapping,
} from './constants';
import { ReactComponent as CheckIcon } from 'icons/CheckMark.icon.svg';
import { ReactComponent as FilledCircleIcon } from 'icons/FilledCircle.icon.svg';
import { useTranslation } from 'react-i18next';
import ScrollableLayout from 'components/ScrollableLayout';
import authSelectors from 'store/selectors/auth.selectors';
import { HiringPreferencesStep } from 'searchality-data';
import utils from 'utils';
import { convertFormToApi, convertHPtoFormValues } from './utils';
import { HiringPreferences } from 'models/School';
import { useUpdateHiringPreferencesMutation } from 'api/users.api';
import { updateUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

const SchoolProfileBuilderRouter: React.FC = () => {
  const [initialValues, setInitialValues] =
    useState<SchoolProfileBuilderFormValues>();

  const {
    allRoutes: routes,
    finishedRoutes,
    currentRoute,
  } = useAppSelector(({ schoolProfileBuilder }) => schoolProfileBuilder);

  const {
    schoolGroup: { hiringPreferences },
    _id,
  } = useAppSelector(authSelectors.selectUser);

  const user = useAppSelector(authSelectors.selectUser);
  const [updateHiringPreferences] = useUpdateHiringPreferencesMutation();
  const { pathname, state } = useLocation();

  const { cameThroughVacancy } = state || {};

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isRouteDisabled = useCallback(
    (route: HiringPreferencesStep) => {
      if (finishedRoutes?.length === routes?.length) return false;

      return !routes.slice(0, finishedRoutes?.length + 1).includes(route);
    },
    [finishedRoutes?.length, routes],
  );

  const onSubmit = useCallback(
    (values: SchoolProfileBuilderFormValues) => {
      let submitObject: Partial<HiringPreferences>;

      const fillStep = !finishedRoutes?.includes(currentRoute);

      if (fillStep) {
        submitObject = convertFormToApi(values);
      } else {
        submitObject = finishedStepsDataMapping[currentRoute]?.(values);
      }

      updateHiringPreferences({
        ...submitObject,
        ...(fillStep && { filledSteps: [...finishedRoutes, currentRoute] }),
      })
        .unwrap()
        .then((data) => {
          dispatch(updateUser({ ...user, schoolGroup: data }));
          dispatch(popSuccess(t('SchoolProfileBuilder.saveAndContinueStep')));
          dispatch(
            schoolProfileBuilderActions.updateFinishedSteps(
              data?.hiringPreferences?.filledSteps,
            ),
          );
          if (currentRoute === routes?.[routes?.length - 1]) {
            return navigate(`/my-account/hiring-preferences-settings`);
          }
          const indexOfCurrentRoute = routes?.findIndex(
            (route) => route === currentRoute,
          );
          dispatch(schoolProfileBuilderActions.nextRoute());
          navigate(
            `/my-account/hiring-preferences-settings/school-profile/${
              routesMapping[routes?.[indexOfCurrentRoute + 1]]
            }`,
          );
        })
        .catch((e) => dispatch(popServerError(e)));
    },
    [
      currentRoute,
      dispatch,
      finishedRoutes,
      navigate,
      routes,
      t,
      updateHiringPreferences,
      user,
    ],
  );

  useEffect(() => {
    if (_id) {
      const { isFilled, filledSteps, ...rest } = hiringPreferences || {};

      const formValues = convertHPtoFormValues(hiringPreferences);
      setInitialValues(formValues);

      const allRoutes = Object.keys(routesMapping) as HiringPreferencesStep[];

      let currentRouteTemp: HiringPreferencesStep;

      if (isFilled) {
        const pathnameSubDomains = pathname.split('/');
        const lastSubDomain =
          pathnameSubDomains?.[pathnameSubDomains?.length - 1];

        if (lastSubDomain === 'school-profile') {
          currentRouteTemp = allRoutes[0];
        }

        currentRouteTemp = pathMapping[lastSubDomain as SPBRoute];
      } else if (
        allRoutes.length === hiringPreferences?.filledSteps?.length ||
        (!filledSteps?.length && !utils.isObjectEmpty(rest))
      ) {
        currentRouteTemp = allRoutes[0];
      } else {
        const numberOfFilledSteps = hiringPreferences?.filledSteps?.length ?? 0;
        currentRouteTemp = allRoutes[numberOfFilledSteps];
      }

      dispatch(
        schoolProfileBuilderActions['initRoutes']({
          allRoutes,
          finishedRoutes: hiringPreferences?.filledSteps || [],
          currentRoute: currentRouteTemp,
          ...(cameThroughVacancy && { cameThroughVacancy }),
        }),
      );

      if (!isFilled) {
        if (utils.isObjectEmpty(rest)) {
          navigate('/my-account/hiring-preferences-settings/school-profile');
        } else {
          navigate(
            `/my-account/hiring-preferences-settings/school-profile/${routesMapping[currentRouteTemp]}`,
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  useEffect(() => {
    return () => {
      dispatch(schoolProfileBuilderActions.resetState());
    };
  }, [dispatch]);

  return (
    <Form<SchoolProfileBuilderFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues ?? {}}
    >
      {({ handleSubmit }) => {
        return (
          <ScrollableLayout
            headerShadow
            sidebarComponent={
              <nav className="searchality-vertical-navigation">
                {routes.map((route) => (
                  <NavLink
                    to={routesMapping[route]}
                    key={routesMapping[route]}
                    onClick={() => {
                      dispatch(schoolProfileBuilderActions.jumpToRoute(route));
                    }}
                    className={({ isActive }) => {
                      return classNames(
                        'searchality-vertical-navigation__nav-link',
                        {
                          done: finishedRoutes?.includes(route),
                          active: isActive,
                          disabled: isRouteDisabled(route),
                        },
                      );
                    }}
                  >
                    <>
                      {finishedRoutes.includes(route) ? (
                        <CheckIcon />
                      ) : (
                        <FilledCircleIcon />
                      )}
                      <div>{iconsMapping[route]}</div>
                      <span>{t(`SchoolProfileBuilder.nav.${route}`)}</span>
                    </>
                  </NavLink>
                ))}
              </nav>
            }
          >
            {/* <MoblileTeacherBuilderHeader
              finishedSteps={finishedRoutes?.length}
              numberOfSteps={routes?.length}
            /> */}
            <TPBPageWrapper>
              <form onSubmit={handleSubmit}>
                <Outlet />
              </form>
            </TPBPageWrapper>
          </ScrollableLayout>
        );
      }}
    </Form>
  );
};

export default SchoolProfileBuilderRouter;
