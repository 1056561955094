import React, { useMemo } from 'react';
import classNames from 'classnames';
import SpbTopActions from '../../components/SpbTopActions';
import TPBPageContent from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageContent';
import TPBPageHeading from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import SpbBottomActions from '../../components/SpbBottomActions';
import FormFieldLabel from 'components/FormFieldLabel';
import { Field } from 'react-final-form';
import InputField from 'components/InputField';
import useEducationLevelOptions from 'router/subrouters/Dashboard/subrouters/Vacancies/pages/VacancyWizard/hooks/useEducationLevelOptions';
import SelectField from 'components/SelectField';
import { YearsOfExperience } from 'searchality-data';
import useHealthRequirementsOptions from '../../hooks/useHealthRequirementsOptions';
import useCountryOptions from '../../hooks/useCountryOptions';
import MultipleSelectField from 'components/MultipleSelectField';

import './SpbHostCountryRequirements.styles.scss';

type SpbHostCountryRequirementsProps = {
  className?: string;
};

const SpbHostCountryRequirements: React.FC<SpbHostCountryRequirementsProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const educationLevelOptions = useEducationLevelOptions(true);
  const healthRequirementsOptions = useHealthRequirementsOptions();
  const countryOptions = useCountryOptions();

  const yearsOfExperience = useMemo(() => {
    const yearsOfExperienceTemp = Object.keys(YearsOfExperience).map((key) => ({
      label: t(`yearsOfExperience.`, {
        years: YearsOfExperience[key as keyof typeof YearsOfExperience],
      }),
      value: YearsOfExperience[key as keyof typeof YearsOfExperience],
    }));
    yearsOfExperienceTemp.shift();

    return yearsOfExperienceTemp;
  }, [t]);

  const baseClass = 'spb-host-country-requirements';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <SpbTopActions to="../hiring-preferences" />
      <TPBPageContent>
        <TPBPageHeading
          text={t('SchoolProfileBuilder.hostCountryRequirementsTitle')}
          description={t('SchoolProfileBuilder.description')}
        />
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.HCR.minAgeLabel')} />
          <p className="description">
            {t('SchoolProfileBuilder.HCR.ageDescription')}
          </p>
          <Field
            name="minimumAge"
            component={InputField}
            label={t('SchoolProfileBuilder.HCR.age')}
            onlyNumbers
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.HCR.maxAgeLabel')} />
          <p className="description">
            {t('SchoolProfileBuilder.HCR.ageDescription')}
          </p>
          <Field
            name="maximumAge"
            component={InputField}
            label={t('SchoolProfileBuilder.HCR.age')}
            onlyNumbers
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.HCR.educationLevelLabel')}
            optionalText={t('SchoolProfileBuilder.HCR.optional')}
            isOptional
          />
          <Field
            name="educationalLevelRequired"
            component={SelectField}
            options={educationLevelOptions}
            label={t('SchoolProfileBuilder.HCR.educationLevelPlaceholder')}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.HCR.yearsOfExperienceLabel')}
            optionalText={t('SchoolProfileBuilder.HCR.optional')}
            isOptional
          />
          <Field
            name="minimumYearsOfExperienceRequired"
            component={SelectField}
            options={yearsOfExperience}
            label={t('SchoolProfileBuilder.HCR.yearsOfExperiencePlaceholder')}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.HCR.healthRequirementsLabel')}
            optionalText={t('SchoolProfileBuilder.HCR.optional')}
            isOptional
          />
          <Field
            name="healthRequirements"
            component={SelectField}
            options={healthRequirementsOptions}
            label={t('SchoolProfileBuilder.HCR.healthRequirementsPlaceholder')}
          />
        </div>
        <div className={`${baseClass}__field-container distinct-container`}>
          <p className="header-text">
            {t('SchoolProfileBuilder.HCR.preferredLabel')}
          </p>
          <p className="description">
            {t('SchoolProfileBuilder.HCR.preferredDescription')}
          </p>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.HCR.preferredAdditional')}
          />
          <Field
            name="preferredPassports"
            component={MultipleSelectField}
            searchable
            options={countryOptions}
            label={t('SchoolProfileBuilder.HCR.countriesPlaceholder')}
          />
        </div>
        <SpbBottomActions />
      </TPBPageContent>
    </div>
  );
};

export default SpbHostCountryRequirements;
