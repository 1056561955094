import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolTypePage from './pages/SchoolType/SchoolType.page';
import NationalPlusRouter from './subrouters/NationalPlusRouter';
import NationalPlusRoutes from './subrouters/NationalPlusRouter/routes';
import NationalStateRouter from './subrouters/NationalStateRouter';
import NationalStateRoutes from './subrouters/NationalStateRouter/routes';
import InternationalRouter from './subrouters/InternationalRouter';
import InternationalRoutes from './subrouters/InternationalRouter/routes';

export default [
  {
    path: 'international',
    element: InternationalRouter,
    routes: InternationalRoutes,
  },
  {
    path: 'national-state',
    element: NationalStateRouter,
    routes: NationalStateRoutes,
  },
  {
    path: 'national-plus',
    element: NationalPlusRouter,
    routes: NationalPlusRoutes,
  },
  {
    index: true,
    element: SchoolTypePage,
  },
] as Array<ComplexRoute>;
