import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateSchoolStepperProvider from 'providers/CreateSchoolWizard';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import { CreateSchoolValues } from 'types';
import CreateSchoolForm from './CreateSchool.form';
import api from 'api';
import { useTranslation } from 'react-i18next';
import MainLayout from 'components/MainLayout';
import useAppDispatch from 'hooks/useAppDispatch';
import { loadCurrentUser } from 'store/slices/auth.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { School } from 'models/School';
import utils from 'utils';
import { ROUTE_CURRICULUM_TYPE } from './subrouters/SchoolTypeRouter/constants';
import { CurriculumRoute } from './subrouters/SchoolTypeRouter/types';

import './CreateSchool.styles.scss';

const CreateSchool: React.FC = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [initialStepIndex, setInitialStepIndex] = useState<number | null>(null);

  let [curriculumType] =
    pathname.match(/school-type\/([a-z-]+)/i)?.slice(1) || [];
  curriculumType = useParams().curriculumType || curriculumType;

  // const [structureType] =
  //   pathname.match(/school-information\/([a-z-]+)/i)?.slice(1) || [];

  let [residentialType] =
    pathname.match(/residential-type\/([a-z-]+)/i)?.slice(1) || [];
  residentialType = useParams().residentialType || residentialType;

  const initData = useRef<CreateSchoolValues>({
    curriculumType: ROUTE_CURRICULUM_TYPE[curriculumType as CurriculumRoute],
    residentialType: utils.mapRouteToResidentialType(residentialType),
  }).current;

  const handleSubmit = useCallback(
    async (values: any) => {
      const { curriculumType, residentialType, schools } = values;

      const schoolsWithoutLogo = schools?.map((school: any) => {
        const { schoolLogo, ...rest } = school;

        return { ...rest, curriculumType, residentialType };
      });

      try {
        const { data } = await api.schools.createSchool({
          schools: schoolsWithoutLogo,
        });

        for (const school of data?.schools as School[]) {
          try {
            const schoolLogo = schools?.find(
              (el: any) => el.name === school.name,
            )?.schoolLogo;

            if (schoolLogo) {
              await api.user.uploadSchoolLogo(schoolLogo[0], school._id);
            }
          } catch (e) {
            dispatch(popServerError(e));
          }
        }

        dispatch(
          popSuccess(
            t('schoolCreateSuccess', {
              type: t('account'),
            }),
          ),
        );
        setTimeout(() => {
          dispatch(loadCurrentUser());
          navigate('/vacancies');
        }, 500);
      } catch (error) {
        dispatch(popServerError(error));
      }
    },
    [dispatch, navigate, t],
  );

  // since we are not patching data
  // we need to redirect form to the begining
  useEffect(() => {
    if (curriculumType && pathname.includes(curriculumType)) {
      setInitialStepIndex(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);
      return;
    }

    if (!pathname.includes('school-type'))
      navigate('school-type', { replace: true });
    // run this only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout className="create-school" headerShadow isGreen>
      <CreateSchoolStepperProvider initialStep={initialStepIndex}>
        <CreateSchoolForm onSubmit={handleSubmit} initialValues={initData} />
      </CreateSchoolStepperProvider>
    </MainLayout>
  );
};

export default CreateSchool;
