import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

import './VacancyPreviewCard.styles.scss';
import './VacancyPreviewCard.styles.responsive.scss';

type VacancyPreviewCardProps = {
  className?: string;
  title: string;
  subtitle?: string;
  noPublish?: boolean;
  noEdit?: boolean;
  noActions?: boolean;
  onPublish?: () => Promise<any>;
  onEdit?: () => void;
};

const VacancyPreviewCard: React.FC<VacancyPreviewCardProps> = (props) => {
  const {
    className,
    title,
    subtitle,
    noPublish = false,
    noEdit = false,
    noActions = false,
    onPublish,
    onEdit,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  const handlePublish = useCallback(async () => {
    setSubmitting(true);
    await onPublish();
    setSubmitting(false);
  }, [onPublish]);

  const baseClass = 'vacancy-preview-card';
  const classes = classNames(baseClass, className);

  const { t } = useTranslation();
  return (
    <div className={classes}>
      <div className={`${baseClass}__text`}>
        <p className={`${baseClass}__text__title`}>{title}</p>
        <p className={`${baseClass}__text__sub`}>{subtitle}</p>
      </div>
      {!noActions && (
        <div className={`${baseClass}__actions`}>
          {!noPublish && (
            <Button onClick={handlePublish} disabled={submitting}>
              {t('publishVacancy')}
            </Button>
          )}
          {!noEdit && (
            <Button variant="outline" onClick={onEdit}>
              {t('editPosition')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default VacancyPreviewCard;
