import React, { useMemo } from 'react';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import useSortByOptions from '../../../../../Candidates/hooks/useSortByOptions';
import { useNavigate, useParams as useUrlParams } from 'react-router-dom';
import { useGetVacancyCandidatesQuery } from 'api/canidates.api';
import { useParams } from 'hooks/useParams';
import { PaginatedResponse } from 'types';
import { Candidate } from 'models/Candidate';
import VacancyCandidatesBoxFilters from './components/VacancyCandidatesBoxFilters';
import PaginationInfo from 'components/PaginationInfo';
import CandidateCard from 'router/subrouters/Dashboard/subrouters/Candidates/components/CandidateCard';
import { useGetVacancyQuery } from 'api/vacancies.api';
import VacancyCandidatesEmptyState from '../../components/VacancyCandidatesEmptyState';
import { FAVOURITE, MATCHING } from 'constants/general';
import CandidateQualification from 'components/CandidateQualification';
import QualificationsService from 'services/Qualifications.service';
import SortSelect from 'components/SortSelect';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { VacancyStatus, VacancyType } from 'searchality-data';
import MatchingVacanciesAddBanner from 'components/MatchingVacanciesAddBanner';
import { ReactComponent as FireIcon } from 'icons/Fire.icon.svg';
import VacancyMatchingToggleSection from './components/VacancyMatchingToggleSection';
import VacancyCandidatesMathingNotPaid from './components/VacancyCandidatesMathingNotPaid';

import './VacancyCandidates.styles.scss';

const VacancyCandidates: React.FC = () => {
  const { vacancyId } = useUrlParams();

  const { params, resetFilters, filterBy, sortBy, changeCurrentPage } =
    useParams(
      {
        $limit: 6,
        $populate: ['schools', 'vacancy'],
      },
      true,
    );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading } = useGetVacancyCandidatesQuery({
    id: vacancyId,
    params,
  });

  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  const sortByOptions = useSortByOptions();

  const { $sort, $where } = params || {};

  const { statusInSchool, isFavourite, wasMatched } = $where || {};

  const currentStatus = useMemo(() => {
    if (isFavourite) return FAVOURITE;

    if (wasMatched) return MATCHING;

    if (!statusInSchool) return;

    return statusInSchool['$in'][0];
  }, [isFavourite, wasMatched, statusInSchool]);

  const { items: candidates, ...pagination } =
    data || ({} as PaginatedResponse<Candidate>);

  const noMatchingForOngoingVacancy =
    vacancy?.status === VacancyStatus.ONGOING &&
    !vacancy?.matchingInformation?.isMatchingPaid &&
    vacancy?.type === VacancyType.ACADEMIC;

  const showVacancyMatchingToggle =
    vacancy?.status === VacancyStatus.ONGOING &&
    vacancy?.matchingInformation?.isMatchingPaid &&
    vacancy?.type === VacancyType.ACADEMIC;

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="vacancy-candidates">
      <VacancyCandidatesBoxFilters
        vacancyId={vacancyId}
        currentStatus={currentStatus}
        resetFilters={resetFilters}
        filterBy={filterBy}
      />
      {noMatchingForOngoingVacancy && (
        <MatchingVacanciesAddBanner
          title={t('VacancyCandidatesBanner.title')}
          subtitle={t('VacancyCandidatesBanner.subtitle')}
          showCheckbox={false}
          icon={<FireIcon />}
          iconColor="teal"
        />
      )}
      {showVacancyMatchingToggle && <VacancyMatchingToggleSection />}
      {noMatchingForOngoingVacancy && wasMatched ? (
        <VacancyCandidatesMathingNotPaid />
      ) : (
        <>
          <section className="vacancy-candidates-info">
            <div>
              <h6>{t('allCandidates')}</h6>
              <PaginationInfo
                name={t('candidatesSmall')}
                limit={params?.$limit}
                totalItems={data?.totalItems}
                currentPage={data?.currentPage}
                totalPages={data?.totalPages}
              />
            </div>
            <SortSelect
              options={sortByOptions}
              value={$sort}
              placeholder={t('sortBy')}
              multiple={false}
              icon={<ArrowIcon />}
              onChange={({ value }) => {
                sortBy(value);
              }}
            />
          </section>
          <ul>
            {candidates.map((candidate) => {
              const { qualifications } = candidate;
              const { numberOfMatchingFields = 0, numberOfTotalFields = 0 } =
                qualifications || {};

              const [variant, message] =
                QualificationsService.getCandidatesQualificationVariantAndMessage(
                  numberOfMatchingFields,
                  numberOfTotalFields,
                );

              return (
                <CandidateCard
                  key={candidate?._id}
                  {...candidate}
                  onClick={() => navigate(`/candidates/${candidate?._id}`)}
                  suffixNode={
                    <CandidateQualification
                      qualificationsNumber={numberOfTotalFields}
                      candidateQualifications={numberOfMatchingFields}
                      variant={variant}
                      message={message}
                    />
                  }
                />
              );
            })}
          </ul>
          {pagination?.totalPages > 1 && (
            <Pagination {...pagination} onChange={changeCurrentPage} />
          )}
        </>
      )}
    </div>
  );
};

export default () => {
  const { vacancyId } = useUrlParams();

  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  if (vacancy?.applicantCount === 0) {
    return <VacancyCandidatesEmptyState />;
  }

  return <VacancyCandidates />;
};
