import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { VacancyStatus } from 'searchality-data';
import { maxNumberValidation } from 'validations';

type AcademicContractDaysFieldsProps = {
  baseClass: string;
};

const AcademicContractDaysFields: React.FC<AcademicContractDaysFieldsProps> = (
  props,
) => {
  const { baseClass } = props;

  const { t } = useTranslation();

  const {
    values: { status },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  return (
    <div className={`${baseClass}__contact-tearms`}>
      <FormFieldLabel
        text={t('contractTerms')}
        hint={t('vacancyWizard.benefits.contractTippyContent')}
      />
      <p className="descriptive-paragraph">
        {t('vacancyWizard.benefits.contractTermsParagraph')}
      </p>
      <Field
        name="studentContactDays"
        component={InputField}
        label={t('studentContactDays')}
        validate={maxNumberValidation(365)}
        onlyNumbers
        maxLength={3}
        disabled={isOngoingVacancyEdit}
      />
      <Field
        name="studentNonContactDays"
        component={InputField}
        label={t('nonContactDays')}
        validate={maxNumberValidation(365)}
        onlyNumbers
        maxLength={3}
        disabled={isOngoingVacancyEdit}
      />
    </div>
  );
};

export default AcademicContractDaysFields;
