import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NonAcademicVacancyCategory } from 'searchality-data';

const useCategoryOptions = (categories?: NonAcademicVacancyCategory[]) => {
  const { t } = useTranslation();

  const entries = useMemo(() => Object.entries(NonAcademicVacancyCategory), []);

  if (categories) {
    return categories?.map((value) => {
      const enumValue = entries.find((element) => element?.[1] === value)?.[0];

      return {
        label: enumValue ? t(`NonAcademicVacancyCategory.${enumValue}`) : value,
        value,
      };
    });
  }

  return entries.map(([key]) => ({
    label: t(`NonAcademicVacancyCategory.${key}`),
    value:
      NonAcademicVacancyCategory[
        key as keyof typeof NonAcademicVacancyCategory
      ],
  }));
};

export default useCategoryOptions;
