import React, { useMemo } from 'react';
import classNames from 'classnames';
import ApplicationInformationCard from '../ApplicationInformationCard';
import { useTranslation } from 'react-i18next';
import { Vacancy } from 'models/Vacancy';
import { ApplicantStage } from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';

import './ApplicationPreviewOffers.styles.scss';

type ApplicationPreviewOffersProps = {
  className?: string;
  vacancy?: Vacancy;
};

const ApplicationPreviewOffers: React.FC<ApplicationPreviewOffersProps> = (
  props,
) => {
  const { className, vacancy } = props;

  const { t } = useTranslation();

  const classes = classNames('application-preview-offers', className);

  const salaryInfo = useMemo(() => {
    const salaryInfo = vacancy?.salaryInfo;
    const { from, to, applicantStage, currency } = salaryInfo || {};
    if (applicantStage !== ApplicantStage.IN_THE_JOB_POSTING) {
      return t(applicantStage);
    }

    const currencySign = currency?.split(' - ');

    return `${numberPriceToCurrencyString(
      from,
      currencySign?.[0],
    )} - ${numberPriceToCurrencyString(to, currencySign?.[0])}`;
  }, [t, vacancy?.salaryInfo]);

  const hasBenefits = useMemo(() => {
    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
    } = vacancy || {};

    return [
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
    ].some((el) => el);
  }, [vacancy]);

  const benefits = useMemo(() => {
    if (!hasBenefits) return null;

    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
      privateHealthInsuranceOffered,
      pensionContributionOffered,
      financialSupportForRelocationOffered,
      professionalDevelopmentOffered,
      additionalBenefitOffered,
      dependentsDevelopmentOffered,
      housingOffered,
      travelAllowanceOffered,
      leaveAllowanceOffered,
    } = vacancy;

    return (
      <li>
        {t('jobPreview.benefits')}
        <ul>
          {isPrivateHealthInsuranceOffered && (
            <li>
              {t('jobPreview.healthcareInsurance', {
                option: privateHealthInsuranceOffered,
              })}
            </li>
          )}
          {isPensionContributionOffered && (
            <li>
              {t('jobPreview.pensionContribution', {
                option: pensionContributionOffered,
              })}
            </li>
          )}
          {isFinancialSupportForRelocationOffered && (
            <li>
              {t('jobPreview.relocationStipend', {
                option: financialSupportForRelocationOffered,
              })}
            </li>
          )}
          {isProfessionalDevelopmentOffered && (
            <li>
              {t('jobPreview.professionalDevelopment', {
                developmentOptions: professionalDevelopmentOffered,
              })}
            </li>
          )}
          {isAdditionalBenefitOffered && (
            <li>
              {t('jobPreview.aditionalBenefits', {
                additionalBenefits: additionalBenefitOffered,
              })}
            </li>
          )}

          {isTuitionForDependentsOffered && (
            <li>
              {t('jobPreview.dependents', {
                options: dependentsDevelopmentOffered,
              })}
            </li>
          )}
          {isHousingOffered && (
            <li>
              {t('jobPreview.housing', {
                options: housingOffered,
              })}
            </li>
          )}
          {isTravelAllowanceOffered && (
            <li>
              {t('jobPreview.travel', {
                options: travelAllowanceOffered,
              })}
            </li>
          )}
          {isLeaveAllowanceOffered && (
            <li>
              {t('jobPreview.leave', {
                options: leaveAllowanceOffered,
              })}
            </li>
          )}
        </ul>
      </li>
    );
  }, [hasBenefits, t, vacancy]);

  return (
    <ApplicationInformationCard className={classes}>
      <h4>{t('whatWeOffer')}</h4>
      <ul>
        <li>
          {t('salary')}
          {salaryInfo}
        </li>
        {(vacancy?.studentContactDays || vacancy?.studentNonContactDays) && (
          <li>
            {t('applicationPreview.ContractTerms')}
            <ul>
              {vacancy?.studentContactDays && (
                <li>
                  {t('applicationPreview.contractDays', {
                    days: vacancy?.studentContactDays,
                  })}
                </li>
              )}
              {vacancy?.studentNonContactDays && (
                <li>
                  {t('applicationPreview.nonContractDays', {
                    days: vacancy?.studentNonContactDays,
                  })}
                </li>
              )}
            </ul>
          </li>
        )}
        {benefits}
      </ul>
    </ApplicationInformationCard>
  );
};

export default ApplicationPreviewOffers;
