import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InternationalCurriculumExperience } from 'searchality-data';

type InternationalCurriculumExperienceKey =
  keyof typeof InternationalCurriculumExperience;

const useCurriculumExperienceOptions = (isWithoutNone?: boolean) => {
  const { t } = useTranslation();

  const curriculumExperienceOptions = useMemo(() => {
    const options = Object.keys(InternationalCurriculumExperience).map(
      (key) => ({
        label: t(`curriculumExperienceOptions.${key}`),
        value:
          InternationalCurriculumExperience[
            key as InternationalCurriculumExperienceKey
          ],
      }),
    );

    if (isWithoutNone) {
      options.shift();
    }

    return options;
  }, [isWithoutNone, t]);

  return curriculumExperienceOptions;
};

export default useCurriculumExperienceOptions;
