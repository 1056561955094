import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HiringPreferencesStep } from 'searchality-data';

type SchoolProfileBuilder = {
  allRoutes: HiringPreferencesStep[];
  currentRoute?: HiringPreferencesStep;
  finishedRoutes?: HiringPreferencesStep[];
  cameThroughVacancy?: boolean;
};

const initialState: SchoolProfileBuilder = {
  allRoutes: [],
};

const initRoutes = createAction('initRoutes');
const updateFinishedSteps = createAction('updateFinishedSteps');
const nextRoute = createAction('nextRoute');
const jumpToRoute = createAction('jumpToRoute');
const resetState = createAction('resetState');

const schoolProfileBuilder = createSlice({
  name: 'schoolProfileBuilder',
  initialState,
  reducers: {
    [initRoutes.type]: (
      _,
      action: PayloadAction<{
        allRoutes: HiringPreferencesStep[];
        finishedRoutes: HiringPreferencesStep[];
        currentRoute: HiringPreferencesStep;
        cameThroughVacancy?: boolean;
      }>,
    ) => {
      const { allRoutes, finishedRoutes, currentRoute, cameThroughVacancy } =
        action.payload;

      return {
        allRoutes,
        finishedRoutes,
        currentRoute,
        cameThroughVacancy,
      };
    },
    [nextRoute.type]: (state) => {
      const currentRouteIndex = state.allRoutes.findIndex(
        (route) => route === state.currentRoute,
      );

      state.currentRoute = state.allRoutes[currentRouteIndex + 1];
    },
    [jumpToRoute.type]: (
      state,
      action: PayloadAction<HiringPreferencesStep>,
    ) => {
      state.currentRoute = action.payload;
    },
    [updateFinishedSteps.type]: (
      state,
      action: PayloadAction<HiringPreferencesStep[]>,
    ) => {
      state.finishedRoutes = action.payload;
    },
    [resetState.type]: (state) => {
      state = {
        allRoutes: [],
      };
    },
  },
});

export default schoolProfileBuilder.reducer;

export const schoolProfileBuilderActions = schoolProfileBuilder.actions;
