import { t } from 'i18next';
import { Candidate, QualificationType } from 'models/Candidate';
import { Vacancy } from 'models/Vacancy';
import { LanguageLevel, RightToWorkType } from 'searchality-data';
import utils from 'utils';
import DateService from './Date.service';

type QualKeyType = keyof Omit<
  QualificationType,
  'numberOfMatchingFields' | 'numberOfTotalFields' | 'curriculumExperience'
>;

function getVacancyKey(
  qualKey: QualKeyType,
):
  | 'yearsOfExperienceRequired'
  | 'requiredLanguages'
  | 'isRightToWorkRequired'
  | 'boardingPositionRequirementStatus'
  | Exclude<
      QualKeyType,
      'yearsOfExperience' | 'languages' | 'rightToWork' | 'boardingRequirements'
    > {
  if (qualKey === 'yearsOfExperience') {
    return 'yearsOfExperienceRequired';
  }

  if (qualKey === 'languages') {
    return 'requiredLanguages';
  }

  if (qualKey === 'rightToWork') {
    return 'isRightToWorkRequired';
  }

  if (qualKey === 'boardingRequirements') {
    return 'boardingPositionRequirementStatus';
  }

  return qualKey;
}

function getVacancyValue(qualKey: QualKeyType, vacancy: Vacancy) {
  const key = getVacancyKey(qualKey);

  if (key === 'requiredLanguages') {
    return vacancy[key]?.length;
  }

  if (key === 'startDate') {
    if (vacancy?.[key]) {
      return DateService.formatDate(vacancy[key]);
    }
    return t('immediately');
  }

  return vacancy[key];
}

function getCandidateKey(
  qualkey: QualKeyType,
):
  | 'languageRequirement'
  | 'earliestAvailableStartingDate'
  | 'education'
  | 'isAbleToWorkAsBoardingParent'
  | Exclude<
      QualKeyType,
      'languages' | 'startDate' | 'educationLevel' | 'boardingRequirements'
    > {
  if (qualkey === 'languages') {
    return 'languageRequirement';
  }
  if (qualkey === 'startDate') {
    return 'earliestAvailableStartingDate';
  }

  if (qualkey === 'educationLevel') {
    return 'education';
  }

  if (qualkey === 'boardingRequirements') {
    return 'isAbleToWorkAsBoardingParent';
  }

  return qualkey;
}

function getCandidateValue(qualkey: QualKeyType, candidate: Candidate) {
  const key = getCandidateKey(qualkey);

  if (key === 'languageRequirement') {
    return candidate['languageRequirement']?.filter(
      ({ doesSpeak }) => doesSpeak,
    )?.length;
  }

  if (key === 'earliestAvailableStartingDate') {
    if (candidate[key]) {
      return DateService.formatDate(candidate[key]);
    } else {
      return t('requestedDate');
    }
  }

  if (key === 'education')
    return candidate[key]?.reduce(
      (prev, curr, index) =>
        index > 0 ? prev + ', ' + curr.degreeLevel : curr.degreeLevel,
      '',
    );

  if (key === 'isAbleToWorkAsBoardingParent') {
    if (candidate[key]) {
      return t(
        'QualificationDescription.candidateHave.canWorkAsBoardingParent',
      );
    }
    return t('QualificationDescription.candidateHave.cantWorkAsBoardingParent');
  }

  if (key === 'rightToWork') {
    if (candidate['hasRightToWork']) {
      const rightToWork = candidate['rightToWork']?.[0];

      if (rightToWork === RightToWorkType.US_CITIZEN)
        return t(
          'QualificationDescription.candidateHave.HaveRightToWorkUsCitizen',
          {
            rightToWork,
          },
        );

      return t('QualificationDescription.candidateHave.HaveRightToWork', {
        rightToWork,
      });
    }
    return t('QualificationDescription.candidateHave.DontHaveRightToWork');
  }

  return candidate[key];
}

function getCandidatesQualificationVariantAndMessage(
  qualifications: number,
  numberOfTotalFields: number,
  isMatchingRate?: boolean,
): ['green' | 'red' | 'blue', string] {
  if (qualifications < numberOfTotalFields / 2) {
    return [
      'red',
      t(
        isMatchingRate
          ? 'didntMeetCriteria'
          : 'candidateDoesNotFitRequirements',
      ),
    ];
  }

  if (qualifications === numberOfTotalFields) {
    return [
      'green',
      t(isMatchingRate ? 'allCriteriaMet' : 'candidateFitAllRequirements'),
    ];
  }

  return [
    'blue',
    t(isMatchingRate ? 'mostCriteriaMet' : 'candidateFitMostOfRequirements'),
  ];
}

function getCandidateLanguagesMatch(candidate: Candidate) {
  const { vacancy, languageRequirement } = candidate;
  const { requiredLanguages } = vacancy;

  const speakedLanguages = languageRequirement.filter(
    ({ doesSpeak }) => doesSpeak,
  );

  return speakedLanguages.filter(({ language, level }) => {
    const tmp = requiredLanguages.find((el) => el.language === language);

    return !utils.isLanguageLevelOkay(tmp?.level as LanguageLevel, level);
  });
}

function getCurriculumExperienceAdditionalDescription(
  doesHaveYearsOfExperience: boolean,
  curriculum: string,
  candidate: Candidate,
) {
  const { curricula } = candidate;

  const currentCurricula = curricula?.find(
    (el) => el.curriculumExperience === curriculum,
  );

  const doesHaveExperienceInTheField =
    currentCurricula?.doesHaveExperienceInTheField;
  const yearsOfExperienceInTheField =
    currentCurricula?.yearsOfExperienceInTheField;

  if (doesHaveYearsOfExperience) return '';

  if (doesHaveExperienceInTheField && yearsOfExperienceInTheField) {
    return `${t(
      'QualificationDescription.curriculumExperienceExplanation1',
    )} ${yearsOfExperienceInTheField} ${t(
      'QualificationDescription.curriculumExperienceExplanation2',
    )}`;
  }

  return '';
}

export default {
  getVacancyKey,
  getVacancyValue,
  getCandidateKey,
  getCandidateValue,
  getCandidatesQualificationVariantAndMessage,
  getCandidateLanguagesMatch,
  getCurriculumExperienceAdditionalDescription,
};
