import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { GROUP_ADDITIONAL_SCHOOL_PRICE, PaymentStatus } from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';

import './SchoolProfileCard.styles.scss';
import './SchoolProfileCard.styles.responsive.scss';

type SchoolProfileCardProps = {
  className?: string;
  schoolId: string;
};

const SchoolProfileCard: React.FC<SchoolProfileCardProps> = (props) => {
  const { className, schoolId } = props;

  const { t } = useTranslation();

  const {
    schoolGroup: { schools, subscriptionStartDate, subscriptionEndDate },
  } = useAppSelector(authSelectors.selectUser);

  const paymentStatusLabel = {
    [PaymentStatus.NOT_PAID]: t('SchoolProfileCard.right.notPaid'),
    [PaymentStatus.PENDING]: t('SchoolProfileCard.right.pending'),
    [PaymentStatus.PAID]: t('SchoolProfileCard.right.nextPay', {
      date: DateService.toDateString(new Date(subscriptionEndDate)),
    }),
  };

  const schoolIndex = schools.findIndex(({ _id }) => _id === schoolId);
  const { paymentStatus } = schools[schoolIndex] || {};

  const subscriptionDate = useMemo(() => {
    if (!subscriptionStartDate || !subscriptionEndDate) return null;

    return (
      <div>
        <p className="title">{t('SchoolProfileCard.left.currentSub')}</p>
        <p className="date">
          {t('SchoolProfileCard.left.date', {
            from: DateService.toDateString(new Date(subscriptionStartDate)),
            to: DateService.toDateString(
              DateService.getDayBefore(subscriptionEndDate),
            ),
          })}
        </p>
      </div>
    );
  }, [subscriptionEndDate, subscriptionStartDate, t]);

  const baseClass = 'school-profile-card';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__left`}>
        <div>
          <p className="title">{t('SchoolProfileCard.left.title')}</p>
          <p className="price">
            {schoolIndex < 2
              ? t('SchoolProfileCard.left.includedInSubscription')
              : t('SchoolProfileCard.left.price', {
                  price: numberPriceToCurrencyString(
                    GROUP_ADDITIONAL_SCHOOL_PRICE,
                  ),
                })}
          </p>
        </div>
        {subscriptionDate}
      </div>

      <p className={`${baseClass}__right`}>
        {t(paymentStatusLabel[paymentStatus])}
      </p>
    </div>
  );
};

export default SchoolProfileCard;
