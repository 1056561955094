import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useMatchedOpportunitiesOptions(sortByMatching: boolean) {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('newest'),
        value: '-createdAt',
      },
      {
        label: t('oldest'),
        value: 'createdAt',
      },
      ...(sortByMatching
        ? [
            {
              label: t('highestMatchingRate'),
              value: '-matchPercentage',
            },
            {
              label: t('lowestMatchingRate'),
              value: 'matchPercentage',
            },
          ]
        : []),
    ],
    [sortByMatching, t],
  );
}
