import httpClient from 'api/httpClient';
import env from 'env';

const getSuggestions = (params: any) =>
  httpClient.get(
    `${env.SERVER_ENDPOINT}/api/google-places/suggestions?locationbias`,
    { params },
  );

const getPlaceDetails = (params: any) => {
  return httpClient.get(`${env.SERVER_ENDPOINT}/api/google-places/details`, {
    params,
  });
};

export default {
  getSuggestions,
  getPlaceDetails,
};
