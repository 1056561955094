import React, { useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { useLeftCardContent } from '../../hooks/useLeftCardContent';
import { useTranslation } from 'react-i18next';
import { WEBSITE_INTEGRATION_PRICE } from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';
import { HOW_IT_WORKS_LINK, WEBSITE_INTEGRATION_MORE } from 'constants/links';

import './PricingPlan.styles.scss';

type PricingPlanProps = {
  className?: string;
};

const PricingPlan: React.FC<PricingPlanProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const leftCardContent = useLeftCardContent();

  const classes = classNames('pricing-plan__cards', className);

  const seeAllLink = useMemo(
    () => (
      <a href={HOW_IT_WORKS_LINK} target="_blank" rel="noreferrer">
        <p className="pricing-plan__links">
          {t('Pricing.seeAll')}
          <ArrowIcon />
        </p>
      </a>
    ),
    [t],
  );

  return (
    <div className={classNames(classes)}>
      <Card
        className="teal pricing-plan__cards__left"
        title={leftCardContent.title}
        subtitle={leftCardContent.subtitle}
        description={leftCardContent.description}
      >
        {leftCardContent.content}
        {seeAllLink}
      </Card>
      <Card
        className="orange pricing-plan__cards__right"
        title={t('Pricing.Website.title')}
        subtitle={t('Pricing.Website.price', {
          price: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
        })}
      >
        <p className="card__description">
          {t(`Pricing.Website.${'descriptionStandalone'}`)}
        </p>
        <a href={WEBSITE_INTEGRATION_MORE} target="_blank" rel="noreferrer">
          <p className="pricing-plan__links">
            {t('Pricing.Website.learnMore')}
            <ArrowIcon />
          </p>
        </a>
      </Card>
    </div>
  );
};

export default PricingPlan;
