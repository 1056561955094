import { t } from 'i18next';
import { Candidate } from 'models/Candidate';
import { ProfileCardType } from 'models/ProfileCard.type';
import { User } from 'models/User';
import { Vacancy } from 'models/Vacancy';
import { JobApplicationFormValues } from 'router/subrouters/JobApplication/types/JobApplicationFormValues';
import { CurriculumExperience, YearsOfExperience } from 'searchality-data';
import { TeacherFormValues } from '../models/TeacherFormValues';
import TeacherProfileDataService from '../services/TeacherProfileData.service';
import utils from 'utils';

export function extractProfileInfoFromUser(user: User) {
  const {
    _id,
    firstName,
    lastName,
    email,
    systemColor,
    startDate,
    educationPhilosophyUrl,
    videoUrl,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    educationPhilosophyKeyUpdatedAt,
    references,
    imageFile,
    certificates,
    profilePdfUrl,
    publications,
    profileStatus,
    passports,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
  } = user;
  return {
    _id,
    firstName,
    lastName,
    passports,
    email,
    profilePdfUrl,
    systemColor,
    startDate,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    videoUrl,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    references,
    certificates,
    publications,
    profileStatus,
    teacherId: _id,
    imageUrl: imageFile?.url,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
  } as unknown as ProfileCardType;
}

export function extractProfileInfoFromJobApplication(
  values: JobApplicationFormValues,
  candidate?: User,
  vacancy?: Vacancy,
) {
  if (JSON.stringify(values) === '{}') return;

  const {
    startDate: jobStartingDate,
    educationLevel,
    yearsOfExperienceRequired,
  } = vacancy;

  const {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber: mobilePhone,
      address,
      videoUrl,
      applicantPhoto,
    } = {},

    relocation: {
      maritalStatus,
      isRelocatingWithPartner,
      isWithChildren,
      numberOfChildren,
    } = {},

    yourProfile: {
      education,
      experience,
      educationPhilosophy,
      coverLetter,
      resume,
    } = {},

    hostCountryRequirements: { citizenships } = {},

    positionRequirements: {
      languageRequirement: languages,
      isAbleToStartOnRequiredDate,
      earliestAvailableStartingDate,
      yearsOfExperienceWithFaithBasedSchools,
      doesHaveExperinceWithFaithBasedSchools,
      curricula,
      englishLevel,
      doesMeetMinimumEducationLevel,
      doesHaveYearsOfExperience,
    } = {},
    references,
    publications,
  } = values || ({} as JobApplicationFormValues);

  const cardInfo: ProfileCardType = {
    firstName,
    lastName,
    email,
    mobilePhone,
    address,
    videoUrl,
    englishLevel,
    education,
    maritalStatus,
    passports: citizenships,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren: parseInt(numberOfChildren),
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    references,
    experience,
    resumeUrl: resume?.length ? URL.createObjectURL(resume[0]) : undefined,
    educationPhilosophyUrl: educationPhilosophy?.length
      ? URL.createObjectURL(educationPhilosophy[0])
      : undefined,
    coverLetterUrl: coverLetter?.length
      ? URL.createObjectURL(coverLetter[0])
      : undefined,
    languages: Object.keys(languages || {}).map((key) => ({
      language: key,
      level: languages[key].level ? languages[key].level : undefined,
      doesSpeak: languages[key].level ? true : false,
    })),
    imageUrl: applicantPhoto?.length
      ? URL.createObjectURL(applicantPhoto[0])
      : undefined,
    systemColor: '',
    startDate: isAbleToStartOnRequiredDate
      ? jobStartingDate ?? null
      : earliestAvailableStartingDate,
    sports: candidate?.sports ?? undefined,
    activities: candidate?.activities ?? undefined,
    curricula: utils.isObjectEmpty(curricula ?? {})
      ? undefined
      : Object.entries(curricula).map(([key, value]) => ({
          curriculumExperience: key as CurriculumExperience,
          yearsOfExperience: value?.yearsOfExperienceInTheField,
        })),
    certificates: candidate?.certificates,
    publications:
      TeacherProfileDataService.formatPublicationFormIntoPublications(
        publications,
      ),
  };

  return cardInfo;
}

export function extractProfileInfoFromCandidate(candidate: Candidate) {
  if (JSON.stringify(candidate) === '{}') return;

  const { vacancy } = candidate || {};

  const { startDate, educationLevel, yearsOfExperienceRequired } =
    vacancy || {};

  const {
    _id,
    firstName,
    lastName,
    email,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterUrl,
    videoUrl,
    resumeUrl,
    education,
    type,
    earliestAvailableStartingDate,
    isAbleToStartOnRequiredDate,
    experience,
    address,
    references,
    curricula,
    doesHaveExperinceWithFaithBasedSchools,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    imageFile,
    color,
    updatedAt,
    isFavourite,
    teacher,
    englishLevel,
    publications,
    languageRequirement,
    maritalStatus,
    numberOfChildren,
    isWithChildren,
    isRelocatingWithPartner,
    citizenships,
    hasRightToWork,
    schools,
    isRelocationNotRequired,
  } = candidate;

  const cardInfo: ProfileCardType = {
    maritalStatus,
    numberOfChildren,
    isWithChildren,
    isRelocatingWithPartner,
    hasRightToWork,
    isRelocationNotRequired,
    country: schools?.[0]?.country,
    passports: citizenships,
    firstName,
    _id,
    lastName,
    email,
    videoUrl,
    resumeUrl,
    educationPhilosophyKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    type,
    education,
    references,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    experience,
    educationPhilosophyUrl,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    coverLetterUrl,
    imageUrl: imageFile?.url,
    address,
    systemColor: color,
    startDate: isAbleToStartOnRequiredDate
      ? startDate
      : earliestAvailableStartingDate,
    updatedAt,
    isFavourite,
    curricula: !curricula?.length
      ? undefined
      : (curricula.map(
          ({
            doesHaveExperienceInTheField,
            yearsOfExperienceInTheField,
            curriculumExperience,
          }) => ({
            yearsOfExperience: doesHaveExperienceInTheField
              ? (yearsOfExperienceInTheField?.toString() as YearsOfExperience)
              : (t('haveNoExperience') as YearsOfExperience),
            curriculumExperience,
          }),
        ) as any),
    englishLevel,
    languages: languageRequirement,
    sports: teacher?.sports,
    activities: teacher?.activities,
    certificates: teacher?.certificates,
    publications,
  };

  return cardInfo;
}

export const convertTeacherFormValuesIntoCard = (
  formValues: User & TeacherFormValues,
) => {
  const {
    videoUrl,
    streetAddress,
    startDate,
    educationPhilosophyUrl,
    education,
    experience,
    systemColor,
    firstName,
    lastName,
    email,
    references,
    curricula,
    sports,
    publications,
    activities,
    mobilePhone,
    immediately,
    englishLevel,
    languages,
    imageFile,
    certificates,
    passports,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    numberOfChildren,
    birthDate,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
  } = formValues;

  const submitObject: ProfileCardType = {
    address: streetAddress,
    firstName,
    lastName,
    systemColor,
    email,
    startDate,
    educationPhilosophyUrl,
    videoUrl,
    references,
    education,
    experience,
    sports,
    activities,
    curricula,
    englishLevel,
    languages,
    mobilePhone,
    imageUrl: '',
    certificates,
    passports,
    maritalStatus,
    isRelocatingWithPartner,
    isWithChildren,
    isRelocatingWithPartnerAnswerRejected,
    isWithChildrenAnswerRejected,
    numberOfChildren,
    birthDate,
  };

  if (publications) {
    submitObject.publications =
      TeacherProfileDataService.formatPublicationFormIntoPublications(
        publications,
      );
  }

  if (imageFile?.[0]) {
    submitObject.imageUrl = URL.createObjectURL(imageFile?.[0]);
  }

  if (immediately) {
    submitObject.startDate = null;
  } else {
    if (immediately === undefined) {
      submitObject.startDate = undefined;
    } else {
      submitObject.startDate = startDate;
    }
  }
  return submitObject;
};
