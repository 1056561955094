import React from 'react';
import classNames from 'classnames';
import FormFieldLabel from 'components/FormFieldLabel';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import MultipleSelectField from 'components/MultipleSelectField';
import useCountryOptions from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/hooks/useCountryOptions';
import { VacancyStatus } from 'searchality-data';

import './HcrFormFields.styles.scss';

type HcrFormFieldsProps = {
  className?: string;
};

const HcrFormFields: React.FC<HcrFormFieldsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const countryOptions = useCountryOptions();

  const { values } = useFormState();

  const {
    minimumAge,
    maximumAge,
    educationalLevelRequired,
    yearsOfExperienceRequired,
    healthRequirements,
    status,
  } = values;

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const classes = classNames('hcr-form-fields', className);

  return (
    <div className={classes}>
      <div className="hcr-form-fields__min-max">
        <FormFieldLabel
          text={t('vacancyWizard.HCR.minmaxLabel')}
          className="disabled-label"
        />
        <div>
          <Field
            name="minimumAge"
            component={InputField}
            label={t('vacancyWizard.HCR.min')}
            disabled
            placeholderValue={minimumAge ?? '-'}
          />
          <Field
            name="maximumAge"
            component={InputField}
            label={t('vacancyWizard.HCR.max')}
            placeholderValue={maximumAge ?? '-'}
            disabled
          />
        </div>
      </div>

      <div>
        <FormFieldLabel
          className="disabled-label"
          text={t('SchoolProfileBuilder.HCR.educationLevelLabel')}
        />
        <p className="description">
          {t('vacancyWizard.HCR.educationDescription')}
        </p>
        <Field
          name="educationalLevelRequired"
          component={InputField}
          label={t('vacancyWizard.HCR.educationPlaceholder')}
          placeholderValue={educationalLevelRequired ?? t('notApplicable')}
          disabled
        />
      </div>
      <div>
        <FormFieldLabel
          text={t('vacancyWizard.HCR.yearsOfExperienceLabel')}
          className="disabled-label"
        />
        <p className="description">{t('vacancyWizard.HCR.yearsDescription')}</p>
        <Field
          name="minimumYearsOfExperienceRequired"
          component={InputField}
          label={t('vacancyWizard.HCR.yearsPlaceholder')}
          placeholderValue={yearsOfExperienceRequired ?? t('notApplicable')}
          disabled
        />
      </div>
      <div>
        <FormFieldLabel
          text={t('vacancyWizard.HCR.healthLabel')}
          className="disabled-label"
        />
        <p className="description">
          {t('vacancyWizard.HCR.healthDescription')}
        </p>
        <Field
          name="healthRequirements"
          component={InputField}
          label={t('vacancyWizard.HCR.healthPlaceholder')}
          placeholderValue={healthRequirements ?? t('notApplicable')}
          disabled
        />
      </div>
      <div className="hcr-form-fields__passport">
        <h3>{t('vacancyWizard.HCR.passportTitle')}</h3>
        <p className="description">
          {t('vacancyWizard.HCR.passportDescription')}
        </p>
        <FormFieldLabel text={t('vacancyWizard.HCR.preferredTitle')} />
        <p className="description">
          {t('vacancyWizard.HCR.preferredDescription')}
        </p>
        <FormFieldLabel
          text={t('vacancyWizard.HCR.preferredLabel')}
          className="hcr-form-fields__passport__preferred-label"
        />
        <Field
          name="preferredPassports"
          component={MultipleSelectField}
          searchable
          options={countryOptions}
          label={t('SchoolProfileBuilder.HCR.countriesPlaceholder')}
          disabled={isOngoingVacancyEdit}
        />
      </div>
    </div>
  );
};

export default HcrFormFields;
