import {
  useFillVacancyMutation,
  useLazyConfirmMagicLinkQuery,
  useLazyGetVacancyQuery,
  useReopenVacancyMutation,
} from 'api/vacancies.api';
import classNames from 'classnames';
import ButtonDropdown from 'components/ButtonDropdown';
import GoBackButton from 'components/GoBackButton';
import Heading from 'components/Heading';
import PageLoader from 'components/PageLoader';
import TabsNavigation from 'components/TabsNavigation';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import confirm from 'modules/confirm';
import { Button } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FillVacancyMethod, Role, VacancyStatus } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import VacancyArchiveConfirmationModal from '../../components/VacancyArchiveConfirmationModal';
import VacancyDeleteConfirmationModal from '../../components/VacancyDeleteConfirmationModal';
import VacancyExpiredUpdateModal from '../../components/VacancyExpiredUpdateModal';
import VacancyFillCandidateModal from './components/VacancyFillCandidateModal';
import VacancyFillModal from './components/VacancyFillModal';
import useVacancyProfileTabs from './pages/VacancyCandidates/hooks/useVacancyProfileTabs';
import vacancyConstants from 'constants/vacancy';
import { updateUser } from 'store/slices/auth.slice';
import ViewGuard from 'router/components/ViewGuard';

import './VacancyProfile.styles.scss';
import './VacancyProfile.styles.responsive.scss';
import CloseVacancyModal from './components/CloseVacancyModal';

const VacancyProfile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { _id: userId, role } = useAppSelector(authSelectors.selectUser);

  const tabs = useVacancyProfileTabs();

  const { t } = useTranslation();

  const { vacancyId } = useParams();
  const [getVacancy, { data: vacancy, isSuccess: vacancyFetchSuccess }] =
    useLazyGetVacancyQuery();
  const [confirmMagicLink, { isSuccess: confirmSuccess }] =
    useLazyConfirmMagicLinkQuery();

  const [fillVacancy] = useFillVacancyMutation();
  const [openVacancy] = useReopenVacancyMutation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const deleteModalRef = useRef<ModalRef>(null);
  const archiveModalRef = useRef<ModalRef>(null);
  const editExpiredModalRef = useRef<ModalRef>(null);
  const vacancyCandidateModalRef = useRef<ModalRef>(null);
  const vacancyFillModalRef = useRef<ModalRef>(null);
  const closeVacancyModalRef = useRef<ModalRef>(null);

  const queryParams = new URLSearchParams(window.location.search);

  const authToken = queryParams.get('authToken');

  const baseClass = 'vacancy-profile-page';

  const isHiringManagerAndNotCreator =
    role === Role.HiringManager && userId !== vacancy?.creatorId;

  const reopenVacancy = useCallback(() => {
    openVacancy({ vacancyId })
      .unwrap()
      .then(() => dispatch(popSuccess(t('openVacancySuccess'))))
      .catch((e) => dispatch(popServerError(e)));
  }, [dispatch, openVacancy, t, vacancyId]);

  const fetchVacancy = useCallback(async () => {
    const actionResult = await getVacancy(vacancyId);

    if (actionResult.isError) {
      if ((actionResult.error as any).status === 403) {
        navigate('/forbidden');
      }

      if ((actionResult.error as any).status === 400) {
        navigate('/not-found');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVacancy, vacancyId]);

  const confirmAccess = useCallback(async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);

    const token = queryParams.get('authToken');

    if (token && role === Role.Reviewer) {
      const actionResult = await confirmMagicLink({ vacancyId, token });

      if (actionResult.isSuccess) {
        dispatch(updateUser(actionResult.data));
        await fetchVacancy();
      }
      if (actionResult.isError) {
        if ((actionResult.error as any).status === 403) {
          navigate('/forbidden');
        }

        if ((actionResult.error as any).status === 400) {
          navigate('/not-found');
        }
      }
      setIsLoading(false);

      return;
    }
    await fetchVacancy();
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmMagicLink, dispatch, fetchVacancy, role, vacancyId]);

  useEffect(() => {
    confirmAccess();
  }, [confirmAccess]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { positionTitle, status, applicantCount } = vacancy || {};

  const isOngoing = status === VacancyStatus.ONGOING;
  const isClosed = status === VacancyStatus.CLOSED;

  const openDeleteModal = () => {
    deleteModalRef.current.open();
  };

  const openArchiveModal = () => {
    archiveModalRef.current.open();
  };

  const openVacancyFillModal = () => {
    vacancyFillModalRef.current.open();
  };

  const closeDeleteModal = () => {
    deleteModalRef.current.close();
  };

  const closeVacancyFillModal = () => {
    vacancyFillModalRef.current.close();
  };

  const closeArchiveModal = () => {
    archiveModalRef.current.close();
  };

  const closeEditExpiredModalRef = () => {
    editExpiredModalRef.current.close();
  };

  const openVacancyCandidateModal = () => {
    vacancyCandidateModalRef.current.open();
  };

  const closeVacancyCandidateModal = () => {
    vacancyCandidateModalRef.current.close();
  };

  const openCloseVacancyModal = () => {
    closeVacancyModalRef.current.open();
  };

  const onDeleteSuccess = () => navigate('../', { replace: true });

  const dropdownContenList = [
    VacancyStatus.FILLED,
    VacancyStatus.ARCHIVED,
    VacancyStatus.ONGOING,
    VacancyStatus.CLOSED,
  ].includes(status)
    ? [
        {
          label: t('delete'),
          onClick: openDeleteModal,
        },
        ...(isOngoing
          ? [
              {
                label: t('closeForApplications'),
                onClick: openCloseVacancyModal,
              },
            ]
          : []),
        ...(isClosed
          ? [
              {
                label: t('openForApplications'),
                onClick: reopenVacancy,
              },
            ]
          : []),
      ]
    : [
        {
          label: t('delete'),
          onClick: openDeleteModal,
        },
        {
          label: t('archive'),
          onClick: openArchiveModal,
        },
      ];

  const onFillInternally = async () => {
    fillVacancy({
      fillMethod: FillVacancyMethod.INTERNALLY,
      vacancyId,
    })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('fillIternallySuccess')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  };

  const onFillFromAnotherSource = async () => {
    fillVacancy({
      fillMethod: FillVacancyMethod.ANOTHER_SOURCE,
      vacancyId,
    })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('fillFromAnotherSourceSuccess')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  };

  const onVacancyFillConfirm = async (fillType: string) => {
    if (fillType === FillVacancyMethod.INTERNALLY) {
      const res = await confirm({
        title: t('vacancyFilledInternaly.title'),
        content: <p>{t('vacancyFilledInternaly.description')}</p>,
        confirmBtnText: t('vacancyFilledInternaly.buttonText'),
        onSubmit: onFillInternally,
      });

      if (res) {
        closeVacancyFillModal();
      }
    }

    if (fillType === FillVacancyMethod.ANOTHER_SOURCE) {
      const res = await confirm({
        title: t('vacancyFillFromAnotherSource.title'),
        content: <p>{t('vacancyFilledInternaly.description')}</p>,
        confirmBtnText: t('vacancyFilledInternaly.buttonText'),
        onSubmit: onFillFromAnotherSource,
      });
      if (res) {
        closeVacancyFillModal();
      }
    }

    if (fillType === FillVacancyMethod.FROM_CANDIDATE_APPLICATIONS) {
      closeVacancyFillModal();
      openVacancyCandidateModal();
    }
  };

  const editVacancy = () => {
    if (status === VacancyStatus.EXPIRED) {
      editExpiredModalRef.current.open();
    } else {
      navigate(
        `/vacancies/${vacancy._id}/edit/${
          vacancyConstants.vacancyTypeToRouteMapping[vacancy.type]
        }`,
      );
    }
  };

  const shouldDisplayHeader =
    pathname.includes('/edit') || pathname.includes('/preview');

  const disableEditButton =
    [
      VacancyStatus.FILLED,
      VacancyStatus.ARCHIVED,
      VacancyStatus.CLOSED,
    ].includes(status) || isHiringManagerAndNotCreator;

  const navigteToDraft = status === VacancyStatus.DRAFT;
  const navigateToArchived = [
    VacancyStatus.ARCHIVED,
    VacancyStatus.FILLED,
    VacancyStatus.EXPIRED,
  ].includes(status);

  const goBackLink = `/vacancies/${
    navigteToDraft ? 'drafted' : navigateToArchived ? 'archived' : 'active'
  }`;

  return (
    <div className={classNames(baseClass)}>
      {!shouldDisplayHeader && (
        <>
          <GoBackButton to={goBackLink} />
          <Heading title={positionTitle}>
            <div className="heading-buttons-group">
              <p className={`${baseClass}__vacancy-status`}>
                {t('status')}:&nbsp; <span>{t(`VacancyStatus.${status}`)}</span>
              </p>
              <Button
                variant="outline"
                onClick={editVacancy}
                disabled={disableEditButton}
              >
                {t('edit')}
              </Button>
              <Button variant="outline" onClick={() => navigate('preview')}>
                {t('Preview')}
              </Button>
              <ViewGuard roles={[Role.Reviewer]} permission="forbid">
                <ButtonDropdown
                  buttonText={t('fillVacancy')}
                  onButtonClick={openVacancyFillModal}
                  buttonDisabled={
                    [
                      VacancyStatus.FILLED,
                      VacancyStatus.ARCHIVED,
                      VacancyStatus.DRAFT,
                    ].includes(status) || isHiringManagerAndNotCreator
                  }
                  contentList={dropdownContenList}
                  disableDropdownButton={isHiringManagerAndNotCreator}
                />
              </ViewGuard>
            </div>
            <ViewGuard roles={[Role.Reviewer]} permission="forbid">
              <>
                <VacancyDeleteConfirmationModal
                  ref={deleteModalRef}
                  vacancyId={vacancy?._id}
                  closeModal={closeDeleteModal}
                  onDeleteSuccess={onDeleteSuccess}
                />
                <VacancyArchiveConfirmationModal
                  ref={archiveModalRef}
                  vacancyId={vacancy?._id}
                  closeModal={closeArchiveModal}
                />
                <VacancyFillModal
                  ref={vacancyFillModalRef}
                  closeModal={closeVacancyFillModal}
                  onConfirm={onVacancyFillConfirm}
                  applicantsCount={applicantCount}
                />
                <CloseVacancyModal
                  positionTitle={positionTitle}
                  schoolName={vacancy?.schools?.[0]?.name}
                  vacancyId={vacancyId}
                  ref={closeVacancyModalRef}
                />
                <ValueContextProvider>
                  <VacancyFillCandidateModal
                    ref={vacancyCandidateModalRef}
                    vacancyId={vacancy?._id}
                    closeModal={closeVacancyCandidateModal}
                  />
                </ValueContextProvider>
              </>
            </ViewGuard>
            <VacancyExpiredUpdateModal
              ref={editExpiredModalRef}
              vacancyId={vacancy?._id}
              closeModal={closeEditExpiredModalRef}
            />
          </Heading>
          <TabsNavigation tabs={tabs} />
        </>
      )}
      {(!authToken ||
        (role === Role.Reviewer && confirmSuccess && vacancyFetchSuccess) ||
        (role !== Role.Reviewer && vacancyFetchSuccess)) && <Outlet />}
    </div>
  );
};

export default VacancyProfile;
