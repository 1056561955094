import React, { ReactNode, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import Banner from 'components/Banner';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'ncoded-component-library';
import { ReactComponent as StarIcon } from 'icons/Star.icon.svg';
import MatchingTarget from 'assets/images/matching-target.webp';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ImageContentModal from 'components/ImageContentModal';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role } from 'searchality-data';
import { useNavigate } from 'react-router-dom';
import storageService from 'services/storageService';
import { useDisableVacanciesBannerMutation } from 'api/matching.api';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateUser } from 'store/slices/auth.slice';

import './MatchingVacanciesAddBanner.styles.scss';
import './MatchingVacanciesAddBanner.styles.responsive.scss';

type MatchingVacanciesAddBannerProps = {
  className?: string;
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  showCheckbox?: boolean;
  iconColor?: 'orange' | 'blue' | 'teal';
};

const MatchingVacanciesAddBanner: React.FC<MatchingVacanciesAddBannerProps> = (
  props,
) => {
  const {
    className,
    showCheckbox = true,
    icon,
    title,
    subtitle,
    iconColor,
  } = props;

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const classes = classNames('matching-vacancies-add-banner', className);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { role } = useAppSelector(authSelectors.selectUser);
  const [disableVacanciesBanner] = useDisableVacanciesBannerMutation();

  const { t } = useTranslation();
  const modalRef = useRef<ModalRef>();

  const handleSubscribe = useCallback(() => {
    if (role === Role.AccountOwner) {
      return navigate('/my-account/manage-subscription');
    }

    modalRef.current.open();
  }, [navigate, role]);

  const handleOnCloseBanner = useCallback(async () => {
    if (!dontShowAgain) {
      return storageService.setItem(
        STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin,
        'true',
      );
    }
    await disableVacanciesBanner(true)
      .unwrap()
      .then((res) => {
        dispatch(updateUser(res));
      });
  }, [disableVacanciesBanner, dispatch, dontShowAgain]);

  return (
    <>
      <Banner
        className={classes}
        iconColor={iconColor}
        title={title ?? t('MatchingVacanciesAddBanner.title')}
        subtitle={subtitle ?? t('MatchingVacanciesAddBanner.subtitle')}
        onClose={handleOnCloseBanner}
        actions={
          <>
            <Button onClick={handleSubscribe}>{t('subscribe')}</Button>
            <a
              href="https://searchality.com/pricing"
              target="_blank"
              rel="noreferrer"
            >
              {t('learnMore')}
            </a>
          </>
        }
        icon={icon ?? <StarIcon />}
      >
        {showCheckbox && (
          <Checkbox
            onChange={(e) => setDontShowAgain(e as boolean)}
            checked={dontShowAgain}
            label={t('dontShowAgain')}
          />
        )}
      </Banner>
      <ImageContentModal
        ref={modalRef}
        imgSrc={MatchingTarget}
        contentText={t('MatchingVacanciesAddBanner.accountOwnerOnly')}
      />
    </>
  );
};

export default MatchingVacanciesAddBanner;
