import React, { ReactNode } from 'react';

import './TPBPageWrapper.styles.scss';
import './TPBPageWrapper.styles.responsive.scss';

const TPBPageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="teacher-profile-builder-page-wrapper">{children}</div>;
};

export default TPBPageWrapper;
