import { useTranslation } from 'react-i18next';
import { InternationalRequestedDocumentTitle } from 'searchality-data';

export default (reqDocuments?: { title: string }[]) => {
  const { t } = useTranslation();

  const removeFromOptionsArray = reqDocuments
    ?.map((el) => el.title)
    ?.filter((el) => el !== InternationalRequestedDocumentTitle.OTHER);

  const options = Object.entries(InternationalRequestedDocumentTitle).map(
    ([key, value]) => ({
      value,
      label: t(`RequestDocumentOptions.Title.${key}`),
      description: t(`RequestDocumentOptions.Description.${key}`),
      inputDescriptionLabel: t(`RequestDocumentOptions.InputLabel.${key}`),
    }),
  );

  return options?.filter(
    (item) => !removeFromOptionsArray?.includes(item.value),
  );
};
