import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import OrderItem from '../OrderItem';
import { numberPriceToCurrencyString } from 'utils';
import {
  GROUP_INTITIAL_PRICE,
  SchoolGroupStructureType,
  STANDALONE_PRICE,
  InternationalStripeProductType,
  WEBSITE_INTEGRATION_PRICE,
} from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import CheckoutContext from 'providers/Checkout/Checkout.context';

import './OrderSchoolGroupItem.styles.scss';

type OrderSchoolGroupItemProps = {
  className?: string;
  schoolNamesIncluded: string;
};

const OrderSchoolGroupItem: React.FC<OrderSchoolGroupItemProps> = (props) => {
  const { className, schoolNamesIncluded } = props;

  const {
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const INITIAL_PRICE =
    structureType === SchoolGroupStructureType.GROUP
      ? GROUP_INTITIAL_PRICE
      : STANDALONE_PRICE;

  const { t } = useTranslation();

  const { stripePreviewObject } = useContext(CheckoutContext);

  const schoolGroupLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
            InternationalStripeProductType.ANNUAL_INITIAL_SCHOOL_GROUP_SUBSCRIPTION ||
          element?.price?.product?.name ===
            InternationalStripeProductType.ANNUAL_INITIAL_STANDALONE_SCHOOL_SUBSCRIPTION,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const schoolGroupPrice = useMemo(() => {
    const amount = schoolGroupLineObject?.discount_amounts?.[0]?.amount;
    return amount > 0 ? INITIAL_PRICE - amount : INITIAL_PRICE;
  }, [INITIAL_PRICE, schoolGroupLineObject?.discount_amounts]);

  const schoolGroupComponent = useMemo(
    () => (
      <>
        {schoolGroupLineObject?.discount_amounts?.[0]?.amount > 0 && (
          <span>{numberPriceToCurrencyString(INITIAL_PRICE)}</span>
        )}{' '}
        {numberPriceToCurrencyString(schoolGroupPrice)}
      </>
    ),
    [INITIAL_PRICE, schoolGroupLineObject?.discount_amounts, schoolGroupPrice],
  );

  const widgetIntegrationPriceObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
            InternationalStripeProductType.SCHOOL_GROUP_WIDGET_INTEGRATION ||
          element?.price?.product?.name ===
            InternationalStripeProductType.WIDGET_INTEGRATION_STANDALONE,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const widgetIntegrationPrice = useMemo(() => {
    const amount = widgetIntegrationPriceObject?.discount_amounts?.[0]?.amount;
    return amount > 0
      ? WEBSITE_INTEGRATION_PRICE - amount
      : WEBSITE_INTEGRATION_PRICE;
  }, [widgetIntegrationPriceObject?.discount_amounts]);

  const widgetIntegrationLabel = useMemo(
    () => (
      <>
        {widgetIntegrationPriceObject?.discount_amounts?.[0]?.amount > 0 && (
          <span>{numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE)}</span>
        )}{' '}
        {numberPriceToCurrencyString(widgetIntegrationPrice)}
      </>
    ),
    [widgetIntegrationPrice, widgetIntegrationPriceObject?.discount_amounts],
  );

  const classes = classNames('order-school-group-item', className);

  return (
    <>
      <OrderItem
        className={classes}
        name={
          structureType === SchoolGroupStructureType.GROUP
            ? t('OrderSummary.initialPrice', { name: schoolNamesIncluded })
            : t('OrderSummary.standaloneInitialPrice')
        }
        price={schoolGroupComponent}
      />
      <OrderItem
        className={classes}
        name={t('OrderSummary.setupFee')}
        price={widgetIntegrationLabel}
      />
    </>
  );
};

export default OrderSchoolGroupItem;
