import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDebouncedState from 'hooks/useDebouncedState';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import api from 'api';
import { FieldRenderProps } from 'react-final-form';
import Select from 'components/Select';
import { v4 as uuid } from 'uuid';
import { UserLocation } from 'types';

import './MapboxAutocomplete.styles.scss';
import './MapboxAutocomplete.styles.responsive.scss';

type MapboxAutocompleteProps = FieldRenderProps<string, HTMLElement> & {
  className?: string;
  resultType?: string[];
  placeholder?: string;
  callback?: (place_id: string, sessiontoken: string) => Promise<void>;
  mapFeaturesToOptions?: (prediction: any) => OptionValue;
  searchable?: boolean;
};

interface Prediction {
  place_id: string;
  description: string;
}

const MapboxAutocompleteField: React.FC<MapboxAutocompleteProps> = (props) => {
  const {
    resultType,
    placeholder,
    searchable = true,
    meta: { error, touched },
    input: { value, onBlur, onChange, ...restInput },
    mapFeaturesToOptions = (prediction: any) => {
      const { description, place_id } = prediction;
      return { label: description, value: place_id };
    },
    callback = () => {},
    ...rest
  } = props;

  const [debouncedValue, setDebouncedValue] = useDebouncedState({
    init: value,
    debounceTime: 500,
  });
  const [suggestions, setSuggestions] = useState<Prediction[]>([]);
  const [selectValue, setSelectValue] = useState<string>();
  const [prevSelected, setPrevSelected] = useState<OptionValue<any>>();
  const [sessiontoken, setSessionToken] = useState(() => uuid());
  const [userLocation, setUserLocation] = useState<UserLocation>();

  const getLocationSuggestions = useCallback(async () => {
    try {
      const suggestionsParams = {
        sessiontoken,
        input: debouncedValue,
        types: resultType?.join('|'),
        ...(userLocation?.latitude && {
          radius: 50000,
          location: `${userLocation.latitude},${userLocation.longitude}`,
        }),
      };

      const { data } = await api.places.getSuggestions(suggestionsParams);

      setSuggestions(data?.predictions);
    } catch (e) {
      console.error(e);
    }
  }, [debouncedValue, resultType, sessiontoken, userLocation]);

  const suggestedOptions = useMemo(() => {
    const sugg = suggestions?.map(mapFeaturesToOptions);

    if (prevSelected && sugg.every((el) => el.value !== prevSelected.value)) {
      return [...sugg, prevSelected];
    }
    return sugg;
  }, [suggestions, mapFeaturesToOptions, prevSelected]);

  // console.log({ debouncedValue, selectValue, value });

  const handleGetLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });
      },
      (error) => {
        console.error(error.message);
      },
    );
  };

  useEffect(() => {
    if (debouncedValue) {
      if (value === debouncedValue) {
        setSuggestions([{ description: value, place_id: value }]);
      } else {
        getLocationSuggestions();
      }
    }
  }, [debouncedValue, getLocationSuggestions, value]);

  useEffect(() => {
    handleGetLocation();
  }, []);

  return (
    <Select
      searchable={searchable}
      value={!selectValue?.length ? value : selectValue}
      options={suggestedOptions}
      placeholder={placeholder}
      error={error && touched ? error : ''}
      async
      onSearchChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedValue(value);
        setSelectValue(value);
      }}
      onChange={async ({ label, value }) => {
        if (label !== value) {
          await callback(value, sessiontoken);
        }

        setSessionToken(uuid());
        setDebouncedValue(value);
        setSelectValue(value);
        setPrevSelected({ value, label: label as any });
        onChange(label);
      }}
      onClose={() => {
        setSelectValue(value);
        onBlur();
      }}
      {...restInput}
      {...rest}
      multiple={false}
    />
  );
};

export default MapboxAutocompleteField;
