import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ReviewCardItemList } from 'types';
import { numberPriceToCurrencyString } from 'utils';
import { useSchoolPrices } from '../../Pricing/hooks/useSchoolPrices';
import { WEBSITE_INTEGRATION_PRICE } from 'searchality-data';

export default function () {
  const {
    values: { schools, structureType },
  } = useFormState();

  const { schoolPrice } = useSchoolPrices(structureType, schools);

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    return [
      {
        label: t('schoolSubscription'),
        value: t('standaloneSchoolReviewPrice', {
          price: numberPriceToCurrencyString(schoolPrice),
        }),
        description: t('standaloneSchoolReviewPriceDescription'),
      },
      {
        label: t('Pricing.Preview.setupFee'),
        value: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
        description: t('standaloneSchoolWebsitePriceDescription'),
      },
    ];
  }, [schoolPrice, t]);

  return list;
}
