import {
  SchoolGroupStructureType,
  SchoolCurriculumType,
} from 'searchality-data';

export const schoolTypeOptions: Record<
  SchoolCurriculumType,
  { label: string }
> = {
  International: {
    label: 'CurriculumType.international',
  },
  'National / State': {
    label: 'CurriculumType.nationalState',
  },
  'National Plus': {
    label: 'CurriculumType.nationalPlus',
  },
};

export const schoolSubTypePublicOptions: Record<
  SchoolGroupStructureType,
  { label: string; description: string }
> = {
  standalone: {
    label: 'publicStandaloneTitle',
    description: 'publicStandaloneDescription',
  },
  group: {
    label: 'publicGroupTitle',
    description: 'publicGroupDescription',
  },
};

export const schoolSubTypeIndependentOptions: Record<
  SchoolGroupStructureType,
  { label: string; description: string }
> = {
  standalone: {
    label: 'independentStandaloneTitle',
    description: 'independentStandaloneDescription',
  },
  group: {
    label: 'independentGroupTitle',
    description: 'independentGroupDescription',
  },
};
