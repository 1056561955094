import React, { useCallback } from 'react';
import PhotoPreview from 'components/PhotoPreview';
import useAppSelector from 'hooks/useAppSelector';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';
import { getNameInitials } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  useAttachEducationPhilosophyMutation,
  useAttachPublicationsMutation,
  useAttachTeacherCoverLetterMutation,
  useAttachTeacherPhotoMutation,
  useAttachTeacherResumeMutation,
  useLazyCreateTeacherApplicationQuery,
  usePatchTeacherApplicationMutation,
} from 'api/teacherApplications.api';
import { JobApplicationFormValues } from '../../types/JobApplicationFormValues';
import { ApplicationStatus, ApplicationType } from 'searchality-data';
import JobApplicationService from '../../services/JobApplication.service';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { updateSubmitting } from 'store/slices/jobs.slice';

import './JobApplicationLoggedIn.styles.scss';
import './JobApplicationLoggedIn.responsive.styles.scss';

const JobApplicationLoggedIn: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector(authSelectors.selectUser);

  const { jobId, applicationId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  const { t } = useTranslation();
  const baseClass = 'job-application-logged-in';

  const { imageFile, firstName, lastName, email } = user;

  const { isRightToWorkRequired } = job || {};

  const { values, submitting } = useFormState();

  const { hostCountryRequirements } = values;
  const {
    hasRightToWork,
    doesCandidateMeetAllRequirementsForVisa,
    rightToWork,
  } = hostCountryRequirements || {};

  const dispatch = useAppDispatch();

  const navigateToApplicationPreview = () => {
    if (
      values?.personalInformation?.firstName &&
      values?.personalInformation?.lastName
    ) {
      navigate('preview');
    } else {
      dispatch(popError(t('enterAtLeastName')));
    }
  };

  const [patchApplication] = usePatchTeacherApplicationMutation();
  const [attachCoverLetter] = useAttachTeacherCoverLetterMutation();
  const [attachPhoto] = useAttachTeacherPhotoMutation();
  const [createTeacherApplication] = useLazyCreateTeacherApplicationQuery();
  const [attachEducationPhilosophy] = useAttachEducationPhilosophyMutation();
  const [attachPublications] = useAttachPublicationsMutation();
  const [attachResume] = useAttachTeacherResumeMutation();

  const handleSaveAsDraft = useCallback(async () => {
    dispatch(updateSubmitting(true));
    const data = JobApplicationService.convertFormIntoApplication(
      values as JobApplicationFormValues,
      ApplicationType.ACADEMIC,
    );

    const {
      personalInformation: { applicantPhoto },
      yourProfile: { coverLetter, educationPhilosophy, resume },
      publications,
    } = values;

    if (applicationId) {
      patchApplication({ data, teacherId: user?._id, applicationId })
        .unwrap()
        .then(async (patchResult) => {
          if (applicantPhoto?.length) {
            await attachPhoto({
              teacherId: user?._id,
              applicationId: patchResult._id,
              imageFile: applicantPhoto[0],
            });
          }

          if (educationPhilosophy?.length) {
            await attachEducationPhilosophy({
              teacherId: user?._id,
              applicationId: patchResult._id,
              educationPhilosophyFile: educationPhilosophy[0],
            });
          }

          if (resume?.length) {
            await attachResume({
              teacherId: user?._id,
              applicationId: patchResult._id,
              resumeFile: resume[0],
            });
          }

          if (coverLetter?.length) {
            await attachCoverLetter({
              teacherId: user?._id,
              applicationId: patchResult._id,
              coverLetterFile: coverLetter[0],
            });
          }

          if (publications?.length) {
            await attachPublications({
              teacherId: user?._id,
              applicationId,
              publications,
            });
          }

          dispatch(popSuccess(t('applicationDraftSuccess')));
          navigate(`/applications/${patchResult._id}`);
        })
        .catch((error) => dispatch(popError(error.message)))
        .finally(() => {
          dispatch(updateSubmitting(false));
        });
    } else {
      createTeacherApplication({
        data: { ...data, vacancyId: job._id, status: ApplicationStatus.DRAFT },
        teacherId: user?._id,
      })
        .unwrap()
        .then(async (patchResult) => {
          if (applicantPhoto?.length) {
            await attachPhoto({
              teacherId: user?._id,
              applicationId: patchResult._id,
              imageFile: applicantPhoto[0],
            });
          }
          if (educationPhilosophy?.length) {
            await attachEducationPhilosophy({
              teacherId: user?._id,
              applicationId: patchResult._id,
              educationPhilosophyFile: educationPhilosophy[0],
            });
          }
          if (resume?.length) {
            await attachResume({
              teacherId: user?._id,
              applicationId: patchResult._id,
              resumeFile: resume[0],
            });
          }

          if (coverLetter?.length) {
            await attachCoverLetter({
              teacherId: user?._id,
              applicationId: patchResult._id,
              coverLetterFile: coverLetter[0],
            });
            dispatch(popSuccess(t('applicationDraftSuccess')));
          }

          if (publications?.length) {
            await attachPublications({
              teacherId: user?._id,
              applicationId,
              publications,
            });
          }

          navigate(`/applications/${patchResult._id}`);
        })
        .catch((error) => dispatch(popError(error.message)))
        .finally(() => {
          dispatch(updateSubmitting(false));
        });
    }
  }, [
    applicationId,
    attachCoverLetter,
    attachEducationPhilosophy,
    attachPhoto,
    attachPublications,
    attachResume,
    createTeacherApplication,
    dispatch,
    job._id,
    navigate,
    patchApplication,
    t,
    user?._id,
    values,
  ]);

  return (
    <div className={baseClass}>
      <section className={`${baseClass}__user`}>
        <PhotoPreview
          src={imageFile?.url}
          placeHolderText={getNameInitials(firstName, lastName)}
        />
        <div className={`${baseClass}__user__info`}>
          <h5>
            {firstName} {lastName}
          </h5>
          <p>{email}</p>
        </div>
      </section>
      {!(isRightToWorkRequired && !rightToWork) && (
        <section className={`${baseClass}__actions`}>
          <Button
            variant="outline"
            onClick={handleSaveAsDraft}
            disabled={
              submitting ||
              (hasRightToWork === false &&
                !doesCandidateMeetAllRequirementsForVisa)
            }
          >
            {t('saveDraft')}
          </Button>
          <Button onClick={navigateToApplicationPreview} disabled={submitting}>
            {t('previewApplication')}
          </Button>
        </section>
      )}
    </div>
  );
};

export default JobApplicationLoggedIn;
