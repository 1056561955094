import { createApi } from '@reduxjs/toolkit/query/react';
import { previewSubscriptionPriceSchool, School } from 'models/School';
import { PreviewSubscriptionPrice } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

type SubscriptionBody = {
  schools: previewSubscriptionPriceSchool[];
  promoCode?: string;
};

type NewSchoolsBody = {
  schools: Partial<School & { optOutIntegration: boolean }>[];
  schoolGroupId?: string;
  promoCode?: string;
};

type SubscribeBody = SubscriptionBody & { paymentMethodId: string };

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    previewSubscriptionPrice: builder.query<
      PreviewSubscriptionPrice,
      SubscriptionBody | void
    >({
      query: (data) => {
        return {
          url: 'school-groups/subscriptions/preview',
          method: 'post',
          data,
        };
      },
    }),
    previewNewSchoolsPrice: builder.query<
      PreviewSubscriptionPrice,
      NewSchoolsBody
    >({
      query: ({ schoolGroupId, ...data }) => {
        return {
          url: `school-groups/${schoolGroupId}/schools/preview`,
          method: 'post',
          data,
        };
      },
    }),
    subscribe: builder.query<any, SubscribeBody>({
      query: (data) => {
        return {
          url: 'school-groups/subscriptions',
          method: 'post',
          data,
        };
      },
    }),
    removeCoupon: builder.query<unknown, void>({
      query: () => {
        return {
          url: 'school-groups/subscriptions/remove-coupon',
          method: 'post',
        };
      },
    }),
  }),
});

export const {
  useLazyPreviewSubscriptionPriceQuery,
  usePreviewSubscriptionPriceQuery,
  useLazySubscribeQuery,
  useLazyPreviewNewSchoolsPriceQuery,
  useLazyRemoveCouponQuery,
} = paymentApi;
