import React from 'react';
import classNames from 'classnames';
import SchoolProfileInformation from './components/SchoolProfileInformation';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import ViewGuard from 'router/components/ViewGuard';
import UploadSchoolLogo from 'components/UploadSchoolLogo';

import './GroupSettings.styles.scss';
import './GroupSettings.styles.responsive.scss';

type GroupSettingsProps = {
  className?: string;
};

const GroupSettings: React.FC<GroupSettingsProps> = (props) => {
  const { className } = props;

  const {
    schoolGroup: { structureType, schools },
  } = useAppSelector(authSelectors.selectUser);

  const baseClass = 'group-settings';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      {/* {structureType !== SchoolGroupStructureType.STANDALONE && (
        <ViewGuard roles={[Role.AccountOwner]} permission="allow">
          <SchoolGroupInformation />
        </ViewGuard>
      )} */}
      {!!schools?.length && <SchoolProfileInformation />}
      {structureType === SchoolGroupStructureType.STANDALONE && (
        <ViewGuard
          roles={[Role.AccountManager, Role.AccountOwner]}
          permission="allow"
        >
          {/* <LinkedAssociations schoolId={schools?.[0]?._id} /> */}
          {/* <WidgetUrlInput schoolId={schools?.[0]?._id} /> */}
          <UploadSchoolLogo schoolId={schools?.[0]?._id} />
        </ViewGuard>
      )}
    </div>
  );
};

export default GroupSettings;
