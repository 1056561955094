import React, { useCallback, useMemo, useRef } from 'react';
import { VacancyFormValues } from 'models/Vacancy';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { ReactComponent as ReturnIcon } from 'assets/images/Return.icon.svg';
import useAppSelector from 'hooks/useAppSelector';
import {
  RouteElementMapping,
  VacancyWizardDescriptionMapping,
  VacancyWizardTitleMapping,
} from './constants';
import { useForm } from 'react-final-form';
import { scrollToFirstValidationError, scrollToRef } from 'utils';
import {
  nextVacancyStep,
  previuosVacancyStep,
} from 'store/slices/createVacancyWizard.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import CloseModal from './components/CloseModal';
import CloseEditModal from './components/CloseEditModal';
import ShareVacancyModal from '../../components/ShareVacancyModal';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import { ShareVacancyModalRef } from '../../components/ShareVacancyModal/ShareVacancyModal.component';
import useSaveAsDraftVacancy from '../../hooks/useSaveAsDraftVacancy';

import './VacancyWizard.styles.scss';

type VacancyWizardProps = {
  onSubmit: (values: VacancyFormValues) => void;
  initialValues: VacancyFormValues;
};
const VacancyWizard: React.FC<VacancyWizardProps> = () => {
  const { pathname } = useLocation();
  const { vacancyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { steps, currentStepIndex, nextStep } = useAppSelector(
    ({ createVacancyWizard }) => {
      const { steps, currentStepIndex } = createVacancyWizard;
      return {
        steps,
        currentStepIndex,
        nextStep: steps[currentStepIndex + 1],
      };
    },
  );

  const modalRef = useRef<ModalRef>();
  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();
  const divRef = useRef<HTMLDivElement>(null);

  const { saveAsDraft, isSubmitting } =
    useSaveAsDraftVacancy(shareVacancyModalRef);
  const { submit, getState } = useForm();
  const { t } = useTranslation();

  const isEditMode = pathname.includes('edit') && vacancyId;
  const baseClass = 'vacancy-wizard-page';

  const isFinalStep = useMemo(
    () => currentStepIndex === steps.length - 1,
    [currentStepIndex, steps.length],
  );

  const descriptionKey =
    VacancyWizardDescriptionMapping[
      steps[currentStepIndex] as keyof typeof VacancyWizardDescriptionMapping
    ];

  const goToNextStep = useCallback(
    async (divRef: React.MutableRefObject<HTMLDivElement>) => {
      await dispatch(nextVacancyStep());
      scrollToRef(divRef);
      navigate(`../${nextStep}`);
    },
    [dispatch, navigate, nextStep],
  );

  const handleGoBack = useCallback(async () => {
    if (currentStepIndex === 0) {
      modalRef.current.open();
      return;
    }
    await dispatch(previuosVacancyStep());

    navigate(`../${steps[currentStepIndex - 1]}`);
  }, [currentStepIndex, dispatch, navigate, steps]);

  const handleOnContinue = useCallback(() => {
    if (getState().invalid) {
      submit();
      scrollToFirstValidationError();
      return;
    }
    if (!isFinalStep) {
      return goToNextStep(divRef);
    }

    submit();
  }, [getState, goToNextStep, isFinalStep, submit]);

  return (
    <div className={baseClass} ref={divRef}>
      <Button
        onClick={handleGoBack}
        className="text-button"
        icon={<ReturnIcon />}
      >
        {t('goBack')}
      </Button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className={`${baseClass}__title-container`}>
          <div>
            <span>{`${currentStepIndex + 1}/${steps.length}`}</span>
            <h1>
              {t(
                VacancyWizardTitleMapping[
                  steps[
                    currentStepIndex
                  ] as keyof typeof VacancyWizardTitleMapping
                ],
              )}
            </h1>
          </div>
          {descriptionKey && <p>{t(descriptionKey)}</p>}
        </div>
        {RouteElementMapping[steps[currentStepIndex] as string]}
        <div className="buttons-container">
          <Button onClick={handleOnContinue}>{t('continue')}</Button>
          {!isEditMode && (
            <>
              <Button
                variant="outline"
                disabled={isSubmitting}
                onClick={() => saveAsDraft()}
              >
                {t('saveDraft')}
              </Button>
            </>
          )}
        </div>
      </form>
      <Button
        className={classNames('text-button', 'color-teal')}
        icon={<CloseIcon />}
        onClick={() => modalRef.current.open()}
      >
        {t('close')}
      </Button>
      {!isEditMode ? (
        <CloseModal ref={modalRef} />
      ) : (
        <CloseEditModal
          ref={modalRef}
          onDiscard={() => {
            modalRef.current.close();
            navigate('/vacancies');
            dispatch(vacancyBuilderActions.clearValues());
          }}
        />
      )}
      <ShareVacancyModal
        ref={shareVacancyModalRef}
        title={t('ShareVacancyModal.draftTitle')}
        description={t('ShareVacancyModal.draftDescription')}
        onClose={() => {
          navigate('/');
        }}
      />
    </div>
  );
};

export default VacancyWizard;
