import React from 'react';
import AcademicContractTerms, {
  AcademicContractTermsProps,
} from '../AcademicContractTerms/AcademicContractTerms.component';

type ContractTermsProps = AcademicContractTermsProps;

const ContractTerms: React.FC<ContractTermsProps> = (props) => {
  const { studentContractDays, studentNonContractDays } = props;

  const renderContractTerms = studentContractDays || studentNonContractDays;

  if (!renderContractTerms) {
    return null;
  }

  return (
    <li>
      <AcademicContractTerms
        studentContractDays={studentContractDays}
        studentNonContractDays={studentNonContractDays}
      />
    </li>
  );
};

export default ContractTerms;
