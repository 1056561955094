import { SchoolCurriculumType } from 'searchality-data';
import { CurriculumRoute } from '../types';

export const CURRICULUM_TYPE_ROUTE: Record<
  SchoolCurriculumType,
  CurriculumRoute
> = {
  International: 'international',
  'National / State': 'national-state',
  'National Plus': 'national-plus',
};

export const ROUTE_CURRICULUM_TYPE: Record<
  CurriculumRoute,
  SchoolCurriculumType
> = {
  international: SchoolCurriculumType.INTERNATIONAL,
  'national-state': SchoolCurriculumType.NATIONAL_STATE,
  'national-plus': SchoolCurriculumType.NATIONAL_PLUS,
};
