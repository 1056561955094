import React from 'react';
import HiringSchoolInformationFormFields from '../forms/HiringSchoolInformationFields/HiringSchoolInformationFields.component';
import MatchingAdvertisementWizardForm from '../forms/MatchingAdvertisementWizardForm';
import MatchingFeatureWizardForm from '../forms/MatchingFeatureWizardForm';
import PositionInformationFormFields from '../forms/PositionInformationFormFields';
import PositionRequirementsFormFields from '../forms/PositionRequirementsFormFields';
import BenefitsFormFields from '../forms/BenefitsFormFields';
import HcrFormFields from '../forms/HcrFormFields';
import ContractInformationFormFields from '../forms/ContractInformationFormFields';
import SalaryFormFields from '../forms/SalaryFormFields';

export const RouteElementMapping: { [name: string]: JSX.Element } = {
  'school-information': <HiringSchoolInformationFormFields />,
  'position-information': <PositionInformationFormFields />,
  'position-requirements': <PositionRequirementsFormFields />,
  'host-country-requirements': <HcrFormFields />,
  benefits: <BenefitsFormFields />,
  'contract-information': <ContractInformationFormFields />,
  salary: <SalaryFormFields />,
  'matching-advertisement': <MatchingAdvertisementWizardForm />,
  'matching-feature': <MatchingFeatureWizardForm />,
};

export const VacancyWizardTitleMapping = {
  'school-information': 'vacancyWizard.step1.title',
  'position-information': 'vacancyWizard.step2.title',
  'position-requirements': 'vacancyWizard.step3.title',
  'host-country-requirements': 'vacancyWizard.HCR.title',
  benefits: 'vacancyWizard.benefits.title',
  'contract-information': 'vacancyWizard.CI.title',
  salary: 'vacancyWizard.Salary.title',
  'matching-advertisement': 'vacancyWizard.step5.advertisementTitle',
  'matching-feature': 'vacancyWizard.step5.featureTitle',
};

export const VacancyWizardDescriptionMapping = {
  'host-country-requirements': 'vacancyWizard.HCR.description',
  benefits: 'vacancyWizard.benefits.description',
  'contract-information': 'vacancyWizard.CI.description',
  salary: 'vacancyWizard.Salary.description',
};

export const countryCurrencyObject = {
  'United Kingdom': '£ - British Pound',
  'United States': '$ - American Dollar',
  Germany: '€ - Euro',
  Afghanistan: '؋ - Afghan Afghani',
  Albania: 'Lek - Albanian Lek',
  Algeria: 'دج - Algerian Dinar',
  Andorra: '€ - Euro',
  Angola: 'Kz - Angolan Kwanza',
  'Antigua and Barbuda': '$ - East Caribbean Dollar',
  Argentina: '$ - Argentine Peso',
  Armenia: '֏ - Armenian Dram',
  Australia: '$ - Australian Dollar',
  Austria: '€ - Euro',
  Azerbaijan: '₼ - Azerbaijani Manat',
  Bahamas: '$ - Bahamian Dollar',
  Bahrain: '.د.ب - Bahraini Dinar',
  Bangladesh: '৳ - Bangladeshi Taka',
  Barbados: '$ - Barbadian Dollar',
  Belarus: 'Br - Belarusian Ruble',
  Belgium: '€ - Euro',
  Belize: 'BZ$ - Belize Dollar',
  Benin: 'CFA - West African CFA Franc',
  Bhutan: 'Nu. - Bhutanese Ngultrum',
  Bolivia: 'Bs - Bolivian Boliviano',
  'Bosnia and Herzegovina': 'KM - Bosnia and Herzegovina Convertible Mark',
  Botswana: 'P - Botswana Pula',
  Brazil: 'R$ - Brazilian Real',
  Brunei: '$ - Brunei Dollar',
  Bulgaria: 'лв - Bulgarian Lev',
  'Burkina Faso': 'CFA - West African CFA Franc',
  Burundi: 'FBu - Burundian Franc',
  'Cabo Verde': '$ - Cape Verdean Escudo',
  Cambodia: '៛ - Cambodian Riel',
  Cameroon: 'CFA - Central African CFA Franc',
  Canada: '$ - Canadian Dollar',
  'Central African Republic': 'CFA - Central African CFA Franc',
  Chad: 'CFA - Central African CFA Franc',
  Chile: '$ - Chilean Peso',
  China: '¥ - Chinese Yuan',
  Colombia: '$ - Colombian Peso',
  Comoros: 'CF - Comorian Franc',
  Congo: 'CFA - Central African CFA Franc',
  'Costa Rica': '₡ - Costa Rican Colón',
  Croatia: 'kn - Croatian Kuna',
  Cuba: '₱ - Cuban Peso',
  Cyprus: '€ - Euro',
  'Czech Republic': 'Kč - Czech Koruna',
  Denmark: 'kr - Danish Krone',
  Djibouti: 'Fdj - Djiboutian Franc',
  Dominica: '$ - East Caribbean Dollar',
  'Dominican Republic': 'RD$ - Dominican Peso',
  Egypt: 'E£ - Egyptian Pound',
  'Equatorial Guinea': 'CFA - Central African CFA Franc',
  Eritrea: 'Nfk - Eritrean Nakfa',
  Estonia: '€ - Euro',
  Eswatini: 'E - Swazi Lilangeni',
  Ethiopia: 'Br - Ethiopian Birr',
  Fiji: '$ - Fijian Dollar',
  Finland: '€ - Euro',
  France: '€ - Euro',
  Gabon: 'CFA - Central African CFA Franc',
  Gambia: 'D - Gambian Dalasi',
  Georgia: '₾ - Georgian Lari',
  Ghana: 'GH₵ - Ghanaian Cedi',
  Greece: '€ - Euro',
  Grenada: '$ - East Caribbean Dollar',
  Guatemala: 'Q - Guatemalan Quetzal',
  Guinea: 'FG - Guinean Franc',
  'Guinea-Bissau': 'CFA - West African CFA Franc',
  Guyana: '$ - Guyanese Dollar',
  Haiti: 'G - Haitian Gourde',
  Honduras: 'L - Honduran Lempira',
  Hungary: 'Ft - Hungarian Forint',
  Iceland: 'kr - Icelandic Króna',
  India: '₹ - Indian Rupee',
  Indonesia: 'Rp - Indonesian Rupiah',
  Iran: '﷼ - Iranian Rial',
  Iraq: 'ع.د - Iraqi Dinar',
  Ireland: '€ - Euro',
  Israel: '₪ - Israeli New Shekel',
  Italy: '€ - Euro',
  Jamaica: 'J$ - Jamaican Dollar',
  Japan: '¥ - Japanese Yen',
  Jordan: 'د.ا - Jordanian Dinar',
  Kazakhstan: '₸ - Kazakhstani Tenge',
  Kenya: 'KSh - Kenyan Shilling',
  Kiribati: '$ - Australian Dollar',
  'Korea, North': '₩ - North Korean Won',
  'Korea, South': '₩ - South Korean Won',
  Kosovo: '€ - Euro',
  Kuwait: 'د.ك - Kuwaiti Dinar',
  Kyrgyzstan: 'сом - Kyrgyzstani Som',
  Laos: '₭ - Lao Kip',
  Latvia: '€ - Euro',
  Lebanon: 'ل.ل - Lebanese Pound',
  Lesotho: 'L - Lesotho Loti',
  Liberia: 'L$ - Liberian Dollar',
  Libya: 'ل.د - Libyan Dinar',
  Liechtenstein: 'CHF - Swiss Franc',
  Lithuania: '€ - Euro',
  Luxembourg: '€ - Euro',
  Madagascar: 'Ar - Malagasy Ariary',
  Malawi: 'MK - Malawian Kwacha',
  Malaysia: 'RM - Malaysian Ringgit',
  Maldives: 'Rf - Maldivian Rufiyaa',
  Mali: 'CFA - West African CFA Franc',
  Malta: '€ - Euro',
  Mauritania: 'UM - Mauritanian Ouguiya',
  Mauritius: '₨ - Mauritian Rupee',
  Mexico: '$ - Mexican Peso',
  Moldova: 'L - Moldovan Leu',
  Monaco: '€ - Euro',
  Mongolia: '₮ - Mongolian Tugrik',
  Montenegro: '€ - Euro',
  Morocco: 'د.م. - Moroccan Dirham',
  Mozambique: 'MT - Mozambican Metical',
  Myanmar: 'Ks - Burmese Kyat',
  Namibia: 'N$ - Namibian Dollar',
  Nauru: '$ - Australian Dollar',
  Nepal: 'रू - Nepalese Rupee',
  Netherlands: '€ - Euro',
  'New Zealand': '$ - New Zealand Dollar',
  Nicaragua: 'C$ - Nicaraguan Córdoba',
  Niger: 'CFA - West African CFA Franc',
  Nigeria: '₦ - Nigerian Naira',
  'North Macedonia': 'ден - Macedonian Denar',
  Norway: 'kr - Norwegian Krone',
  Oman: 'ر.ع. - Omani Rial',
  Pakistan: 'Rs - Pakistani Rupee',
  Palestine: '₪ - Israeli New Shekel',
  Panama: 'B/. - Panamanian Balboa',
  'Papua New Guinea': 'K - Papua New Guinean Kina',
  Paraguay: '₲ - Paraguayan Guarani',
  Peru: 'S/ - Peruvian Sol',
  Philippines: '₱ - Philippine Peso',
  Poland: 'zł - Polish Złoty',
  Portugal: '€ - Euro',
  Qatar: 'ر.ق - Qatari Riyal',
  Romania: 'lei - Romanian Leu',
  Russia: '₽ - Russian Ruble',
  Rwanda: 'RF - Rwandan Franc',
  'Saint Kitts and Nevis': '$ - East Caribbean Dollar',
  'Saint Lucia': '$ - East Caribbean Dollar',
  'Saint Vincent and the Grenadines': '$ - East Caribbean Dollar',
  Samoa: 'WS$ - Samoan Tala',
  'San Marino': '€ - Euro',
  'Sao Tome and Principe': 'Db - São Tomé and Príncipe Dobra',
  'Saudi Arabia': 'ر.س - Saudi Riyal',
  Senegal: 'CFA - West African CFA Franc',
  Serbia: 'дин - Serbian Dinar',
  Seychelles: 'Rs - Seychellois Rupee',
  'Sierra Leone': 'Le - Sierra Leonean Leone',
  Singapore: '$ - Singapore Dollar',
  Slovakia: '€ - Euro',
  Slovenia: '€ - Euro',
  'Solomon Islands': 'SI$ - Solomon Islands Dollar',
  Somalia: 'S - Somali Shilling',
  'South Africa': 'R - South African Rand',
  'South Sudan': 'SSP - South Sudanese Pound',
  Spain: '€ - Euro',
  'Sri Lanka': 'Rs - Sri Lankan Rupee',
  Sudan: 'SDG - Sudanese Pound',
  Suriname: '$ - Surinamese Dollar',
  Sweden: 'kr - Swedish Krona',
  Switzerland: 'CHF - Swiss Franc',
  Syria: '£S - Syrian Pound',
  Taiwan: 'NT$ - New Taiwan Dollar',
  Tajikistan: 'ЅМ - Tajikistani Somoni',
  Tanzania: 'TSh - Tanzanian Shilling',
  Thailand: '฿ - Thai Baht',
  Togo: 'CFA - West African CFA Franc',
  Tonga: 'T$ - Tongan Paʻanga',
  'Trinidad and Tobago': 'TT$ - Trinidad and Tobago Dollar',
  Tunisia: 'د.ت - Tunisian Dinar',
  Turkey: '₺ - Turkish Lira',
  Turkmenistan: 'm - Turkmenistani Manat',
  Tuvalu: '$ - Australian Dollar',
  Uganda: 'USh - Ugandan Shilling',
  Ukraine: '₴ - Ukrainian Hryvnia',
  'United Arab Emirates': 'د.إ - UAE Dirham',
  Uruguay: '$U - Uruguayan Peso',
  Uzbekistan: 'лв - Uzbekistani Som',
  Vanuatu: 'VT - Vanuatu Vatu',
  'Vatican City': '€ - Euro',
  Venezuela: 'Bs.S. - Venezuelan Bolívar',
  Vietnam: '₫ - Vietnamese Dong',
  Yemen: '﷼ - Yemeni Rial',
  Zambia: 'ZK - Zambian Kwacha',
  Zimbabwe: 'Z$ - Zimbabwean Dollar',
};

const uniqueCurrencyArray = [...new Set(Object.values(countryCurrencyObject))];

export const currencyOptions = uniqueCurrencyArray.map((value) => ({
  label: value,
  value,
}));
