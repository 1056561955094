import React, { useCallback } from 'react';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RectanglesIcon } from 'icons/Rectangles.icon.svg';
import { ReactComponent as LocationIcon } from 'icons/Location.icon.svg';
import { ReactComponent as CurriculumIcon } from 'icons/Curriculum.icon.svg';
import { ReactComponent as BenefitsIcon } from 'icons/Benefits.icon.svg';
import { Button } from 'ncoded-component-library';
import useAppDispatch from 'hooks/useAppDispatch';
import { schoolProfileBuilderActions } from 'store/slices/schoolProfileBuilder.slice';
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from '../../constants';

import './SpbGetStarted.styles.scss';

const SpbGetStarted: React.FC = () => {
  const { t } = useTranslation();
  const baseClass = 'spb-get-started';

  const { allRoutes } = useAppSelector(
    ({ schoolProfileBuilder }) => schoolProfileBuilder,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const startTheBuilder = useCallback(() => {
    dispatch(schoolProfileBuilderActions.jumpToRoute(allRoutes[0]));
    navigate(`${routesMapping[allRoutes[0]]}`);
  }, [allRoutes, dispatch, navigate]);

  return (
    <div className={baseClass}>
      <GoBackButton to="/my-account/hiring-preferences-settings" />
      <div className={`${baseClass}__content`}>
        <h3>{t('SchoolProfileBuilder.GetStarted.title')}</h3>
        <p>{t('SchoolProfileBuilder.GetStarted.description')}</p>
        <ul className={`${baseClass}__flow`}>
          <li className={`${baseClass}__flow__stickers`}>
            <span className={`${baseClass}__flow__stickers__rectangles`}>
              <RectanglesIcon />
            </span>
            <span className={`${baseClass}__flow__stickers__location`}>
              <LocationIcon />
            </span>
            <span className={`${baseClass}__flow__stickers__curriculum`}>
              <CurriculumIcon />
            </span>
            <span className={`${baseClass}__flow__stickers__benefits`}>
              <BenefitsIcon />
            </span>
          </li>
          <li className={`${baseClass}__flow__names`}>
            <p>{t('personalInformation')}</p>
            <p>{t('teacherProfileBuilder.startPage.education/experience')}</p>
            <p>{t('teacherProfileBuilder.startPage.workReferrals')}</p>
            <div className="target">
              <p>{t('teacherProfileBuilder.startPage.targetPositions')}</p>
            </div>
          </li>
        </ul>
      </div>
      <Button onClick={startTheBuilder} className="get-started">
        {t('getStarted')}
      </Button>
    </div>
  );
};

export default SpbGetStarted;
