import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TPBPageHeading from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageHeading';
import YesNoExplainField from 'components/YesNoExplainField';
import SpbTopActions from '../../components/SpbTopActions';
import useHiringPreferencesFields from '../../hooks/useHiringPreferencesFields';
import SpbBottomActions from '../../components/SpbBottomActions';

import './SpbHiringPreferences.styles.scss';
import TPBPageContent from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageContent';

type SpbHiringPreferencesProps = {
  className?: string;
};

const SpbHiringPreferences: React.FC<SpbHiringPreferencesProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const fields = useHiringPreferencesFields();
  const classes = classNames('spb-hiring-preferences', className);

  return (
    <div className={classes}>
      <SpbTopActions to=".." />
      <TPBPageContent>
        <TPBPageHeading
          text={t('SchoolProfileBuilder.hiringPreferencesTitle')}
          description={t('SchoolProfileBuilder.description2')}
        />
        {fields?.map(({ name, label }) => (
          <YesNoExplainField
            key={name}
            name={name}
            label={label}
            noExplain
            required
          />
        ))}
        <SpbBottomActions />
      </TPBPageContent>
    </div>
  );
};

export default SpbHiringPreferences;
