import { School } from 'models/School';
import React from 'react';
import DateService from 'services/Date.service';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './SchoolCard.styles.scss';
import './SchoolCard.responsive.styles.scss';

type SchoolCardProps = School & {
  onClick?: (_id: string) => void;
  className?: string;
};

const SchoolCard: React.FC<SchoolCardProps> = ({
  _id,
  name,
  createdAt,
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  const classes = classNames('school-card', className, {
    'school-card--clickable': !!onClick,
  });

  return (
    <div className={classes} onClick={() => onClick?.(_id)}>
      <div className="school-card__name">{name}</div>
      <div className="school-card__date">
        {`${t('addedOn')} ${DateService.toDateString(createdAt)}`}
      </div>
    </div>
  );
};

export default SchoolCard;
