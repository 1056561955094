import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Anim from 'components/animations';
import { RadioButton } from 'ncoded-component-library';

import './ToggleField.styles.scss';
import './ToggleField.styles.responsive.scss';

type ToggleFieldProps = FieldRenderProps<boolean, HTMLElement>;

const ToggleField: React.FC<ToggleFieldProps> = (props) => {
  const {
    label,
    className,
    input: { value, onChange, checked, ...restInput },
    meta: { error, touched },
    noLabel = 'no',
    yesLabel = 'yes',
    reverse = false,
    disabled,
    ...rest
  } = props;

  const { t } = useTranslation();

  const classes = classNames('toggle-field', className);

  const toggleGroupClasses = classNames('toggle-field__group', {
    'toggle-field__group--reverse': reverse,
  });
  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <div className={toggleGroupClasses}>
        <RadioButton
          {...rest}
          {...restInput}
          checked={value === false}
          label={t(noLabel)}
          onChange={() => onChange(false)}
          disabled={disabled}
          withoutCheckmark
        />
        <RadioButton
          {...rest}
          {...restInput}
          checked={value}
          label={t(yesLabel)}
          onChange={() => onChange(true)}
          withoutCheckmark
          disabled={disabled}
        />
      </div>
      <Anim.Collapse active={!!error && touched}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
    </div>
  );
};

export default ToggleField;
