import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import VacancyFillFactory from '../VacancyFillFactory';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { useFillVacancyMutation } from 'api/vacancies.api';
import { useParams } from 'react-router-dom';
import ValueContext from 'providers/ValueContext/Value.context';
import { FillVacancyMethod } from 'searchality-data';

import './VacancyFillCandidateModal.styles.scss';

type VacancyFillCandidateModalProps = {
  className?: string;
  vacancyId: string;
  closeModal: () => void;
};

const VacancyFillCandidateModal: React.ForwardRefRenderFunction<
  ModalRef,
  VacancyFillCandidateModalProps
> = (props, ref) => {
  const { className, closeModal } = props;
  const [fillCandidates, { isLoading }] = useFillVacancyMutation();
  const dispatch = useAppDispatch();
  const { vacancyId } = useParams();

  const { t } = useTranslation();

  const baseClass = 'vacancy-fill-candidate-modal';

  const classes = classNames(baseClass, className);

  const { selectedIds, clearIds } = useContext(ValueContext);
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const onFillCandidates = async () => {
    fillCandidates({
      fillMethod: FillVacancyMethod.FROM_CANDIDATE_APPLICATIONS,
      vacancyId,
      applicantIds: selectedIds,
    })
      .unwrap()
      .then(() => {
        closeModal();
        dispatch(popSuccess(t('vacancySuccesfllyFilled')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  };

  useEffect(() => {
    if (modal?.openState === false) {
      clearIds();
    }
  }, [modal?.openState, clearIds]);

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('vacancyFillCandidateModalModal.title')}
      footer={
        <>
          <Button variant="outline" onClick={props.closeModal}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!selectedIds[0] || isLoading}
            onClick={onFillCandidates}
          >
            {t('vacancyFillCandidateModalModal.confirmButton')}
          </Button>
        </>
      }
    >
      {modal?.openState && <VacancyFillFactory />}
    </Modal>
  );
};

export default forwardRef(VacancyFillCandidateModal);
