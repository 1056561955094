import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import { required as req } from '../../validations';
import ToggleField from 'components/ToggleField';
import InputField from 'components/InputField';
import Anim from 'components/animations';

import './YesNoExplainField.styles.scss';

type YesNoExplainFieldProps = {
  className?: string;
  name: string;
  label?: string;
  required?: boolean;
  inputLabel?: string;
  inputDescription?: string;
  inputName?: string;
  noExplain?: boolean;
  disabled?: boolean;
};

const YesNoExplainField: React.FC<YesNoExplainFieldProps> = (props) => {
  const {
    className,
    name,
    label,
    required,
    inputLabel,
    inputDescription,
    inputName,
    noExplain,
    disabled = false,
  } = props;

  const { values } = useFormState();
  const { change } = useForm();

  const classes = classNames('yes-no-explain-field', className);

  useEffect(() => {
    if (!noExplain && values[name] === false) {
      change(inputName, '');
    }
  }, [change, inputName, name, noExplain, values]);

  return (
    <div className={classes}>
      <Field
        label={label}
        name={name}
        component={ToggleField}
        validate={req()}
        disabled={disabled}
      />
      {!noExplain && (
        <Anim.Collapse active={values[name]}>
          {values[name] && (
            <div className="yes-no-explain-field__input">
              {inputDescription && <p>{inputDescription}</p>}
              <Field
                name={inputName}
                component={InputField}
                required={required}
                validate={required ? req() : undefined}
                label={inputLabel}
                disabled={disabled}
              />
            </div>
          )}
        </Anim.Collapse>
      )}
    </div>
  );
};

export default YesNoExplainField;
