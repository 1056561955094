import React, { useCallback } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { schoolProfileBuilderActions } from 'store/slices/schoolProfileBuilder.slice';
import useAppSelector from 'hooks/useAppSelector';
import { useForm } from 'react-final-form';
import { convertFormToApi } from '../../utils';
import { SchoolProfileBuilderFormValues } from '../../types';
import { useUpdateHiringPreferencesMutation } from 'api/users.api';

import './SpbTopActions.styles.scss';
import './SpbTopActions.styles.responsive.scss';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { updateUser } from 'store/slices/auth.slice';
import authSelectors from 'store/selectors/auth.selectors';

type SpbTopActionsProps = {
  className?: string;
  to: string;
};

const SpbTopActions: React.FC<SpbTopActionsProps> = (props) => {
  const { className, to } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { allRoutes, currentRoute, finishedRoutes, cameThroughVacancy } =
    useAppSelector(({ schoolProfileBuilder }) => schoolProfileBuilder);

  const user = useAppSelector(authSelectors.selectUser);
  const [updateHiringPreferences] = useUpdateHiringPreferencesMutation();

  const { t } = useTranslation();

  const { getState } = useForm();

  const handleSaveAndExit = useCallback(() => {
    const { valid, values } = getState();

    const submitObject = convertFormToApi(
      values as SchoolProfileBuilderFormValues,
    );

    const fillStep = !finishedRoutes?.includes(currentRoute) && valid;

    updateHiringPreferences({
      ...submitObject,
      ...(fillStep && { filledSteps: [...finishedRoutes, currentRoute] }),
    })
      .unwrap()
      .then((data) => {
        dispatch(updateUser({ ...user, schoolGroup: data }));
        dispatch(popSuccess(t('SchoolProfileBuilder.saveAndExitSuccess')));
        navigate(
          cameThroughVacancy
            ? '/vacancies'
            : '/my-account/hiring-preferences-settings',
        );
      })
      .catch((e) => dispatch(popServerError(e)));
  }, [
    cameThroughVacancy,
    currentRoute,
    dispatch,
    finishedRoutes,
    getState,
    navigate,
    t,
    updateHiringPreferences,
    user,
  ]);

  const handleGoBack = useCallback(() => {
    if (currentRoute !== allRoutes?.[0]) {
      const indexOfPrevRoute = allRoutes?.findIndex(
        (route) => route === currentRoute,
      );

      dispatch(
        schoolProfileBuilderActions.jumpToRoute(
          allRoutes?.[indexOfPrevRoute - 1],
        ),
      );
    }
    navigate(to);
  }, [allRoutes, currentRoute, dispatch, navigate, to]);

  const classes = classNames('spb-top-actions', className);

  return (
    <div className={classes}>
      <GoBackButton onClick={handleGoBack} />
      <Button variant="outline" onClick={handleSaveAndExit}>
        {t('saveAndExit')}
      </Button>
    </div>
  );
};

export default SpbTopActions;
