import React, { useState } from 'react';
import InputField from 'components/InputField';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import PhoneNumberField from 'components/PhoneNumberField';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { AddressObject } from 'types';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import TPBBottomActions from '../../components/TPBBottomActions';
import { required } from 'validations';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import bus from 'modules/bus';
import useGetPlaceDetails from 'hooks/useGetPlaceDetails';

import './ContactInformation.styles.scss';
import './ContactInformation.styles.responsive.scss';

const ContactInformationPage: React.FC = () => {
  const { t } = useTranslation();
  const baseClass = 'tpb-contact-information';

  const { getAddressObject } = useGetPlaceDetails();

  const [addressInfo, setAddressInfo] = useState<AddressObject>();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { batch, change, submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = [
    'streetAddress',
    'city',
    'postcode',
    'state',
    'mobilePhone',
  ].some((fieldName) => dirtyFields[fieldName]);

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Work availability');
    }
  };

  return (
    <div className={baseClass}>
      <TPBTopActions />
      <TPBPageContent centered>
        <TPBPageHeading
          text={t('teacherProfileNavItems.contact-information')}
        />
        <Field
          name="streetAddress"
          component={MapboxAutocompleteField}
          key={JSON.stringify(addressInfo)}
          callback={async (place_id: string, sessiontoken: any) => {
            const address = await getAddressObject(place_id, sessiontoken);

            batch(() => {
              if (address?.city) {
                change('city', address.city);
              }
              if (address?.country) {
                change('state', address.country);
              }
              if (address?.postcode) {
                change('postcode', address.postcode);
              }

              setAddressInfo(address);
            });
          }}
          resultType={['address']}
          placeholder={t(
            'teacherProfileBuilder.contactInformation.currentAddress',
          )}
        />
        <div className={baseClass + '__row-fields'}>
          <Field
            name="city"
            placeholder={t('city')}
            component={MapboxAutocompleteField}
            validate={required()}
            key={addressInfo?.city}
            searchable={false}
          />
          <Field
            name="postcode"
            component={InputField}
            validate={required()}
            placeholder={t('zipCode')}
          />
        </div>
        <Field
          name="state"
          validate={required()}
          placeholder={t('country')}
          component={MapboxAutocompleteField}
          key={addressInfo?.country}
          searchable={false}
        />
        <PhoneNumberField name="mobilePhone" label={t('phoneNumber')} />
        <TPBBottomActions submitBtnDisabled={submitting} onSubmit={onSubmit} />
      </TPBPageContent>
    </div>
  );
};

export default ContactInformationPage;
