import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
} from 'api/vacancies.api';
import { useCallback, useState } from 'react';

export default () => {
  const [uploadPositionDescription] = useAttachPositionDescriptionMutation();
  const [deletePositionDescription] = useDeletePositionDescriptionMutation();
  const [uploadBoardingPositionDescription] =
    useAttachBoardingPositionDescriptionMutation();
  const [deleteBoardingPositionDescription] =
    useDeleteBoardingPositionDescriptionMutation();

  const [isLoading, setIsLoading] = useState(false);

  const uploadVacancyFiles = useCallback(
    async (
      positionDescription: File[],
      boardingRoleDescription: File[],
      _id: string,
      positionDescriptionUrl: string,
      boardingPositionRequirementsUrl: string,
    ) => {
      setIsLoading(true);
      if (positionDescription?.length) {
        await uploadPositionDescription({
          vacancyId: _id,
          positionDescriptionFile: positionDescription[0],
        });
      } else if (!positionDescription?.length && positionDescriptionUrl) {
        await deletePositionDescription({
          vacancyId: _id,
        });
      }
      if (boardingRoleDescription?.length) {
        await uploadBoardingPositionDescription({
          vacancyId: _id,
          boardingPositionDescriptionFile: boardingRoleDescription[0],
        });
      } else if (
        !boardingRoleDescription?.length &&
        boardingPositionRequirementsUrl
      ) {
        await deleteBoardingPositionDescription({
          vacancyId: _id,
        });
      }
      setIsLoading(false);
    },
    [
      deleteBoardingPositionDescription,
      deletePositionDescription,
      uploadBoardingPositionDescription,
      uploadPositionDescription,
    ],
  );

  return { uploadVacancyFiles, isLoading };
};
