import React from 'react';
import classNames from 'classnames';
import './SpbBottomActions.styles.scss';
import './SpbBottomActions.styles.responsive.scss';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';

type SpbBottomActionsProps = {
  className?: string;
};

const SpbBottomActions: React.FC<SpbBottomActionsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('spb-bottom-actions', className);

  return (
    <div className={classes}>
      <Button type="submit">{t('saveAndContinue')}</Button>
    </div>
  );
};

export default SpbBottomActions;
