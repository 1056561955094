import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CreateSchoolValues, ReviewCardItemList } from 'types';
import { CURRICULUM_TYPE_ROUTE } from '../../../subrouters/SchoolTypeRouter/constants';

export default function () {
  const { values } = useFormState();
  const { curriculumType } = values as CreateSchoolValues;

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    return [
      {
        label: t('type'),
        value: t(`CurriculumType.${CURRICULUM_TYPE_ROUTE[curriculumType]}`),
      },
    ];
  }, [curriculumType, t]);

  return list;
}
