import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolInformationStandAlone from './pages/SchoolInformation';
import SchoolLogo from 'router/subrouters/Dashboard/components/SchoolLogo';

export default [
  // {
  //   path: 'part-of-association',
  //   element: SchoolPartOfAssociation,
  // },
  // {
  //   path: 'choose-associations',
  //   element: SchoolAssociations,
  // },
  // {
  //   path: 'association-contact-details',
  //   element: AssociationContactDetails,
  // },
  {
    path: 'school-logo',
    element: SchoolLogo,
  },
  {
    index: true,
    element: SchoolInformationStandAlone,
  },
] as Array<ComplexRoute>;
