import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';
import { required, composeValidators, websiteValidation } from 'validations';
import GoBackButton from 'components/GoBackButton';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import { SchoolCurriculumType } from 'searchality-data';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import useGetPlaceDetails from 'hooks/useGetPlaceDetails';
import { CURRICULUM_TYPE_ROUTE } from '../../../SchoolTypeRouter/constants';

import './SchoolInformation.styles.scss';

type SchoolInformationProps = {
  className?: string;
};

const SchoolInformation: React.FC<SchoolInformationProps> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { submit, change, getState, batch } = useForm();

  const {
    values: { schools, school, curriculumType },
  } = useFormState();

  const classes = classNames('school-information-stand-alone', className);

  const checkIfSchoolExists = useCallback(async () => {
    setIsLoading(true);
    try {
      const { name, address, website } = school;

      const {
        data: { exists },
      } = await api.schools.checkIfSchoolExists({
        name,
        address,
        website,
      });

      if (!exists) {
        navigate(`school-logo`);
      } else {
        dispatch(popError(t('schoolAlreadyExists')));
      }
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [school, navigate, dispatch, t]);

  const { getAddressObject } = useGetPlaceDetails();

  useEffect(() => {
    if (schools?.length > 0) {
      change('school', schools[0]);
    }
  }, [change, schools]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        checkIfSchoolExists();
      }}
    >
      <GoBackButton
        to={`/create-school/school-type/${
          CURRICULUM_TYPE_ROUTE[curriculumType as SchoolCurriculumType]
        }/residential-type`}
      />
      <h1>{t('schoolInforamtion.title')}</h1>
      <p className="description">{t('schoolInforamtion.description')}</p>
      <Field
        name="school.name"
        component={InputField}
        label={t('schoolName')}
        validate={required()}
        required
      />
      <Field
        name="school.address"
        key={school?.address}
        component={MapboxAutocompleteField}
        callback={async (place_id: string, sessiontoken: string) => {
          const address = await getAddressObject(place_id, sessiontoken);

          // console.log(address);

          batch(() => {
            if (address?.city) {
              change('school.city', address.city);
            }
            if (address?.country) {
              change('school.country', address.country);
            }
            if (address?.postcode) {
              change('school.postcode', address.postcode);
            }
          });
        }}
        resultType={['address']}
        label={t('address')}
        required
        validate={required()}
      />
      <Field
        name="school.city"
        key={school?.city}
        component={MapboxAutocompleteField}
        label={t('city')}
        validate={required()}
        required
        searchable={false}
      />
      <div className="fields-row">
        <Field
          name="school.country"
          key={school?.country}
          component={MapboxAutocompleteField}
          searchable={false}
          label={t('country')}
          validate={required()}
          required
        />
        <Field
          name="school.postcode"
          component={InputField}
          label={t('zipcode')}
          validate={required()}
          maxLength={10}
          required
        />
      </div>
      <Field
        name="school.website"
        component={InputField}
        label={t('schoolWebsite')}
        validate={composeValidators(required(), websiteValidation())}
        required
      />
      <Button type="submit" disabled={isLoading}>
        {t('continue')}
      </Button>
    </form>
  );
};

export default SchoolInformation;
