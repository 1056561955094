import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { ReactComponent as CheckIcon } from 'icons/CheckMark.icon.svg';

import './CandidateRequirement.styles.scss';

type CandidateRequirementProps = {
  className?: string;
  title: string;
  requirement: string;
  fitCriteria: boolean;
  candidateHave?: string;
  additionalDescription?: string;
};

const CandidateRequirement: React.FC<CandidateRequirementProps> = (props) => {
  const {
    className,
    candidateHave,
    requirement,
    fitCriteria,
    title,
    additionalDescription,
  } = props;

  const baseClass = 'candidate-requirement';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__left`}>
        <p className={`${baseClass}__left__title`}>{title}</p>
        <p className={`${baseClass}__left__requirement`}>{requirement}</p>
        {candidateHave && (
          <p className={`${baseClass}__left__candidate-have`}>
            {candidateHave}
          </p>
        )}
        {additionalDescription && (
          <p className={`${baseClass}__left__error`}>{additionalDescription}</p>
        )}
      </div>
      {fitCriteria ? <CheckIcon /> : <CloseIcon />}
    </div>
  );
};

export default CandidateRequirement;
