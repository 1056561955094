import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { candidatesApi } from 'api/canidates.api';
import { teacherApplicationsApi } from 'api/teacherApplications.api';
import authSlice, { loadCurrentUser, logout } from './slices/auth.slice';
import createVacancyWizardSlice from './slices/createVacancyWizard.slice';
import popNotificationsSlice from './slices/popNotifications.slice';
import vacancyBuilderSlice from './slices/vacancyBuilder.slice';
import teacherProfileBuilderSlice from './slices/teacherProfileBuilder.slice';
import { membersApi } from 'api/members.api';
import { vacanciesApi } from 'api/vacancies.api';
import { associationsApi } from 'api/associations.api';
import credentialsService from 'services/credentialsService';
import { paymentApi } from 'api/payment.api';
import paymentSlice from './slices/payment.slice';
import addNewSchoolSlice from './slices/addNewSchool.slice';
import { createParamsReducer } from './reducer/params.reducer';
import requestParamsMiddleware from './middlewares/requestParams.middleware';
import { jobsApi } from 'api/jobs.api';
import jobsSlice from './slices/jobs.slice';
import { matchingApi } from 'api/matching.api';
import storageService from 'services/storageService';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';
import { usersApi } from 'api/users.api';
import schoolProfileBuilderSlice from './slices/schoolProfileBuilder.slice';

const allReducers = combineReducers({
  auth: authSlice,
  payment: paymentSlice,
  popNotifications: popNotificationsSlice,
  createVacancyWizard: createVacancyWizardSlice,
  vacancyBuilder: vacancyBuilderSlice,
  vacanciesParams: createParamsReducer('vacanciesParams', {
    $limit: 6,
  }),
  associationSchoolsParams: createParamsReducer('associationSchoolsParams', {
    $limit: 6,
    $populate: ['schoolGroup'],
  }),
  associationMembersParams: createParamsReducer('associationMembersParams', {
    $limit: 20,
  }),
  vacanciesTemplateFactoryParams: createParamsReducer(
    'vacanciesTemplateFactoryParams',
    {
      $limit: 6,
    },
  ),
  [associationsApi.reducerPath]: associationsApi.reducer,
  [candidatesApi.reducerPath]: candidatesApi.reducer,
  [teacherApplicationsApi.reducerPath]: teacherApplicationsApi.reducer,
  [membersApi.reducerPath]: membersApi.reducer,
  [vacanciesApi.reducerPath]: vacanciesApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [matchingApi.reducerPath]: matchingApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  teacherProfileBuilder: teacherProfileBuilderSlice,
  schoolProfileBuilder: schoolProfileBuilderSlice,
  addNewSchool: addNewSchoolSlice,
  jobs: jobsSlice,
});

const store = configureStore({
  reducer: (state: ReturnType<typeof allReducers>, action: AnyAction) => {
    if (action.type === logout.type) {
      credentialsService.removeAuthBody();
      storageService.removeItem(STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin);
      return allReducers(undefined, action);
    }
    return allReducers(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      associationsApi.middleware,
      candidatesApi.middleware,
      teacherApplicationsApi.middleware,
      membersApi.middleware,
      vacanciesApi.middleware,
      paymentApi.middleware,
      matchingApi.middleware,
      jobsApi.middleware,
      usersApi.middleware,
      requestParamsMiddleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

!window.location.href.includes('auth/school-signup') &&
  credentialsService.token &&
  credentialsService.refreshToken &&
  store.dispatch(loadCurrentUser());

window.location.href.includes('auth/school-signup?token=') &&
  store.dispatch(logout());

export default store;
