import FormFieldLabel from 'components/FormFieldLabel';
import TwoOptionsSelectField from 'components/TwoOptionsSelectField';
import YesNoExplainField from 'components/YesNoExplainField';
import useCurriculumExperienceOptions from 'hooks/selectOptions/useCurriculumExperienceOptions';
import React, { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { VacancyStatus, YearsOfExperience } from 'searchality-data';

type CurriculumExperienceFieldsProps = {
  baseClass: string;
};

const CurriculumExperienceFields: React.FC<CurriculumExperienceFieldsProps> = (
  props,
) => {
  const { baseClass } = props;

  const { t } = useTranslation();

  const curriculumExperienceOptions = useCurriculumExperienceOptions();

  const {
    values: { status, isSpecificCurriculumExperienceRequired },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const yearsOfCurriculumExperience = useMemo(
    () =>
      Object.keys(YearsOfExperience)
        .filter((key) => key !== 'NONE')
        .map((key, index) => ({
          label: t(`yearsOfExperience.`, {
            years: YearsOfExperience[key as keyof typeof YearsOfExperience],
          }),
          value: (index + 1).toString(),
        })),
    [t],
  );

  return (
    <>
      <div>
        <FormFieldLabel text={t('vacancyWizard.step3.curriculumExpLabel')} />
        <p className={`${baseClass}__experience__sub`}>
          {t('vacancyWizard.step3.curriculumExpParagraph')}
        </p>
      </div>
      <YesNoExplainField
        name="isSpecificCurriculumExperienceRequired"
        noExplain
        required
        disabled={isOngoingVacancyEdit}
      />
      {isSpecificCurriculumExperienceRequired && (
        <Field
          name="curricula"
          component={TwoOptionsSelectField}
          firstSelectOptions={curriculumExperienceOptions}
          secondSelectOptions={yearsOfCurriculumExperience}
          firstSelectLabel={t('curriculumExperience')}
          secondSelectLabel={t(
            'teacherProfileBuilder.careerExprexience.yearsOfExperience',
          )}
          firstOptionName="curriculumExperience"
          secondOptionName="yearsOfCurriculumExperienceRequired"
          otherOptionInputPlaceholder="Please specify"
          disabled={isOngoingVacancyEdit}
        />
      )}
    </>
  );
};

export default CurriculumExperienceFields;
