import SelectField from 'components/SelectField';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useCategoryOptions from '../../../../hooks/useCategoryOptions';
import { required } from 'validations';
import { NonAcademicVacancyCategory } from 'searchality-data';
import InputField from 'components/InputField';
import FormFieldLabel from 'components/FormFieldLabel';

type NonAcademicJobPositionsProps = {
  className: string;
  isDisabled?: boolean;
};

const NonAcademicJobPositions: React.FC<NonAcademicJobPositionsProps> = (
  props,
) => {
  const { className, isDisabled } = props;

  const { t } = useTranslation();

  const {
    values: { category },
  } = useFormState();

  const categoryOptions = useCategoryOptions();

  return (
    <div className={className}>
      <FormFieldLabel text={t('department')} hint={t('categoryTippyContent')} />
      <Field
        name="category"
        options={categoryOptions}
        label={t('category')}
        component={SelectField}
        validate={required()}
        required
        disabled={isDisabled}
      />
      {category === NonAcademicVacancyCategory.OTHER && (
        <Field
          name="categoryOther"
          component={InputField}
          label={t('pleaseEnterCategory')}
          validate={required()}
          required
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

export default NonAcademicJobPositions;
