import classNames from 'classnames';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import React, { ReactNode, useMemo } from 'react';

import './ScrollableLayout.styles.scss';
import './ScrollableLayout.styles.responsive.scss';

import variables from 'scss/layout.module.scss';

type ScrollableLayoutContextValue = {
  setMainClassName: React.Dispatch<React.SetStateAction<string>>;
};

export const ScrollableLayoutContext =
  React.createContext<ScrollableLayoutContextValue>({
    setMainClassName: () => {},
  });

type MainProps = {
  children: ReactNode;
  className?: string;
  sidebarComponent?: any;
  isGreen?: boolean;
  headerShadow?: boolean;
  isTeacher?: boolean;
};

export const ScrollableLayout: React.FC<MainProps> = ({
  className: pClassName,
  children,
  sidebarComponent,
  isGreen = false,
  headerShadow = false,
  isTeacher = false,
}) => {
  const background = useMemo(() => {
    const isAuth = pClassName?.includes('auth');
    if (isGreen)
      return 'radial-gradient(112.74% 222.95% at 101.53% 6.84%, #00416A 0%, #007078 49.75%, #BDD8D9 100%)';

    if (isTeacher) {
      return variables['color-primary-dark-blue'];
    }
    if (isAuth) {
      return `
      ${variables['color-gray-f5']} 
      ${variables['signup-image-url']} 
      no-repeat;
      background-position: 0 100%;
      background-size: 100%; }`;
    }

    return variables['color-gray-f6'];
  }, [pClassName, isGreen, isTeacher]);

  return (
    <div className={classNames(pClassName, 'scrollable-layout')}>
      <style>{`#root {background:${background};}`}</style>
      <Header shadow={headerShadow} />
      <main>
        {sidebarComponent && <Sidebar>{sidebarComponent}</Sidebar>}
        {children}
      </main>
    </div>
  );
};

export default ScrollableLayout;
