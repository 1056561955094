import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { VacancyType } from 'searchality-data';
import {
  changeIsFormFilled,
  changeVacancyType,
  resetVacancyStep,
  resetVacancyType,
} from 'store/slices/createVacancyWizard.slice';
import vacancyConstants from 'constants/vacancy';
import VacancyBuilderFormWrapper from '../../components/VacancyBuilderFormWrapper/VacancyBuilderFormWrapper.component';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import authSelectors from 'store/selectors/auth.selectors';
import { ShareVacancyModalRef } from '../../components/ShareVacancyModal/ShareVacancyModal.component';
import useStandaloneSchoolId from '../../pages/VacancyWizard/hooks/useStandaloneSchoolId';
import { useAddVacancyMutation } from 'api/vacancies.api';
import VacancyWizardService from '../../services/VacancyWizard.service';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import ShareVacancyModal from '../../components/ShareVacancyModal';
import { useTranslation } from 'react-i18next';
import useUploadVacancyFiles from '../../hooks/useUploadVacancyFiles';
import PageLoader from 'components/PageLoader';

const CreateVacancy: React.FC = () => {
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );
  const templateVacancy = useAppSelector((state) => state.vacancyBuilder);
  const user = useAppSelector(authSelectors.selectUser);

  const [initialValues, setInitialValues] =
    useState<Partial<Omit<VacancyFormValues, 'salaryInfo'>>>();
  const [isLoaingInitialValues, setIsLoadingInitialValues] = useState(false);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const shareVacancyRef = useRef<ShareVacancyModalRef>();
  const standaloneSchoolId = useStandaloneSchoolId();

  const [addVacancy] = useAddVacancyMutation();
  const { uploadVacancyFiles } = useUploadVacancyFiles();

  const onPublish = useCallback(
    async (values: VacancyFormValues) => {
      if (!pathname.includes('preview')) {
        navigate(
          `/vacancies/create-vacancy/${vacancyConstants.vacancyTypeToRouteMapping[type]}/preview`,
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(changeIsFormFilled(true));
        return;
      }

      const { positionDescription, boardingRoleDescription } = values;
      const vacancy = VacancyWizardService.convertFormIntoVacancy(
        values,
        type,
        standaloneSchoolId,
        user,
      ) as Vacancy;

      const {
        positionDescriptionUrl,
        boardingPositionRequirementsUrl,
        ...forAddVacancy
      } = vacancy;

      await addVacancy({
        ...forAddVacancy,
      })
        .unwrap()
        .then(
          async ({
            _id,
            positionDescriptionUrl,
            boardingPositionRequirementsUrl,
          }) => {
            await uploadVacancyFiles(
              positionDescription,
              boardingRoleDescription,
              _id,
              positionDescriptionUrl,
              boardingPositionRequirementsUrl,
            );
            dispatch(popSuccess(t('successPublishedVacancy')));
            shareVacancyRef?.current?.openShareVacancy(_id);
          },
        )
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [
      addVacancy,
      dispatch,
      navigate,
      pathname,
      standaloneSchoolId,
      t,
      type,
      uploadVacancyFiles,
      user,
    ],
  );

  useEffect(() => {
    async function setFormValues() {
      setIsLoadingInitialValues(true);
      let formValues;

      if (templateVacancy) {
        formValues = await VacancyWizardService.convertVacancyIntoForm(
          templateVacancy,
          false,
        );
      } else {
        formValues = VacancyWizardService.convertHPIntoForm(
          user?.schoolGroup?.hiringPreferences,
          user?.schools?.[0]?.country,
          type,
        );
      }

      setInitialValues(formValues);
      setIsLoadingInitialValues(false);
    }

    setFormValues();
  }, [
    templateVacancy,
    type,
    user?.schoolGroup?.hiringPreferences,
    user?.schools,
  ]);

  useEffect(() => {
    if (!type) {
      if (
        pathname.includes(
          vacancyConstants.vacancyTypeToRouteMapping[VacancyType.NON_ACADEMIC],
        )
      ) {
        dispatch(changeVacancyType(VacancyType.NON_ACADEMIC));
      } else {
        dispatch(changeVacancyType(VacancyType.ACADEMIC));
      }
    }
  }, [dispatch, pathname, type]);

  useEffect(() => {
    return () => {
      dispatch(resetVacancyType());
      dispatch(changeIsFormFilled(false));
      dispatch(resetVacancyStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaingInitialValues) {
    return <PageLoader />;
  }

  return (
    <VacancyBuilderFormWrapper
      initialValues={initialValues}
      onSubmit={onPublish}
    >
      <>
        <Outlet />
        <ShareVacancyModal
          ref={shareVacancyRef}
          title={t('ShareVacancyModal.publishTitle')}
          description={t('ShareVacancyModal.publishDescription')}
          onClose={() => {
            dispatch(resetVacancyStep());
            dispatch(vacancyBuilderActions.clearValues());
            navigate('/vacancies/active');
          }}
        />
      </>
    </VacancyBuilderFormWrapper>
  );
};

export default CreateVacancy;
