import React, { ReactNode, useState, useCallback } from 'react';
import classNames from 'classnames';
import useAppDispatch from 'hooks/useAppDispatch';
import { useDisableVacanciesBannerMutation } from 'api/matching.api';
import { useTranslation } from 'react-i18next';
import storageService from 'services/storageService';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';
import { updateUser } from 'store/slices/auth.slice';
import Banner from 'components/Banner';
import { ReactComponent as FireIcon } from 'icons/Fire.icon.svg';
import { Checkbox } from 'ncoded-component-library';
import useMatchMedia from 'hooks/useMatchMedia';

import './MatchingInformationBetaBanner.styles.scss';
import './MatchingInformationBetaBanner.styles.responsive.scss';

type MatchingInformationBetaBannerProps = {
  className?: string;
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  showCheckbox?: boolean;
  iconColor?: 'orange' | 'blue' | 'teal';
};

const MatchingInformationBetaBanner: React.FC<
  MatchingInformationBetaBannerProps
> = (props) => {
  const {
    className,
    showCheckbox = true,
    icon,
    title,
    subtitle,
    iconColor,
  } = props;

  const classes = classNames('matching-information-beta-banner', className);

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const isPhablet = useMatchMedia('isPhablet');

  const dispatch = useAppDispatch();
  const [disableVacanciesBanner] = useDisableVacanciesBannerMutation();

  const { t } = useTranslation();

  const handleOnCloseBanner = useCallback(async () => {
    if (!dontShowAgain) {
      return storageService.setItem(
        STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin,
        'true',
      );
    }
    await disableVacanciesBanner(true)
      .unwrap()
      .then((res) => {
        dispatch(updateUser(res));
      });
  }, [disableVacanciesBanner, dispatch, dontShowAgain]);

  return (
    <>
      <Banner
        className={classes}
        iconColor={iconColor}
        title={title ?? t('MatchingInformationBetaBanner.title')}
        subtitle={subtitle ?? t('MatchingInformationBetaBanner.subtitle')}
        onClose={handleOnCloseBanner}
        actions={
          <a
            href="https://searchality.com/pricing"
            target="_blank"
            rel="noreferrer"
          >
            {t('learnMore')}
          </a>
        }
        icon={!isPhablet && (icon ?? <FireIcon />)}
      >
        {showCheckbox && (
          <Checkbox
            onChange={(e) => setDontShowAgain(e as boolean)}
            checked={dontShowAgain}
            label={t('dontShowAgain')}
          />
        )}
      </Banner>
    </>
  );
};

export default MatchingInformationBetaBanner;
