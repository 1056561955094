import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import ObjectPreviewCard from 'components/ObjectPreviewCard';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import InputField from 'components/InputField';
import { required } from 'validations';
import { Field, withTypes } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import TextAreaField from 'components/TextAreaField';
import SelectField from 'components/SelectField';
import useDegreeLevelOptions from './hooks/useDegreeLevelOptions';
import DateService from 'services/Date.service';
import { FORM_ERROR } from 'final-form';
import ErrorMessage from 'components/ErrorMessage';
import { EducationType } from 'models/Candidate';
import { DateTime } from 'luxon';
import ConditionalField from 'components/ConditionalField';
import YearMonthInput from 'components/YearMonthInput';
import { FieldYearMonthInput } from 'components/YearMonthInput/YearMonthInput.component';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';

import './Educations.styles.scss';
import './Educations.styles.responsive.scss';

type EducationsProps = {
  className?: string;
  value: EducationType[];
  touched?: boolean;
  onChange: (value: EducationType[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
};

const Educations: React.FC<EducationsProps> = (props) => {
  const { className, value, onChange } = props;

  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<Partial<EducationType>>();
  const [editIndex, setEditIndex] = useState<number>();

  const addModalRef = useRef<ModalRef>();

  const degreeLevelOptions = useDegreeLevelOptions();

  const { Form } = withTypes<EducationType>();

  const classes = classNames('education', className);

  const handleDelete = useCallback(
    (index: number) => {
      onChange(value.filter((_, ind) => ind !== index));
    },
    [onChange, value],
  );

  const handleEdit = useCallback((education: EducationType, index: number) => {
    setInitialValues(education);
    setEditIndex(index);
    addModalRef.current.open();
  }, []);

  const educationArray = useMemo(
    () =>
      value?.map((education, index) => {
        const { isCurrentlyStudying, startDate, endDate, ...rest } = education;

        const { monthLong, year } = DateTime.fromJSDate(new Date(startDate));
        const { monthLong: endMonth, year: endYear } = DateTime.fromJSDate(
          new Date(endDate),
        );

        return (
          <ObjectPreviewCard
            data={{
              startDate: `${monthLong}  ${year}`,
              ...(endDate && { endDate: `${endMonth}  ${endYear}` }),
              ...rest,
            }}
            key={`${rest.schoolName}-${index}`}
            handleEdit={() => handleEdit(education, index)}
            handleDelete={() => handleDelete(index)}
          />
        );
      }),
    [handleDelete, handleEdit, value],
  );

  const handleSubmit = useCallback(
    (values: EducationType) => {
      const { startDate, endDate } = values;

      if (
        startDate &&
        endDate &&
        DateService.compareDates(startDate, endDate)
      ) {
        return {
          [FORM_ERROR]: t('startAfterEndDateError'),
        };
      }
      if (editIndex === undefined) {
        onChange([...value, values]);
      } else {
        const valueCopy = structuredClone(value);
        valueCopy[editIndex] = values;

        onChange(valueCopy);
      }
      addModalRef.current.close();
    },
    [editIndex, t, onChange, value],
  );

  const endDateValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.isCurrentlyStudying) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
        const year = value.getFullYear();

        if (year < 1900 || year > DateTime.now().year) {
          return t('pleaseEnterYearBetween', {
            from: 1900,
            to: DateTime.now().year,
          });
        }
      }
      return undefined;
    },
    [t],
  );

  const startDateValidation = useCallback(
    (value: any) => {
      if (typeof value === 'undefined' || value === null) {
        return t('requiredField');
      }
      const year = value.getFullYear();

      if (year < 1900 || year > DateTime.now().year) {
        return t('pleaseEnterYearBetween', {
          from: 1900,
          to: DateTime.now().year,
        });
      }

      return undefined;
    },
    [t],
  );

  return (
    <div className={classes}>
      <Button
        variant="outline"
        icon={<PlusIcon />}
        onClick={() => {
          addModalRef.current.open();
        }}
        className="education__button"
      >
        {t('add')}
      </Button>
      {!!value?.length && (
        <div className="education__cards">{educationArray}</div>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          startDate: initialValues?.startDate
            ? new Date(initialValues?.startDate)
            : undefined,
          endDate: initialValues?.endDate
            ? new Date(initialValues?.endDate)
            : undefined,
        }}
        render={({
          handleSubmit,
          hasSubmitErrors,
          submitError,
          modifiedSinceLastSubmit,
          values,
          form: { reset },
        }) => {
          return (
            <Modal
              className="education-modal"
              ref={addModalRef}
              title={t(
                editIndex !== undefined
                  ? 'educationModal.editEducation'
                  : 'educationModal.addEducation',
              )}
              onClose={() => {
                reset();
                setInitialValues({});
                setEditIndex(undefined);
              }}
              footer={
                <Button onClick={handleSubmit}>
                  {t(
                    editIndex !== undefined
                      ? 'educationModal.editEducation'
                      : 'educationModal.addEducation',
                  )}
                </Button>
              }
            >
              <form onSubmit={handleSubmit}>
                <div className="education-modal__basic-info">
                  <div className="education-modal__basic-info__first-row">
                    <Field
                      name="schoolName"
                      component={InputField}
                      label={t('schoolName')}
                      validate={required()}
                      required
                    />
                    <Field
                      name="degreeLevel"
                      component={SelectField}
                      label={t('educationModal.degreeLevel')}
                      validate={required()}
                      options={degreeLevelOptions}
                      required
                    />
                  </div>
                  <div className="education-modal__basic-info__second-row">
                    <Field
                      name="location"
                      key={values?.location}
                      component={MapboxAutocompleteField}
                      label={t('educationModal.county')}
                      allCountries
                      resultType={['locality', 'administrative_area_level_3']}
                      validate={required()}
                      required
                    />
                    <Field
                      name="fieldOfStudy"
                      component={InputField}
                      label={t('educationModal.fieldOfStudy')}
                      validate={required()}
                      required
                    />
                  </div>
                </div>
                <div className="education-modal__date-range">
                  <FieldYearMonthInput
                    name="startDate"
                    labelYear={t('enterYear')}
                    labelMonth={t('selectMonth')}
                    label={t('startDate')}
                    onlyPast
                    validate={startDateValidation}
                  />
                  <ConditionalField<Date>
                    component={YearMonthInput}
                    name="endDate"
                    labelYear={t('enterYear')}
                    labelMonth={t('selectMonth')}
                    label={t('endDate')}
                    onlyPast
                    validate={endDateValidation}
                    shouldClear={values?.isCurrentlyStudying}
                    disabled={values?.isCurrentlyStudying}
                  />
                </div>
                <Field
                  name="isCurrentlyStudying"
                  component={CheckboxField}
                  type="checkbox"
                  isStyledDefault
                  label={t('educationModal.currentlyStudyLabel')}
                  className="education-modal__checkbox"
                />
                <p className="education-modal__activities-title">
                  {t('activitiesAndSocieties')}
                </p>
                <p className="education-modal__activities-description">
                  {t('educationModal.activitiesAndSocietiesDescription')}
                </p>
                <Field
                  name="activitiesAndSocieties"
                  component={TextAreaField}
                  className="education-modal__activities-textarea"
                  maxLength={250}
                  showLengthCount
                />
                {hasSubmitErrors && !modifiedSinceLastSubmit && (
                  <ErrorMessage message={submitError} />
                )}
              </form>
            </Modal>
          );
        }}
      />
    </div>
  );
};

export default Educations;
