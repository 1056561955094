import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationMetadataCard from '../../components/ApplicationMetadataCard';
import { ApplicationStatus } from 'searchality-data';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import { useGetApplicationQuery } from 'api/teacherApplications.api';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import PageLoader from 'components/PageLoader';
import TeacherApplicationUploadRequestedDocuments from '../../components/TeacherApplicationUploadRequestedDocuments';
import ApplicationPreviewAboutPosition from '../../components/ApplicationPreviewAboutPosition';
import ApplicationPreviewOffers from '../../components/ApplicationPreviewOffers';
import ApplicationPreviewBoarding from '../../components/ApplicationPreviewBoarding';

import './ApplicationPreview.styles.scss';

type ApplicationPreviewProps = {
  className?: string;
};

const ApplicationPreview: React.FC<ApplicationPreviewProps> = (props) => {
  const { className } = props;

  const { id: applicationId } = useParams() || {};
  const { _id: teacherId } = useAppSelector(authSelectors.selectUser) || {};

  const { data: application, isLoading } = useGetApplicationQuery(
    {
      applicationId,
      teacherId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const baseClass = 'application-preview';
  const classes = classNames(baseClass, className);

  const handleFinishApplication = useCallback(async () => {
    navigate(
      `/job-application/${application?.vacancy?._id}/${application?._id}`,
    );
  }, [application, navigate]);

  const { status } = application || {};

  const isActiveApplication = [
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.IN_PROCESS,
    ApplicationStatus.DRAFT,
    ApplicationStatus.INFORMATION_REQUESTED,
    ApplicationStatus.INFORMATION_SUBMITTED,
  ].includes(status);

  const isPreviousApplication = [
    ApplicationStatus.REJECTED_BY_SCHOOL,
    ApplicationStatus.VACANCY_CLOSED,
    ApplicationStatus.EXPIRED,
    ApplicationStatus.EXPIRED_HIRED_EXTERNALLY,
    ApplicationStatus.EXPIRED_HIRED_INTERNALLY,
  ].includes(status);

  const isSuccessfulApplication = [ApplicationStatus.ACCEPTED].includes(status);

  const navigateTo = isActiveApplication
    ? '/applications/active'
    : isPreviousApplication
    ? '/applications/previous'
    : isSuccessfulApplication
    ? '/applications/successful'
    : undefined;

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <GoBackButton to={navigateTo} />
      {application && (
        <>
          <ApplicationMetadataCard
            handleFinish={handleFinishApplication}
            data={{
              title: application.vacancy?.positionTitle,
              schoolName: application.schools
                ?.map((school) => school.name)
                .join(', '),
              date:
                application.status === ApplicationStatus.DRAFT
                  ? application.createdAt
                  : application.appliedAt,
              status: application.status,
              positionDivision: application.vacancy?.positionDivision,
              positionType: application.vacancy?.isFullTimeEmployment
                ? t('fullTime')
                : t('partTime'),
              schoolType: application?.schoolGroup?.residentialType,
              website: application.schools?.[0]?.website,
              vacancyStatus: application?.vacancy?.status,
            }}
          />
          {[
            ApplicationStatus.INFORMATION_REQUESTED,
            ApplicationStatus.INFORMATION_SUBMITTED,
          ].includes(application?.status) && (
            <TeacherApplicationUploadRequestedDocuments
              questionsAndVideo={application?.requestedAdditionalInformation}
              requestedDocuments={application?.requestedDocuments}
              isSubmitted={
                application?.status === ApplicationStatus.INFORMATION_SUBMITTED
              }
            />
          )}
          <ApplicationPreviewAboutPosition vacancy={application?.vacancy} />
          <ApplicationPreviewOffers vacancy={application?.vacancy} />
          <ApplicationPreviewBoarding vacancy={application?.vacancy} />
        </>
      )}
    </div>
  );
};

export default ApplicationPreview;
