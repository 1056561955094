import React from 'react';
import Pagination from 'components/Pagination';
import PageLoader from 'components/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'hooks/useParams';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import ApplicationsPaginationInfo from '../../components/ApplicationsPaginationInfo';
import classNames from 'classnames';
import { useGetMatchingOpportunitiesQuery } from 'api/matching.api';
import MatchedOpportunitiesEmptyState from '../../components/MatchedOpportunitiesEmptyState';
import { TeacherProfileStatus } from 'searchality-data';
import OpportunityCard from '../../components/OpportunityCard';
import { PaginatedResponse } from 'types';
import { Opportunity } from 'models/Teacher';

import './MatchedOpportunities.styles.scss';
import './MatchedOpportunities.styles.responsive.scss';

type MatchedOpportunitiesProps = {
  className?: string;
};

const MatchedOpportunities: React.FC<MatchedOpportunitiesProps> = (props) => {
  const { className } = props;

  const classes = classNames('matched-opportunities', className);

  const { profileStatus } = useAppSelector(authSelectors.selectUser);

  const { params, sortBy, changeCurrentPage } = useParams();

  const { data, isLoading } = useGetMatchingOpportunitiesQuery({ params });

  const navigate = useNavigate();

  const { $sort } = params || {};

  const { items: matchedOpportunities, ...pagination } =
    data || ({} as PaginatedResponse<Opportunity>);

  return (
    <div className={classes}>
      <ApplicationsPaginationInfo
        limit={0}
        pagination={pagination}
        onSort={sortBy}
        sortedBy={$sort}
        sortByMatching={true}
      />
      {isLoading ? (
        <PageLoader />
      ) : matchedOpportunities?.length > 0 ? (
        <ul>
          {matchedOpportunities?.map(
            ({
              _id,
              qualifications,
              vacancy: {
                startDate,
                publishedAt,
                subjectGradeLevel,
                positionTitle,
                schools,
              },
              isTeacherInterested,
            }) => (
              <OpportunityCard
                key={_id}
                schoolName={schools[0].name}
                schoolState={schools[0].country}
                positionTitle={positionTitle}
                subjectGradeLevel={subjectGradeLevel}
                publishedAt={publishedAt}
                startDate={startDate}
                qualifications={qualifications}
                opportunityId={_id}
                isTeacherInterested={isTeacherInterested}
                onClick={() => navigate(`/applications/matched/${_id}`)}
              />
            ),
          )}
        </ul>
      ) : (
        <MatchedOpportunitiesEmptyState
          isProfilePublished={profileStatus === TeacherProfileStatus.PUBLISHED}
        />
      )}
      {pagination?.totalPages > 1 && (
        <Pagination {...pagination} onChange={changeCurrentPage} />
      )}
    </div>
  );
};

export default MatchedOpportunities;
