import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  useDraftVacancyMutation,
} from 'api/vacancies.api';
import { useCallback } from 'react';
import { useForm } from 'react-final-form';
import VacancyWizardService from '../services/VacancyWizard.service';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import useStandaloneSchoolId from '../pages/VacancyWizard/hooks/useStandaloneSchoolId';
import useAppDispatch from 'hooks/useAppDispatch';
import { ShareVacancyModalRef } from '../components/ShareVacancyModal/ShareVacancyModal.component';

export default (
  shareVacancyModalRef: React.MutableRefObject<ShareVacancyModalRef>,
) => {
  const [draftVacancy, { isLoading: isDraftReqLoading }] =
    useDraftVacancyMutation();
  const [
    uploadPositionDescription,
    { isLoading: isUploadPositionDescLoading },
  ] = useAttachPositionDescriptionMutation();
  const [
    deletePositionDescription,
    { isLoading: isDeletePositionDescLoading },
  ] = useDeletePositionDescriptionMutation();
  const [
    uploadBoardingPositionDescription,
    { isLoading: isUploadBoardingDescLoading },
  ] = useAttachBoardingPositionDescriptionMutation();
  const [
    deleteBoardingPositionDescription,
    { isLoading: isDeleteBoardingDescLoading },
  ] = useDeleteBoardingPositionDescriptionMutation();

  const { getState } = useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const standaloneSchoolId = useStandaloneSchoolId();

  const isSubmitting =
    isDraftReqLoading ||
    isUploadPositionDescLoading ||
    isDeletePositionDescLoading ||
    isUploadBoardingDescLoading ||
    isDeleteBoardingDescLoading;

  const saveAsDraft = useCallback(async () => {
    const vacancyValues = getState().values;

    const {
      status,
      positionDescriptionUrl,
      boardingPositionRequirementsUrl,
      ...vacancyObject
    } = VacancyWizardService.convertFormIntoVacancy(
      vacancyValues as VacancyFormValues,
      type,
      standaloneSchoolId,
    );

    if (JSON.stringify(vacancyObject) === '{}') {
      dispatch(popError(t('cantSaveAsDraft')));
      return;
    }
    draftVacancy(vacancyObject as Vacancy)
      .unwrap()
      .then(
        async ({
          _id,
          positionDescriptionUrl,
          boardingPositionRequirementsUrl,
        }) => {
          if (vacancyValues?.positionDescription?.length) {
            await uploadPositionDescription({
              vacancyId: _id,
              positionDescriptionFile: vacancyValues.positionDescription[0],
            });
          } else if (
            !vacancyValues?.positionDescription?.length &&
            positionDescriptionUrl
          ) {
            await deletePositionDescription({
              vacancyId: _id,
            });
          }
          if (vacancyValues?.boardingRoleDescription?.length) {
            await uploadBoardingPositionDescription({
              vacancyId: _id,
              boardingPositionDescriptionFile:
                vacancyValues.boardingRoleDescription[0],
            });
          } else if (
            !vacancyValues?.boardingRoleDescription?.length &&
            boardingPositionRequirementsUrl
          ) {
            await deleteBoardingPositionDescription({
              vacancyId: _id,
            });
          }
          dispatch(popSuccess(t('successSaveAsDraftVacancy')));
          shareVacancyModalRef?.current?.openShareVacancy(_id);
        },
      )
      .catch((err) => dispatch(popServerError(err)));
  }, [
    deleteBoardingPositionDescription,
    deletePositionDescription,
    dispatch,
    draftVacancy,
    getState,
    shareVacancyModalRef,
    standaloneSchoolId,
    t,
    type,
    uploadBoardingPositionDescription,
    uploadPositionDescription,
  ]);

  return { saveAsDraft, isSubmitting };
};
