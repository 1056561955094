import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import Input from 'components/Input';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import { useTranslation } from 'react-i18next';
import { InternationalRequestedDocumentTitle } from 'searchality-data';
import utils from 'utils';

import './CheckboxWithInputGroupField.styles.scss';

type CheckboxOption = {
  label: string;
  value: string;
  description?: string;
  inputDescriptionLabel?: string;
};

type FieldValue = {
  title: string;
  description: string;
};

type CheckboxWithInputGroupFieldProps = FieldRenderProps<
  FieldValue[],
  HTMLElement
> & {
  className?: string;
  options: CheckboxOption[];
};

const CheckboxWithInputGroupField: React.FC<
  CheckboxWithInputGroupFieldProps
> = (props) => {
  const {
    className,
    options,
    input: { value, onChange, onBlur },
    meta: { touched, error },
  } = props;

  const classes = classNames('checkbox-with-input-group-field', className);

  const { t } = useTranslation();

  const [refs, setRefs] = useState<
    Record<string, React.RefObject<HTMLInputElement>>
  >({});

  const [checkedValues, setCheckedValues] = useState<Record<string, boolean>>(
    {},
  );

  const [decsriptions, setDescriptions] = useState<Record<string, string>>();

  const handleCheckboxChange = (optionValue: string, checked: boolean) => {
    setCheckedValues({
      ...checkedValues,
      [optionValue]: checked,
    });
  };

  const handleClick = (optionValue: string) => {
    refs[optionValue]?.current?.click();
  };

  const handleAddClick = useCallback(
    (option: CheckboxOption) => {
      onChange([
        ...value,
        {
          title: option.value,
          description: decsriptions[option.value],
        },
      ]);
      setDescriptions(({ [option.value]: _, ...rest }) => rest);
      setCheckedValues(({ [option.value]: _, ...rest }) => rest);
    },
    [decsriptions, onChange, value],
  );

  useEffect(() => {
    const checkboxRefs: Record<string, React.RefObject<HTMLInputElement>> = {};

    options.forEach((option) => {
      checkboxRefs[option.value] = React.createRef<HTMLInputElement>();
    });

    setRefs(checkboxRefs);
  }, [options]);

  useEffect(() => {
    if ((value as any) === '') {
      setCheckedValues({});
    }
  }, [value]);

  return (
    <div className={classes}>
      {options.map((option) => (
        <>
          <div
            key={option.value}
            className="checkbox-with-input-group-field__checkbox"
            onClick={() => handleClick(option.value)}
          >
            <div className="checkbox-with-input-group-field__checkbox__input--wrapper">
              <div className="checkbox-with-input-group-field__checkbox__input--label">
                <label>{option.label}</label>
                {!!option.description && <p>{option?.description}</p>}
              </div>
              <div className="checkbox-with-input-group-field__checkbox__input">
                <input
                  type="checkbox"
                  ref={refs?.[option.value]}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  id={option.value}
                  checked={checkedValues[option.value]}
                  onChange={(e) => {
                    handleCheckboxChange(
                      option.value as string,
                      e.target.checked,
                    );
                  }}
                  onBlur={onBlur}
                />
                <label
                  className="checkbox-with-input-group-field__checkbox__input--checkmark"
                  htmlFor={option.value}
                  onClick={(e) => e.stopPropagation()}
                >
                  <PlusIcon />
                </label>
              </div>
            </div>
          </div>
          {!!value?.length &&
            !!value?.filter((el) => el.title === option.value)?.length && (
              <div className="checkbox-with-input-group-field__list">
                {value
                  ?.filter((el) => el.title === option.value)
                  .map((el, index) => (
                    <div
                      key={index}
                      className="checkbox-with-input-group-field__list__li"
                    >
                      <p>
                        {t(
                          `RequestDocumentOptions.Title.${utils.getKeyByValue(
                            InternationalRequestedDocumentTitle,
                            el.title,
                          )}`,
                        )}{' '}
                        - <span>{el.description}</span>
                      </p>
                      <Button
                        className="svg-button-wrapper"
                        onClick={() => {
                          onChange(value.filter((_, ind) => ind !== index));
                        }}
                      >
                        {t('remove')}
                      </Button>
                    </div>
                  ))}
              </div>
            )}
          {checkedValues[option.value as string] && (
            <div className="checkbox-with-input-group-field__input-container">
              <Input
                label={option?.inputDescriptionLabel}
                value={decsriptions?.[option.value]}
                onChange={(e) =>
                  setDescriptions((prev) => ({
                    ...prev,
                    [option.value]: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddClick(option);
                  }
                }}
              />
              <Button
                onClick={() => handleAddClick(option)}
                icon={<PlusIcon />}
              />
            </div>
          )}
        </>
      ))}
      {error && touched && (
        <p className="checkbox-with-input-group-field--error">{error}</p>
      )}
    </div>
  );
};

export default CheckboxWithInputGroupField;
