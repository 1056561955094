import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { DateTime, Info } from 'luxon';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import { MonthDay } from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/types';

import './MonthDayField.styles.scss';
import './MonthDayField.styles.responsive.scss';

type MonthDayFieldProps = FieldRenderProps<MonthDay, HTMLElement> & {
  className?: string;
};

const MonthDayField: React.FC<MonthDayFieldProps> = (props) => {
  const {
    className,
    input: { onBlur, onChange, value, name, ...restInput },
    meta: { error, touched },
    ...rest
  } = props;

  const { t } = useTranslation();

  const classes = classNames('month-day-field', className);

  const monthsOptions = useMemo(() => {
    return Info.months()?.map((month) => ({
      label: month,
      value: month,
    }));
  }, []);

  const daysOptions = useMemo(() => {
    if (!value?.month) return [];

    const currentDate = DateTime.local();
    const newDateWithSpecifiedMonth = currentDate.set({
      month: Info.months().indexOf(value.month) + 1, // Adding 1 to convert from 0-based index to 1-based month number
      year: 2021,
    });

    const numberOfDays = newDateWithSpecifiedMonth.daysInMonth;

    return Array.from({ length: numberOfDays }, (_, index) => index + 1).map(
      (day) => ({
        label: day.toString(),
        value: day,
      }),
    );
  }, [value?.month]);

  return (
    <div className={classes}>
      <div className="month-day-field__fields">
        <Select
          {...rest}
          {...restInput}
          options={monthsOptions}
          value={value?.month}
          hasError={!!error && touched}
          onClose={onBlur}
          label={t('month')}
          multiple={false}
          onChange={(option) => {
            onChange({ month: option.value, day: 1 });
          }}
        />
        <Select
          {...rest}
          {...restInput}
          options={daysOptions}
          value={value?.day}
          hasError={!!error && touched}
          onClose={onBlur}
          label={t('day')}
          multiple={false}
          onChange={(option) => {
            onChange({ month: value?.month, day: option.value });
          }}
        />
      </div>
      {!!error && touched && (
        <p className="contract-duration-field__error">{error}</p>
      )}
    </div>
  );
};

export default MonthDayField;
