import React from 'react';
import { HiringPreferencesStep } from 'searchality-data';
import { ReactComponent as ProfileIcon } from 'icons/Profile.icon.svg';
import { ReactComponent as LocationIcon } from 'icons/Location.icon.svg';
import { ReactComponent as DocumentIcon } from 'icons/Documents.icon.svg';
import { ReactComponent as BenefitsIcon } from 'icons/Benefits.icon.svg';
import { SPBRoute, SchoolProfileBuilderFormValues } from '../types';
import {
  convertBenefitsFormValues,
  convertContactInformationFormValues,
  convertHiringPreferencesFormValues,
  convertHostCountryRequirementsFormValues,
} from '../utils';
import { HiringPreferences } from 'models/School';

export const routesMapping: { [name in HiringPreferencesStep]: SPBRoute } = {
  'Hiring preferences': 'hiring-preferences',
  'Host country requirements': 'host-country-requirements',
  'Contract information': 'contract-information',
  Benefits: 'benefits',
};

export const pathMapping: Record<SPBRoute, HiringPreferencesStep> = {
  'hiring-preferences': HiringPreferencesStep.HIRING_PREFERENCES,
  'host-country-requirements': HiringPreferencesStep.HOST_COUNTRY_REQUIREMENTS,
  'contract-information': HiringPreferencesStep.CONTRACT_INFORMATION,
  benefits: HiringPreferencesStep.BENEFITS,
};

export const iconsMapping: { [name in HiringPreferencesStep]: JSX.Element } = {
  'Hiring preferences': <ProfileIcon />,
  'Host country requirements': <LocationIcon />,
  'Contract information': <DocumentIcon />,
  Benefits: <BenefitsIcon />,
};

export const ProfileStatusCardMapping: {
  [name in HiringPreferencesStep]: {
    title: string;
    description?: string;
  };
} = {
  'Hiring preferences': {
    title: 'SchoolProfileBuilder.hiringPreferencesTitle',
    description: 'SchoolProfileBuilder.description',
  },
  'Host country requirements': {
    title: 'SchoolProfileBuilder.hostCountryRequirementsTitle',
    description: 'SchoolProfileBuilder.description',
  },
  'Contract information': {
    title: 'SchoolProfileBuilder.contactInformationTitle',
    description: 'SchoolProfileBuilder.description',
  },
  Benefits: {
    title: 'SchoolProfileBuilder.benefitsTitle',
    description: 'SchoolProfileBuilder.benefitsDescription',
  },
};

export const finishedStepsDataMapping: {
  [name in HiringPreferencesStep]: (
    values: SchoolProfileBuilderFormValues,
  ) => Partial<HiringPreferences>;
} = {
  'Hiring preferences': convertHiringPreferencesFormValues,
  'Host country requirements': convertHostCountryRequirementsFormValues,
  'Contract information': convertContactInformationFormValues,
  Benefits: convertBenefitsFormValues,
};
