import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import ToggleField from 'components/ToggleField';
import SelectField from 'components/SelectField';
import { required } from 'validations';
import useAnotherLanguageLevel from 'router/subrouters/Dashboard/subrouters/Vacancies/pages/VacancyWizard/hooks/useAnotherLanguageLevel';
import { DateTime } from 'luxon';
import DatepickerField from 'components/DatepickerField';
import {
  NonAcademicEducationLevel,
  SchoolGroupType,
  VacancyType,
  YearsOfExperience,
} from 'searchality-data';
import { useParams } from 'react-router-dom';
import { useGetOpenJobQuery } from 'api/jobs.api';

import './PositionRequirements.styles.scss';
import './PositionRequirements.styles.responsive.scss';

type PositionRequirementsProps = {
  className?: string;
};

const PositionRequirements: React.FC<PositionRequirementsProps> = (props) => {
  const { className } = props;

  const { values } = useFormState();

  const { jobId } = useParams();
  const { data: job } = useGetOpenJobQuery(jobId);

  const {
    startDate,
    requiredLanguages,
    yearsOfExperienceRequired,
    schoolGroup,
    type,
    educationLevel,
    isSpecificCurriculumExperienceRequired,
    curricula,
  } = job || {};

  const { positionRequirements } = values;

  const {
    curricula: formCurricula,
    isAbleToStartOnRequiredDate,
    languageRequirement,
    doesHaveExperinceWithFaithBasedSchools,
  } = positionRequirements || {};

  const { t } = useTranslation();

  const formatDate = useCallback((startDate: string) => {
    const { monthLong, day, year } = DateTime.fromJSDate(new Date(startDate));
    return `${day} ${monthLong} ${year}`;
  }, []);

  const languageLevelOptions = useAnotherLanguageLevel();

  const baseClass = 'job-application-position-requirements';
  const classes = classNames(baseClass, className);

  const yearsOfExperience = useMemo(() => {
    const yearsOfExperienceTemp = Object.keys(YearsOfExperience).map((key) => ({
      label: t(`yearsOfExperience.`, {
        years: YearsOfExperience[key as keyof typeof YearsOfExperience],
      }),
      value: YearsOfExperience[key as keyof typeof YearsOfExperience],
    }));

    yearsOfExperienceTemp.shift();

    return yearsOfExperienceTemp;
  }, [t]);

  return (
    <div className={classes}>
      {schoolGroup?.type === SchoolGroupType.RELIGIOUS && (
        <div className={`${baseClass}__faith`}>
          <p className={`${baseClass}__paragraph__title`}>
            {t('jobApplicationPositionRequirements.faithBasedTitle')}
          </p>
          <Field
            name="positionRequirements.doesHaveExperinceWithFaithBasedSchools"
            component={ToggleField}
            validate={required()}
            className={`${baseClass}__reverse-toggle`}
          />
          {doesHaveExperinceWithFaithBasedSchools && (
            <Field
              name="positionRequirements.yearsOfExperienceWithFaithBasedSchools"
              component={SelectField}
              options={yearsOfExperience}
              label={t(
                'jobApplicationPositionRequirements.yearsOfExperienceWithFaithBasedSchools',
              )}
              className={`${baseClass}__select`}
              validate={required()}
              required
            />
          )}
        </div>
      )}
      {type === VacancyType.NON_ACADEMIC &&
        educationLevel !==
          NonAcademicEducationLevel.NO_SPECIFIC_REQUIREMENTS && (
          <div className={`${baseClass}__educationLevel`}>
            <p className={`${baseClass}__paragraph__title`}>
              <b>{t('educationLevel')}</b>
            </p>
            <p className={`${baseClass}__paragraph__description`}>
              {t('jobApplicationPositionRequirements.educationLevelQuestion', {
                educationLevel,
              })}
            </p>
            <Field
              name="positionRequirements.doesMeetMinimumEducationLevel"
              component={ToggleField}
              validate={required()}
              className={`${baseClass}__reverse-toggle`}
            />
          </div>
        )}
      {!!yearsOfExperienceRequired && (
        <div className={`${baseClass}__curriculum`}>
          <p className={`${baseClass}__paragraph__title`}>
            <b>{t('workingExperience')}</b>
          </p>
          <p className={`${baseClass}__paragraph__description`}>
            {t(
              'jobApplicationPositionRequirements.doesHaveExperienceDescription',
              {
                yearsOfExperienceRequired,
              },
            )}
          </p>
          <Field
            name="positionRequirements.doesHaveYearsOfExperience"
            component={ToggleField}
            validate={required()}
            className={`${baseClass}__reverse-toggle`}
          />
        </div>
      )}
      {isSpecificCurriculumExperienceRequired &&
        type === VacancyType.ACADEMIC &&
        curricula?.map((el) => (
          <div
            className={`${baseClass}__curriculum`}
            key={el?.curriculumExperience}
          >
            <p className={`${baseClass}__paragraph__title`}>
              <b>{`${t('curriculumExperience')} - `}</b>
              <span>{el?.curriculumExperience}</span>
            </p>
            <p className={`${baseClass}__paragraph__description`}>
              {t(
                'jobApplicationPositionRequirements.curriculumExperienceDescription',
              )}
            </p>
            <Field
              name={`positionRequirements.curricula.${el?.curriculumExperience}.doesHaveExperienceInTheField`}
              component={ToggleField}
              validate={required()}
              className={`${baseClass}__reverse-toggle`}
            />
            {formCurricula?.[el?.curriculumExperience]
              ?.doesHaveExperienceInTheField && (
              <Field
                name={`positionRequirements.curricula.${el?.curriculumExperience}.yearsOfExperienceInTheField`}
                component={SelectField}
                options={yearsOfExperience}
                label={t('yearsOfExperienceIn', {
                  curriculumExperience: t('curriculum'),
                })}
                className={`${baseClass}__select`}
                validate={required()}
                required
              />
            )}
          </div>
        ))}
      <div className={`${baseClass}__language`}>
        <p className={`${baseClass}__paragraph__title`}>
          <b>{t('englishLevel')}</b>
        </p>
        <p className={`${baseClass}__paragraph__description`}>
          {t('jobApplicationPositionRequirements.englishLevelDescription')}
        </p>
        <Field
          name={`positionRequirements.englishLevel`}
          component={SelectField}
          options={languageLevelOptions}
          label={t('levelOfEnglish')}
          className={`${baseClass}__select`}
          validate={required()}
          required
        />
      </div>
      {requiredLanguages?.map(({ language }) => (
        <div className={`${baseClass}__language`} key={language}>
          <p className={`${baseClass}__paragraph__title`}>
            <b>{t('requiredLanguageForThisPosition')}</b>
            <span>{language}</span>
          </p>
          <p className={`${baseClass}__paragraph__description`}>
            {t(
              'jobApplicationPositionRequirements.requiredLanguageForThisPositionDescription',
            )}
          </p>
          <Field
            name={`positionRequirements.languageRequirement.${language}.doesSpeak`}
            component={ToggleField}
            validate={required()}
            className={`${baseClass}__reverse-toggle`}
          />
          {languageRequirement?.[`${language}`]?.[`doesSpeak`] && (
            <Field
              name={`positionRequirements.languageRequirement.${language}.level`}
              component={SelectField}
              options={languageLevelOptions}
              label={t('levelOf', {
                language,
              })}
              className={`${baseClass}__select`}
              validate={required()}
              required
            />
          )}
        </div>
      ))}
      <div className={`${baseClass}__start-date`}>
        <p className={`${baseClass}__paragraph__title`}>
          <b>{t('startDate')}</b> {' - '}
          <span>{startDate ? formatDate(startDate) : t('immediately')}</span>
        </p>
        <p className={`${baseClass}__paragraph__description`}>
          {t('jobApplicationPositionRequirements.startingDateDescription')}
          {startDate ? `on ${formatDate(startDate)}` : t('immediately')}
        </p>
        <Field
          name="positionRequirements.isAbleToStartOnRequiredDate"
          component={ToggleField}
          validate={required()}
          className={`${baseClass}__reverse-toggle`}
        />
        {isAbleToStartOnRequiredDate === false && (
          <>
            <p className={`${baseClass}__start-date__datepicker-label`}>
              {t(
                'jobApplicationPositionRequirements.datePickerLabelImmediately',
              )}
            </p>
            <Field
              name="positionRequirements.earliestAvailableStartingDate"
              component={DatepickerField}
              validate={required()}
              placeholder={t(
                'jobApplicationPositionRequirements.datePickerLabel',
              )}
              onlyFuture
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PositionRequirements;
