import { createAction, createSlice } from '@reduxjs/toolkit';
import { SchoolGroupStructureType, VacancyType } from 'searchality-data';

type createVacancyWizard = {
  currentStepIndex: number;
  steps: any[];
  type?: VacancyType;
  isFormFilled?: boolean;
};

const initialState: createVacancyWizard = {
  currentStepIndex: 0,
  steps: [],
};

export const initializeVacancyWizard = createAction<{
  structureType: SchoolGroupStructureType;
  isAcademic?: boolean;
  isMatchingPaid: boolean;
  isMatchingAdDisabled: boolean;
}>('INIT_STEPS');
export const nextVacancyStep = createAction('NEXT_STEP');
export const previuosVacancyStep = createAction('PREVIOUS_STEP');
export const resetVacancyStep = createAction('RESET_STEPPER');
export const changeVacancyType = createAction<VacancyType>(
  'CHANGE_VACANCY_TYPE',
);
export const changeIsFormFilled = createAction<boolean>(
  'CHANGE_IS_FORM_FILLED',
);

export const resetVacancyType = createAction('RESET_VACANCY_TYPE');

const createVacancyWizardSlice = createSlice({
  name: 'createVacancyWizard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeVacancyWizard, (state, action) => {
      state.currentStepIndex = 0;

      const lastStep = action.payload.isMatchingPaid
        ? ['matching-feature']
        : !action.payload.isMatchingAdDisabled
        ? ['matching-advertisement']
        : [];

      state.steps =
        action.payload.structureType === SchoolGroupStructureType.STANDALONE
          ? [
              'position-information',
              'position-requirements',
              'host-country-requirements',
              'salary',
              'benefits',
              'contract-information',
              ...(action.payload.isAcademic ? lastStep : []),
            ]
          : [
              'school-information',
              'position-information',
              'position-requirements',
              'host-country-requirements',
              'salary',
              'benefits',
              'contract-information',
              ...(action.payload.isAcademic ? lastStep : []),
            ];
    });

    builder.addCase(nextVacancyStep, (state) => {
      state.currentStepIndex = Math.min(
        state.currentStepIndex + 1,
        state.steps.length,
      );
    });
    builder.addCase(previuosVacancyStep, (state) => {
      state.currentStepIndex = Math.max(state.currentStepIndex - 1, 0);
    });
    builder.addCase(resetVacancyStep, (state) => {
      state.currentStepIndex = 0;
    });
    builder.addCase(changeVacancyType, (state, action) => {
      state.type = action.payload;
    });
    builder.addCase(changeIsFormFilled, (state, action) => {
      state.isFormFilled = action.payload;
    });

    builder.addCase(resetVacancyType, (state) => {
      state.type = undefined;
    });
  },
});

export default createVacancyWizardSlice.reducer;
