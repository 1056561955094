import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import ObjectPreviewCard from 'components/ObjectPreviewCard';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import InputField from 'components/InputField';
import { required } from 'validations';
import { Field, Form } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import TextAreaField from 'components/TextAreaField';
import SelectField from 'components/SelectField';
import useEmploymentTypeOptions from './hooks/useEmploymentTypeOptions';
import DateService from 'services/Date.service';
import { FORM_ERROR } from 'final-form';
import ErrorMessage from 'components/ErrorMessage';
import { ExperienceType } from 'models/Candidate';
import ConditionalField from 'components/ConditionalField';
import usePositionDivisionOptions from 'router/subrouters/Dashboard/subrouters/Vacancies/pages/VacancyWizard/hooks/usePositionDivisionOptions';
import { formatDate } from 'utils';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import DatepickerField from 'components/DatepickerField';
import FormFieldLabel from 'components/FormFieldLabel';

import './Experiences.styles.scss';
import './Experiences.styles.responsive.scss';

type ExperienceProps = {
  className?: string;
  value: ExperienceType[];
  touched?: boolean;
  onChange: (value: ExperienceType[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
};

const Experiences: React.FC<ExperienceProps> = (props) => {
  const { className, value, onChange } = props;

  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<Partial<ExperienceType>>();

  const [editIndex, setEditIndex] = useState<number>();

  const employmentTypeOptions = useEmploymentTypeOptions();
  const positionDivisionOptions = usePositionDivisionOptions();

  const addModalRef = useRef<ModalRef>();

  const classes = classNames('experience', className);

  const handleDelete = useCallback(
    (index: number) => {
      onChange(value.filter((_, ind) => ind !== index));
    },
    [onChange, value],
  );

  const handleEdit = useCallback(
    (experience: ExperienceType, index: number) => {
      setInitialValues(experience);
      setEditIndex(index);
      addModalRef.current.open();
    },
    [],
  );

  const experienceArray = useMemo(
    () =>
      value?.map((experience, index) => {
        const {
          isCurrentlyWorking,
          endDate,
          startDate,
          employmentType,
          positionDivision,
          ...rest
        } = experience;

        return (
          <ObjectPreviewCard
            data={{
              ...rest,
              employmentType: t(employmentType),
              startDate: formatDate(startDate),
              ...(endDate && { endDate: formatDate(endDate) }),
              ...(positionDivision && { schoolLevel: positionDivision }),
            }}
            key={`${rest.positionTitle}-${index}`}
            handleEdit={() => handleEdit(experience, index)}
            handleDelete={() => handleDelete(index)}
          />
        );
      }),
    [handleDelete, handleEdit, t, value],
  );

  const handleSubmit = useCallback(
    (values: ExperienceType) => {
      const { startDate, endDate } = values;

      if (
        startDate &&
        endDate &&
        DateService.compareDates(startDate, endDate)
      ) {
        return {
          [FORM_ERROR]: 'Start date must be before end date',
        };
      }
      if (editIndex === undefined) {
        onChange([...value, values]);
      } else {
        const valueCopy = JSON.parse(JSON.stringify(value));
        valueCopy[editIndex] = values;
        onChange(valueCopy);
      }
      addModalRef.current.close();
    },
    [editIndex, onChange, value],
  );

  const endDateValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.isCurrentlyWorking) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  return (
    <div className={classes}>
      <Button
        variant="outline"
        icon={<PlusIcon />}
        onClick={() => {
          addModalRef.current.open();
        }}
        className="experience__button"
      >
        {t('add')}
      </Button>
      {!!value?.length && (
        <div className="experience__cards">{experienceArray}</div>
      )}

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          hasSubmitErrors,
          submitError,
          modifiedSinceLastSubmit,
          form,
          values: { isCurrentlyWorking, location },
        }) => (
          <Modal
            className="experience-modal"
            ref={addModalRef}
            title={t(
              editIndex !== undefined
                ? 'experienceModal.editExperience'
                : 'experienceModal.addExperience',
            )}
            onClose={() => {
              form.reset();
              setInitialValues({});
              setEditIndex(undefined);
            }}
            footer={
              <Button onClick={handleSubmit}>
                {t(
                  editIndex !== undefined
                    ? 'experienceModal.editExperience'
                    : 'experienceModal.addExperience',
                )}
              </Button>
            }
          >
            <form onSubmit={handleSubmit}>
              <div className="experience-modal__basic-info">
                <div className="experience-modal__basic-info__first-row">
                  <Field
                    name="positionTitle"
                    component={InputField}
                    label={t('positionTitle')}
                    validate={required()}
                    required
                  />
                  <Field
                    name="employmentType"
                    component={SelectField}
                    label={t('employmentType')}
                    validate={required()}
                    options={employmentTypeOptions}
                    required
                  />
                </div>
                <div className="experience-modal__basic-info__first-row">
                  <Field
                    name="positionDivision"
                    options={positionDivisionOptions}
                    label={t('schoolLevel')}
                    component={SelectField}
                    validate={required()}
                    className="experience-modal__position-division"
                    required
                  />
                  <Field
                    name="schoolName"
                    component={InputField}
                    label={t('schoolName')}
                    validate={required()}
                    required
                  />
                </div>
                <div className="experience-modal__basic-info__first-row">
                  <Field
                    name="location"
                    key={location}
                    component={MapboxAutocompleteField}
                    label={t('city')}
                    allCountries
                    validate={required()}
                    resultType={['locality', 'administrative_area_level_3']}
                    required
                  />
                  <div />
                </div>
              </div>
              <FormFieldLabel text={t('experienceModal.periodOfEmployment')} />
              <div className="experience-modal__date-range">
                <Field
                  name="startDate"
                  component={DatepickerField}
                  onlyPast
                  validate={required()}
                  placeholder={t('startDate')}
                />
                <ConditionalField<string>
                  name="endDate"
                  placeholder={t('endDate')}
                  component={DatepickerField}
                  validate={endDateValidation}
                  disabled={isCurrentlyWorking}
                  onlyPast
                  shouldClear={isCurrentlyWorking}
                />
              </div>
              <Field
                name="isCurrentlyWorking"
                component={CheckboxField}
                type="checkbox"
                isStyledDefault
                label={t('experienceModal.isCurrentlyWorkingLabel')}
                className="experience-modal__checkbox"
              />
              <p className="experience-modal__activities-title">
                {t('experienceModal.roleDescription')}
              </p>

              <Field
                name="roleDescription"
                component={TextAreaField}
                label={t('experienceModal.roleDescriptionPlaceholder')}
                validate={required()}
                className="experience-modal__activities-textarea"
                required
                maxLength={500}
                showLengthCount
              />
              {hasSubmitErrors && !modifiedSinceLastSubmit && (
                <ErrorMessage message={submitError} />
              )}
            </form>
          </Modal>
        )}
      />
    </div>
  );
};

export default Experiences;
