import React, { useRef } from 'react';
import BasicTag from 'components/BasicTag';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Role } from 'searchality-data';
import { Dropdown } from 'ncoded-component-library';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';

import './PendingInvite.styles.scss';
import {
  useCancelInviteMutation,
  useResendInviteMutation,
} from 'api/members.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

type PendingInviteProps = {
  id: string;
};

const PendingInvite: React.FC<PendingInviteProps> = (props) => {
  const { id } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { role } = useAppSelector(authSelectors.selectUser);

  const dropdownRef = useRef<DropdownRef>();

  const [cancelInv] = useCancelInviteMutation();
  const [resendInv] = useResendInviteMutation();

  if (role !== Role.AccountOwner) {
    return <BasicTag text={t('pendingInvite')} />;
  }

  return (
    <div className="pending-invite">
      <p>{t('pendingInvite')}</p>
      <Dropdown
        trigger={
          <div
            className="pending-invite__dots"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className="pending-invite__dots__dot"
              onClick={(e) => e.stopPropagation()}
            />
            <span className="pending-invite__dots__dot" />
            <span className="pending-invite__dots__dot" />
          </div>
        }
        ref={dropdownRef}
      >
        <div
          className="pending-invite__actions"
          onClick={(e) => e.stopPropagation()}
        >
          <span
            className="pending-invite__actions__action"
            onClick={(e) => {
              e.stopPropagation();
              dropdownRef.current.setIsOpen(false);
              cancelInv(id)
                .unwrap()
                .then(() => dispatch(popSuccess(t('cancelInviteSuccess'))))
                .catch((e) => dispatch(popServerError(e)));
            }}
          >
            <p>{t('deleteInvite')}</p>
          </span>
          <span
            className="pending-invite__actions__action"
            onClick={(e) => {
              e.stopPropagation();
              dropdownRef.current.setIsOpen(false);
              resendInv(id)
                .unwrap()
                .then(() => dispatch(popSuccess(t('resendInviteSuccess'))))
                .catch((e) => dispatch(popServerError(e)));
            }}
          >
            <p>{t('resendInvite')}</p>
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default PendingInvite;
