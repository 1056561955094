import React, { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import CheckboxField from 'components/CheckboxField';
import TPBTopActions from '../../components/TPBTopActions';
import TPBBottomActions from '../../components/TPBBottomActions';
import DatepickerField from 'components/DatepickerField';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import FormFieldLabel from 'components/FormFieldLabel';
import bus from 'modules/bus';
import MultipleSelectField from 'components/MultipleSelectField';
import useCountryOptions from '../../../MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/hooks/useCountryOptions';

import './WorkAvailability.styles.scss';

const WorkAvailabilityPage: React.FC = () => {
  const { t } = useTranslation();
  const baseClass = 'tpb-work-availability';

  const countryOptions = useCountryOptions();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const startDateValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.immediately) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  const {
    values: { immediately },
  } = useFormState();

  const { submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = ['passports', 'startDate', 'immediately'].some(
    (fieldName) => dirtyFields[fieldName],
  );

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Education Statements');
    }
  };

  return (
    <div className={baseClass}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent centered>
        <TPBPageHeading
          text={t('teacherProfileBuilder.workAvailability.title')}
        />
        <FormFieldLabel text={t('startDate')} />
        <div className={`${baseClass}__start-date__inputs`}>
          <Field
            name="startDate"
            placeholder={t('startDate')}
            component={DatepickerField}
            validate={startDateValidation}
            onlyFuture
            required
            disabled={immediately}
          />
          <Field
            name="immediately"
            label={t('immediately')}
            component={CheckboxField}
            type="checkbox"
          />
        </div>
        <div className={`${baseClass}__work-rights`}>
          <div>
            <FormFieldLabel text={t('yourPassport')} />
            <p className="description">{t('yourPassportDescription')}</p>
          </div>
          <Field
            name="passports"
            component={MultipleSelectField}
            searchable
            options={countryOptions}
            label={t('passportCitizenship')}
          />
        </div>
        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default WorkAvailabilityPage;
