import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { Field, useForm, useFormState } from 'react-final-form';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';
import { useNavigate } from 'react-router-dom';
import { School } from 'models/School';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { CREATE_SCHOOL_WIZARD } from 'enums';

import './SchoolLogo.styles.scss';

type SchoolLogoProps = {
  className?: string;
};

const SchoolLogo: React.FC<SchoolLogoProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = classNames('school-logo', className);

  const { completeStep } = useContext(CreateSchoolWizardContext);

  const {
    values: { school },
  } = useFormState();

  const { getState, submit, change } = useForm();

  const { schoolLogo } = school || {};

  const handleContinue = useCallback(() => {
    const schoolCopy = structuredClone(school as School);

    change('schools', [schoolCopy]);

    completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_INFORMATION);

    navigate('/create-school/pricing-information');
  }, [change, completeStep, navigate, school]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();

        handleContinue();
      }}
    >
      <GoBackButton to={'..'} />
      <div>
        <h1>{t('SchoolLogo.title')}</h1>
        <p className="description">{t('SchoolLogo.description')}</p>
      </div>
      <div className="school-logo__hint-container">
        <p className="school-logo__hint-container__add">
          {t('SchoolLogo.add')}
        </p>
        <p className="description">{t('schoolLogoHint')}</p>
      </div>
      <Field
        name="school.schoolLogo"
        render={(props) => {
          const {
            input: { value, onChange },
          } = props;

          return (
            <DropzoneProfilePhoto
              value={value?.length ? value[0] : null}
              setFileCallback={(file: File) => onChange(file ? [file] : [])}
              cropShape="rect"
              aspect={5 / 3}
              maxSize={1048576}
              accept={{
                'image/svg+xml': [],
                'image/png': [],
                'image/jpeg': [],
              }}
            />
          );
        }}
      />
      <div className="school-logo__button-container">
        <Button disabled={!schoolLogo?.length} type="submit">
          {t('continue')}
        </Button>
        <Button
          variant="outline"
          onClick={handleContinue}
          disabled={schoolLogo?.length}
        >
          {t('skipForNow')}
        </Button>
      </div>
    </form>
  );
};

export default SchoolLogo;
