import React from 'react';
import {
  BoardingPositionRequirementStatus,
  SchoolResidentialType,
} from 'searchality-data';
import { Vacancy } from 'models/Vacancy';
import ApplicationInformationCard from '../ApplicationInformationCard';
import JobPreviewBoardingSection from 'components/JobPreviewComponent/components/JobPreviewBoardingSection';
import { useTranslation } from 'react-i18next';

type ApplicationPreviewBoardingProps = {
  vacancy: Vacancy;
};

const ApplicationPreviewBoarding: React.FC<ApplicationPreviewBoardingProps> = (
  props,
) => {
  const { vacancy } = props;

  const { t } = useTranslation();

  const showBoarding =
    vacancy?.residentialType !== SchoolResidentialType.DAY_SCHOOL &&
    vacancy?.boardingPositionRequirementStatus !==
      BoardingPositionRequirementStatus.NOT_REQUIRED;

  if (!showBoarding) {
    return null;
  }

  return (
    <ApplicationInformationCard>
      <h4>{t('boardingSupervisionAndBenefits')}</h4>
      <JobPreviewBoardingSection
        additionalBenefits={
          vacancy?.boardingPositionBenefits?.additionalBenefits
        }
        salary={vacancy?.boardingPositionBenefits?.salary}
        isRequired={
          vacancy?.boardingPositionRequirementStatus ===
          BoardingPositionRequirementStatus.REQUIRED
        }
        roleDescription={vacancy?.boardingPositionRequirements?.roleDescription}
        roleDescriptionUrl={vacancy?.boardingPositionRequirementsUrl}
        noSubTitles
      />
    </ApplicationInformationCard>
  );
};

export default ApplicationPreviewBoarding;
