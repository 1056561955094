import React from 'react';
import PaymentMethodsProvider from 'providers/PaymentMethods';
import { Outlet } from 'react-router-dom';

const ManageSubscription: React.FC = () => (
  <PaymentMethodsProvider>
    <Outlet />
  </PaymentMethodsProvider>
);

export default ManageSubscription;
