import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { VacancyFormValues } from 'models/Vacancy';
import { salaryInfoFormValidation } from 'validations';
import { useLocation } from 'react-router-dom';
import { initializeVacancyWizard } from 'store/slices/createVacancyWizard.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import { SchoolGroupStructureType } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';

type VacancyBuilderForWrapperProps = {
  children: React.ReactNode;
  initialValues: Partial<VacancyFormValues>;
  onSubmit: (values: VacancyFormValues) => Promise<void>;
};

const VacancyBuilderForWrapper: React.FC<VacancyBuilderForWrapperProps> = (
  props,
) => {
  const { children, initialValues, onSubmit } = props;

  const { schools, marketingInformation } = useAppSelector(
    authSelectors.selectUser,
  );

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      initializeVacancyWizard({
        structureType: SchoolGroupStructureType.STANDALONE,
        isAcademic: !pathname.includes('non-academic'),
        isMatchingPaid: schools?.[0]?.matchingInformation?.isMatchingPaid,
        isMatchingAdDisabled: marketingInformation?.isMatchingAdDisabled,
      }),
    );
    return () => {
      dispatch(
        initializeVacancyWizard({
          structureType: SchoolGroupStructureType.STANDALONE,
          isMatchingPaid: schools?.[0]?.matchingInformation?.isMatchingPaid,
          isMatchingAdDisabled: marketingInformation?.isMatchingAdDisabled,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) =>
        pathname.includes('teacher-offers')
          ? salaryInfoFormValidation(values?.salaryInfo)
          : {}
      }
      render={() => {
        return children;
      }}
    />
  );
};

export default VacancyBuilderForWrapper;
