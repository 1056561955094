import { School } from 'models/School';
import { ReactNode } from 'react';
import {
  ApplicationSchoolStatus,
  SchoolCurriculumType,
  SchoolResidentialType,
  InternationalStripeProductType,
  SubscriptionCancellationReason,
} from 'searchality-data';

export type ExtractPropTypes<Type> = Type extends React.FC<infer P> ? P : never;

export type StructureType = 'standalone' | 'district' | 'group';

export type CreateSchoolValues = {
  curriculumType: SchoolCurriculumType;
  residentialType: SchoolResidentialType;
  name?: string;
  website?: string;
};

export type UserLocation = {
  longitude: number;
  latitude: number;
};

export type AddNewSchoolValues = {
  schools: Partial<School & { optOutIntegration: boolean }>[];
  promoCode?: string;
  paymentMethodId?: string;
};

export type ReviewCardItemList = {
  label: string;
  value: string;
  description?: string;
  subtitle?: string;
  className?: string;
}[];

export type TableHeaderElement = {
  id: string;
  label: string;
};

export type MongoWhere = {
  $all?: (string | boolean | number)[];
  $in?: (string | boolean | number)[];
};

export type PhoneNumber = {
  countryCode: string;
  phoneNumber?: string;
};

export type Params = {
  $page?: number;
  $limit?: number;
  $sort?: string;
  $where?: {
    [name: string]: MongoWhere | any;
  };
  $projection?: string[];
  $populate?: string[];
  status?: string;
  $searchString?: string;
};

export type PaginationProps = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
};

export type PaginatedResponse<T> = {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: T[];
};

export type RegisterData = LoginData & {
  firstName: string;
  lastName: string;
  title?: string;
};

export type LoginData = {
  email: string;
  password: string;
};

export type AuthBody = {
  token: string;
  refreshToken: string;
};

export type GlobalFilters = {
  subjects: string[];
  divisions: string[];
  schools: string[];
};

export type ApplicationsFilters = {
  subjects: string[];
  divisions: string[];
  statuses: string[];
};

export type MapboxTypes =
  | 'place'
  | 'district'
  | 'country'
  | 'region'
  | 'address';

export type PositionRequirementsType = {
  startDate: string;
  requiredLanguages: {
    language: string;
    level: string;
  }[];
  curriculumExperience: string;
};

export type AddressObject = {
  city: string;
  country: string;
  postcode: string;
  countryShortCode: string;
  state: string;
  address: string;
};

export type VacancyFilters = {
  subjects: Record<string, string>;
  divisions?: Record<string, string>;
  schools?: School[];
};

export type GetCommentsType = {
  $sort: string;
  $populate: string | string[];
  $limit: number;
  $page: number;
};

export type AddNewSchoolProps = {
  handlePreviousStep: () => void;
};

export type AddSchoolValue = {
  schools: Partial<School>[];
};

export type SchoolGroupExist = {
  name: string;
  website?: string;
};

export type SchoolExist = SchoolGroupExist & {
  address: string;
};

export type TemplateIllustrationProps = {
  className?: string;
  img?: ReactNode;
  title: string;
  description?: string;
  children?: ReactNode;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  imgFirst?: boolean;
};

export type ContactFormValues = {
  content: string;
  images?: File[];
};

export type ApplicationSchoolStatusWithFavourites =
  | ApplicationSchoolStatus
  | 'Favourite'
  | 'Matching';

export type InvoiceItem = {
  amount?: number;
  description?: string;
  quantity?: number;
};

export type Invoice = {
  id: string;
  amountDue: number;
  amountRemaining: number;
  status: string;
  createdAt: string;
  hostedInvoiceUrl: string;
  items: InvoiceItem[];
};

export type UnsubscribeValues = {
  reasons: SubscriptionCancellationReason[];
  otherReason?: string;
};

export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

// payment types

export type PaymentMethodType = 'card' | 'us_bank_account';

export type PaymentInfo = {
  paymentMethods: PaymentMethod[];
  purchaseOrder?: string;
  coupon?: string;
};

export type BillingAddress = {
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  postal_code: string;
  state: string;
};

export type PaymentCard = {
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  last4: number;
};

export type PaymentUSBA = {
  bank_name: string;
  last4: string;
};

export type PaymentMethod = {
  id: string;
  created: number;
  fullName?: string;
  isDefault?: boolean;
  type: PaymentMethodType;
  usBankAccount?: PaymentUSBA;
  card?: PaymentCard;
  billingAddress?: BillingAddress;
};

export type PreviewSubscriptionPrice = {
  amount_due: number;
  amount_paid: number;
  currency: string;
  period_end: string;
  custom_fields: null;
  discount?: {
    id: string;
    object: string;
    checkout_session: null;
    coupon: {
      id: string;
      object: string;
      amount_off: number;
      created: number;
      currency: string;
      duration: string;
      duration_in_months?: string;
      livemode: boolean;
      max_redemptions: null;
      metadata: {
        description: string;
      };
      name: string;
      percent_off: null;
      redeem_by: null;
      times_redeemed: number;
      valid: boolean;
    };
    customer: string;
    end: null;
    invoice: null;
    invoice_item: null;
    promotion_code: null;
    start: number;
    subscription: string;
  };
  discounts?: string[];
  isPromoCodeValid: boolean;
  lines: {
    object: string;
    data: {
      id: string;
      object: string;
      amount: number;
      amount_excluding_tax: number;
      currency: string;
      description: string;
      discount_amounts: {
        amount: number;
        discount: string;
      }[];
      discountable: boolean;
      discounts: string[];
      livemode: boolean;
      period: {
        end: number;
        start: number;
      };
      plan: {
        id: string;
        object: string;
        active: boolean;
        aggregate_usage: null;
        amount: number;
        amount_decimal: string;
        billing_scheme: string;
        created: number;
        currency: string;
        interval: string;
        interval_count: number;
        livemode: boolean;
        nickname: null;
        product: string;
        tiers_mode: null;
        transform_usage: null;
        trial_period_days: null;
        usage_type: string;
      };
      price: {
        id: string;
        object: string;
        active: boolean;
        billing_scheme: string;
        created: number;
        currency: string;
        custom_unit_amount: null;
        livemode: boolean;
        lookup_key: null;
        nickname: null;
        product: {
          description: null | string;
          metadata: null;
          name: InternationalStripeProductType;
        };
        recurring: {
          aggregate_usage: null;
          interval: string;
          interval_count: number;
          trial_period_days: null;
          usage_type: string;
        };
        tax_behavior: string;
        tiers_mode: null;
        transform_quantity: null;
        type: string;
        unit_amount: number;
        unit_amount_decimal: string;
      };
      proration: boolean;
      proration_details: {
        credited_items: null;
      };
      quantity: number;
      subscription: string;
      subscription_item: string;
      tax_amounts: string[];
      tax_rates: string[];
      type: string;
      unit_amount_excluding_tax: string;
    }[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  promoCodeErrorMessage: string;
  subtotal: number;
  tax?: number;
  total: number;
};

export enum FavouriteOptions {
  'ALL_CANDIDATES' = 'allCandidates',
  'FAV_CANDIDATES' = 'favCandidates',
}

type CommunicationSettingList = {
  name?: string;
  label: string;
  value?: string;
};

export type CommunicationSettingData = {
  title?: string;
  name?: string;
  type: 'toggle' | 'group';
  hint?: string;
  list: CommunicationSettingList[];
  id: string;
};
